import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import Corpus from "../../../../assets/img/newsimgMain/corpus.JPG";
import { Image } from "../../../common";
export const HistoricalReference = observer(() => {
  return (
    <>
      <div
        className={toggleClassName(
          "education__container",
          "education__container-white",
          "education__container-black",
          "education__container-contrast"
        )}
      >
        <h1 className="education__container-title">Историческая справка</h1>

        <div className="education__container-content">
          <p>
            <div className="messageImage-container">
              <Image src={Corpus} width="330px" />
            </div>{" "}
            <b>Уфимское речное училище</b> создано на основании приказа Министра
            речного флота РСФСР №89 от 07.08.1970 года.
          </p>
          <p>
            С 25.05.1995 года на основании постановления Главы администрации
            Ленинского района г. Уфы РБ №601 от 25.05.1995 переименовано в{" "}
            <b> Уфимское командное речное училище.</b>
          </p>
          <p>
            С 29.01.2003 на основании постановления Главы администрации
            Ленинского района г. Уфы РБ №464 от 24.03.1999 переименовано в{" "}
            <b>
              Государственное образовательное учреждение среднего
              профессионального образования «Уфимское командное речное училище».
            </b>
          </p>
          <p>
            С 26.02.2004 на основании распоряжения Министерства транспорта РФ
            №НС-11-р от 30.01.2004 переименовано в{" "}
            <b>
              Федеральное государственное образовательное учреждение среднего
              профессионального образования «Уфимское командное речное училище».
            </b>
          </p>
          <p>
            С 24.01.2006 на основании распоряжения Правительства РФ № 1550 от
            30.09.2005 о реорганизации, распоряжений Федерального агентства
            морского и речного транспорта №288-р от 10.11.2005 и №313-р от
            23.12.2005 реорганизовано в форме присоединения к ФГОУ ВПО «МГАВТ» в
            форме{" "}
            <b>
              Уфимского филиала Федерального государственного образовательного
              учреждения высшего профессионального образования «Московская
              государственная академия водного транспорта».
            </b>
          </p>
          <p>
            С 28.06.2011 в соответствии с распоряжением Федерального агентства
            морского и речного транспорта №150-р от 26.05.2011 переименовано в{" "}
            <b>
              Уфимский филиал Федерального бюджетного образовательного
              учреждения высшего профессионального образования «Московская
              государственная академия водного транспорта».
            </b>
          </p>
          <p>
            С 05.04.2015 в соответствии с распоряжением Федерального агентства
            морского и речного транспорта №АД-368-р от 22.09.2014 переименовано
            в{" "}
            <b>
              Уфимский филиал Федерального государственного бюджетного
              образовательного учреждения высшего образования «Московская
              государственная академия водного транспорта».
            </b>
          </p>
          <p>
            23.01.2017 на основании приказа Министерства транспорта РФ №218 от
            29.07.2016 о реорганизации ФГБОУ ВО "МГАВТ" и присоединении к ФГБОУ
            ВО "ГУМРФ имени адмирала С.О. Макарова" и распоряжения Федерального
            агентства морского и речного транспорта №ВО-282-р от 28.11.2016
            присоединено к ФГБОУ ВО "ГУМРФ имени адмирала С.О. Макарова" в форме{" "}
            <b>
              Уфимского филиала Федерального государственного бюджетного
              образовательного учреждения высшего образования «Государственный
              университет имени адмирала С.О. Макарова».
            </b>
          </p>
          <p>
            06.06.2018 в соответствии с распоряжением Федерального агентства
            морского и речного транспорта №ВО-148-р от 08.05.2018 года{" "}
            <b>
              Уфимский филиал Федерального государственного бюджетного
              образовательного учреждения высшего образования «Государственный
              университет имени адмирала С.О. Макарова» ликвидирован.
            </b>
          </p>
          <p>
            31.08.2017 на основании распоряжения Федерального агентства морского
            и речного транспорта №АП-193-р от 16.08.2017 создан{" "}
            <b>
              Уфимский филиал Федерального государственного бюджетного
              образовательного учреждения высшего образования «Волжский
              государственный университет водного транспорта».
            </b>
          </p>
        </div>
      </div>
    </>
  );
});
