import React, { useEffect } from "react";
import { EmployeeStore } from "../../../store/employee-store";
import { Card, Flex } from "antd";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";

export const EmployeeList = observer(() => {
  const { employee, isLoadingEmployee, loadEmployee } = EmployeeStore;
  useEffect(() => {
    loadEmployee();
  }, [loadEmployee]);

  const navigate = useNavigate();
  if (isLoadingEmployee) return "Загрузка...";
  return (
    <>
      <Flex
        wrap="wrap"
        justify="center"
        gap={"middle"}
        style={{ margin: "15px 0" }}
      >
        {employee.length === 0 &&
          "Добавьте сотрудника, чтобы настроить дни приёма"}
        {employee &&
          employee.map((e) => (
            <Card
              style={{ minWidth: "200px", cursor: "pointer" }}
              key={e.id}
              title={e.post}
              bordered={false}
              onClick={() => navigate("employee/" + e.id)}
            >
              <div>ФИО: {e.surname + " " + e.name + " " + e.lastname}</div>
              <div>Почта: {e.email}</div>
            </Card>
          ))}
      </Flex>
    </>
  );
});
