import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Flex,
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  TimePicker,
  Typography,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { TimePickerComponent } from "./time-picker-component";
import { EmployeeList } from "./employee-list";
import { HrLine } from "../../common";
import { EmployeeStore } from "../../../store/employee-store";
export const CreateMeeting = observer(() => {
  const [form] = Form.useForm();
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([
    {
      daysOfWeek: { day: "", isActive: false },
      recordingDuration: "",
      recordingTime: { start: "", end: "" },
    },
  ]);
  const optionsOfRecordingDurationTime = [
    {
      value: 5,
      label: "5 минут",
    },
    {
      value: 10,
      label: "10 минут",
    },
    {
      value: 15,
      label: "15 минут",
    },
    {
      value: 20,
      label: "20 минут",
    },
    {
      value: 25,
      label: "25 минут",
    },
    {
      value: 30,
      label: "30 минут",
    },
  ];
  const optionsOfSelectedDay = [
    { label: "Понедельник", value: 0 },
    { label: "Вторник", value: 1 },
    { label: "Среда", value: 2 },
    { label: "Четверг", value: 3 },
    { label: "Пятница", value: 4 },
    { label: "Суббота", value: 5 },
  ];
  const optionsOfHours = [
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
  ];
  const optionsOfMinutes = [
    { label: "00", value: 0 },
    { label: "05", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "30", value: 30 },
    { label: "35", value: 35 },
    { label: "40", value: 40 },
    { label: "45", value: 45 },
    { label: "50", value: 50 },
    { label: "55", value: 55 },
  ];
  function checkTimeStartStop(value) {
    /* номер карточки для проверки времени записи */
    const numberOfCard = value.field.split(".")[1];

    const startH = form.getFieldsValue().days[numberOfCard].startH;
    const startM = form.getFieldsValue().days[numberOfCard].startM;
    const endH = form.getFieldsValue().days[numberOfCard].endH;
    const endM = form.getFieldsValue().days[numberOfCard].endM;

    const startTime = startH * 60 + startM;
    const endTime = endH * 60 + endM;

    if (endTime < startTime) {
      return Promise.reject(
        new Error(
          "Время окончания записи должно быть больше времени начала записи"
        )
      );
    } else {
      return Promise.resolve();
    }
  }
  // Если какое-то поле не проходит валидацию, нельзя добавить новый день
  const [someEmtyField, setSomeEmtyField] = useState("");

  const handleAdd = async () => {
    setSomeEmtyField("");
    try {
      const values = await form.validateFields();
      if (
        Object.keys(values).length === Object.keys(form.getFieldsValue()).length
      ) {
        if (!weeks.includes(form.getFieldsValue().days.at(-1).daysOfWeek))
          setWeeks((prevState) => [
            ...prevState,
            form.getFieldsValue().days.at(-1).daysOfWeek,
          ]);
        form.setFieldsValue({
          days: [...form.getFieldValue("days"), {}], // Добавление нового дня
        });
      } else {
        throw new Error("Ошибки валидации");
      }
    } catch (errorInfo) {
      if (errorInfo.errorFields.length !== 0) {
        setSomeEmtyField("Для добавления нового дня, устраните ошибки");
        console.error("Validation failed:", errorInfo);
      }
    }
  };

  // проверяем, последняя ли карточка
  function checkIsLastCard(fields, index) {
    return fields.length - 1 === index ? false : true;
  }
  // проверяем, кратна ли продолжительность записи времени на одну запись
  function checkMultipacity(index) {
    if (index === undefined) return;
    const currentDays = form.getFieldsValue().days[index];
    const endTime = currentDays.endH * 60 + currentDays.endM;
    const startTime = currentDays.startH * 60 + currentDays.startM;
    const differnce = endTime - startTime;
    const reminder = differnce % currentDays.recordingDuration;

    if (reminder !== 0) {
      return Promise.reject(
        new Error(
          "Продолжительность записи должна быть кратна длительности одной записи"
        )
      );
    } else {
      return Promise.resolve();
    }
  }
  // проверяем, чтобы не добавить лишний день недели

  function checkWeeks(value, b) {}

  const { createEmployee, isCreateEmployeeLoading, errorOfCreateingEmployee } =
    EmployeeStore;
  function handleSubmit(value) {
    form
      .validateFields()
      .then(() => {
        createEmployee(value, form.resetFields);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <EmployeeList />

      <Flex align="center" justify="center" style={{ width: "auto" }}>
        <Form
          name="createMeeting"
          onFinish={handleSubmit}
          form={form}
          style={{
            width: 600,
          }}
          autoComplete="off"
          initialValues={{
            days: [{}],
          }}
        >
          <h2>Добавьте сотрудника</h2>
          <Form.Item
            name="post"
            rules={[
              {
                required: true,
                message: "Должность сотрудника не должна быть пустой!",
              },
              {
                pattern: /^[\u0400-\u042F]/,
                message: "Название должности должно быть с большой буквы!",
              },
            ]}
          >
            <Input placeholder="Укажите должность сотрудника" />
          </Form.Item>
          <Form.Item
            name="surname"
            rules={[
              {
                required: true,
                message: "Введите фамилию",
              },
              {
                pattern: /^[А-ЯЁ][а-яё]*$/,
                message: "Введите корректно фамилию, например Иванов",
              },
            ]}
          >
            <Input placeholder="Введите фамилию" />
          </Form.Item>{" "}
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Введите имя",
              },
              {
                pattern: /^[А-ЯЁ][а-яё]*$/,
                message: "Введите корректно имя, например Иван",
              },
            ]}
          >
            <Input placeholder="Введите имя" />
          </Form.Item>{" "}
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                message: "Введите отчество",
              },
              {
                pattern: /^[А-ЯЁ][а-яё]*$/,
                message: "Введите корректно отчество, например Иванович",
              },
            ]}
          >
            <Input placeholder="Введите отчество" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Укажите email!",
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
                message: "Проверьте правильность ввода email!",
              },
            ]}
          >
            <Input placeholder="Укажите email сотрудника" />
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            block
            style={{ marginBottom: "20px" }}
          >
            {isCreateEmployeeLoading ? "Загрузка..." : "Создать"}
          </Button>
          <Typography>{errorOfCreateingEmployee}</Typography>
        </Form>
      </Flex>
    </>
  );
});
