import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
export const DistanceLearning = observer(() => {
  return (
    <div
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <section className="education__container-content">
        <h1 className="education__container-title">
          Cистемa дистанционного обучения (СДО)
        </h1>
        <h2>О системе дистанционного обучения (СДО)</h2>
        <p>
          При реализации образовательных программ филиал использует различные
          образовательные технологии, в том числе дистанционные образовательные
          технологии, электронное обучение.
        </p>
        <p>
          Дистанционные образовательные технологии - это образовательные
          технологии, реализуемые в основном с применением
          информационно-телекоммуникационных сетей при опосредованном (на
          расстоянии) взаимодействии обучающихся и педагогических работников.
        </p>
        <p>
          Электронное обучение – это организация образовательной деятельности с
          применением содержащейся в базах данных и используемой при реализации
          образовательных программ информации и обеспечивающих ее обработку
          информационных технологий, технических средств, а также
          информационно-телекоммуникационных сетей, обеспечивающих передачу по
          линиям связи указанной информации, взаимодействие обучающихся и
          педагогических работников.
        </p>
        <p>
          Электронное обучение в филиале осуществляется на платформе
          дистанционного обучения «Парус»{" "}
          <a href="http://parus.vsuwt.ru/" target={"_blank"} rel="noreferrer">
            http://parus.vsuwt.ru/
          </a>
        </p>
      </section>
    </div>
  );
});
