import { Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { toggleFontSize } from "../../../../../../utils/disabled";
import { AddDocForm } from "../add-doc-form/add-doc-form";
import { ListOfDocs } from "../list-of-docs/list-of-docs";
import { observer } from "mobx-react-lite";
import { Context } from "../../../../../../..";

export const SpoLvl = observer(() => {
  const { token } = theme.useToken();
  const { user } = useContext(Context);
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const getItemsStudyProcess = (panelStyle) => [
    {
      key: "1",
      label: "Списки лиц, подавших заявление на поступление",
      children: (
        <>
          {user.auth && user.user.role === "ADMIN" && (
            <AddDocForm educationLvl={"middle"} category={"listOfApplicants"} />
          )}
          <ListOfDocs educationLvl={"middle"} category={"listOfApplicants"} />
        </>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "Конкурсные списки",
      children: (
        <>
          {user.auth && user.user.role === "ADMIN" && (
            <AddDocForm educationLvl={"middle"} category={"competitionLists"} />
          )}

          <ListOfDocs educationLvl={"middle"} category={"competitionLists"} />
        </>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: "Проекты приказов на зачисление",
      children: (
        <>
          {user.auth && user.user.role === "ADMIN" && (
            <AddDocForm
              educationLvl={"middle"}
              category={"enlistedOrdersProjects"}
            />
          )}

          <ListOfDocs
            educationLvl={"middle"}
            category={"enlistedOrdersProjects"}
          />
        </>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: "Приказы зачисленных",
      children: (
        <>
          {user.auth && user.user.role === "ADMIN" && (
            <AddDocForm educationLvl={"middle"} category={"enlistedOrders"} />
          )}

          <ListOfDocs educationLvl={"middle"} category={"enlistedOrders"} />
        </>
      ),
      style: panelStyle,
    },
  ];
  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
          fontSize: toggleFontSize(16),
        }}
        items={getItemsStudyProcess(panelStyle)}
      />
    </>
  );
});
