import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { observer } from "mobx-react-lite";
import { BreadCrumbs, EducationContainer } from "../../../common";
import { ChapterMenu } from "../../../ui";
import { Outlet } from "react-router";

export const AntiCorruption = observer(() => {
  const linksMenu = [
    {
      title:
        "Нормативные правовые и иные акты в сфере противодействия коррупции",
      path: "",
    },
    {
      title: "Антикоррупционная экспертиза",
      path: "anti-corruption-expertise",
    },
    {
      title: "Методические материалы",
      path: "methodological-materials",
    },

    {
      title:
        "Формы документов, связанных с противодействием коррупции, для заполнения",
      path: "document-forms",
    },

    {
      title:
        "Сведения о доходах, расходах, об имуществе и обязательствах имущественного характера",
      path: "income-information",
    },

    {
      title:
        "Комиссия по соблюдению требований к служебному поведению и урегулированию конфликта интересов (аттестационная комиссия)",
      path: "commission",
    },
    {
      title: "Обратная связь",
      path: "feedback",
    },
  ];
  return (
    <>
      <BreadCrumbs />
      <section
        className={toggleClassName(
          "education__container",
          "education__container-white",
          "education__container-black",
          "education__container-contrast"
        )}
      >
        <h1
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Противодействие коррупции
        </h1>
        <ChapterMenu title={"Навигация раздела"} linksArray={linksMenu} />
        <EducationContainer>
          {" "}
          <Outlet />
        </EducationContainer>
      </section>
    </>
  );
});
