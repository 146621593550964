import React from "react";
import { NavLink } from "react-router-dom";
import Image1 from "../../../../../assets/pdf/educationPage/15. График учебного процесса2024.pdf";
import OOP1 from "../../../../../assets/pdf/educationPage/260203/СВ 2022 гп/1. Описание образовательной программы.pdf";
import OOP2 from "../../../../../assets/pdf/educationPage/260203/СВ 2022 гп/26 02 03 Судовождение 2022 гп Среднее образование.pdf";
import UP1 from "../../../../../assets/pdf/educationPage/260203/СВ 2022 гп/2. Учебный план 26.02.03  Судовождение очное.pdf";
import UP2 from "../../../../../assets/pdf/educationPage/260203/СВ 2022 гп/2. Учебный план 26.02.03 Судовождение заочное.pdf";
import rp_praktic from "../../../../../assets/pdf/educationPage/РП Практик/РП Практик 2022 СВ.zip";
import vr from "../../../../../assets/pdf/educationPage/Ссылка на методические и иные документы СВ.rar";

export const SPOSudovojdenie_22_260203 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">26.02.03</td>
      <td>
        <NavLink itemProp="eduName" to="../../educationPrograms/navigation2022">
          Судовождение ФГОС №691 от 02.12.2020 (в ред. Приказа Минобранауки
          России от 13.07.2021 №450)
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink itemProp="eduProf" to="../../educationPrograms/navigation2022">
          Судовождение (прием 2022)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная-Заочная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP1} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP2} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP1}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (очная)
            </a>
          </li>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP2}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (заочная)
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/navigation2022"
            >
              Скачать РП
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image1}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={rp_praktic}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={vr}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
};
