import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
/* import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import Pologenie_Parus from "../../../../assets/pdf/educationPage/Pologenie_Parus.pdf";
import Pologhenie_DO from "../../../../assets/pdf/educationPage/Pologhenie_DO.pdf";
import Pologhenie_EL_Sreda from "../../../../assets/pdf/educationPage/Pologhenie_EL_Sreda.pdf";
import Pologhenie_kontrolDO from "../../../../assets/pdf/educationPage/Pologhenie_kontrolDO.pdf";
import FOS_IA_SV_uglubl from "../../../../assets/pdf/educationPage/260203/FOS_IA_SV_uglubl.pdf";
import Programma_IA_SV_uglubl from "../../../../assets/pdf/educationPage/260203/Programma_IA_SV_uglubl.pdf";
import KPV from "../../../../assets/pdf/educationPage/KPV.pdf";

import { NavLink } from "react-router-dom";
import adOOP1 from "../../../../assets/pdf/educationPage/260203/oop/adOOP_260203.pdf";
import UP19 from "../../../../assets/pdf/educationPage/260203/syllabus/adUP_260203.pdf";
import adAnnot from "../../../../assets/pdf/educationPage/260203/annot/annot_SV_AD.pdf";
import RP_260203 from "../../../../assets/pdf/educationPage/260203/RP_Uch_prakt_2018ugl_3-10.pdf";
import FOS_UCH_PR_260203 from "../../../../assets/pdf/educationPage/260203/FOS_Uch_prakt_2018ugl_3-10.pdf";
import RP_UCH_PR_260203 from "../../../../assets/pdf/educationPage/260203/RP_pr_prakt_2018ugl_3-10.pdf";
import FOS_PR_PR_260203 from "../../../../assets/pdf/educationPage/260203/FOS_pr_prakt_2018ugl_3-10.pdf";
import RP_PR_PR_260203 from "../../../../assets/pdf/educationPage/260203/RP_Pr_prakt_2018ugl_ZO_3-10.pdf";
import RP_UCH_PR_Z_260203 from "../../../../assets/pdf/educationPage/260203/RP_Uch_prakt_2018ugl_ZO_3-10.pdf";
import adkug_260203 from "../../../../assets/pdf/educationPage/260203/adkug_260203.pdf";
import Pologenie_ucheb_prozess_ovz_2017 from "../../../../assets/pdf/educationPage/260203/Pologenie_ucheb_prozess_ovz_2017.pdf";
import Pologen_Fizra from "../../../../assets/pdf/educationPage/260203/Pologen_Fizra.pdf"; */
import { InfoORealisUrovnyahObrazovaniya } from "./info-o-realis-urovnyah-obrazovaniya";
import { InfoOChislenObuch } from "./info-o-chislen-obuch";
import { InfoOResultPriema } from "./info-o-result-priema";
import { InfoOResultatahPerevoda } from "./info-o-resultatah-perevoda";
import { NeadaptirovannieProgrammi } from "./neadaptirovannie-programmi";
import { TableWrapper } from "../../../common";
import { InfoOTrudoustr } from "./info-o-trudoustr";

export const Education = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Образование
      </h1>
      <InfoORealisUrovnyahObrazovaniya />
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о профессионально-общественной аккредитации образовательной
        программы (при наличии) - образовательные программы, реализуемые в
        образовательном учреждении, не имеют профессионально-общественной
        аккредитации.
      </h3>
      <div className="common__container scroll-table struct__scrollTable">
        <table className="">
          <tbody>
            <tr itemProp="eduPOAccred">
              <td itemProp="eduCode">Код</td>
              <td itemProp="eduName">
                Наименование профессии, специальности, направления подготовки,
                научной специальности
              </td>
              <td itemProp="eduLvl">Уровень образования</td>
              <td itemProp="orgName">
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td itemProp="">Наименование аккредитующей организации</td>
              <td itemProp="dateEnd">
                Срок действия профессионально-общественной аккредитации (дата
                окончания действия свидетельства о профессионально-общественной
                аккредитации)
              </td>
            </tr>
            <tr itemProp="eduPOAccred">
              <td itemProp="eduCode">Отсутствует</td>
              <td itemProp="eduName">Отсутствует</td>
              <td itemProp="eduLvl">Отсутствует</td>
              <td itemProp="orgName">Отсутствует</td>
              <td itemProp="">Отсутствует</td>
              <td itemProp="dateEnd">Отсутствует</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация об общественной аккредитации образовательной организации -
        образовательные программы, реализуемые в образовательном учреждении, не
        имеют общественной аккредитации.
      </h3>
      <div className="common__container scroll-table struct__scrollTable">
        <table className="">
          <tbody>
            <tr itemProp="eduPOAccred">
              <td itemProp="eduCode">Код</td>
              <td itemProp="eduName">
                Наименование профессии, специальности, направления подготовки,
                научной специальности
              </td>
              <td itemProp="eduLvl">Уровень образования</td>
              <td itemProp="orgName">
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td itemProp="">Наименование аккредитующей организации</td>
              <td itemProp="dateEnd">
                Срок действия общественной аккредитации (дата окончания действия
                свидетельства о профессионально-общественной аккредитации)
              </td>
            </tr>
            <tr itemProp="eduPOAccred">
              <td itemProp="eduCode">Отсутствует</td>
              <td itemProp="eduName">Отсутствует</td>
              <td itemProp="eduLvl">Отсутствует</td>
              <td itemProp="orgName">Отсутствует</td>
              <td itemProp="">Отсутствует</td>
              <td itemProp="dateEnd">Отсутствует</td>
            </tr>
          </tbody>
        </table>
      </div>{" "}
      <InfoOChislenObuch />
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        По состоянию контингента обучающихся Уфимского филиала на 01.09.2024
        иностранные студенты отсутствуют
      </h3>
      <InfoOResultPriema />
      <InfoOResultatahPerevoda />
      <NeadaptirovannieProgrammi />
      <TableWrapper title="Информация по адаптированным образовательным программам:">
        {" "}
        <table>
          <tbody>
            <tr itemProp="eduAdOp">
              <td itemProp="eduCode">Код, шифр</td>
              <td itemProp="eduName">
                Наименование профессии, специальности, направления подготовки,
                наименование группы научных специальностей
              </td>
              <td itemProp="eduLevel">Уровень образования</td>
              <td itemProp="eduProf">
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td itemProp="eduForm">Реализуемые формы обучения</td>
              <td itemProp="opMain">
                Ссылка на описание образовательной программы с приложением ее
                копии
              </td>
              <td itemProp="educationPlan">Ссылка на учебный план</td>
              <td itemProp="educationAnnotation">
                Ссылки на рабочие программы (по каждой дисциплине в составе
                образовательной программы)
              </td>
              <td itemProp="educationShedule">
                Ссылка на календарный учебный график
              </td>
              <td itemProp="educationRpd">
                Ссылка на рабочие программы практик, предусмотренных
                соответствующей образовательной программой
              </td>

              <td itemProp="methodology">
                Ссылка на методические и иные документы, разработанные ОО для
                обеспечения образовательного процесса, а также рабочие программы
                воспитания и календарные планы воспитательной работы, включаемых
                в ООП
              </td>
            </tr>
            <tr itemProp="eduAdOp">
              <td itemProp="eduCode">Не реализуется</td>
              <td itemProp="eduName">Не реализуется</td>
              <td itemProp="eduLevel">Не реализуется</td>
              <td itemProp="eduProf">Не реализуется</td>
              <td itemProp="eduForm">Не реализуется</td>
              <td itemProp="opMain">Не реализуется</td>
              <td itemProp="educationPlan">Не реализуется</td>
              <td itemProp="educationAnnotation">Не реализуется</td>
              <td itemProp="educationShedule">Не реализуется</td>
              <td>Не реализуется</td>

              <td itemProp="methodology">Не реализуется</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <TableWrapper title="Информация о направлениях и результатах научной (научно-исследовательской) деятельности и научно-исследовательской базе для ее осуществления (для образовательных организаций высшего образования и организаций дополнительного профессионального образования)">
        <table>
          <tbody>
            <tr>
              <td>Код, шифр</td>
              <td>
                Наименование специальности, направления подготовки, наименование
                группы научных специальностей
              </td>
              <td>
                Перечень научных направлений, в рамках которых ведется научная
                (научно-исследовательская) деятельность
              </td>
              <td>
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td>Уровень образования</td>
              <td>Название научного направления/научной школы</td>
              <td>
                Результаты научной (научно-исследовательской) деятельности
              </td>
              <td>
                Сведения о научно-исследовательской базе для осуществления
                научной (научно-исследовательской) деятельности
              </td>
            </tr>
            <tr itemProp="eduNir">
              <td itemProp="eduCode">26.05.06</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="perechenNir" rowSpan="5">
                Инновационные технологии в преподавании дисциплин;
                Компетентностный подход в системе СПО
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel">Высшее образование, специалитет</td>
              <td itemProp="napravNir">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="resultNir" rowSpan="5">
                Публикация статей в сборник ежегодной НПК преподавателей филиала
                «Инновационные технологии в преподавании дисциплин»; Участие в
                работе НПК, в том числе международного уровня (свыше 20
                публикаций); Защита кандидатской диссертации, публикация
                монографии; Участие в работе РУМО УГС 26.00.00; РУМО
                преподавателей БЖД и ОБЖ; Руководство исследовательской
                деятельностью курсантов – участников предметных и
                профессиональных олимпиад, конкурсов проф.мастерства
              </td>
              <td itemProp="baseNir" rowSpan="5">
                Научно-образовательный портал Znanium (new.znanium.com)
                Электронно-библиотечная система "Лань" Электронная библиотечная
                система «Юрайт» biblio-online.ru Система дистанционного обучения
                «Парус» Комплект лицензионного программного обеспечения: •
                Операционные системы Windows XP, Windows 10, Windows 7 •
                Прикладные пакеты MS Office 2007; MS Office 2013 • Прикладные
                пакеты 1c:8.0, 1C:8.2, 1C:7.7; • Прикладные пакеты Finereader 7;
                • Прикладные пакеты Консультант плюс; • Прикладные пакеты
                WinRar; • Средства проектирования и разработки платформа 1С •
                Конфигурация 1С: АСУ Учебный процесс Компьютерная служба
                Компьютерный сервер Система видеоконференцсвязи Центр
                коллективного пользования информационным и коммуникационным
                оборудованием (компьютерные классы) Локальная сеть филиала
                Научно-образовательный центр (библиотека) Кабинет основ
                исследовательской деятельности Аудитория для проведения круглых
                столов, научных семинаров (музей им.В. Альбанова, каб.20)
              </td>
            </tr>
            <tr itemProp="eduNir">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">Судовождение</td>
              <td itemProp="eduProf">Судовождение</td>
              <td itemProp="eduLevel" rowSpan="4">
                среднее профессиональное образование
              </td>
              <td itemProp="napravNir">Судовождение</td>
            </tr>
            <tr itemProp="eduNir">
              <td itemProp="eduCode">26.02.05</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="napravNir">
                Эксплуатация судовых энергетических установок
              </td>
            </tr>
            <tr itemProp="eduNir">
              <td itemProp="eduCode">26.02.06</td>
              <td itemProp="eduName">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduProf">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="napravNir">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
            </tr>
            <tr itemProp="eduNir">
              <td itemProp="eduCode">26.02.01</td>
              <td itemProp="eduName">
                Организация перевозок и управление на транспорте (по видам)
              </td>
              <td itemProp="eduProf">
                Организация перевозок и управление на транспорте (по видам)
              </td>
              <td itemProp="napravNir">
                Организация перевозок и управление на транспорте (по видам)
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <InfoOTrudoustr />
      {/* <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        4. Информация о численности обучающихся, являющихся иностранными
        гражданами: 1 обучающийся
      </h3>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        5. Языки, на которых осуществляется образование (обучение): Русский
      </h3> */}
      {/* <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Реализуемые образовательные программы (учебные предметы, курсы,
        дисциплины (модули), практики), в том числе адаптированные
      </h3>
      <SpisokObsheobrazovatelnDisciplin /> */}
    </div>
  );
});
