import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { EducationContainer } from "../../../common";
import { observer } from "mobx-react-lite";
import poloj from "../../../../assets/pdf/learning/6.2.3.48.04.02.2024. ПЛЖ о ДОТ и ЭО СПО и ВО.pdf";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
export const DistanceEducation = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Дистанционное обучение в том числе для инвалидов и лиц с ОВЗ
      </h1>

      <EducationContainer>
        <p>
          Уфимский филиал имеет возможности реализации образовательных программ
          или их частей с применением дистанционных образовательных технологий в
          предусмотренных Федеральным законом от 29 декабря 2012 г. №273-ФЗ «Об
          образовании в Российской Федерации» формах обучения или при их
          сочетании в том числе для обучающихся из числа инвалидов и лиц с ОВЗ.{" "}
        </p>
        <p>
          Порядок применения регламентируется внутренним локальным актом
          «Положение о реализации образовательной деятельности с применением
          электронного обучения и дистанционных образовательных технологий».
        </p>
        <p>
          <PDF width="25px" height="25px" />{" "}
          <a
            href={poloj}
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Положение о реализации образовательной деятельности с применением
            электронного обучения и и дистанционных образовательных технологий
          </a>{" "}
        </p>
        <p>
          Под дистанционными образовательными технологиями понимаются
          образовательные технологии, реализуемые, в основном, с применением
          информационно-телекоммуникационных сетей при опосредованном (на
          расстоянии) взаимодействии обучающихся и работников из числе
          профессорско-преподавательского состава (иных педагогических
          работников).{" "}
        </p>
        <p>
          <a
            href="https://parus.vsuwt.ru/"
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Система дистанционного обучения (СДО) «Парус»
          </a>{" "}
        </p>
      </EducationContainer>
    </section>
  );
});
