import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import otv_ovz from "../../../../assets/pdf/ovzPage/otv_ovz.pdf";
import OVZ from "../../../../assets/pdf/ovzPage/OVZ.pdf";
import Usloviya from "../../../../assets/doc/sveden/УСЛОВИЯ ОХРАНЫ ЗДОРОВЬЯ ОБУЧАЮЩИХСЯ.docx";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { ReactComponent as DOC } from "../../../../assets/svg/office/word.svg";
import { observer } from "mobx-react-lite";
import { TableWrapper } from "../../../common";
export const Ovz = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Доступная среда
      </h1>
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a href={otv_ovz} target={"_blank"} rel="noreferrer">
            Приказ о назначении ответственного лица за обеспечение доступности
            для инвалидов{" "}
          </a>
        </li>
      </ul>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о специально оборудованных учебных кабинетах, приспособленных
        объектах для проведения практических занятий
      </h3>
      <p>
        В филиале оборудован 21 кабинет видеопроекторами, в 5 кабинетах
        установлены интерактивные доски, имеются компьютерные классы,
        оборудованные звуковыми колонками и наушниками.
      </p>
      <p>
        На первом этаже корпусов оборудовано по 1 аудитории, приспособленной для
        использования инвалидами и лицами с ограниченными возможностями
        здоровья.
      </p>
      <p>
        Для проведения практических занятий оборудована 1 лаборатория,
        приспособленная для использования инвалидами и лицами с ограниченными
        возможностями здоровья.
      </p>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о наличии специально оборудованных учебных кабинетов
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Адрес </td>
              <td>Наименование специально оборудованных учебных кабинетов</td>
              <td>Оснащенность специально оборудованных учебных кабинетов</td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">
                Кабинет Технологии технического обслуживания и теории
                электрических машин{" "}
              </td>
              <td itemProp="osnCab">
                ученические столы, стулья, наглядные пособия и макеты{" "}
              </td>
              <td itemProp="ovzCab">Да</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="3">
                г. Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">
                Кабинет Судовых вспомогательных механизмов и систем
              </td>
              <td itemProp="osnCab" rowSpan="3">
                Макеты: кренометр, радиолокатор, магнитный компас, тумба
                штурвала с аксиометром, пульт управления ДВС левым и правым, щит
                приборов ДВС, импульсные отмашки, прибор ночного видения;
                штурманский стол, видеопроектор, компьютер, учебная доска,
                ученические столы и стулья, преподавательский стол, наглядные
                пособия: стенды, презентации.{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="3">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td>Кабинет Теории и устройства судна</td>
            </tr>
            <tr itemProp="purposeCab">
              <td>Кабинет Технологии судоремонта</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">
                Музей филиала. Кабинет гуманитарных дисциплин имени штурмана
                В.Альбанова{" "}
              </td>
              <td itemProp="osnCab">
                Компьютер, видеопроектор , учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия : презентации.{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">
                Кабинет Социально-экономических дисциплин
              </td>
              <td itemProp="osnCab">
                Компьютер, телевизор , учебная доска, ученические столы и
                стулья, преподавательский стол
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">
                Кабинет Безопасности жизнедеятельности и охраны труда{" "}
              </td>
              <td itemProp="osnCab" rowSpan="2">
                Радиодозиметры, химические приборы, комплекты ОЗК, противогаз
                ГП-7, противогазы ГП-5, пневматические винтовки 6 шт, мишенное
                поле, мишень № 6, сейф, лазерный тренажер-автомат Калашникова,
                видеофильмы 50 штук, компьютер, видеопроектор , учебная доска,
                ученические столы и стулья, преподавательский стол, наглядные
                пособия: стенды, экран{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">Стрелковый тир</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="3">
                г.Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">Кабинет Механики </td>
              <td itemProp="osnCab" rowSpan="3">
                Модели зубчатой передачи, червячной передачи, Модель для
                демонстрации видов деформаций, Модель эвольвентного профиля
                зуба, микроскоп, Модель для демонстрации действия винта.
                штангенинструменты, микрометры, набор концевых мер длины,
                калибры, индикаторные головки, компьютер, видеопроектор,
                интерактивная доска, учебная доска, ученические столы и стулья,
                преподавательский стол, наглядные пособия и плакаты{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="3">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Метрологии, стандартизации и сертификации
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">Кабинет Метрологии и стандартизации</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г.Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">
                Кабинет Литературы и русского языка имени С.Т. Аксакова
              </td>
              <td itemProp="osnCab">
                Компьютер, телевизор, видеомагнитофон, учебная доска,
                ученические столы и стулья, преподавательский стол, наглядные
                пособия: стенды, презентации и плакаты{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г.Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Иностранного языка</td>
              <td itemProp="osnCab">
                Компьютер, программа "Англомар", электронный учебник по
                английскому языку , учебная доска, ученические столы и стулья,
                преподавательский стол, наглядные пособия и плакаты.{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г.Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Иностранного языка</td>
              <td itemProp="osnCab">
                Компьютер, программа "Англомар", электронный учебник по
                английскому языку , учебная доска, ученические столы и стулья,
                преподавательский стол, наглядные пособия и плакаты.{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г.Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">Кабинет Физики</td>
              <td itemProp="osnCab" rowSpan="2">
                Лабораторное оборудование: прибор для демонст. длины световой
                волны, набор из линз и стекла, штативы, измерительные приборы ,
                компьютер, видеопроектор, интерактивное устройство,
                интерактивный учебник по физике, учебная доска, ученические
                столы и стулья, преподавательский стол, наглядные пособия:
                стенды, презентации{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет технической термодинамики и теплопередачи
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г.Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Математики</td>
              <td itemProp="osnCab">
                Математический набор, калькуляторы, наглядные пособия,
                компьютер, интерактивная доска, видеопроектор, учебная доска,
                ученические столы и стулья, преподавательский стол, наглядные
                пособия: стенды, презентации{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г.Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">Кабинет Информатики</td>
              <td itemProp="osnCab" rowSpan="2">
                Компьютер, видеопроектор, экран, учебная доска, ученические
                столы, преподавательский стол и стулья, наглядные пособия:
                стенды, презентации{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Информатики и информационных систем
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">Кабинет Инженерной графики</td>
              <td itemProp="osnCab">
                Набор чертёжных инструментов для работы у доски,
                демонстрационные модели, наглядные пособия: стенды, презентации,
                компьютер, видеопроектор, экран, компьютерная программа
                "Компас-график", учебная доска, ученические столы и стулья,
                преподавательский стол{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Иностранного языка </td>
              <td itemProp="osnCab">
                Компьютер, наглядные пособия, учебная доска, ученические столы и
                стулья, преподавательский стол{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Иностранного языка </td>
              <td itemProp="osnCab">
                Компьютер, магнитофон, программа "Англомар", электронный учебник
                по английскому языку , учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия и плакаты{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Иностранного языка </td>
              <td itemProp="osnCab">
                Компьютер, видеопроектор, интерактивная доска, программа
                "Англомар", электронный учебник по английскому языку , учебная
                доска, ученические столы и стулья, преподавательский стол,
                наглядные пособия и плакаты{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">Кабинет Технологии перевозок грузов </td>
              <td itemProp="osnCab" rowSpan="2">
                Интерактивная доска SMART, компьютер, видеопроектор,
                калькуляторы, модель судна, учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия: стенды,
                презентации, фильмы, плакаты{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">Кабинет Транспортной системы России</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">Кабинет Управления судном </td>
              <td itemProp="osnCab">
                Макеты: магнитный компас, судно, компьютер, видеопроектор,
                экран, учебная доска, ученические столы и стулья,
                преподавательский стол{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">
                Кабинет Навигации и лоции. Им. Ф.Ф. Беллинсгаузена{" "}
              </td>
              <td itemProp="osnCab" rowSpan="2">
                Навигационный тренажер: класс штурманской прокладки тренажер NPS
                12 мест. Магнитный компас «МК- 127» , МК-145, МК-75, «КМ-100М2»,
                «КМ-100М3», барометр, анемометр, гигрометр, модели судов,
                штурманский прокладочный инструмент, морские карты, секстант,
                звездный глобус, 12 компьютеров, видеопроектор, экран, принтер,
                учебная доска, штурманские столы, ученические столы и стулья,
                преподавательский стол, наглядные пособия: стенды, презентации.{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">Навигационный тренажер</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">Кабинет Химии и биологии</td>
              <td itemProp="osnCab">
                Микроскопы, периодическая таблица Менделеева, видеофильмы,
                колбонагреватель, прибор для электролиза, колонки адсорбционные,
                прибор для окисления спиртов, баня комбинированная, весы,
                разновесы, мультимедиа учебный курс «1С: Органическая химия» и
                электронное издание «Виртуальная химическая лаборатория»,
                компьютер, видеопроектор, учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия: стенды{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="nameCab">
                Кабинет Судовых электроприводов. Электрических машин.
                Технического обслуживания{" "}
              </td>
              <td itemProp="osnCab">
                Компьютер, видеопроектор, экран, преподавательский, ученические
                столы, стулья, наглядные пособия: стенды, презентации,
                видеофильмы.{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="nameCab">
                Кабинет Экологических основ природопользования
              </td>
              <td itemProp="osnCab" rowSpan="2">
                Географические и политические карты, глобус, компьютер,
                видеопроектор, учебная доска, ученические столы и стулья,
                преподавательский стол{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Технические средства (по видам транспорта)
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">
                Кабинет Технологии технического обслуживания и теории
                электрических машин{" "}
              </td>
              <td itemProp="osnCab">
                Компьютер, ученические столы, стулья, наглядные пособия и макеты{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">
                Кабинет Социально-экономических дисциплин
              </td>
              <td itemProp="osnCab">
                Ученические столы и стулья, наглядные пособия: стенды
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="nameCab">
                Кабинет Безопасности жизнедеятельности на судне
              </td>
              <td itemProp="osnCab" rowSpan="2">
                Компьютер, ученические столы и стулья, наглядные пособия: стенды{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">Кабинет Безопасности движения</td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab" rowSpan="5">
                г. Уфа, 450017, ул. Ахметова, 275{" "}
              </td>
              <td itemProp="nameCab">
                Кабинет Основ анализа эффективности работы судна{" "}
              </td>
              <td itemProp="osnCab" rowSpan="5">
                Компьютер, ученические столы и стулья, преподавательский стол и
                стул, учебная доска, наглядные пособия{" "}
              </td>
              <td itemProp="ovzCab" rowSpan="5">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Организация перевозочного процесса (по видам транспорта)
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Организация сервисного обслуживания на транспорте (по
                видам транспорта)
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Организация транспортно-логистической деятельности (по
                видам транспорта)
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="nameCab">
                Кабинет Управления качеством и персоналом
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">Кабинет дополнительного образования</td>
              <td itemProp="osnCab">
                Компьютеры 10 шт, видеопроектор, ученические и преподавательский
                столы и стулья, доска{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposeCab">
              <td itemProp="addressCab">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="nameCab">
                Кабинет Управления судами и составами и эксплуатации судовых
                энергетических установок{" "}
              </td>
              <td itemProp="osnCab">
                Ученические и преподавательский столы и стулья, доска, наглядные
                пособия{" "}
              </td>
              <td itemProp="ovzCab">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о приспособленных объектах для проведения практических занятий
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Адрес </td>
              <td>
                Наименование приспособленных объектов для проведения
                практических занятий{" "}
              </td>
              <td>
                Оснащенность приспособленных объектов для проведения
                практических занятий{" "}
              </td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">Стрелковый тир</td>
              <td itemProp="osnPrac">
                Пневматические винтовки 6 шт, мишенное поле, мишень № 6, сейф,
                лазерный тренажер-автомат Калашникова, видеофильмы 50 штук,
                компьютер, видеопроектор, учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия: стенды,
                экран.
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">Лаборатория Информатики</td>
              <td itemProp="osnPrac">
                Наглядные пособия, видеопроектор, компьютер, ученические и
                преподавательский столы и стулья, доска.{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">Лаборатория Информатики</td>
              <td itemProp="osnPrac" rowSpan="2">
                Лицензионное программное обеспечение: офисные программы MS
                Office Word, MS Office Excel, MS Office Access, MS Office
                PowerPoint , 13 компьютеров, учебная доска, компьютерные столы и
                стулья, преподавательский стол{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Лаборатория Автоматизированных систем управления
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">
                Лаборатория Радионавигационных и электрорадионавигационных
                приборов и систем технических средств судовождения
              </td>
              <td itemProp="osnPrac">
                Магнитный компас «МК- 127», основной прибор ГК «Амур», РЛС
                «Донец - 2», радиопеленгатор «СРП-5», РЛС Р-722, эхолоты Кубань
                и НЭЛ-5, Лаг МГЛ-25 и ИЭЛ-2М, авторулевой Печора, модели судов,
                компьютер, телевизор, видеопроектор, учебная доска, ученические
                столы и стулья, преподавательский стол, наглядные пособия:
                стенды, видеофильмы.
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="3">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">
                Лаборатория Судовых энергетических установок.{" "}
              </td>
              <td itemProp="osnPrac" rowSpan="3">
                Тренажер судовой энергетической установки : UNITEST - тренажер,
                макет двигателя 3Д6 в разрезе, детали цилиндро- поршневой
                группы, топливный насос, фильтры, наборы ключей, компьютер,
                видеопроектор, преподавательский, ученические столы и стулья,
                наглядные пособия: стенды, плакаты, видеофильмы, презентации.{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="3">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Тренажер судовой энергетической установки
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Лаборатория Энергетического оборудования, механизмов и систем
                судна
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">Лаборатория Судовых электроприводов </td>
              <td itemProp="osnPrac" rowSpan="2">
                Стенд лабораторный «Исследование двигателей пост. тока с
                независимым возбуждением и послед. возбуждением», стенд
                лабораторный «ИАДК - К - АД с к.з. ротором», компьютеры,
                преподавательский, ученические столы и стулья, плакаты
              </td>
              <td itemProp="ovzPrac" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория Электрических машин.</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="6">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">
                Лаборатория Судовых электроэнергетических систем
              </td>
              <td itemProp="osnPrac" rowSpan="6">
                Лабораторные столы "Промэлектроника", макеты электродвигателей,
                электрооборудования, лабораторные стенды: Пуск ассинхронного
                реверсивного двигателя, Схема управления освещением,
                Светоимпульсные отмашки, Коммутатор отличительных огней, Судовые
                сигнальные огни, тестер, инструменты, преподавательский,
                ученические столы и стулья.{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="6">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория Электронной техники</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Лаборатория Электрических систем автоматики и контроля судовых
                технических средств
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Лаборатория судового электрооборудования и электронной
                аппаратуры
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория электрооборудования судов</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Электромонтажная мастерская</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="3">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">
                Лаборатория Электроники и электротехники
              </td>
              <td itemProp="osnPrac" rowSpan="3">
                Компьютер, лабораторные столы «Уралочка», макеты
                электрооборудования, учебная доска, ученические столы и стулья,
                преподавательский стол, наглядные пособия и плакаты.{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="3">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Лаборатория Электротехники и электроники
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория Электротехники</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">Кабинет Материаловедения</td>
              <td itemProp="osnPrac" rowSpan="2">
                Компьютер, видеопроектор, ученические и преподавательский столы
                и стулья, наглядные пособия: стенды, микроскоп, образцы
                металлов.{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория Материаловедения</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">
                Тренажерный класс "РЛС и САРП" имени Петра I{" "}
              </td>
              <td itemProp="osnPrac" rowSpan="2">
                Навигационный тренажер: (NT PRO 4000), версия 4.Х.Х., 2 места,
                "АИС 1.2", телевизор, наглядные пособия: стенды, видеофильмы,
                видеоплакаты, компьютеры 8 шт.{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">Лаборатория Управления движением</td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac" rowSpan="2">
                г. Уфа, 450017, ул. Ахметова, 275
              </td>
              <td itemProp="namePrac">
                Лаборатория судового радиооборудования{" "}
              </td>
              <td itemProp="osnPrac" rowSpan="2">
                Тренажер "Глобальной морской системы связи при бедствии" : пост
                аппаратуры ГМССБ, макеты АРБ, макет РЛО, макет переносной
                станции УКВ, «(ГМССБ)», учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия, 3 компьютера{" "}
              </td>
              <td itemProp="ovzPrac" rowSpan="2">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="namePrac">
                Тренажерный класс "Глобальной морской системы связи при
                бедствии" имени адмирала С.О.Макарова.
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">Навигационный тренажер</td>
              <td itemProp="osnPrac">
                Навигационный тренажер: класс штурманской прокладки тренажер NPS
                12 мест. Магнитный компас «МК- 127», МК-145, МК-75, «КМ-100М2»,
                «КМ-100М3», барометр, анемометр, гигрометр, модели судов,
                штурманский прокладочный инструмент, морские карты, секстант,
                звездный глобус, 12 компьютеров, видеопроектор, экран, принтер,
                учебная доска, штурманские столы, ученические столы и стулья,
                преподавательский стол, наглядные пособия: стенды, презентации.{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">Тренажер машинного отделения</td>
              <td itemProp="osnPrac">
                Компьютер, видеопроектор, экран, преподавательский, ученические
                столы, стулья, наглядные пособия: стенды, презентации,
                видеофильмы. Оборудование, Тренажер машинного отделения{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">
                Лаборатория Информатики. Читальный зал
              </td>
              <td itemProp="osnPrac">
                Калькуляторы, наглядные пособия, кассовые аппараты, 12
                компьютеров, программное обеспечение: «1С: Бухгалтерия», СПС
                «Гарант», «Пом. Бухгалтера», офисные программы MS Office Word,
                MS Office Excel, MS Office Access, MS Office PowerPoint , СПС
                «Консультант Плюс» ЭБС, электронный каталог, Эл. база УМК
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">
                Тренажерный класс "Способы личной выживаемости"
              </td>
              <td itemProp="osnPrac">
                Жилет спасательный, гидротермокостюм, макет снабжения
                спасательной шлюпки, буй светодымящий, спасательный жилет
                надувной, спасательный круг, теплозащитное средство, средство
                хранения ПСН, огонь поиска, гидростат для ПСН, линемет,
                компьютер, видеопроектор, учебная доска, ученические столы и
                стулья, преподавательский стол, наглядные пособия: стенды,
                видеофильмы, видеоплакаты{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275</td>
              <td itemProp="namePrac">
                Тренажерный класс "Охрана судов и портовых средств"
              </td>
              <td itemProp="osnPrac">
                Тренажерные стенды: "Тревожная кнопка", "Видеонаблюдение",
                "Пожарная сигнализация" электронный учебник "Охрана судна" 15
                мест, телевизор{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Ахметова, 275 </td>
              <td itemProp="namePrac">
                Тренажерный класс "Противопожарной безопасности и борьбы с
                пожаром"{" "}
              </td>
              <td itemProp="osnPrac">
                Телевизор, дыхательные аппараты, газодымозащитный комплект,
                самоспасатели, снаряжение пожарного, теплоотражающий костюм
                пожарного, противопожарное снаряжение, стенд настенный с
                извещателями и оповещателями, огнетушители ОУ-5 и ОПУ-5, учебная
                доска, ученические столы и стулья, преподавательский стол,
                наглядные пособия: стенды. МПК "Противопожарная подготовка" 3
                места
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Дымовой лабиринт</td>
              <td itemProp="osnPrac">
                Дымогенератор, трап-люк, видеокамеры, 5 пожарных костюмов, 3
                дыхательных аппарата{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Отсек по заделке пробоин</td>
              <td itemProp="osnPrac">
                Отсек по борьбе с водой, пульт инструктора, трап, аварийный пост{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Имитация машинного отделения. Отсек заполнения пеной
              </td>
              <td itemProp="osnPrac">
                Макеты электродвигателей, макет двигателя, макет помпы, макет
                электрощита, макет ГРЩ, макет подъема в вертолет, видеокамеры,
                пенный отсек{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Имитация кубрика и камбуза</td>
              <td itemProp="osnPrac">
                Шкаф, кровать стол с телевизором, плита, умывальник,
                холодильник, огнетушители{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Малярная мастерская</td>
              <td itemProp="osnPrac">
                малярное оборудование, кисти, валики, распылители
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Тренажерный класс "Подготовка специалистов по спасательным
                шлюпкам и плотам", "Оказание первой медицинской помощи"
              </td>
              <td itemProp="osnPrac">
                Пиротехника, спасательный жилет, учебный пластырь, спасательный
                плот, контейнер спасательного плота, снабжение спасательного
                плота, образцы аварийно-спасательного имущества,
                гидротермокостюм, стенды методов заделки пробоин, спасательный
                круг, наглядные пособия манекен "Алекс", носилки полевые и
                корабельные продольно-складочные, манекен-тренажер "Максим" с
                комплектом имитации ранений, манекен "Степа" для отработки
                выноса пострадавшего, макет скелета человека.
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Тренажерный класс "Танкерная подготовка"
              </td>
              <td itemProp="osnPrac">наглядные пособия </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Спортивный зал</td>
              <td itemProp="osnPrac">
                Волейбольная сетка, баскетбольные щиты, гимнастическая стенка,
                спортивные снаряды, мячи, лыжи, комната для переодевания{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Зал атлетической гимнастики</td>
              <td itemProp="osnPrac">
                Тренажеры – 17 шт. Штанги – 1 шт. – 80-90 кг, 1 шт. – 90-100 кг.
                Гири – 16 кг – 2 шт., 24 кг – 4 шт., 32 кг – 4 шт. Скамейка – 1
                шт. Перекладина – 1 шт. Шведская стенка – 1 шт. Зеркало – 12 шт.
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Зал спортивных единоборств</td>
              <td itemProp="osnPrac">Маты, турники, спортивные груши, гири</td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">Хоккейная коробка</td>
              <td itemProp="osnPrac">Ворота металлические – 2 шт</td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Крытая спортивная площадка стадиона широкого профиля с
                элементами полосы препятствий{" "}
              </td>
              <td itemProp="osnPrac">
                Cпортивная площадка, мячи, эстафетная палочка, скакалка
                гимнастическая, секундомер.{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Открытый стадион широкого профиля с полосой препятствий
              </td>
              <td itemProp="osnPrac">
                Мячи, эстафетная палочка, скакалка гимнастическая, обручи
                гимнастические, секундомер, место для прыжков, полоса
                препятствий, волейбольная и баскетбольная площадка, футбольное
                поле, гимнастический городок{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
            <tr itemProp="purposePrac">
              <td itemProp="addressPrac">г. Уфа, 450017, ул. Союзная</td>
              <td itemProp="namePrac">
                Лыжная трасса (освещенная) и велосипедная дорожка
              </td>
              <td itemProp="osnPrac">
                Освещенная асфальтовая лыжная трасса совмещенная с велосипедной
                дорожкой{" "}
              </td>
              <td itemProp="ovzPrac">
                Обеспечена возможность беспрепятственного доступа для
                слабослышащих, а также для лиц с инвалидностью по общим
                заболеваниям
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о приспособленных библиотеках
      </h3>
      <p>В филиале имеется библиотека с двумя читальными залами.</p>
      <p>
        В читальном зале учебного корпуса библиотеки 3 места приспособлено для
        использования инвалидами и лицами с ограниченными возможностями
        здоровья.
      </p>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Вид помещения </td>
              <td>Адрес места нахождения </td>
              <td>Площадь, м2 </td>
              <td>Количество мест </td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="purposeLibr">
              <td itemProp="objName">Библиотека</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">238,4</td>
              <td itemProp="objCnt">64</td>
              <td itemProp="objOvz">Да</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о специальных объектах спорта
      </h3>
      <p>
        Филиал имеет 3 спортивных зала, хоккейную коробку, стрелковый тир
        (электронный), стадион с полосой препятствий, волейбольной,
        баскетбольной площадкой, футбольным полем, велосипедной дорожкой и
        освещенной лыжной трассой. Стадион может использоваться лицами с
        ограниченными возможностями здоровья.
      </p>

      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Вид помещения </td>
              <td>Адрес места нахождения </td>
              <td>Площадь, м2 </td>
              <td>Количество мест </td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="purposeSport">
              <td itemProp="objName">
                Открытый стадион широкого профиля с элементами полосы
                препятствий
              </td>
              <td itemProp="objAddress">г. Уфа ул. Союзная</td>
              <td itemProp="objSq">2362</td>
              <td itemProp="objCnt">100</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="purposeSport">
              <td itemProp="objName">
                Крытая спортивная площадка стадиона широкого профиля с
                элементами полосы препятствий
              </td>
              <td itemProp="objAddress">г. Уфа ул. Союзная</td>
              <td itemProp="objSq">478,5</td>
              <td itemProp="objCnt">35</td>
              <td itemProp="objOvz">Да</td>
            </tr>

            <tr itemProp="purposeSport">
              <td itemProp="objName">Спортивный зал</td>
              <td itemProp="objAddress">Г. Уфа ул. Ахметова, 275</td>
              <td itemProp="objSq">147,0</td>
              <td itemProp="objCnt">35</td>
              <td itemProp="objOvz">Да</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация об обеспечении беспрепятственного доступа в здания
        образовательной организации
      </h3>
      <p>
        В целях обеспечения беспрепятственного доступа обучающихся с
        ограниченными возможностями здоровья, имеющих нарушения
        опорно-двигательного аппарата, в здания образовательной организации
        созданы следующие условия:
      </p>
      <ul>
        <li>
          перед входом на территорию имеется разметка стоянки автотранспорта для
          инвалидов.
        </li>
        <li>
          учебный корпус: Имеются пандусы, поручни, перед входом установлена
          кнопка вызова персонала. Дверные проемы расширенные.
        </li>
        <li>
          общежитие: Имеются пандусы, поручни, перед входом установлена кнопка
          вызова персонала. Дверные проемы расширенные. На 1 этаже 1 комната
          оборудована для проживания лиц с ОВЗ и (или) инвалидов.
        </li>
      </ul>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>Наименование объекта </td>
              <td>Адрес объекта </td>
              <td>Назначение объекта </td>
              <td>Площадь в м2</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Учебный корпус </td>
              <td>г.Уфа ул. Ахметова, 275 </td>
              <td>нежилое</td>
              <td>3451,8 кв. м</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Земельный участок (учебный корпус) </td>
              <td>г.Уфа ул. Ахметова, 275 </td>
              <td>нежилое</td>
              <td>16871 кв. м.</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Общежитие </td>
              <td>г.Уфа ул. Ахметова, 275 </td>
              <td>нежилое</td>
              <td>5973,6 кв. м</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Столовая </td>
              <td>г.Уфа ул. Ахметова, 275 </td>
              <td>нежилое</td>
              <td>1354 кв. м</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Крытая спортивная площадка</td>
              <td>г. Уфа, ул. Союзная</td>
              <td>нежилое</td>
              <td>478,5 кв. м</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Земельный участок (крытая спортивная площадка)</td>
              <td>г. Уфа, ул. Союзная</td>
              <td>нежилое</td>
              <td>3791 кв. м.</td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                Открытый стадион широкого профиля с элементами полосы
                препятствий{" "}
              </td>
              <td>г. Уфа, ул. Союзная</td>
              <td>нежилое</td>
              <td>2362 кв. м.</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о специальных условиях питания и охраны здоровья обучающихся
      </h3>
      <p>
        Уфимский филиал для организации питания курсантов имеет на своём балансе
        столовую общей площадью 1354 м2 на 160 посадочных мест, а также
        помещение, расположенный на 1-м этаже общежития, предназначенное для
        приема пищи лицами с ограниченными возможностями здоровья и инвалидами.
      </p>
      <p>
        В соответствии с требованиями «Санитарно-эпидемиологических правил и
        нормативов 2.4.5. 2409-08 п.6.8» в филиале организовано пятиразовое
        питание (завтрак, обед, полдник, ужин и второй ужин) для курсантов,
        проживающих в общежитии, и трехразовое питание для курсантов,
        проживающих вне общежития.
      </p>
      <p>
        Питание курсантов организовано при наличии 1 и 2 курсов в две смены, при
        наличии 1, 2, 3, 4 курсов в 4 смены под руководством сотрудников
        организационно-воспитательного отдела.
      </p>
      <p>
        Приготовление и отпуск курсантам 3-х разового питания осуществляется по
        установленным нормам продуктов питания и суточному рациону, согласно
        двухнедельного плана-меню, согласованного с управлением Роспотребнадзора
        по РБ и суточной заявке, подаваемой сотрудниками
        организационно-воспитательного отдела.
      </p>
      <p>
        Вопросы организации питания курсантов регламентируются законодательством
        Российской Федерации и находятся под постоянным контролем Управления
        Роспотребнадзора по Республике Башкортостан.
      </p>
      <p>
        Столовая филиала: перед входом установлена кнопка вызова персонала.
        Дверные проемы расширенные.
      </p>
      <p>
        На первом этаже общежития оборудована отдельная комната для приема пищи
        лицами ограниченными возможностями здоровья и инвалидами.
      </p>
      <p>
        Уфимский филиал ФГБОУ ВО «ВГУВТ» обеспечивает охрану здоровья
        обучающихся, в том числе инвалидов и лиц с ограниченными возможностями
        здоровья, в соответствии со статьей 41 Федерального закона от 29 декабря
        2012 г. N 273-ФЗ «Об образовании в Российской Федерации».
      </p>
      <p>Меры по охране здоровья включают:</p>
      <ol>
        <li>
          оказание первичной медико-санитарной помощи в порядке, установленном
          законодательством в сфере охраны здоровья;
        </li>
        <li>организацию питания обучающихся, включая ежедневный бракераж;</li>
        <li>пропаганду и обучение навыкам здорового образа жизни;</li>
        <li>
          организацию и создание условий для профилактики заболеваний и
          оздоровления обучающихся, для занятия ими физической культурой и
          спортом;
        </li>
        <li>
          прохождение обучающимися в соответствии с законодательством Российской
          Федерации медицинских осмотров, в том числе профилактических
          медицинских осмотров, в связи с занятиями физической культурой и
          спортом, диспансеризации;
        </li>
        <li>
          профилактику и запрещение курения, употребления алкогольных,
          слабоалкогольных напитков, пива, наркотических средств, психотропных
          веществ и других одурманивающих веществ;
        </li>
        <li>
          профилактику несчастных случаев с обучающимися во время пребывания в
          организации;
        </li>
        <li>
          проведение санитарно-противоэпидемических и профилактических
          мероприятий
        </li>
      </ol>
      <p>
        В филиале созданы необходимые условия для охраны здоровья обучающихся, в
        том числе инвалидов и лиц с ограниченными возможностями здоровья:
      </p>
      <ol>
        <li>
          Проводится мониторинг состояния здоровья обучающихся. В частности, при
          поступлении абитуриенты проходят предварительный медицинский осмотр.
          На основании результатов медосмотра составляются списки студентов,
          состоящих на диспансерном учете, определяется медицинская группа для
          занятий физкультурой. В течение всего срока обучения студенты проходят
          вакцинацию согласно Национальному календарю профилактических прививок,
          периодические медицинские осмотры и флюорографическое обследование с
          занесением результатов в медицинские карты.
        </li>
        <li>
          Обеспечивается проведение санитарно-гигиенических, профилактических и
          оздоровительных мероприятий, обучение и воспитание в сфере охраны
          здоровья.
        </li>
        <li>
          Организован контроль за соблюдением государственных
          санитарно-эпидемиологических правил и нормативов.
        </li>
      </ol>
      <p>
        Медицинское обслуживание несовершеннолетних обучающихся организовано по
        договору с Государственным бюджетным учреждением здравоохранения
        Республики Башкортостан «Детская поликлиника №6 города Уфа» (г. Уфа, ул.
        Аксакова, д. 62; педиатрическое отделение №3 г. Уфа, ул. Ахметова, д.
        318/1). Медицинское обслуживание совершеннолетних обучающихся и
        сотрудников организовано по договору с Государственным бюджетным
        учреждением здравоохранения Республики Башкортостан «Городская больница
        №9 города Уфа» (г. Уфа, ул. Чкалова, д.125).
      </p>
      <p>
        В филиале работает медицинский пункт, в состав которого входят: кабинет
        приема, процедурный кабинет, двухместный изолятор, комната персонала,
        комната временного хранения медицинских отходов, комната хранения
        уборочного инвентаря и дезинфекционных средств.
      </p>
      <ul>
        <li>
          <DOC height={"25px"} width={"25px"} />
          <a href={Usloviya} target={"_blank"} rel="noreferrer">
            УСЛОВИЯ ОХРАНЫ ЗДОРОВЬЯ ОБУЧАЮЩИХСЯ
          </a>
        </li>
      </ul>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Вид помещения </td>
              <td>Адрес места нахождения </td>
              <td>Площадь, м2 </td>
              <td>Количество мест </td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="meals">
              <td itemProp="objName">Столовая</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">1354</td>
              <td itemProp="objCnt">300</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Медицинский пункт</td>
              <td itemProp="objAddress" rowSpan="6">
                г. Уфа, ул. Ахметова, 275
              </td>
              <td itemProp="objSq">13,7</td>
              <td itemProp="objCnt" rowSpan="6">
                3
              </td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Изолятор</td>
              <td itemProp="objSq">16,5</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Процедурный кабинет</td>
              <td itemProp="objSq">17,6</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Помещение мед. персонала</td>
              <td itemProp="objSq">12,3</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Место хранения медицинских отходов</td>
              <td itemProp="objSq">1,1</td>
              <td itemProp="objOvz">Да</td>
            </tr>
            <tr>
              <td>Всего</td>
              <td>61,2</td>
              <td>Да</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <p itemProp="purposeFacilOvz">
        Сведения о наличии средств обучения и воспитания, в том числе
        приспособленных для использования инвалидами и лицами с ограниченными
        возможностями здоровья
      </p>
      <h3
        itemProp="purposeFacilOvz"
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о наличии средств обучения и воспитания, в том числе
        приспособленных для использования инвалидами и лицами с ограниченными
        возможностями здоровья
      </h3>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        БЛОК ОБЩЕОБРАЗОВАТЕЛЬНЫХ ДИСЦИПЛИН
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Родной язык (все специальности)</td>
              <td>
                Стенды, плакаты, конспект лекций, тестовые задания,
                мультимедийные презентации, словари
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Родная литература (все специальности)</td>
              <td>
                Мультимедийные презентации, видеофильмы экранизаций литературных
                произведений, конспекты лекций, аудиозаписи со стихотворениями,
                музыкальными произведениями, тестовые задания в программе VTE
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Иностранный язык (все специальности)</td>
              <td>
                Стенды, плакаты, видео и аудиозаписи с текстами на английском
                языке, раздаточный материал (тексты, упражнения)
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>История (все специальности)</td>
              <td>
                Мультимедийные презентации, плакаты, видеофильмы с фрагментами
                общественно-политических телепрограмм, тестовые задания в
                программе VTE
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Физическая культура (все специальности)</td>
              <td>
                волейбольная сетка, баскетбольные щиты, гимнастическая стенка,
                лыжи, тренажеры, штанги (80-90 кг, 90-100 кг), гири (16 кг, 24
                кг, 32 кг), перекладина, шведская стенка, маты, турники,
                спортивные груши, сетка для бадминтона, футбольное поле с
                искусственным покрытием, лыжи, мячи (футбол, баскетбол,
                волейбол), гимнастический городок, полоса препятствий,
                освещенная асфальтовая лыжная трасса, совмещенная с велосипедной
                дорожкой
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Основы безопасности жизнедеятельности (все специальности)</td>
              <td>
                приборы радиодозиметра, химические приборы, комплекты ОЗК,
                противогазы ГП-7, противогазы ГП-5, видеофильмы, пневматические
                винтовки, мишеньное поле, мишень № 6, лазерный тренажер-автомат
                Калашникова, наглядные пособия и плакаты. Манекен-тренажер
                «Максим»
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Информатика (все специальности)</td>
              <td>
                Компьютеры с лицензионными программами MS Office Word, MS Office
                Excel, MS Office Access, MS Office PowerPoint, мультимедийные
                презентации, стенды, тестовые задания в программах My Test, VTE
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Химия (все специальности)</td>
              <td>
                Периодическая таблица Менделеева, мультимедийные презентации,
                раздаточный материал с вариантами контрольных работ,
                методических указаний для выполнения лабораторно-практических
                работ, видеофильмы с записями демонстрации химических опытов,
                мультимедиа учебный курс «1С: Органическая химия» и электронное
                издание «Виртуальная химическая лаборатория», наглядные пособия
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                Обществознание (включая экономику и право)(все специальности)
              </td>
              <td>Мультимедийные презентации, видеофильмы, плакаты</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Биология (все специальности)</td>
              <td>
                Раздаточный материал с вариантами контрольных работ,
                методических указаний для выполнения лабораторно-практических
                работ, мультимедийные презентации, видеофильмы
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>Математика (все специальности)</td>
              <td>
                Математический набор у доски, транспортир, логарифмическая
                линейка, калькуляторы, наглядные пособия по геометрии,
                мультимедийные презентации, раздаточный материал с вариантами
                контрольных работ, методических указаний для выполнения
                практических работ
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>Физика (все специальности)</td>
              <td>
                Лабораторное оборудование: прибор для демонстрации длины
                световой волны, набор из линз и стекол, штативы, измерительные
                приборы, наглядные пособия, раздаточный материал с вариантами
                контрольных работ, методических указаний для выполнения
                лабораторно-практических работ, мультимедийные презентации,
                видеофильмы с записями демонстрации физических опытов
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>География (все специальности)</td>
              <td>
                Глобус, карты настенные, мультимедийные презентации,
                видеофильмы, конспект лекций
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>Астрономия (все специальности)</td>
              <td>Мультимедийные презентации, видеофильмы</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        ОБЩИЙ ГУМАНИТАРНЫЙ И СОЦИАЛЬНО-ЭКОНОМИЧЕСКИЙ ЦИКЛ ПРОФЕССИОНАЛЬНОЙ
        ПОДГОТОВКИ
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Основы философии (все специальности)</td>
              <td>Мультимедийные презентации, плакаты</td>
            </tr>
            <tr>
              <td>2</td>
              <td>История (все специальности)</td>
              <td>
                Мультимедийные презентации, видеофильмы, конспект лекций,
                плакаты
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Психология общения 26.02.03 Судовождение</td>
              <td>Мультимедийные презентации и плакаты</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Иностранный язык (все специальности)</td>
              <td>
                Стенды, плакаты, электронный учебник по английскому языку,
                аудиозаписи с текстами на английском языке, раздаточный материал
                (тексты, упражнения, варианты контрольных работ)
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                Судовой английский язык (26.02.03 Судовождение, 26.02.05.
                Эксплуатация судовых энергетических установок 26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Стенды, плакаты, тренажер «Англомар», электронный учебник по
                английскому языку, аудиозаписи с текстами на английском языке,
                раздаточный материал (тексты, упражнения, варианты контрольных
                работ)
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Математика (все специальности)</td>
              <td>
                Математический набор у доски, калькуляторы, наглядные пособия,
                мультимедийные презентации, материалы для проведения web-квеста,
                раздаточный материал с вариантами контрольных работ,
                методических указаний для выполнения практических работ
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Информатика (все специальности)</td>
              <td>
                Компьютеры с лицензинными программами MS Office Word, MS Office
                Excel, MS Office Access, MS Office PowerPoint , мультимедийные
                презентации, стенды, тестовые задания в программах My Test, VTE
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                Экологические основы природопользования (26.02.03 Судовождение,
                26.02.05. Эксплуатация судовых энергетических установок 26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Географические и политические карты, глобус, калькуляторы,
                конспект лекций, стенды, мультимедийные презентации и
                видеофильмы
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Физическая культура (все специальности)</td>
              <td>
                Тренажеры различного назначения, штанги (80-90 кг, 90-100 кг),
                гири (16 кг, 24 кг, 32 кг), перекладина, шведская стенка, маты,
                турники, спортивные груши, сетки для бадминтона и большого
                тенниса, волейбольная сетка, футбольное поле с искусственным
                покрытием, лыжи, мячи (футбол, баскетбол, волейбол),
                гимнастический городок, полоса препятствий, освещенная
                асфальтовая лыжная трасса совмещенная с велосипедной дорожкой
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        ОБЩЕПРОФЕССИОНАЛЬНЫЕ ДИСЦИПЛИНЫ ПРОФЕССИОНАЛЬНОГО ЦИКЛА
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Инженерная графика (все специальности)</td>
              <td>
                Набор чертёжных инструментов для работы у доски,
                демонстрационные модели, наглядные пособия, компьютерная
                программа «Компас-график»
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Механика (26.02.03 Судовождение, 26.02.05. Эксплуатация судовых
                энергетических установок 26.02.06 Эксплуатация судового
                электрооборудования и средств автоматики)
              </td>
              <td>
                Модели зубчатой передачи, червячной передачи, Модель для
                демонстрации видов деформаций, Модель эвольвентного профиля
                зуба, микроскоп, модель для демонстрации действия винта,
                штангенинструменты, микрометры, набор концевых мер длины,
                калибры, индикаторные головки, мультимедийные презентации,
                видеофильмы
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Электроника и электротехника (26.02.03 Судовождение, 26.02.05.
                Эксплуатация судовых энергетических установок 26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Лабораторные столы «Уралочка», наглядные пособия, стенды, макеты
                электрооборудования
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Правовые основы профессиональной деятельности (26.02.03
                Судовождение, 23.02.01 Организация перевозок и управление на
                транспорте (по видам)
              </td>
              <td>
                Мультимедийные презентации, видеофильмы, тестовые задания в
                программах My Test, VTE
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                Метрология и стандартизация (26.02.03 Судовождение, 26.02.05.
                Эксплуатация судовых энергетических установок 26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Штангенинструменты, микрометры, набор концевых мер длины,
                калибры, индикаторные головки, стенды, мультимедийные
                презентации,видеофильмы
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                Метрология, стандартизация и сертификация (23.02.01 Организация
                перевозок и управление на транспорте (по видам)
              </td>
              <td>
                Штангенинструменты, микрометры, набор концевых мер длины,
                калибры, индикаторные головки, стенды, мультимедийные
                презентации,видеофильмы
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                Теория устройства судна (26.02.03 Судовождение, 26.02.05.
                Эксплуатация судовых энергетических установок 26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Кренометр, радиолокатор, магнитный компас, тумба штурвала с
                аксиометром, пульт управления ДВС левым и правым, щит приборов
                ДВС, импульсные отмашки, штурманский стол, конспект лекций,
                видеофильмы, стенды
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                Электротехника и электроника (23.02.01 Организация перевозок и
                управление на транспорте (по видам)
              </td>
              <td>
                Лабораторные столы «Уралочка», наглядные пособия, стенды, макеты
                электрооборудования
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Безопасность жизнедеятельности (все специальности)</td>
              <td>
                Радиодозиметры, химические приборы, комплекты ОЗК, противогаз
                ГП-7, противогазы ГП-5, видеофильмы, пневматические винтовки,
                мишенное поле, мишень № 6, лазерный тренажер-автомат
                Калашникова, видеофильмы, мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                Техническая термодинамика и теплопередача (26.02.05.
                Эксплуатация судовых энергетических установок)
              </td>
              <td>
                Мультимедийные презентации, конспект лекций, раздаточный
                материал с вариантами контрольных работ, методических указаний
                для выполнения практических работ
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                Материаловедение (26.02.05. Эксплуатация судовых энергетических
                установок 26.02.06 Эксплуатация судового электрооборудования и
                средств автоматики)
              </td>
              <td>
                Стенды, наглядные пособия, раздаточный материал с вариантами
                контрольных работ, методических указаний для выполнения
                практических работ, мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                Энергетическое оборудование, механизмы и системы судна (26.02.06
                Эксплуатация судового электрооборудования и средств автоматики)
              </td>
              <td>
                Кренометр, радиолокатор, магнитный компас, тумба штурвала с
                аксиометром, пульт управления ДВС левым и правым, щит приборов
                ДВС, импульсные отмашки, штурманский стол, набор корпуса,
                6ЧНСП-18/22-, вспомогательные механизмы, радио и
                электрооборудование, приборы, локатор, компас, спасательные
                средства
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                Правовое обеспечение профессиональной деятельности (23.02.01
                Организация перевозок и управление на транспорте (по видам)
              </td>
              <td>
                Мультимедийные презентации, видеофильмы, тестовые задания в
                программах My Test, VTE Магнитный компас «МК- 127» , МК-145,
                МК-75, «КМ-100М2», «КМ-100М3», барометр, анемометр, гигрометр,
                модели судов, штурманский прокладочный инструмент, секстант,
                звездный глобус
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>
                Охрана труда (23.02.01 Организация перевозок и управление на
                транспорте (по видам)
              </td>
              <td>
                Радиодозиметры, химические приборы, комплекты ОЗК, противогаз
                ГП-7, противогазы ГП-5, видеофильмы, мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>15</td>
              <td>
                Транспортная система России (23.02.01 Организация перевозок и
                управление на транспорте (по видам)
              </td>
              <td>
                Мультимедийные презентации, видеофильмы, тестовые задания в
                программах My Test, VTE, калькуляторы, модель судна
              </td>
            </tr>
            <tr>
              <td>16</td>
              <td>
                Технические средства (по видам транспорта) (23.02.01 Организация
                перевозок и управление на транспорте (по видам)
              </td>
              <td>
                Мультимедийные презентации, видеофильмы, тестовые задания в
                программах My Test, VTE, географические и политические карты,
                глобус
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        ПРОФЕССИОНАЛЬНЫЕ МОДУЛИ
      </h3>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        26.02.03 Судовождение
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>
                Профессиональный модуль 1 Управление и эксплуатация судна с
                правом эксплуатации судовых энергетических установок
              </td>
              <td>
                Кренометр, радиолокатор, магнитный компас, тумба штурвала с
                аксиометром, пульт управления ДВС левым и правым, щит приборов
                ДВС, импульсные отмашки, штурманский стол, основной прибор ГК
                «Амур», блоки РЛС «Донец - 2», радиопеленгатор «СРП-5», блоки
                РЛС Р-722, эхолоты Кубань и НЭЛ-5, Лаг МГЛ-25 и НЭЛ-5,
                авторулевой Печора, модели судов, штурманский стол, наглядные
                пособия, макет пассажирского теплохода «Родина», тренажер ЭССО,
                наглядные пособия, ZAICOV тренажер навигации р. Белая, тренажер
                РЛС, магнитные компасы МК- 127 , МК-145, МК-75, КМ-100М2,
                КМ-100М3, барометр, анемометр, гигрометр, наглядные пособия,
                класс штурманской прокладки тренажер NPS, тестовая программа по
                МППСС-72, навигации и лоции, безопасности плавания,
                навигационный тренажерный комплекс (NT PRO 4000), версия 4.Х.Х.,
                программный комплекс «Дельта-танкер», набор корпуса,
                6ЧНСП-18/22, вспомогательные механизмы, радио и
                электрооборудование, локатор, атласы с районами плавания,
                мультимедийные презентации и видеофильмы, раздаточный и
                электронный материал с тестовыми заданиями (в том числе в
                программе VTE), методическими указаниями для выполнения
                практических работ, курсовых работ, выпускной квалификационной
                работы, карты, плакаты, стенды, макеты судов и опознавательных
                знаков на реке, Двигатель 3Д6 в разрезе, комплект наглядных
                пособий, тренажер машинного отделения (UNITEST - тренажер)
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Профессиональный модуль 2 Обеспечение безопасности плавания
              </td>
              <td>
                Тренажерные стенды: «Тревожная кнопка», «Видеонаблюдение»,
                «Пожарная сигнализация», электронный учебник «Охрана судна», ПГС
                «Фарватер» дыхательные аппараты, газодымозащитный комплект,
                самоспасатели, снаряжение пожарного, теплоотражающий костюм
                пожарного, противопожарное снаряжение (КИПы, ППК, АСВ-2), трап
                посадочный, стенд настенный с извещателями и оповещателями,
                огнетушители ОУ-5 и ОПУ-5, МПК «Противопожарная подготовка»,
                макет АРБ, макет РЛО, макет переносной станции, жилет
                спасательный, гидротермокостюм, жилет страховочный, макет
                снабжения спасательной шлюпки, буй светодымящий, спасательный
                жилет надувной, самозажигающийся огонь, спасательный круг,
                теплозащитное средство, средство хранения ПСН, макет двигателя
                дежурной шлюпки, огонь поиска, гидростат для ПСН, линемет,
                программные комплексы «Дельта-матрос», «АИС 1.2»,
                «Дельта-танкер», «Дельта-SSO», СДКИ, дымогенератор, трап-люк,
                видеокамеры, пожарные костюмы, дыхательные аппараты, отсек по
                борьбе с водой, пульт инструктора, трап, аварийный пост, макеты
                электродвигателей, макет двигателя, макет помпы, макет
                электрощита, макет ГРЩ, макет подъема в вертолет, пенный отсек,
                пиротехника, спасательный жилет, учебный пластырь, спасательный
                плот, контейнер спасательного плота, снабжение спасательного
                плота, образцы аварийно-спасательного имущества, стенды методов
                заделки пробоин, спасательный круг, манекен «Алекс», носилки
                полевые и корабельные продольно-складочные, манекен-тренажер
                «Максим» с комплектом имитации ранений, манекен «Степа» для
                отработки выноса пострадавшего, макет скелета человека,
                программный комплекс «Судовая медицина» для проверки знаний
                членов экипажей
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Профессиональный модуль 3 Обработка и размещение груза</td>
              <td>
                Мультимедийные презентации, видеофильмы, конспект лекций,
                раздаточные материалы (методические указания для выполнения
                практических работ), калькуляторы, модели судов, наглядные
                пособия, стенды, «Draft survey», тестовые задания по каждому
                разделу и итоговый тест в программе My test
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Профессиональный модуль 4 Анализ эффективности работы судна
              </td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы судовой
                документации, калькуляторы, наглядные пособия
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                Профессиональный модуль 5 Теоретическая подготовка по профессии
                «Матрос»
              </td>
              <td>
                Стенды, макеты буев, наборы для отработки вязания узлов,
                видеофильмы, мультимедийные презентации с изображением
                сигнальных огней на судах различной конфигурации, плакаты,
                инструменты для такелажных работ, малярное оборудование, кисти,
                валики, распылители, сверлильный станок, фрезерный станок,
                точильный станок, столы рабочие с тисками, станок для заплетки
                тросов, спасательные средства, шлюпки, спасательные жилеты
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        26.02.05. Эксплуатация судовых энергетических установок
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>
                Профессиональный модуль 01 Эксплуатация, техническое
                обслуживание и ремонт судового энергетического оборудования
              </td>
              <td>
                Стенды, двигатель 3Д6 в разрезе, комплект наглядных пособий,
                тренажер машинного отделения (UNITEST - тренажер), раздаточный
                материал (методические указания для проведения практических
                работ), компьютерные тестовые тренажеры «Дельта-тест»,
                лабораторные столы "Промэлектроника", электродвигатели,
                наглядные пособия, электрооборудование, паяльники, стенд
                лабораторный ИАД(К), Стенд лабораторный ИДПТ-У, наглядные
                пособия, «Исследование двигателей пост. тока с независимым
                лабораторный ИАД(К), Стенд лабораторный ИДПТ-У, наглядные
                пособия, «Исследование двигателей пост. тока с независимым
                возбуждением и послед. возбуждением» и «ИАДК - К - АД с к.з.
                ротором»; сверлильный станок, фрезерный станок, точильный
                станок, столы рабочие с тисками, станок для заплётки тросов
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Профессиональный модуль 02 Обеспечение безопасности плавания
              </td>
              <td>
                Тренажерные стенды: «Тревожная кнопка», «Видеонаблюдение»,
                «Пожарная сигнализация», электронный учебник «Охрана судна», ПГС
                «Фарватер» дыхательные аппараты, газодымозащитный комплект,
                самоспасатели, снаряжение пожарного, теплоотражающий костюм
                пожарного, противопожарное снаряжение (КИПы, ППК, АСВ-2), трап
                посадочный, стенд настенный с извещателями и оповещателями,
                огнетушители ОУ-5 и ОПУ-5, МПК «Противопожарная подготовка»,
                макет АРБ, макет РЛО, макет переносной станции, жилет
                спасательный, гидротермокостюм, жилет страховочный, макет
                снабжения спасательной шлюпки, буй светодымящий, спасательный
                жилет надувной, самозажигающийся огонь, спасательный круг,
                теплозащитное средство, средство хранения ПСН, макет двигателя
                дежурной шлюпки, огонь поиска, гидростат для ПСН, линемет,
                программные комплексы «Дельта-матрос», «АИС 1.2»,
                «Дельта-танкер», «Дельта-SSO», СДКИ, дымогенератор, трап-люк,
                видеокамеры, пожарные костюмы, дыхательные аппараты, отсек по
                борьбе с водой, пульт инструктора, трап, аварийный пост, макеты
                электродвигателей, макет двигателя, макет помпы, макет
                электрощита, макет ГРЩ, макет подъема в вертолет, пенный отсек,
                пиротехника, спасательный жилет, учебный пластырь, спасательный
                плот, контейнер спасательного плота, снабжение спасательного
                плота, образцы аварийно-спасательного имущества, стенды методов
                заделки пробоин, спасательный круг, манекен «Алекс», носилки
                полевые и корабельные продольно-складочные, манекен-тренажер
                «Максим» с комплектом имитации ранений, манекен «Степа» для
                отработки выноса пострадавшего, макет скелета человека,
                программный комплекс «Судовая медицина» для проверки знаний
                членов экипажей
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Профессиональный модуль 03 Организация работы структурного
                подразделения
              </td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы судовой
                документации, калькуляторы, наглядные пособия, мультимедийные
                презентации
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Профессиональный модуль 04 Выполнение работ по профессии
                «Моторист (машинист)»
              </td>
              <td>
                Двигатель 3Д6 в разрезе, комплект наглядных пособий, тренажер
                машинного отделения (UNITEST - тренажер), набор корпуса,
                6ЧНСП-18/22, вспомогательные механизмы, радио и
                электрооборудование, приборы, локатор, компас, спасательные
                средства
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        26.02.06 Эксплуатация судового электрооборудования и средств автоматики
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>
                Профессиональный модуль 01 Техническая эксплуатация судового
                электрооборудования и средств автоматики
              </td>
              <td>
                Мультимедийные презентации, раздаточный материал (методические
                указания для выполнения лабораторно-практических работ, курсовой
                и выпускной квалификационной работы), стенд лабораторный
                «Исследование двигателей постоянного тока с независимым
                возбуждением и последующим возбуждением» ИДПТ-У и «ИАДК - К - АД
                с к.з. ротором», наглядные пособия ИДПТ-У и «ИАДК - К - АД с
                к.з. ротором», лабораторные столы «Промэлектроника»,
                электродвигатели, электрооборудование, паяльники, провода
                различного диаметра
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Профессиональный модуль 02 Организация работы коллектива
                исполнителей
              </td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы судовой
                документации, калькуляторы, наглядные пособия, мультимедийные
                презентации
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Профессиональный модуль 03 Обеспечение безопасности плавания
              </td>
              <td>
                Тренажерные стенды: «Тревожная кнопка», «Видеонаблюдение»,
                «Пожарная сигнализация», электронный учебник «Охрана судна», ПГС
                «Фарватер» дыхательные аппараты, газодымозащитный комплект,
                самоспасатели, снаряжение пожарного, теплоотражающий костюм
                пожарного, противопожарное снаряжение (КИПы, ППК, АСВ-2), трап
                посадочный, стенд настенный с извещателями и оповещателями,
                огнетушители ОУ-5 и ОПУ-5, МПК «Противопожарная подготовка»,
                макет АРБ, макет РЛО, макет переносной станции, жилет
                спасательный, гидротермокостюм, жилет страховочный, макет
                снабжения спасательной шлюпки, буй светодымящий, спасательный
                жилет надувной, самозажигающийся огонь, спасательный круг,
                теплозащитное средство, средство хранения ПСН, макет двигателя
                дежурной шлюпки, огонь поиска, гидростат для ПСН, линемет,
                программные комплексы «Дельта-матрос», «АИС 1.2»,
                «Дельта-танкер», «Дельта-SSO», СДКИ, дымогенератор, трап-люк,
                видеокамеры, пожарные костюмы, дыхательные аппараты, отсек по
                борьбе с водой, пульт инструктора, трап, аварийный пост, макеты
                электродвигателей, макет двигателя, макет помпы, макет
                электрощита, макет ГРЩ, макет подъема в вертолет, пенный отсек,
                пиротехника, спасательный жилет, учебный пластырь, спасательный
                плот, контейнер спасательного плота, снабжение спасательного
                плота, образцы аварийно-спасательного имущества, стенды методов
                заделки пробоин, спасательный круг, манекен «Алекс», носилки
                полевые и корабельные продольно-складочные, манекен-тренажер
                «Максим» с комплектом имитации ранений, манекен «Степа» для
                отработки выноса пострадавшего, макет скелета человека,
                программный комплекс «Судовая медицина» для проверки знаний
                членов экипажей
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Профессиональный модуль 04 Теоретическая подготовка по профессии
                «Электрик судовой»
              </td>
              <td>
                Мультимедийные презентации, раздаточный материал (методические
                указания для выполнения лабораторно-практических работ), стенд
                лабораторный «Исследование двигателей пост. тока с независимым
                возбуждением и послед. возбуждением» ИДПТ-У и «ИАДК - К - АД с
                к.з. ротором», наглядные пособия ИДПТ-У и «ИАДК - К - АД с к.з.
                ротором», лабораторные столы «Промэлектроника»,
                электродвигатели, наглядные пособия, электрооборудование,
                паяльники, провода различного диаметра
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        23.02.01 Организация перевозок и управление на транспорте (по видам)
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>
                Профессиональный модуль 01 Организация перевозочного процесса
                (по видам транспорта)
              </td>
              <td>
                Мультимедийные презентации, раздаточный материал (методические
                указания для выполнения лабораторно-практических и
                самостоятельных работ), наглядные пособия: стенды Навигационный
                тренажерный комплекс (NT PRO 4000), версия 4.Х.Х., "АИС 1.2",
                видеофильмы
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Профессиональный модуль 02 Организация сервисного обслуживания
                на транспорте (по видам транспорта)
              </td>
              <td>
                Мультимедийные презентации, раздаточный материал (методические
                указания для выполнения лабораторно-практических и
                самостоятельных работ), видеофильмы
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Профессиональный модуль 03 Организация транспортно-
                логистической деятельности (по видам транспорта)
              </td>
              <td>
                Мультимедийные презентации, раздаточный материал (методические
                указания для выполнения лабораторно-практических и
                самостоятельных работ), видеофильмы , лицензионное программное
                обеспечение: офисные программы MS Office Word, MS Office Excel,
                MS Office Access, MS Office PowerPoint
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Профессиональный модуль 04 Выполнение работ по одной или
                нескольким профессиям рабочих, должностям служащих
              </td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                калькуляторы, наглядные пособия, мультимедийные презентации
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Вариативная часть циклов ППССЗ
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>
                Наименование учебной дисциплины/профессионального модуля,
                специальность
              </td>
              <td>Средства обучения</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Русский язык и культура речи</td>
              <td>
                Стенды, плакаты, конспект лекций, тестовые задания,
                мультимедийные презентации, словари
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Деловой иностранный язык</td>
              <td>
                Стенды, плакаты, видео и аудиозаписи с текстами на английском
                языке, раздаточный материал (тексты, упражнения), программа
                "Англомар", электронный учебник по английскому языку
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Основы делопроизводства</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                мультимедийные презентации, словари, лицензионное программное
                обеспечение: офисные программы MS Office Word, MS Office Excel,
                MS Office Access, MS Office PowerPoint
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Внутренние водные пути России</td>
              <td>
                Макеты: магнитный компас, судно, мультимедийные презентации,
                раздаточный материал (методические указания для выполнения
                лабораторно-практических и самостоятельных работ), видеофильмы
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Организация доступной среды для инвалидов на транспорте</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Информационные технологии в профессиональной деятельности</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                мультимедийные презентации, словари, лицензионное программное
                обеспечение: офисные программы MS Office Word, MS Office Excel,
                MS Office Access, MS Office PowerPoint
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Коммерческая работа на транспорте</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                калькуляторы, наглядные пособия, мультимедийные презентации,
                модель судна
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Страхование и риски</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                калькуляторы, наглядные пособия, мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Экономика и управление на водном транспорте</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                калькуляторы, наглядные пособия, мультимедийные презентации
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Бортпроводник</td>
              <td>
                Раздаточные материалы (методические указания для выполнения
                практических и самостоятельных работ), образцы документации,
                калькуляторы, наглядные пособия, мультимедийные презентации
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о доступе к электронной информационно-образовательной среде,
        приспособленным информационным системам и
        информационно-телекоммуникационным сетям и электронным ресурсам, к
        которым обеспечивается доступ обучающихся инвалидов и лиц с
        ограниченными возможностями здоровья:
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Наименование</td>
              <td>Количество</td>
            </tr>
            <tr>
              <td>
                Наличие в образовательной организации электронной
                информационно-образовательной среды
              </td>
              <td>Да</td>
            </tr>
            <tr>
              <td>
                Общее количество компьютеров с выходом в
                информационно-телекоммуникационную сеть «Интернет», к которым
                имеют доступ обучающиеся
              </td>
              <td>81</td>
            </tr>
            <tr>
              <td>
                Общее количество ЭБС, к которым имеют доступ обучающиеся
                (собственных или на договорной основе)
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                Наличие собственных электронных образовательных и информационных
                ресурсов
              </td>
              <td>Да</td>
            </tr>
            <tr>
              <td>
                Наличие сторонних электронных образовательных и информационных
                ресурсов
              </td>
              <td>Да</td>
            </tr>
            <tr>
              <td>Наличие базы данных электронного каталога</td>
              <td>Да</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <p itemProp="comNetOvz">
        Доступ к информационным системам и информационно-телекоммуникационным
        сетям, в том числе приспособленных для использования инвалидами и лицами
        с ограниченными возможностями здоровья обеспечен:
      </p>
      <ul>
        <li>количество персональных компьютеров – 150;</li>
        <li>из них задействовано в учебном процессе – 90;</li>
        <li>количество серверов – 4;</li>
        <li>количество ПК с доступом к сети Internet – 125;</li>
        <li>количество видеопроекторов – 23;</li>
        <li>количество интерактивных досок – 5;</li>
        <li>количество аудиторий, оснащенных проекционными системами – 21.</li>
      </ul>
      <p>
        В филиале создана электронная информационно-образовательная средаа,
        включающая в себя следующие элементы:
      </p>
      <ul>
        <li>Официальный сайт.</li>
        <li>Автоматизированная система управления АСУ "Учебный процесс".</li>
      </ul>
      <p itemProp="comNetOvz">
        Электронная информационно-образовательная среда филиала обеспечивает:
      </p>
      <ul>
        <li>
          доступ к учебным планам, рабочим программам дисциплин (модулей),
          практик, к изданиям электронных библиотечных систем и электронным
          образовательным ресурсам, указанным в рабочих программах;
        </li>
        <li>
          фиксацию хода образовательного процесса, результатов промежуточной
          аттестации и результатов освоения программы;
        </li>
      </ul>
      <p itemProp="erListOvz">
        Перечень приспособленных электронных образовательных ресурсов, к которым
        обеспечивается доступ обучающихся инвалидов и лиц с ограниченными
        возможностями здоровья:
      </p>
      <ul>
        <li>
          <a
            href="http://www.minobrnauki.gov.ru"
            target={"_blank"}
            rel="noreferrer"
          >
            minobrnauki.gov.ru{" "}
          </a>
          - Министерство науки и высшего образования Российской Федерации
        </li>
        <li>
          <a href={"http://edu.gov.ru"} target={"_blank"} rel="noreferrer">
            edu.gov.ru{" "}
          </a>
          - Министерство просвещения Российской Федерации
        </li>
        <li>
          <a
            href={"http://obrnadzor.gov.ru"}
            target={"_blank"}
            rel="noreferrer"
          >
            obrnadzor.gov.ru{" "}
          </a>
          - Федеральная служба по надзору в сфере образования и науки
        </li>
        <li>
          <a href={"http://edu.ru "} target={"_blank"} rel="noreferrer">
            edu.ru{" "}
          </a>
          - Федеральный портал "Российское образование"
        </li>
        <li>
          <a href={"http://window.edu.ru/"} target={"_blank"} rel="noreferrer">
            window.edu.ru{" "}
          </a>
          - Информационная система "Единое окно доступа к образовательным
          ресурсам"
        </li>
        <li>
          <a href={"http://fcior.edu.ru"} target={"_blank"} rel="noreferrer">
            fcior.edu.ru{" "}
          </a>
          - Федеральный центр информационно-образовательных ресурсов
        </li>
        <li>
          <a href={"http://garant.ru"} target={"_blank"} rel="noreferrer">
            garant.ru{" "}
          </a>
          - «ГАРАНТ»- информационно-правовой портал
        </li>
        <li>
          <a href={"http://consultant.ru/ "} target={"_blank"} rel="noreferrer">
            www.consultant.ru{" "}
          </a>
          - «Консультант плюс»- Разработка правовых систем
        </li>
      </ul>
      <p itemProp="erListOvz">
        Официальный сайт Уфимского филиала ВГУВТ адаптирован под нужды
        слабовидящих обучающихся посредством специальной версии.
      </p>
      <p>Электронные библиотечные системы и ресурсы:</p>
      <ul>
        <li>
          <a
            href="http://lib.vsuwt.ru/marcweb2/"
            target={"_blank"}
            rel="noreferrer"
          >
            Электронный каталог ВГУВТ{" "}
          </a>
          (через Электронный каталог ВГУВТ возможен доступ к электронной
          библиотеке университета и НРУ)
        </li>
        <li>
          <a href="https://urait.ru/" target={"_blank"} rel="noreferrer">
            Электронно-библиотечная система «Юрайт»{" "}
          </a>
        </li>
        <li>
          <a href={"http://e.lanbook.com"} target={"_blank"} rel="noreferrer">
            Электронно-библиотечная система «Лань»{" "}
          </a>
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a href="https://znanium.com/" target={"_blank"} rel="noreferrer">
            «Знаниум»
          </a>{" "}
        </li>
        <li>
          <a
            href={"http://morkniga.ru/library/"}
            target={"_blank"}
            rel="noreferrer"
          >
            Электронно – библиотечная система "Моркнига"{" "}
          </a>
        </li>
        <li>
          <a href={"http://iprbookshop.ru/"} target={"_blank"} rel="noreferrer">
            Электронно- библиотечная система ЭБС «IPRbooks»(ООО Компания «Ай Пи
            Ар Медиа»){" "}
          </a>
        </li>
      </ul>
      <p itemProp="techOvz">
        Специальные технические средства обучения коллективного и
        индивидуального пользования для инвалидов и лиц с ограниченными
        возможностями здоровья:
      </p>
      <ul>
        <li>Программы-синтезаторы речии</li>
        <li>Звукоусиливающая аппаратура</li>
        <li>Устройства воспроизведения информации</li>
        <li>Индукционная петля VERT</li>
      </ul>
      <h3
        itemProp="hostelInterOvz"
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация об обеспечении беспрепятственного доступа в общежитие
        образовательной организации
      </h3>
      <p itemProp="ovz">
        В целях обеспечения беспрепятственного доступа обучающихся с
        ограниченными возможностями здоровья, имеющих нарушения
        опорно-двигательного аппарата, в общежитие образовательной организации
        созданы следующие условия: Имеются пандусы, поручни, перед входом
        установлена кнопка вызова персонала. Дверные проемы расширенные. На 1
        этаже 1 комната оборудована для проживания лиц с ОВЗ и (или) инвалидов.
      </p>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о количестве жилых помещений в общежитии, приспособленных для
        использования инвалидами и лицами с ограниченными возможностями здоровья
      </h3>
      <p itemProp="hostelNumOvz">
        Общежитие филиала приспособлено для использования инвалидами и лицами с
        ограниченными возможностями здоровья: имеются пандусы, поручни, перед
        входом установлена кнопка вызова персонала. Дверные проемы расширенные.
        На первом этаже 1 комната оборудована для проживания лиц с ОВЗ и (или)
        инвалидов. На первом этаже оборудована 1 аудитория, приспособленная для
        использования инвалидами и лицами с ограниченными возможностями
        здоровья. На первом этаже оборудована комната для приема пищи лицами
        ограниченными возможностями здоровья.{" "}
        <b> Адрес общежития: г.Уфа, ул. Ахметова, 275.</b>.
      </p>
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a href={OVZ} target={"_blank"} rel="noreferrer">
            Информация о наличии материально-технических условий,
            приспособленных для использования инвалидами и лицами с
            ограниченными возможностями здоровья, обеспечении возможности
            беспрепятственного доступа, условиях питания, условиях охраны
            здоровья, о доступе к информационным системам и
            информационно-телекоммуникационным сетям, об электронных
            образовательных ресурсах, о наличии специальных технических средств
            обучения коллективного и индивидуального использования для инвалидов
            и лиц с ограниченными возможностями здоровья
          </a>
        </li>
      </ul>
    </div>
  );
});
