// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".site-map_container__-0Hqq {\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n\n.site-map_element__9twGC {\n  width: 350px;\n  display: flex;\n}\n\n.site-map_letter__f5KYA {\n  color: red;\n  font-size: 24px;\n  font-weight: bold;\n  margin: 0 20px 0 0;\n}\n\n.site-map_links__gQerl {\n  display: flex;\n  flex-direction: column;\n  padding-top: 5px;\n}\n\n.site-map_linkContainer__uWVmH {\n  margin-bottom: 10px;\n}\n\n.site-map_linkElement__ObDjU {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/app/components/page/home-page/site-map/site-map.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 20px;\r\n  flex-wrap: wrap;\r\n}\r\n\r\n.element {\r\n  width: 350px;\r\n  display: flex;\r\n}\r\n\r\n.letter {\r\n  color: red;\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  margin: 0 20px 0 0;\r\n}\r\n\r\n.links {\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  padding-top: 5px;\r\n}\r\n\r\n.linkContainer {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.linkElement {\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "site-map_container__-0Hqq",
	"element": "site-map_element__9twGC",
	"letter": "site-map_letter__f5KYA",
	"links": "site-map_links__gQerl",
	"linkContainer": "site-map_linkContainer__uWVmH",
	"linkElement": "site-map_linkElement__ObDjU"
};
export default ___CSS_LOADER_EXPORT___;
