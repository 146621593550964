import React from "react";
import { NavLink } from "react-router-dom";
import RPV_EM from "../../../../../assets/pdf/educationPage/260206/ЭМ 2022 гп/ВР ЭМ 2022-2023.pdf";
import Image12 from "../../../../../assets/pdf/educationPage/260206/ЭМ 2022 гп/5. Календарный график учебного процесса 2022-2023.pdf";
import OOP5 from "../../../../../assets/pdf/educationPage/260206/opisanie/ОП ППССЗ  26. 02. 06  Эксплуатация судового электрического оборудования и средств автоматики 2023 г.п..docx";
import Annot5 from "../../../../../assets/pdf/educationPage/260206/annot/Аннотации программ дисциплин и проф.модулей 26.02.06 .Эксплуатация судового электрообрудования и средств автоматики, 2021 г.п..doc";
import educationPlan from "../../../../../assets/pdf/educationPage/260206/uchebnie_plani/26.02.06 2024-25 1 курс.pdf";

export const SPOEkspluatSudovEelectrooborud_24_260206 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">26.02.06</td>
      <td>
        <NavLink
          itemProp="eduName"
          to="../../educationPrograms/marineElectricalEquipment2024"
        >
          Эксплуатация судового электрооборудования и средств автоматики ФГОС
          №675 от 26.11.2020
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink
          itemProp="eduProf"
          to="../../educationPrograms/marineElectricalEquipment2024"
        >
          Эксплуатация судового электрооборудования и средств автоматики (прием
          2024)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная-заочная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP5} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp={"educationPlan"}
              href={educationPlan}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationAnnotation"
              href={Annot5}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/marineElectricalEquipment2024"
            >
              Скачать РП
            </NavLink>
          </li>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/marineElectricalEquipment2024"
            >
              Скачать РП РЦ
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image12}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/marineElectricalEquipment2022FOS"
            >
              Скачать
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a href={RPV_EM} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a href={"KPV"} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <NavLink to={"../../local-acts"}>
              Локальные нормативные акты
            </NavLink>
          </li>
        </ul>
      </td>
    </tr>
  );
};
