import React from "react";
import { TableWrapper } from "../../../common";
export const PurposePrac = () => {
  return (
    <TableWrapper title="Сведения об объектах для проведения практических занятий">
      <table>
        <tbody>
          <tr>
            <td>Адрес</td>
            <td>
              Наименование оборудованных объектов для проведения практических
              занятий
            </td>
            <td>
              Оснащенность оборудованных объектов для проведения практических
              занятий
            </td>
            <td>
              Приспособленность для использования инвалидами и лицами с
              ограниченными возможностями здоровья
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">№11. Диспетчерский пункт.</td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, кренометр, радиолокатор, магнитный
              компас, тумба штурвала с аксиометром, пульт управления ДВС левым и
              правым, щит приборов ДВС, импульсные отмашки, прибор ночного
              видения; штурманский стол, макеты судов.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              29 Музей «Боевой славы». Стрелковый тир.
            </td>
            <td itemProp="osnPrac">
              электронный тир (винтовка, цель), макет АК
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              30 Кабинет механики. Кабинет метрологии, стандартизации и
              сертификации. Кабинет метрологии и стандартизации.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, модели зубчатой передачи, червячной
              передачи, модель для демонстрации видов деформаций, модель
              эвольвентного профиля зуба, микроскоп, модель для демонстрации
              действия винта, штанген инструменты, микрометры, набор концевых
              мер длины, калибры, индикаторные головки.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              32 имени С.Т. Аксакова Кабинет русского языка и литературы.
              Кабинет родного языка и литературы
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды).
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              33, 42, 42а ,43, Кабинет Иностранного языка
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды), программа «Англомар», электронный учебник по английскому
              языку, наглядные пособия и плакаты.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              №34. Кабинет информатики. Лаборатория информатики. Лаборатория
              автоматизированных систем управления.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран., лицензионное программное
              обеспечение: офисные программы MS Office Word, MS Office Excel, MS
              Office Access, MS Office PowerPoint
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              №35. Кабинет физики. Кабинет математических и естественнонаучных
              дисциплин. Кабинет технической термодинамики и теплопередачи.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, прибор для демонст. длины световой
              волны, набор из линз и стекла, штативы, измерительные приборы.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              №№36, 38, 109, 225. Кабинет математики. Кабинет математических и
              естественнонаучных дисциплин.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, набор чертёжных инструментов для
              работы у доски, демонстрационные модели.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              №39. Кабинет инженерной графики. Кабинет охраны труда.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, компьютерная программа
              «Компас-график», набор чертёжных инструментов для работы у доски,
              демонстрационные модели.{" "}
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              №40. Лаборатория судового радиооборудования. Лаборатория
              радионавигационных и электрорадионавигационных приборов, систем
              технических средств судовождения.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, магнитный компас «МК- 127» , МК-145,
              МК-75, «КМ-100М2», «КМ-100М3», барометр, анемометр, гигрометр,
              основной прибор ГК «Амур», блоки РЛС «Донец - 2», радиопеленгатор
              «СРП-5», блоки РЛС Р-722, эхолоты Кубань и НЭЛ-5, Лаг МГЛ-25 и
              НЭЛ-5, авторулевой Печора, модели судов.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              44 Лаборатория судовых энергетических установок. Лаборатория
              энергетического оборудования, механизмов и систем судна. Тренажер
              судовой энергетической установки.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, макет двигателя 3Д6 в разрезе,
              детали цилиндропоршневой группы, топливный насос, фильтры, наборы
              ключей, стенды, плакаты, видеофильмы, презентации. Тренажер
              судовой энергетической установки «UNITEST»
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              45 имени Ф.А. Кибальника Кабинет технологии перевозки грузов.
              Кабинет организации перевозочного процесса (по видам транспорта).
              Организации транспортно-логистической деятельности (по видам
              транспорта). Кабинет транспортной системы России.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, модели судов.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              46 Кабинет управления судном. Кабинет безопасности движения.
              Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, макеты судов, плавучих знаков
              судоходной обстановки, магнитный компас.{" "}
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              47 имени адмирала Ф.Ф. Беллинсгаузена Лаборатория управления
              движением Навигационный тренажер.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, магнитный компас «МК- 127», МК-145,
              МК-75, «КМ-100М2», «КМ-100М3», барометр, анемометр, гигрометр,
              модели судов, штурманский прокладочный инструмент, морские карты,
              секстант, звездный глобус
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              48 Кабинет химии и биологии. Кабинет математических и
              естественнонаучных дисциплин. Кабинет экологических основ
              природопользования.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, микроскопы, периодическая таблица
              Менделеева, колбонагреватель, прибор для электролиза, колонки
              адсорбционные, прибор для окисления спиртов, баня комбинированная,
              весы, разновесы, мультимедиа учебный курс «1С: Органическая химия»
              и электронное издание «Виртуальная химическая лаборатория».
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              106 Лаборатория судовых электроприводов. Лаборатория электрических
              машин.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, стенд
              лабораторный «Исследование двигателей пост. тока с независимым
              возбуждением и послед. возбуждением», стенд лабораторный «ИАДК - К
              - АД с к.з. ротором».
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              107 Кабинет судовых электроприводов. Электрических машин.
              Технического обслуживания.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран.{" "}
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              108 Лаборатория электрооборудования судов. Лаборатория судового
              электрооборудования и электронной аппаратуры. Лаборатория судовых
              электроэнергетических систем. Лаборатория электрических систем
              автоматики и контроля судовых технических средств. Лаборатория
              электронной техники. Электромонтажная мастерская. Мастерская
              учебная.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              лабораторные столы «Промэлектроника», макеты электродвигателей,
              электрооборудования, лабораторные стенды «Пуск асинхронного
              реверсивного двигателя», «Схема управления освещением»,
              «Светоимпульсные отмашки», «Коммутатор отличительных огней»,
              «Судовые сигнальные огни», тестер, инструменты
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              110 Кабинет экологических основ природопользования. Кабинет
              технических средств (по видам транспорта).
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, географические и политические карты,
              глобус.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              111 Кабинет Безопасности жизнедеятельности на судне. Тренажерный
              класс охрана судов и портовых средств
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, тренажерные стенды: «Тревожная кнопка», «Видеонаблюдение»,
              «Пожарная сигнализация».
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              114 Лаборатория электроники и электротехники. Лаборатория
              электротехники и электроники. Лаборатория электротехники.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель, лабораторные столы «Уралочка»,
              технические средства обучения с набором демонстрационного
              оборудования и учебно-наглядных пособий (компьютерная техника с
              возможностью выхода в Интернет и доступом в электронную
              информационно-образовательную среду филиала, мультимедийные
              презентации, видео-аудиоматериалы по темам лекций, стенды, макеты
              электрооборудования.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">116 Лаборатория материаловедения.</td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, микроскоп, образцы металлов
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              117 Кабинет безопасности жизнедеятельности на судне. Кабинет
              безопасности движения. Тренажерный класс «Противопожарной
              безопасности и борьбы с пожаром».
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, дыхательные аппараты, газодымозащитный комплект,
              самоспасатели, снаряжение пожарного, теплоотражающий костюм
              пожарного, противопожарное снаряжение, стенд настенный с
              извещателями и оповещателями, огнетушители ОУ-5 и ОПУ-5.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              218 имени адмирала С.О. Макарова Лаборатория судового
              радиооборудования. Тренажёр «Глобальной морской системы связи при
              бедствии». Тренажёрный класс «Глобальной морской системы связи при
              бедствии»
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, тренажер «Глобальной морской системы связи при бедствии»:
              пост аппаратуры ГМССБ, макеты АРБ, макет РЛО, макет переносной
              станции УКВ, «(ГМССБ)».
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              219 имени Петра I Лаборатория радионавигационных и
              электрорадионавигационных приборов и систем и технических средств
              судовождения. Лаборатория управления движением. Тренажерный класс
              «РЛС и САРП»
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, навигационный тренажер: (NT PRO 4000), версия 4.Х.Х., 2
              места, «АИС 1.2».
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              223 Кабинет основ анализа эффективности работы судна. Кабинет
              организации перевозочного процесса (по видам транспорта). Кабинет
              организации сервисного обслуживания на транспорте (по видам
              транспорта). Кабинет управления качеством и персоналом.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, карта внутренних водных путей.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              228 Кабинет безопасности жизнедеятельности на судне. Кабинет
              безопасности движения.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран, манекен-тренажер «Максим» с
              комплектом имитации ранений, жилет спасательный, гидротермокостюм,
              макет снабжения спасательной шлюпки, буй светодымящий,
              спасательный жилет надувной, спасательный круг, теплозащитное
              средство, средство хранения ПСН, огонь поиска, гидростат для ПСН,
              линемет.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              Дизельная лаборатория. Лаборатория судовых энергетических
              установок.
            </td>
            <td itemProp="osnPrac">
              специализированная мебель, наборы инструментов, дизель
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">
              Слесарная мастерская. Такелажная мастерская. Слесарно-механическая
              мастерская. Мастерская учебная.
            </td>
            <td itemProp="osnPrac">
              сверлильный станок, фрезерный станок, точильный станок, столы
              рабочие с тисками, станок для заплетки тросов
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">Спортивный зал</td>
            <td itemProp="osnPrac">
              волейбольная сетка, баскетбольные щиты, гимнастическая стенка,
              спортивные снаряды, мячи, лыжи, мячи, эстафетная палочка, скакалка
              гимнастическая, обручи гимнастические, секундомер, компьютерная
              техника с возможностью выхода в Интернет и доступом в электронную
              информационно-образовательную среду филиала.
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">Зал атлетической гимнастики</td>
            <td itemProp="osnPrac">
              тренажеры, штанги, гири, перекладина, шведская стенка
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">Зал спортивных единоборств</td>
            <td itemProp="osnPrac">Маты, груши боксерские, гири</td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="namePrac">Хоккейная коробка</td>
            <td itemProp="osnPrac">
              Металлические ворота для игры в хоккей, ограждение
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Союзная
            </td>
            <td itemProp="namePrac">
              Крытая спортивная площадка стадиона широкого профиля с элементами
              полосы препятствий
            </td>
            <td itemProp="osnPrac">
              волейбольная сетка, баскетбольные щиты, полоса препятствий, маты
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposePrac">
            <td itemProp="addressPrac">
              450017, Республика Башкортостан, г.Уфа, ул. Союзная
            </td>
            <td itemProp="namePrac">
              Открытый стадион широкого профиля с элементами полосы препятствий
              (с оборудованной лыжной трассой и велосипедной дорожкой)
            </td>
            <td itemProp="osnPrac">
              волейбольная сетка, баскетбольные щиты, турник, полоса препятствий
            </td>
            <td itemProp="ovzPrac">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};
