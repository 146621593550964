import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import protocol from "../../../../assets/pdf/grants/protocol.pdf";
import protocol15 from "../../../../assets/pdf/grants/Протокол.pdf";
import poloj_stepend_obesp from "../../../../assets/pdf/grants/Положение о стипендиальном обеспечении.pdf";
import sirotam from "../../../../assets/pdf/grants/Порядок назначения выплат детям-сиротам и детям, оставшимся без попечения родителей.pdf";
import polojen from "../../../../assets/pdf/documentPage/Pol_st_dorm_2024.pdf";
import smeta from "../../../../assets/pdf/documentPage/Смета общ с 01.07.24.pdf";
import stipPrez from "../../../../assets/pdf/grants/Стипендия Президента Российской Федерации.pdf";
import ukaz from "../../../../assets/pdf/grants/Указ Главы РБ О стипендиях Главы РБ №УГ-80 от 18.03.2019.pdf";
import post from "../../../../assets/pdf/grants/Постановление Правительства РФ О стипендиях Прав. РФ №1114 от 23.12.2011.pdf";
import prikazStipKom from "../../../../assets/pdf/grants/Приказ об утв. стипенд.комиссии №432 от 03.09.2024.pdf";
import polStipKom from "../../../../assets/pdf/grants/Положение о стипендиальной комиссии.pdf";
import { HrLine } from "../../../common";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

export const Grants = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Стипендии и меры поддержки обучающихся
      </h1>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Федеральные нормативные акты
      </h2>
      <ul>
        <li className="doc__item">
          <a
            itemProp="localAct"
            href={"http://kremlin.ru/acts/bank/36698/page/1"}
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон «Об образовании в Российской Федерации» № 273-ФЗ
            от 29 декабря 2012 года
          </a>
        </li>
        <li className="doc__item">
          <a
            itemProp="localAct"
            href={"http://government.ru/docs/all/109497/"}
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации № 1390 от
            17.12.2016 г. «О формировании стипендиального фонда»
          </a>
        </li>
      </ul>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о наличии и условиях предоставления обучающимся стипендий, о
        мерах социальной поддержки обучающихся
      </h2>
      <p>
        Виды стипендий, выплачиваемых обучающимся Уфимского филиала ФГБОУ ВО
        «ВГУВТ»:
      </p>
      <ol style={{ marginBottom: "20px" }}>
        <li>Государственная академическая стипендия</li>
        <li>Государственная социальная стипендия</li>
        <li>
          {" "}
          <PDF height={"25px"} width={"25px"} />
          <a itemProp="localAct" href={ukaz} target={"_blank"} rel="noreferrer">
            Стипендия Главы Республики Башкортостан
          </a>
        </li>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="localAct"
            href={stipPrez}
            target={"_blank"}
            rel="noreferrer"
          >
            Стипендия Президента Российской Федерации
          </a>
        </li>
        <li>
          {" "}
          <PDF height={"25px"} width={"25px"} />
          <a itemProp="localAct" href={post} target={"_blank"} rel="noreferrer">
            Стипендия Правительства Российской Федерации
          </a>
        </li>
      </ol>
      <HrLine />
      <ul>
        {" "}
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="support"
            href={poloj_stepend_obesp}
            target={"_blank"}
            rel="noreferrer"
          >
            Положение о стипендиальном обеспечении и других формах материальной
            поддержки студентов и аспирантов
          </a>
        </li>
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="support"
            href={polStipKom}
            target={"_blank"}
            rel="noreferrer"
          >
            Положение о стипендиальной комиссии
          </a>
        </li>
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="support"
            href={prikazStipKom}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ о стипендиальной комиссии
          </a>
        </li>{" "}
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="localAct"
            href={protocol15}
            target={"_blank"}
            rel="noreferrer"
          >
            Протокол заседания стипендиальной комиссии №18 от 17.09.2024 об
            установлении размера стипендий
          </a>
        </li>
      </ul>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация об иных видах материальной поддержки обучающихся
      </h2>
      <ul>
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="localAct"
            href={sirotam}
            target={"_blank"}
            rel="noreferrer"
          >
            Порядок назначения выплат детям-сиротам и детям, оставшимся без
            попечения родителей
          </a>
        </li>
      </ul>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Меры поддержки детей-сирот
      </h3>
      <p>
        Детям-сиротам и детям, оставшимся без попечения родителей, выплачивается
        государственная социальная стипендия, ежегодное пособие на приобретение
        учебной литературы и письменных принадлежностей, предоставляется
        бесплатное питание, бесплатный комплект одежды и обуви, бесплатный
        проезд один раз в год к месту жительства и обратно к месту учебы.
      </p>

      <h3
        itemProp="hostelInfo"
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Информация о наличии общежития, интерната, в том числе приспособленных
        для использования инвалидами и лицами с ограниченными возможностями
        здоровья
      </h3>
      <p>
        Общежитие филиала приспособлено для использования инвалидами и лицами с
        ограниченными возможностями здоровья: имеются пандусы, поручни, перед
        входом установлена кнопка вызова персонала. Дверные проемы расширенные.
      </p>
      <p itemProp="hostelNumOvz">
        На первом этаже 1 комната оборудована для проживания лиц с ОВЗ и (или)
        инвалидов.
      </p>
      <p itemProp="hostelNumOvz">
        На первом этаже оборудована 1 аудитория, приспособленная для
        использования инвалидами и лицами с ограниченными возможностями
        здоровья.
      </p>
      <p>
        На первом этаже оборудована комната для приема пищи лицами ограниченными
        возможностями здоровья.
      </p>
      <p itemProp="hostelTS">
        Филиал имеет общежитие общей площадью 5978,2 кв.м. на 516 мест, согласно
        норм СанПин.
      </p>
      <p>Для абитуриентов выделено 115 мест для юношей и 7 мест для девушек.</p>

      <p>Адрес общежития: г.Уфа, ул. Ахметова, 275. </p>
      <div className="scroll-table struct__scrollTable">
        <table>
          <tbody>
            <tr>
              <td>Наименование показателя</td>
              <td>Общежития</td>
              <td>Интернаты</td>
            </tr>
            <tr>
              <td>Количество общежитий, интернатов </td>
              <td>1</td>
              <td>Отсутствуют</td>
            </tr>

            <tr>
              <td itemProp="hostelNum">Количество мест</td>
              <td>516</td>
              <td>Нет</td>
            </tr>

            <tr>
              <td itemProp="hostelFd">
                Количество жилых помещений, приспособленных для использования
                инвалидами и лицами с ограниченными возможностями здоровья
              </td>
              <td>1</td>
              <td>Нет</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul>
        <li className="doc__item">
          <PDF height={"25px"} width={"25px"} />
          <a href={polojen} target={"_blank"} rel="noreferrer">
            Положение о студенческом общежитии
          </a>
        </li>
      </ul>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Локальные нормативные акты, регламентирующие формирование платы за
        пользование жилым помещением и коммунальные услуги в общежитии
      </h3>
      <ul>
        <li className="doc__item ">
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="localActObSt"
            href={smeta}
            target={"_blank"}
            rel="noreferrer"
          >
            Смета стоимости проживания в общежитии
          </a>
        </li>
      </ul>
    </div>
  );
});
