import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import { observer } from "mobx-react-lite";
export const AntiCorruptionExpertise = observer(() => {
  return (
    <>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Антикоррупционная экспертиза
      </h2>
      <ul>
        <li>
          <a
            href="https://regulation.gov.ru/projects/List/AdvancedSearch?q=антикоррупционная+экспертиза&departmentId="
            target={"_blank"}
            rel="noreferrer"
          >
            Антикоррупционная экспертиза
          </a>
        </li>
      </ul>
    </>
  );
});
