import { makeAutoObservable } from "mobx";
import {
  createEmployee as createNewEmployee,
  fetchEmployee,
  fetchOneEmployee,
} from "../httpService/employee-api";

export const EmployeeStore = makeAutoObservable({
  employee: [],
  isLoadingEmployee: false,
  errorEmployee: null,
  isCreateEmployeeLoading: false,
  errorOfCreateingEmployee: null,
  isEditedEmployeeLoading: false,
  errorEditedEmployee: null,
  currentEmployee: null,
  isLoadingCurrentEmployee: true,
  *loadEmployee() {
    EmployeeStore.isLoadingEmployee = true;
    try {
      const data = yield fetchEmployee();
      if (data) {
        EmployeeStore.employee = data;
      } else {
        EmployeeStore.errorEmployee = "Данные не найдены";
      }
    } catch (e) {
      if (e) EmployeeStore.error = e.message;
    } finally {
      EmployeeStore.isLoadingEmployee = false;
    }
  },
  *loadOneEmployee(id) {
    EmployeeStore.currentEmployee = null;
    EmployeeStore.isLoadingCurrentEmployee = true;
    try {
      const data = yield fetchOneEmployee(id);

      if (data) {
        EmployeeStore.isLoadingCurrentEmployee = false;
        EmployeeStore.currentEmployee = data.data;
      } else {
        EmployeeStore.errorEmployee = "Данные не найдены";
      }
    } catch (e) {
      if (e) EmployeeStore.error = e.message;
    } finally {
      EmployeeStore.isLoadingCurrentEmployee = false;
    }
  },
  *createEmployee(employee, reset) {
    EmployeeStore.errorOfCreateingEmployee = null;
    EmployeeStore.isCreateEmployeeLoading = true;
    try {
      const data = yield createNewEmployee(employee);
      if (data) {
        EmployeeStore.employee.push(data);
        reset();
      } else {
        EmployeeStore.errorOfCreateingEmployee =
          "Что-то пошло не так, попробуйте обновить страницу";
      }
    } catch (e) {
      if (e) EmployeeStore.errorOfCreateingEmployee = e.response.data.message;
    } finally {
      EmployeeStore.isCreateEmployeeLoading = false;
    }
  },

  /*  *editCurrentEmployee(currentNews) {
    EmployeeStore.isEditedNewsLoading = true;
    try {
      const data = yield editCurrentNews(currentNews);
      if (data) {
        // eslint-disable-next-line array-callback-return
        EmployeeStore.news = EmployeeStore.news.map((e) => {
          if (e.id === currentNews.id) {
            return { ...data };
          }
        });
      } else {
        EmployeeStore.errorEditedNews =
          "Что-то пошло не так, новость не создана :(";
      }
    } catch (e) {
      if (e) EmployeeStore.errorEditedNews = e.message;
    } finally {
      EmployeeStore.isEditedNewsLoading = false;
    }
  }, */
});
