import React from "react";
import { NavLink } from "react-router-dom";
import RPV_SM from "../../../../../assets/pdf/educationPage/260205/СМ 2022 гп/ВР СМ 2022-2023.pdf";
import Image16 from "../../../../../assets/pdf/educationPage/260205/СМ 2022 гп/5. Календарный учебный график.pdf";
import OOP9 from "../../../../../assets/pdf/educationPage/260205/opisanie/ОП ППССЗ  26. 02. 05 Эксплуатация судовых энергетических установок 2023 г.п. (Восстановлен).pdf";
import Annot9 from "../../../../../assets/pdf/educationPage/260205/annot/Аннотации программ дисциплин и проф.модулей 26.02.05.Эксплуатация судовых энергетических установок, 2023г.п..doc";
import UP12 from "../../../../../assets/pdf/educationPage/260205/uchebnie_plani/26.02.05 2024-25 1 курс.pdf";
export const SPOEkspluatSudovEnergUstanovok_24_260205 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">26.02.05</td>
      <td>
        <NavLink
          itemProp="eduName"
          to="../../educationPrograms/shipPowerPlants2024"
        >
          Эксплуатация судовых энергетических установок ФГОС №674 от 26.11.2020
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink
          itemProp="eduProf"
          to="../../educationPrograms/shipPowerPlants2024"
        >
          Эксплуатация судовых энергетических установок (прием 2024)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная-заочная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP9} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP12}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationAnnotation"
              href={Annot9}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/shipPowerPlants2024"
            >
              Скачать РП
            </NavLink>
          </li>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/shipPowerPlants2024"
            >
              Скачать РП РЦ
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image16}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/shipPowerPlants2024"
            >
              Скачать
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a href={RPV_SM} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a href={"KPV"} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <NavLink to={"../../local-acts"}>
              Локальные нормативные акты
            </NavLink>
          </li>
        </ul>
      </td>
    </tr>
  );
};
