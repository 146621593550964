import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import matemat from "../../../../assets/pdf/educationPage/260203/2024/1. Математика 26.02.03, РУП 26.pdf";
import fizra1 from "../../../../assets/pdf/educationPage/260203/2024/2. СВ Физкультура   2-5 курс новый.pdf";
import bz from "../../../../assets/pdf/educationPage/260203/2024/3. БЖ СВ 26.02.03 РУП 26 2024 гп.pdf";
import biologiya from "../../../../assets/pdf/educationPage/260203/2024/4. Биология СВ РУП 26, 2024.pdf";
import geografiya from "../../../../assets/pdf/educationPage/260203/2024/5. География 26.02.03 РУП 26, 2024.pdf";
import ingenernGraf from "../../../../assets/pdf/educationPage/260203/2024/6. Инженерная графика СВ РУП 26 2024.pdf";
import inYaz from "../../../../assets/pdf/educationPage/260203/2024/7. Иностранный язык 1к.СВ 26.02.03 РУП 26, 2024.pdf";
import inform1 from "../../../../assets/pdf/educationPage/260203/2024/8. Информатика 1 курс 26.02.03, 2024 г.п., РУП 26.pdf";
import inform2 from "../../../../assets/pdf/educationPage/260203/2024/9. Информатика 26.02.03, 2024 г.п., РУП 26.pdf";
import istoriya from "../../../../assets/pdf/educationPage/260203/2024/10. История 1 курс РУП 24 26.02.03, 2024 гп.pdf";
import istoriya2 from "../../../../assets/pdf/educationPage/260203/2024/11. История 2 курс 26.02.03, РУП 26 2024 г.п..pdf";
import iya from "../../../../assets/pdf/educationPage/260203/2024/12. ИЯ в ПД 2-5 курс СВ 26.02.03.pdf";
import liter from "../../../../assets/pdf/educationPage/260203/2024/13. Литература 26.02.03, 2024 г.п..pdf";
import matem2 from "../../../../assets/pdf/educationPage/260203/2024/14. Математика 2 курс  26.02.03, 2024 г.п., РУП 26.pdf";
import material from "../../../../assets/pdf/educationPage/260203/2024/15. Материаловедение 26.02.03, РУП 26.pdf";
import metrol from "../../../../assets/pdf/educationPage/260203/2024/16. Метрология и стандартизация 26.02.03. РУП 26 2024 гп.pdf";
import mehanika from "../../../../assets/pdf/educationPage/260203/2024/17. Механика 26.02.03. РУП 26.pdf";
import obiz from "../../../../assets/pdf/educationPage/260203/2024/18. ОБиЗР 26.02.03, РУП 26 2024 г.п..pdf";
import obshestvo from "../../../../assets/pdf/educationPage/260203/2024/19. Обществознание СВ 26.02.03, 2024.pdf";
import filosof from "../../../../assets/pdf/educationPage/260203/2024/20. Основы философии 26.02.03, РУП 26 2024 г.п..pdf";
import fingram from "../../../../assets/pdf/educationPage/260203/2024/21. Основы финансовой грамотности 26.02.03, РУП 26 2024 г.pdf";
import pm02 from "../../../../assets/pdf/educationPage/260203/2024/22. ПМ 02 26.02.03 2024 гп  РУП 26.pdf";
import pm03 from "../../../../assets/pdf/educationPage/260203/2024/23. ПМ 3 СВ  2024 гп РУП 26.pdf";
import pm04 from "../../../../assets/pdf/educationPage/260203/2024/24. ПМ 4 26.02.03.РУП 26.pdf";
import pm05 from "../../../../assets/pdf/educationPage/260203/2024/25. ПМ1 26.02.03   РУП 26 2024.pdf";
import pm06 from "../../../../assets/pdf/educationPage/260203/2024/26. ПМ05.pdf";
import psihObsh from "../../../../assets/pdf/educationPage/260203/2024/27. Психология общения 26.02.03, РУП 26.pdf";
import rusYaz from "../../../../assets/pdf/educationPage/260203/2024/28. Русский язык РУП 26  26.02.03 2024гп.pdf";
import termodin from "../../../../assets/pdf/educationPage/260203/2024/29. Техническая термодинамика и теплопередача 26.02.03, 2024 г.п. РУП 26.pdf";
import tus from "../../../../assets/pdf/educationPage/260203/2024/30. ТУС 26.02.03, РУП 26 2024 г.п..pdf";
import fizika from "../../../../assets/pdf/educationPage/260203/2024/31. Физика 26.02.03, РУП 26 2024.pdf";
import fizra from "../../../../assets/pdf/educationPage/260203/2024/32. Физкультура  1 курс 26.02.03 РУП 26 2024 гп.pdf";
import himiya from "../../../../assets/pdf/educationPage/260203/2024/33. Химия 26.02.06, РУП 26   2024 г.п..pdf";
import electronica from "../../../../assets/pdf/educationPage/260203/2024/34. Электроника и электротехника  26.02.03. РУП 26.pdf";
import eop from "../../../../assets/pdf/educationPage/260203/2024/35. ЭОП 26.02.03,  РУП 26 2024 г.п..pdf";

export const Navigation2024 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.03 "Судовождение"
        (углубленная подготовка) (год набора 2024)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matemat}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 2-5 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={bz}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнидеятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={biologiya}
                target={"_blank"}
                rel="noreferrer"
              >
                Биология
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={geografiya}
                target={"_blank"}
                rel="noreferrer"
              >
                География
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={ingenernGraf}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform1}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform2}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istoriya}
                target={"_blank"}
                rel="noreferrer"
              >
                История 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istoriya2}
                target={"_blank"}
                rel="noreferrer"
              >
                История 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={iya}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык в профессионлаьной деятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={liter}
                target={"_blank"}
                rel="noreferrer"
              >
                Литература
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem2}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={material}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrol}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология и стандартизация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mehanika}
                target={"_blank"}
                rel="noreferrer"
              >
                Механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obiz}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы безопасности и защиты родины
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obshestvo}
                target={"_blank"}
                rel="noreferrer"
              >
                Обществознание
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={filosof}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы философии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fingram}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы финансовой грамотности в профессиональной деятельности
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm05}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 01 Управление и эксплуатация судна с правом эксплуатации
                судовых энергетических установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm02}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 02 Обеспечение безопасности плавания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm03}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 03 Обработка и размещение груза
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm04}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 04 Анализ эффективности работы судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm06}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 05 Выполнение работ по одной или нескольким профессиям
                рабочих, должностям служащих
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={psihObsh}
                target={"_blank"}
                rel="noreferrer"
              >
                Психология общения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rusYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Русский язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={termodin}
                target={"_blank"}
                rel="noreferrer"
              >
                Техническая термодинамика и теплопередача
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tus}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория устройства судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizika}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>33</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={himiya}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>34</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={electronica}
                target={"_blank"}
                rel="noreferrer"
              >
                Электроника и электротехника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>35</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={eop}
                target={"_blank"}
                rel="noreferrer"
              >
                Экологические основы природопользования
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
