import React, { useContext, useState } from "react";
import { Context } from "../../../../../../..";
import { MonitoringDocsStore } from "../../../../../../store/monitoring-docs-store";
import { toggleClassName } from "../../../../../../utils/disabled";
import { ReactComponent as DOC } from "../../../../../../assets/svg/office/word.svg";
import { Button, Modal } from "antd";
import classes from "../style/monitoring.module.scss";
import { observer } from "mobx-react-lite";
export const ListOfDocs = observer(({ category, educationLvl }) => {
  const { user } = useContext(Context);
  const { monitoringDocs, removeMonitoringDocs, errorMonitoringDocs } =
    MonitoringDocsStore;
  const monitoringDocsListsOfPersonsWhoAppliedForAdmission =
    monitoringDocs && monitoringDocs.filter((d) => d.category === category);
  const data = monitoringDocsListsOfPersonsWhoAppliedForAdmission.filter(
    (d) => d.educationLvl === educationLvl
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const successRemoove = () => {
    setIsModalOpen(false);
    setCurrentId("");
    setRemoveLoading(false);
  };
  const [errorDelite, setErrorDelite] = useState(
    errorMonitoringDocs && "Не удалось удалить документ!"
  );
  const onDelete = (id) => {
    setErrorDelite("");
    showModal();
    setCurrentId(id);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrentId("");
  };
  const handleOk = () => {
    setRemoveLoading(true);
    removeMonitoringDocs(currentId, successRemoove);
  };

  if (!data || data.length === 0) {
    return "Документы будут добавлены позже";
  }
  return (
    <section className={classes.listOfDocs}>
      {data.map((d) => (
        <div className={classes.listOfDocsItem} key={d.id}>
          <div>
            <a
              href={process.env.REACT_APP_IMAGES_API_URL + d.file}
              target={"_blank"}
              rel="noreferrer"
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              <DOC
                width={"25px"}
                height={"25px"}
                style={{ marginLeft: "15px", marginRight: "15px" }}
              />
              {d.title}
            </a>
          </div>
          {user.auth && user.user.role === "ADMIN" && (
            <Button onClick={() => onDelete(d.id)} className={"delite"}>
              Удалить
            </Button>
          )}
        </div>
      ))}
      <Modal
        title="Удалить документ?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Удалить"
        okType="danger"
      >
        <p>{removeLoading ? "Загрузка" : "Это документ будет удален!"}</p>
        <p>{errorDelite}</p>
      </Modal>
    </section>
  );
});
