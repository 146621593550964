import React from "react";
import PropTypes from "prop-types";
import Ustav from "../../../../assets/pdf/documentPage/Ustav_2017.pdf";
import UstavChange1415 from "../../../../assets/pdf/documentPage/rasp_ustav_2015.pdf";
import UstavChange17 from "../../../../assets/pdf/documentPage/rasp_ustav_2017.pdf";
import UstavChange18 from "../../../../assets/pdf/documentPage/rasp_ustav_2018.pdf";
import UstavChange19 from "../../../../assets/pdf/documentPage/rasp_ustav_2019.pdf";
import UstavChange21 from "../../../../assets/pdf/documentPage/rasp_ustav_2021.pdf";
import UstavChange23 from "../../../../assets/pdf/documentPage/Изменения в устав от 08.09.2023.pdf";
import listZapisi from "../../../../assets/pdf/documentPage/Лист записи от 08.09.2023.pdf";
import Pologen22 from "../../../../assets/pdf/documentPage/Pologen_2022.pdf";
import Pologen22Dop from "../../../../assets/pdf/documentPage/Pologen_dop.pdf";
/* import LicenzVip from "../../../../assets/pdf/documentPage/Реестровая выписка.pdf";
import vipiskaIzReestra from "../../../../assets/pdf/documentPage/Выписка из Реестра о Гос.аккредитации от 18.03.2024.pdf";
 */
import Plan_FHD from "../../../../assets/doc/documentPage/План ФХД.docx";

import PolozOvz from "../../../../assets/pdf/documentPage/Pologenie_ucheb_prozess_ovz_2017.pdf";
import PolozPromItog from "../../../../assets/pdf/documentPage/Pologenie_promegut_itogov_attest2017.pdf";
import PolozPromItogDot from "../../../../assets/pdf/documentPage/Pologenie_promegut_itogov_attest2017Dot.PDF";
import Pologenie_GIA from "../../../../assets/pdf/documentPage/Pologenie_GIA.pdf";
import Perev21 from "../../../../assets/pdf/documentPage/Perevod_UF_2021.pdf";
import Otchisl24 from "../../../../assets/pdf/documentPage/Otchisl_UF_2021.pdf";

import Otchisl17 from "../../../../assets/pdf/documentPage/Otnoshenie_2017.pdf";

import TrudRasp17 from "../../../../assets/pdf/documentPage/Pravila_trud_rasporyadka_2017.pdf";
import KolDogov from "../../../../assets/pdf/documentPage/Kol_dogovor_2017.pdf";
import KolDogovPril from "../../../../assets/pdf/documentPage/Pril_kol_dogovor_2017.pdf";
import KolDogovDop from "../../../../assets/pdf/documentPage/kol_dogovor_dop.pdf";
import SamoobsOrchet19 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2019.pdf";
import SamoobsOrchet20 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2020.pdf";
import SamoobsOrchet21 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2021.pdf";
import SamoobsOrchet22 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2022.pdf";
import SamoobsOrchet23 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2023.pdf";

import PoryadPlatnUslug from "../../../../assets/pdf/documentPage/Poryadok_platn_uslug.pdf";
import PoryadPlatnObrUslug21 from "../../../../assets/pdf/applicant/Dogovor_platn_obraz_uslug_2024.pdf";

import { ReactComponent as WORD } from "../../../../assets/svg/office/word.svg";
import { ReactComponent as EXCEL } from "../../../../assets/svg/office/excel.svg";

import prikazStoimost from "../../../../assets/pdf/enrolle/приказ № 187-с от 17.04.2024 о стоимости обучения.pdf";
import prikazStoimostPril from "../../../../assets/pdf/enrolle/Приложение к приказу №187-с от 17.04.2024 г. О стоимости обучения на 2024-2025 уч год в Уфимском филиале.docx";

import prikaz221s from "../../../../assets/pdf/applicant/приказ № 221-с от 07.05.2024 стоим.обуч..pdf";
import prilPrikaz221s from "../../../../assets/pdf/applicant/стоимость обучения 1 курс.xlsx";
import smeta from "../../../../assets/pdf/documentPage/Смета общ с 01.07.24.pdf";
import prikaz_286 from "../../../../assets/pdf/documentPage/Приказ о стоим общ 286 от 20.06.24.pdf";
import PolStDorm18 from "../../../../assets/pdf/documentPage/Pol_st_dorm_2024.pdf";
import Predpis from "../../../../assets/pdf/documentPage/predpisanie_transp_prok1.pdf";
import PismoTransp from "../../../../assets/pdf/documentPage/pismo_transp_prok_2020.pdf";

import Pismo_pozh from "../../../../assets/pdf/documentPage/pismo_pozh.pdf";
import Zakl_pozh2 from "../../../../assets/pdf/documentPage/zakl_pozh_2.pdf";
import Zakl_pozh3 from "../../../../assets/pdf/documentPage/zakl_pozh_3.pdf";
import ZaklSanitar1 from "../../../../assets/pdf/documentPage/zakl_sanitar_1.pdf";
import ZaklSanitar2 from "../../../../assets/pdf/documentPage/zakl_sanitar_2.pdf";
import ZaklSanitar5 from "../../../../assets/pdf/documentPage/zakl_sanitar_5.pdf";
import DoDop from "../../../../assets/pdf/documentPage/DO_dop.pdf";
import PolozPd from "../../../../assets/pdf/documentPage/Pologenie_PD.pdf";
import { DropDown } from "../../../common";

import vipiska_iz_reestra11042023 from "../../../../assets/pdf/documentPage/vipiska_iz_reestra11.04.2023.pdf";
import SEZ_predreisovaya from "../../../../assets/pdf/documentPage/SEZ_predreisovaya.pdf";
import polojenie_ob_electron_obr_srede from "../../../../assets/pdf/documentPage/ПЛЖ об ЭИОС 2017.pdf";
import polOtp from "../../../../assets/pdf/documentPage/Положение об академическом отпуске.pdf";
import prikaz_63 from "../../../../assets/pdf/documentPage/приказ № 63 от 26.03.2024 утв. ПЛЖ о курсовой работе.pdf";
import poloz_o_poryadke from "../../../../assets/pdf/documentPage/Положение о порядке выполнения и защиты обучающимися курсовой работы.pdf";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { ReactComponent as DOC } from "../../../../assets/svg/office/word.svg";
import { toggleClassName } from "../../../../utils/disabled";
import prikaz151 from "../../../../assets/pdf/documentPage/local/Приказ 151.pdf";
import polObrDeyat from "../../../../assets/pdf/documentPage/local/Положение о реализации образовательной деятельности.pdf";
import raspOIzm from "../../../../assets/pdf/documentPage/Распоряжение от 03.06.24.pdf";
import raspOIzm17 from "../../../../assets/pdf/documentPage/Распоряжение от 17.01.2023.pdf";
import raspOIzmastr from "../../../../assets/pdf/documentPage/Изменения в устав от 22.11.2019  Астрахань.pdf";
import raspOIzm11 from "../../../../assets/pdf/documentPage/Распоряжение от 11.04.2022.pdf";
import raspOIzm1105 from "../../../../assets/pdf/documentPage/Распоряжение от 11.05.2023.pdf";
import dogovorObsh from "../../../../assets/doc/documentPage/ДОГОВОР 2024г курсанты образец.docx";

import { HrLine } from "../../../common";

export const MainDocs = ({ isActive }) => {
  return (
    <>
      <div className={`${isActive ? "doc_item-show" : "doc_item-hidden"}`}>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Уставные документы"}
        >
          <ul>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Ustav} target={"_blank"} rel="noreferrer">
                Устав
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={raspOIzm} target={"_blank"} rel="noreferrer">
                Распоряжение о внесении изменений в устав от 03.06.2024
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={raspOIzm17} target={"_blank"} rel="noreferrer">
                Распоряжение от 17.01.2023
              </a>
            </li>

            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={raspOIzm1105} target={"_blank"} rel="noreferrer">
                Распоряжение от 11.05.2023
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={raspOIzm11} target={"_blank"} rel="noreferrer">
                Распоряжение от 11.04.2022
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={raspOIzmastr} target={"_blank"} rel="noreferrer">
                Изменения в устав от 22.11.2019 Астрахань
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange23} target={"_blank"} rel="noreferrer">
                Распоряжение о внесении изменений в Устав от 08.09.2023г
                3Д-320-р
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange21} target={"_blank"} rel="noreferrer">
                Распоряжение о внесении изменений в Устав 2021 - №АЛ-344-р от
                11.08.2021
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange19} target={"_blank"} rel="noreferrer">
                Распоряжение в внесении изменений в Устав 2019 - №ЮЦ-55-р от
                04.03.19
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange18} target={"_blank"} rel="noreferrer">
                Распоряжение в внесении изменений в Устав 2018
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange17} target={"_blank"} rel="noreferrer">
                Распоряжение о внесении изменений в Устав 2017
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={UstavChange1415} target={"_blank"} rel="noreferrer">
                Распоряжение о внесении изменений в Устав 2014,2015
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={listZapisi} target={"_blank"} rel="noreferrer">
                Лист записи ЕГРЮЛ о внесении изменений в Устав 2023 года
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pologen22} target={"_blank"} rel="noreferrer">
                Положение о филиале{" "}
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pologen22Dop} target={"_blank"} rel="noreferrer">
                Изменения в Положение о филиале (приказ №42 от 01.04.2022)
              </a>
            </li>
          </ul>
        </DropDown>
        {/*   <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Свидетельство об аккредитации и лицензиях"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={LicenzVip} target={"_blank"} rel="noreferrer">
                Выписка из реестра лицензий Федеральной службы по надзору в
                сфере образования и науки (2.04.2024)
              </a>
            </li>
          </ul>

          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="accreditationDocLink"
                href={vipiskaIzReestra}
                target={"_blank"}
                rel="noreferrer"
              >
                Выписка из Реестра о Государственной аккредитации от
                18.03.2024г.
              </a>
            </li>
          </ul>
        </DropDown> */}
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"План ФХД"}
        >
          <ul>
            <li className="doc__item">
              <DOC height={"40px"} width={"40px"} />
              <a href={Plan_FHD} target={"_blank"} rel="noreferrer">
                План финансово-хозяйственной деятельности
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={
            "Локальные акты, регламентирующие образовательную деятельность"
          }
        >
          <h3
            className={`common__container-title ${toggleClassName(
              "title",
              "title-white",
              "title-black",
              "title-contrast"
            )}`}
          >
            Документы, регламентирующие формы, периодичность и порядок текущего
            контроля успеваемости и промежуточной аттестации обучающихся
          </h3>
          <HrLine className="document__hrLine" />
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PolozOvz} target={"_blank"} rel="noreferrer">
                Положение об организации учебного процесса для инвалидов и лиц с
                ограниченными возможностями здоровья
              </a>
            </li>
            <li itemProp="tekKontrolDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PolozPromItog} target={"_blank"} rel="noreferrer">
                Положение о проведении текущего контроля и промежуточной
                аттестации обучающихся
              </a>
            </li>
            <li itemProp="tekKontrolDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PolozPromItogDot} target={"_blank"} rel="noreferrer">
                Положение о проведении текущего контроля и промежуточной
                аттестации обучающихся c применением дистанционных
                образовательных технологий
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pologenie_GIA} target={"_blank"} rel="noreferrer">
                Положение об итоговой аттестации выпускников
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={DoDop} target={"_blank"} rel="noreferrer">
                Положение о порядке применения дистанционных образовательных
                технологий и электронного обучения при реализации программ
                дополнительного образования детей и взрослых, дополнительного
                профессионального образования и профессионального обучения
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                href={polojenie_ob_electron_obr_srede}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение об электронной информационно-образовательной среде
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={prikaz_63} target={"_blank"} rel="noreferrer">
                Приказ № 63 от 26.03.2024 утв. ПЛЖ о курсовой работе
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={poloz_o_poryadke} target={"_blank"} rel="noreferrer">
                Положение о порядке выполнения и защиты обучающимися курсовой
                работы
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={polObrDeyat} target={"_blank"} rel="noreferrer">
                Положение о реализации образовательной деятельности с
                применением электронного обучения и дистанционных
                образовательных технологий
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={prikaz151} target={"_blank"} rel="noreferrer">
                Приказ 151
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Перевод и восстановление"}
        >
          <ul>
            <li itemProp="perevodDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Perev21} target={"_blank"} rel="noreferrer">
                Положение о переводе и восстановлении обучающихся
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Otchisl24} target={"_blank"} rel="noreferrer">
                Положение об отчислении обучающихся{" "}
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={polOtp} target={"_blank"} rel="noreferrer">
                Положение об академическом отпуске
              </a>
            </li>
            <li className="doc__item">
              <a href={"/sveden/education"} target={"_blank"} rel="noreferrer">
                Информация о результатах перевода, восстановления и отчисления
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Локальные акты, регламентирующие трудовую деятельность"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="vozDocLink"
                href={Otchisl17}
                target={"_blank"}
                rel="noreferrer"
              >
                Порядок оформления возникновения, приостановления и прекращения
                отношений между образовательной организацией и обучающимися и
                (или) родителями (законными представителями) несовершеннолетних
                обучающихся
              </a>
            </li>
          </ul>
          <HrLine className="document__hrLine" />
          <ul>
            <li itemProp="localActOrder" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={TrudRasp17} target={"_blank"} rel="noreferrer">
                Правила внутреннего трудового распорядка
              </a>
            </li>
          </ul>
          <HrLine className="document__hrLine" />
          <ul>
            <li itemProp="localActCollec" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={KolDogov} target={"_blank"} rel="noreferrer">
                Коллективный договор
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={KolDogovPril} target={"_blank"} rel="noreferrer">
                Приложение к коллективному договору{" "}
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={KolDogovDop} target={"_blank"} rel="noreferrer">
                Доп соглашение к коллективному договору
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PolozPd} target={"_blank"} rel="noreferrer">
                Положение о защите персональных данных
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Отчеты о самообследовании"}
        >
          <ul>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet23} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2023 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet22} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2022 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet21} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2021 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet20} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2020 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet19} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2019 год
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Платные услуги"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PoryadPlatnUslug} target={"_blank"} rel="noreferrer">
                Положение об оказании платных образовательных услуг
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                href={PoryadPlatnObrUslug21}
                target={"_blank"}
                rel="noreferrer"
              >
                Образец договора об оказании платных образовательных услуг
              </a>
            </li>
            <li>
              <PDF width={"40px"} height={"40px"} />{" "}
              <a
                href={prikaz221s}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
                target={"_blank"}
                rel="noreferrer"
              >
                Приказ № 221-с от 7.05.2024 о стоимости обучения поступающих на
                1 курс в 2024 учебном году по договорам об образовании
              </a>
            </li>
            <li>
              <EXCEL width={"40px"} height={"40px"} />{" "}
              <a
                href={prilPrikaz221s}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
                target={"_blank"}
                rel="noreferrer"
              >
                Приложение к приказу № 221-с от 7.05.2024 Стоимость обучения для
                поступающих на 1 курс в 2024 году по программам высшего и
                среднего профессионального образования, обучающихся по договорам
                об образовании за счет средств физических и/или юридических лиц
                в Уфимский филиал ФГБОУ ВО «ВГУВТ»
              </a>
            </li>
            <li>
              <PDF width={"40px"} height={"40px"} />{" "}
              <a
                href={prikazStoimost}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
                target={"_blank"}
                rel="noreferrer"
              >
                Приказ № 187-с от 17.04.2024 о стоимости обучения
              </a>
            </li>
            <li>
              <WORD width={"40px"} height={"40px"} />{" "}
              <a
                href={prikazStoimostPril}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
                target={"_blank"}
                rel="noreferrer"
              >
                Приложение к приказу №187-с от 17.04.2024 г. О стоимости
                обучения на 2024-2025 уч год в Уфимском филиале
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Общежитие"}
        >
          <ul>
            <li className="doc__item">
              <WORD height={"40px"} width={"40px"} />
              <a href={dogovorObsh} target={"_blank"} rel="noreferrer">
                Договор найма жилого помещения в студенческом общежитии
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={smeta} target={"_blank"} rel="noreferrer">
                Смета стоимости проживания в общежитии с 01.07.2024
              </a>
            </li>{" "}
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={prikaz_286} target={"_blank"} rel="noreferrer">
                Приказ о стоимости общежития №286 от 20.06.24
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PolStDorm18} target={"_blank"} rel="noreferrer">
                Положение о студенческом общежитии
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Предписания и отчеты"}
        >
          <h3
            className={`common__container-title ${toggleClassName(
              "title",
              "title-white",
              "title-black",
              "title-contrast"
            )}`}
          >
            Предписания органов, осуществляющих государственный контроль в сфере
            образования и отчеты об их исполнении:
          </h3>
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={Predpis}
                target={"_blank"}
                rel="noreferrer"
              >
                Предписание Уфимской транспортной прокуратуры № 23/1-5-2020 от
                09.09.2020
              </a>
            </li>
          </ul>
          <HrLine className="document__hrLine" />
          <h3
            className={`common__container-title ${toggleClassName(
              "title",
              "title-white",
              "title-black",
              "title-contrast"
            )}`}
          >
            Отчет об исполнении Предписаний:
          </h3>
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PismoTransp} target={"_blank"} rel="noreferrer">
                Письмо на предписание Уфимской транспортной прокуратуры №
                23/1-5-2020 от 09.09.2020 ЭЦП
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Заключения санэпиднадзора и противопожарной службы"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pismo_pozh} target={"_blank"} rel="noreferrer">
                Письмо МЧС России № 533-2-4-28 от 17.05.2019
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Zakl_pozh2} target={"_blank"} rel="noreferrer">
                Заключение №296/02 о соответствии объекта защиты обязательным
                требованиям пожарной безопасности
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Zakl_pozh3} target={"_blank"} rel="noreferrer">
                Заключение №96/02 о соответствии объекта защиты обязательным
                требованиям пожарной безопасности (стадион "Нефтяник")
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={ZaklSanitar1} target={"_blank"} rel="noreferrer">
                Санитарно-эпидемиологическое заключение на здания, строения,
                сооружения, помещения, оборудование и иное имущество,
                используемое для осуществления образовательной деятельности по
                программам среднего профессионального образования (с
                приложением) по адресу г. Уфа, ул. Ахметова, д. 275
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={ZaklSanitar2} target={"_blank"} rel="noreferrer">
                Санитарно-эпидемиологическое заключение на здания, строения,
                сооружения, помещения, оборудование и иное имущество,
                используемое для осуществления образовательной деятельности по
                программам дополнительного образования и профессионального
                обучения
              </a>
            </li>

            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SEZ_predreisovaya} target={"_blank"} rel="noreferrer">
                Санитарно-эпидемиологическое заключение на здания, строения,
                сооружения и (или) помещения для осуществления медицинской
                деятельности по адресу г. Уфа, ул. Ахметова, д. 275
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                href={vipiska_iz_reestra11042023}
                target={"_blank"}
                rel="noreferrer"
              >
                Выписка из реестра лицензий по состоянию на 15:02 11.04.2023 г.
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={ZaklSanitar5} target={"_blank"} rel="noreferrer">
                Санитарно-эпидемиологическое заключение на здания, строения,
                сооружения, помещения, оборудование и иное имущество,
                используемое для осуществления образовательной деятельности по
                программам среднего профессионального образования (с
                приложением) по адресу г. Уфа, ул. Союзная
              </a>
            </li>
          </ul>
        </DropDown>
      </div>
    </>
  );
};
MainDocs.propTypes = {
  isActive: PropTypes.bool,
};
