import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import bz from "../../../../assets/pdf/educationPage/260205/2024/1. БЖ  26.02.05, 2024 г.п. РУП 14.pdf";
import biologiya from "../../../../assets/pdf/educationPage/260205/2024/2. Биология СМ РУП 14.pdf";
import geografiya from "../../../../assets/pdf/educationPage/260205/2024/3. География 26.02.05 РУП 14 2024 гп.pdf";
import inzGraf from "../../../../assets/pdf/educationPage/260205/2024/5. Инженерная графика 26.02.05, 2024 г.п. РУП 14.pdf";
import inYaz from "../../../../assets/pdf/educationPage/260205/2024/4. Ин.яз. в проф.деят. 2-4 курс СМ 26.02.05.pdf";
import unYaz1 from "../../../../assets/pdf/educationPage/260205/2024/6. Иностранный язык 1к.  26.02.05 РУП 14.pdf";
import inform from "../../../../assets/pdf/educationPage/260205/2024/7. Информатика  23.02.05. 2024 гп РУП 14.pdf";
import inform1k from "../../../../assets/pdf/educationPage/260205/2024/8. Информатика 1 курс 26.02.05, 2024 г.п., РУП 14.pdf";
import istor1 from "../../../../assets/pdf/educationPage/260205/2024/9. История 1 курс  26.02.05, 2024 г.п..pdf";
import istor2 from "../../../../assets/pdf/educationPage/260205/2024/10. История 26.02.05, 2024  г.п., РУП 14.pdf";
import liter from "../../../../assets/pdf/educationPage/260205/2024/11. Литература 26.02.05, 2024 г.п..pdf";
import matem1 from "../../../../assets/pdf/educationPage/260205/2024/12. Математика 2 курс 26.02.05  2024гп РУП 14.pdf";
import mater1 from "../../../../assets/pdf/educationPage/260205/2024/13. Материаловедение 26.02.05, 2024 г.п. РУП 14.pdf";
import metrol from "../../../../assets/pdf/educationPage/260205/2024/14. Метрология и стандартизация 26.02.05, 2024 г.п. РУП 14.pdf";
import mehan from "../../../../assets/pdf/educationPage/260205/2024/15. Механика 26.02.05, 2024 г.п. РУП 14 ,.pdf";
import obiz from "../../../../assets/pdf/educationPage/260205/2024/16. ОБиЗР 26.02.05, РУП 14 2024 г.п..pdf";
import obshest from "../../../../assets/pdf/educationPage/260205/2024/17. Обществознание СМ РУП 14  2024гп.pdf";
import filosof from "../../../../assets/pdf/educationPage/260205/2024/18. Основы философии 26.02.05, 2024 г.п. РУП 14.pdf";
import finGram from "../../../../assets/pdf/educationPage/260205/2024/19. Основы финансовой грамотности 26.02.05, 2023 г.п. РУП 13.pdf";
import pm1 from "../../../../assets/pdf/educationPage/260205/2024/20. ПМ 1, 26.02.05, 2024 г.п. РУП 14.pdf";
import pm2 from "../../../../assets/pdf/educationPage/260205/2024/21. ПМ 02, 26.02.05, 2024 г.п., РУП 14.pdf";
import pm3 from "../../../../assets/pdf/educationPage/260205/2024/22. ПМ 3, 26.02.05, 2024г.п. РУП 14.pdf";
import pm4 from "../../../../assets/pdf/educationPage/260205/2024/23. ПМ 4, 26.02.05, 2024 г.п. РУП 14.pdf";
import psihObsh from "../../../../assets/pdf/educationPage/260205/2024/24. Психология общения 26.02.05, 2024 г.п. РУП 14.pdf";
import rusYaz from "../../../../assets/pdf/educationPage/260205/2024/25. Русский язык 26.02.05.pdf";
import sm from "../../../../assets/pdf/educationPage/260205/2024/26. СМ 26.02.05, РУП13 2024 г.п.pdf";
import fizRa from "../../../../assets/pdf/educationPage/260205/2024/27. СМ Физкультура 2-4 курс 26.02.05, 2024 г.п. РУП 14.pdf";
import termodin from "../../../../assets/pdf/educationPage/260205/2024/28. Техническая термодинамика и теплопередача 26.02.05, 2023 г.п.pdf";
import tus from "../../../../assets/pdf/educationPage/260205/2024/29. ТУС 26.02.05, 2024  г.п. РУП 14.pdf";
import fizika from "../../../../assets/pdf/educationPage/260205/2024/30. Физика 26.02.05, 2024. РУП 14.pdf";
import fizra1 from "../../../../assets/pdf/educationPage/260205/2024/31. Физкультура  1 курс 26.02.05 2024 гп РУП 14.pdf";
import him from "../../../../assets/pdf/educationPage/260205/2024/32. Химия 26.02.05 2024 гп.pdf";
import electron from "../../../../assets/pdf/educationPage/260205/2024/33. Электроника и электротехника 26.02.05, 2024 г.п. РУП 14.pdf";
import eop from "../../../../assets/pdf/educationPage/260205/2024/34. ЭОП 26.02.05, 2024 г.п. РУП 14.pdf";

export const ShipPowerPlants2024 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.05 "Эксплуатация
        судовых энергетических установок" (год набора 2024)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={bz}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнидеятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={biologiya}
                target={"_blank"}
                rel="noreferrer"
              >
                Биология
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={geografiya}
                target={"_blank"}
                rel="noreferrer"
              >
                География
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык в профессиональной деятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inzGraf}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={unYaz1}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform1k}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor1}
                target={"_blank"}
                rel="noreferrer"
              >
                История 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor2}
                target={"_blank"}
                rel="noreferrer"
              >
                История 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={liter}
                target={"_blank"}
                rel="noreferrer"
              >
                Литература
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem1}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mater1}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrol}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология и стандартизация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mehan}
                target={"_blank"}
                rel="noreferrer"
              >
                Механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obiz}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы безопасности и защиты родины
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obshest}
                target={"_blank"}
                rel="noreferrer"
              >
                Обществознание
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={filosof}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы философии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={finGram}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы финансовой грамотности в предпринимательской деятельности
                в профессиональной сфере
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 01 Эксплуатация главной судовой двигательной установки
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm2}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 02 Обеспечения безопасности плавания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm3}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 03 Организация работы структурного подразделения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm4}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 04 Выполнение работ по одной или нескольким профессиям
                рабочих, должностям служащих
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={psihObsh}
                target={"_blank"}
                rel="noreferrer"
              >
                Психология общения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rusYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Русский язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sm}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizRa}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 2-4 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={termodin}
                target={"_blank"}
                rel="noreferrer"
              >
                Техническая термодинамика и теплопередача
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tus}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория устройства судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizika}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={him}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>33</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={electron}
                target={"_blank"}
                rel="noreferrer"
              >
                Электроника и электротехника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={eop}
                target={"_blank"}
                rel="noreferrer"
              >
                Экологические основы природопользования
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
