import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as RiverUniversityLogo } from "../../../assets/svg/riveruniversityLogo.svg";
import { ReactComponent as Vk } from "../../../assets/svg/vk.svg";
import {
  toggleClassName,
  toggleFontSize,
  toggleIconColor,
} from "../../../utils/disabled";
export const Footer = observer(() => {
  return (
    <footer
      style={{ fontSize: toggleFontSize(18) }}
      className={toggleClassName(
        "footer",
        "footer-white",
        "footer-black",
        "footer-contrast"
      )}
    >
      <div className="footer__container _container">
        <div className="footer__vguvt">
          <div className="">ВГУВТ 2024</div>
          <a href="https://vk.com/uf_vsuwt" target={"_blank"} rel="noreferrer">
            <Vk className="footer__vk" />
          </a>
          <a href="https://t.me/ufavguwt" target={"_blank"} rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="24"
              height="24"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fillRule="nonzero"
                stroke="none"
                strokeWidth="1"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeDasharray=""
                strokeDashoffset="0"
                fontFamily="none"
                fontWeight="none"
                fontSize="none"
                textAnchor="none"
              >
                <g transform="scale(5.12,5.12)">
                  <path d="M46.137,6.552c-0.75,-0.636 -1.928,-0.727 -3.146,-0.238h-0.002c-1.281,0.514 -36.261,15.518 -37.685,16.131c-0.259,0.09 -2.521,0.934 -2.288,2.814c0.208,1.695 2.026,2.397 2.248,2.478l8.893,3.045c0.59,1.964 2.765,9.21 3.246,10.758c0.3,0.965 0.789,2.233 1.646,2.494c0.752,0.29 1.5,0.025 1.984,-0.355l5.437,-5.043l8.777,6.845l0.209,0.125c0.596,0.264 1.167,0.396 1.712,0.396c0.421,0 0.825,-0.079 1.211,-0.237c1.315,-0.54 1.841,-1.793 1.896,-1.935l6.556,-34.077c0.4,-1.82 -0.156,-2.746 -0.694,-3.201zM22,32l-3,8l-3,-10l23,-17z"></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
        <div className="footer__logo">
          <NavLink to="">
            <object>
              <RiverUniversityLogo
                fill={toggleIconColor("#0C1C2B", "", "#000")}
              />
            </object>
          </NavLink>
        </div>

        <div className="footer__collegeInfo">
          <table>
            <tbody>
              <tr>
                <td>Наш адрес:</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="2">450017,ул. Ахметова, 275,</td>
              </tr>
              <tr>
                <td colSpan="2">Уфа, Республика Башкортостан</td>
              </tr>
              <tr>
                <td> Контакты:</td>
                <td></td>
              </tr>
              <tr>
                <td>Тел./факс:</td>
                <td>8(347) 215-14-00</td>
              </tr>
              <tr>
                <td>Тел.доверия:</td>
                <td>8(347) 215-14-00 # 7</td>
              </tr>
              <tr>
                <td>e-mail:</td>
                <td>uf-vsuwt@uf-vsuwt.ru</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </footer>
  );
});
