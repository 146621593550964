import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { toggleFontSize } from "../../../../utils/disabled";

import qr1 from "../../../../assets/img/services/1.png";
import qr2 from "../../../../assets/img/services/2.png";
import qr3 from "../../../../assets/img/services/3.png";
import qr4 from "../../../../assets/img/services/4.png";
import qr5 from "../../../../assets/img/services/5.png";
import qr6 from "../../../../assets/img/services/6.png";
import qr7 from "../../../../assets/img/services/7.png";
import qr8 from "../../../../assets/img/services/8.png";
import qr9 from "../../../../assets/img/services/9.png";
import { BreadCrumbs } from "../../../common";
import { EducationContainer } from "../../../common";

export const Services = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const getItemsStudyProcess = (panelStyle) => [
    {
      key: "1",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о реализации дополнительных
          общеобразовательных программ!
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
            <li>Слушатели</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00 доп.4)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              {" "}
              <a href="https://rivercollege.ru/training/obsherazvivaushie-programmi">
                {" "}
                Основное меню – переход Раздел Дополнительное образование и
                профессиональное обучение – переход Программы дополнительного
                образования детей и взрослых – переход открыть один из
                документов программ дополнительного образования детей и
                взрослых.
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr1} alt="Картинка уплыла" />

            <li>
              Открыть документ Программы дополнительного образования детей и
              взрослых
            </li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/65dd5f90c769f11de234d235/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о реализации программ основного среднего и
          высшего профессионального образования
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00 доп.4)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              {" "}
              <a href="https://rivercollege.ru/sveden/education">
                {" "}
                Основное меню – переход Раздел Главная – переход Сведения об
                образовательной организации – переход Образование – переход
                развернуть таблицу Информация о реализуемых уровнях образования,
                о формах обучения, нормативных сроках обучения, сроке действия
                государственной аккредитации образовательной программы (при
                наличии государственной аккредитации), о языках, на которых
                осуществляется образование (обучение)
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr2} alt="Картинка уплыла" />
            <li>
              Развернуть таблицу Информация о реализуемых уровнях образования, о
              формах обучения, нормативных сроках обучения, сроке действия
              государственной аккредитации образовательной программы (при
              наличии государственной аккредитации), о языках, на которых
              осуществляется образование (обучение)
            </li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/65dd54a302848f1d463dd684/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о реализации основных программ
          профессионального обучения
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
            <li>Слушатели</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00 доп.4)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              <a href="https://rivercollege.ru/training/programmi-prof-obuchenia">
                {" "}
                Основное меню – переход Раздел Дополнительное образование и
                профессиональное обучение – переход Программы профессионального
                обучения – переход открыть документ Программы профессионального
                обучения
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr3} alt="Картинка уплыла" />

            <li>Открыть документ Программы профессионального обучения</li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/65dd5ed65056901e2d12fb73/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о реализации дополнительных профессиональных
          программ
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
            <li>Слушатели</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00 доп.4)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              <a href="https://rivercollege.ru/training/programmi-prof-perepodgotovka">
                Основное меню – переход Раздел Дополнительное образование и
                профессиональное обучение – переход Программы повышения
                квалификации и профессиональной переподготовки– переход открыть
                документ Программы повышения квалификации и профессиональной
                переподготовки
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr9} alt="Картинка уплыла" width={300} />

            <li>Открыть документ Программы профессионального обучения</li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/66e6ad6284227ca1789df494/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о результатах тестирования и иных
          вступительных испытаний, а также о зачислении в филиал
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Родители/законные представители абитуриентов</li>
          </ul>

          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00 доп.4)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              <a href="https://rivercollege.ru/enrollee/monitoring">
                {" "}
                Основное меню – переход Раздел Абитуриенту – переход раздел
                Мониторинг подачи документов
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr4} alt="Картинка уплыла" />

            <li>Открыть раздел Мониторинг подачи документов</li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/65dd60adc769f11e9a34d213/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации об образовательных программах и учебных
          планах, рабочих программах учебных курсов, предметах, дисциплинах
          (модулях), годовых календарных учебных графиках
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              <a href="https://rivercollege.ru/sveden/education">
                Основное меню – переход Раздел Главная – переход Сведения об
                образовательной организации – переход Образование – переход
                пролистать вниз и развернуть таблицу Информация о
                неадаптированных образовательных программах
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr5} alt="Картинка уплыла" />

            <li>
              Пролистать вниз и развернуть таблицу Информация о неадаптированных
              образовательных программах
            </li>
          </ul>
          <a href="https://forms.yandex.ru/cloud/65dd61e1e010db1df69dcdef/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о порядке проведения государственной
          (итоговой) аттестации обучающихся, освоивших профессиональные
          образовательные программы
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Обучающиеся</li>
            <li>Родители/законные представители обучающихся</li>
          </ul>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              {" "}
              <a href="https://rivercollege.ru/sveden/document">
                Основное меню – переход Раздел Главная – переход Сведения об
                образовательной организации – переход раздел Документы – переход
                вкладка Локальные нормативные акты образовательной организации
                по основным вопросам организации и осуществления образовательной
                деятельности
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr6} alt="Картинка уплыла" />

            <li>
              Ознакомиться с документом Положение об итоговой государственной
              аттестации выпускников
            </li>
          </ul>{" "}
          <a href="https://forms.yandex.ru/cloud/65dd5d0443f74f1df1635284/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "8",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о расписании учебных занятий, промежуточной
          и итоговой аттестации по основным профессиональным образовательным
          программам
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ ( `{" "}
              <a href="https://rivercollege.ru/">сайт филиала</a>`)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              <a href="https://rivercollege.ru/schedule">
                {" "}
                На главной странице вкладка Расписание – переход Расписание –
                переход задать искомые параметры Группа, Курс/ либо, нажав на
                вкладку Неделя автоматически скачать документ с расписанием на
                неделю
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr7} alt="Картинка уплыла" />

            <li>
              Задать искомые параметры Группа, Курс/ либо, нажав на вкладку
              Неделя автоматически скачать документ с расписанием на неделю
            </li>
          </ul>{" "}
          <a href="https://forms.yandex.ru/cloud/65dd5cf92530c21f0f57e0a3/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "9",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставление информации о порядке оказания медицинской помощи
        </span>
      ),

      children: (
        <div style={{ borderTop: "solid 1px rgba(128, 128, 128, 0.3)" }}>
          <h2>Получатели услуги</h2>
          <p>
            Физические лица, граждане РФ, граждане государств СНГ, дружественных
            иностранных государств, владеющие русским языком
          </p>
          <ul>
            <li>Абитуриенты</li>
            <li>Обучающиеся</li>
            <li>Студенты</li>
          </ul>
          <p>
            Родители/законные представители абитуриентов, обучающихся, студентов
          </p>
          <h2>Каналы взаимодействия получателя услуги</h2>
          <ul>
            <li>
              Электронный способ (
              <a href="https://rivercollege.ru/">сайт филиала</a>)
            </li>
            <li>Неэлектронный (лично, звонок по телефону 215-14-00)</li>
          </ul>
          <h2>Оптимальный путь для получения услуги Электронный способ</h2>
          <h3>ВАРИАНТ 1. </h3>
          <ul>
            <li>Зайти на сайт филиала https://rivercollege.ru </li>
            <li>
              {" "}
              <a href="https://rivercollege.ru/learning/health">
                {" "}
                На главной странице вкладка Образование переход Здоровье –
                нажать на вкладку
              </a>
            </li>
          </ul>
          <h3>ВАРИАНТ 2. </h3>
          <ul>
            <li>Навести камеру мобильного телефона на QR-код</li>
            <img src={qr8} alt="Картинка уплыла" />
          </ul>{" "}
          <a href="https://forms.yandex.ru/cloud/65dd5cf92530c21f0f57e0a3/">
            Просим вас оценить качество предоставленной услуги
          </a>
        </div>
      ),
      style: panelStyle,
    },
  ];

  return (
    <>
      <BreadCrumbs />
      <EducationContainer title="Предоставляемые услуги">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{
            background: token.colorBgContainer,
            fontSize: toggleFontSize(14),
          }}
          items={getItemsStudyProcess(panelStyle)}
        />
      </EducationContainer>
    </>
  );
};
