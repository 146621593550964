import React from "react";
import { NavLink } from "react-router-dom";
import Image16 from "../../../../../assets/pdf/educationPage/15. График учебного процесса2024.pdf";
import OOP9 from "../../../../../assets/pdf/educationPage/260205/opisanie/ОП ППССЗ  26. 02. 05 Эксплуатация судовых энергетических установок 2023 г.п. (Восстановлен).pdf";
import UP12 from "../../../../../assets/pdf/educationPage/260205/uchebnie_plani/26.02.05 2024-25 1 курс.pdf";
import rp_praktic from "../../../../../assets/pdf/educationPage/РП Практик/РП Практик 2024 СМ.zip";
import vr from "../../../../../assets/pdf/educationPage/Ссылка на методические и иные документы СМ.rar";

export const SPOEkspluatSudovEnergUstanovok_24_260205 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">26.02.05</td>
      <td>
        <NavLink
          itemProp="eduName"
          to="../../educationPrograms/shipPowerPlants2024"
        >
          Эксплуатация судовых энергетических установок ФГОС №674 от 26.11.2020
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink
          itemProp="eduProf"
          to="../../educationPrograms/shipPowerPlants2024"
        >
          Эксплуатация судовых энергетических установок (прием 2024)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP9} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP12}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      {/*  <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationAnnotation"
              href={Annot9}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td> */}
      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/shipPowerPlants2024"
            >
              Скачать РП
            </NavLink>
          </li>
          {/*  <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/shipPowerPlants2024"
            >
              Скачать РП РЦ
            </NavLink>
          </li> */}
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image16}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={rp_praktic}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={vr}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
};
