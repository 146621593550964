import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import osnov_filosof_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/osnov_filosof_op.pdf";
import osnov_filosof_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/osnov_filosof_op.pdf.sig";
import osnov_filosof_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_osnov_filosof_op.pdf";

import istoriya2_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/istoriya2_op.pdf";
import istoriya2_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/istoriya2_op.pdf.sig";
import istoriya2_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_istoriya2_op.pdf";

import inostr2_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/inostr2_op.pdf";
import inostr2_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/inostr2_op.pdf.sig";
import inostr2_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_inostr2_op.pdf";

import fiz_kultura2_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/fiz_kultura2_op.pdf";
import fiz_kultura2_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/fiz_kultura2_op.pdf.sig";
import fiz_kultura2_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_fiz_kultura2_op.pdf";

import matematika2_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/matematika2_op.pdf";
import matematika2_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/matematika2_op.pdf.sig";
import matematika2_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_matematika2_op.pdf";

import informatika2_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/informatika2_op.pdf";
import informatika2_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/informatika2_op.pdf.sig";
import informatika2_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_informatika2_op.pdf";

import ingener_grafika_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/ingener_grafika_op.pdf";
import ingener_grafika_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/ingener_grafika_op.pdf.sig";
import ingener_grafika_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_ingener_grafika_op.pdf";

import elektrotechnika_elektronika_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/elektrotechnika_elektronika_op.pdf";
import elektrotechnika_elektronika_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/elektrotechnika_elektronika_op.pdf.sig";
import elektrotechnika_elektronika_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_elektrotechnika_elektronika_op.pdf";

import metrolog_standart_sert_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/metrolog_standart_sert_op.pdf";
import metrolog_standart_sert_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/metrolog_standart_sert_op.pdf.sig";
import metrolog_standart_sert_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_metrolog_standart_sert_op.pdf";

import transp_syst_Rossii_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/transp_syst_Rossii_op.pdf";
import transp_syst_Rossii_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/transp_syst_Rossii_op.pdf.sig";
import transp_syst_Rossii_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_transp_syst_Rossii_op.pdf";

import techn_sredstva_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/techn_sredstva_op.pdf";
import techn_sredstva_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/techn_sredstva_op.pdf.sig";
import techn_sredstva_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_techn_sredstva_op.pdf";

import popd_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/popd_op.pdf";
import popd_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/popd_op.pdf.sig";
import popd_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_popd_op.pdf";

import ochrana_truda_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/ochrana_truda_op.pdf";
import ochrana_truda_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/ochrana_truda_op.pdf.sig";
import ochrana_truda_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_ochrana_truda_op.pdf";

import bezop_ghizned_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/bezop_ghizned_op.pdf";
import bezop_ghizned_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/bezop_ghizned_op.pdf.sig";
import bezop_ghizned_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_bezop_ghizned_op.pdf";

import PM01_org_perev_process_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM01_org_perev_process_op.pdf";
import PM01_org_perev_process_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM01_org_perev_process_op.pdf.sig";
import PM01_org_perev_process_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_PM01_org_perev_process_op.pdf";
import FOS_MU_Kurs_PM01_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_MU_Kurs_PM01_op.pdf";

import PM02_org_serv_obsl_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM02_org_serv_obsl_op.pdf";
import PM02_org_serv_obsl_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM02_org_serv_obsl_op.pdf.sig";
import PM02_org_serv_obsl_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_PM02_org_serv_obsl_op.pdf";

import PM03_org_trasp_logist_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM03_org_trasp_logist_op.pdf";
import PM03_org_trasp_logist_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM03_org_trasp_logist_op.pdf.sig";
import PM03_org_trasp_logist_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_PM03_org_trasp_logist_op.pdf";
import FOS_MU_Kurs_PM03_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_MU_Kurs_PM03_op.pdf";

import PM04_vipoln_rabot_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM02_org_serv_obsl_op.pdf";
import PM04_vipoln_rabot_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/PM02_org_serv_obsl_op.pdf.sig";
import PM04_vipoln_rabot_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_PM02_org_serv_obsl_op.pdf";

import russ_yaz_kul_rehi_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/russ_yaz_kul_rehi_op.pdf";
import russ_yaz_kul_rehi_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/russ_yaz_kul_rehi_op.pdf.sig";
import russ_yaz_kul_rehi_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_russ_yaz_kul_rehi_op.pdf";

import delov_inostr_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/delov_inostr_op.pdf";
import delov_inostr_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/delov_inostr_op.pdf.sig";
import delov_inostr_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_delov_inostr_op.pdf";

import osnov_delopr_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/osnov_delopr_op.pdf";
import osnov_delopr_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/osnov_delopr_op.pdf.sig";
import osnov_delopr_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_osnov_delopr_op.pdf";

import vnutr_vodn_puti_Rossii_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/vnutr_vodn_puti_Rossii_op.pdf";
import vnutr_vodn_puti_Rossii_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/vnutr_vodn_puti_Rossii_op.pdf.sig";
import vnutr_vodn_puti_Rossii_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_vnutr_vodn_puti_Rossii_op.pdf";

import org_dostup_sred_invalid_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/org_dostup_sred_invalid_op.pdf";
import org_dostup_sred_invalid_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/org_dostup_sred_invalid_op.pdf.sig";
import org_dostup_sred_invalid_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_org_dostup_sred_invalid_op.pdf";

import inform_techn_prof_deyat_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/inform_techn_prof_deyat_op.pdf";
import inform_techn_prof_deyat_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/inform_techn_prof_deyat_op.pdf.sig";
import inform_techn_prof_deyat_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_inform_techn_prof_deyat_op.pdf";

import kommerh_rabota_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/kommerh_rabota_op.pdf";
import kommerh_rabota_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/kommerh_rabota_op.pdf.sig";
import kommerh_rabota_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_kommerh_rabota_op.pdf";

import strach_riski_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/strach_riski_op.pdf";
import strach_riski_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/strach_riski_op.pdf.sig";
import strach_riski_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_strach_riski_op.pdf";

import ekonomika_upravl_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/ekonomika_upravl_op.pdf";
import ekonomika_upravl_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/ekonomika_upravl_op.pdf.sig";
import ekonomika_upravl_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_ekonomika_upravl_op.pdf";

import bortprovodnik_op from "../../../../assets/pdf/educationPage/230201/workPrograms1/bortprovodnik_op.pdf";
import bortprovodnik_opSig from "../../../../assets/pdf/educationPage/230201/workPrograms1/bortprovodnik_op.pdf.sig";
import bortprovodnik_opFos from "../../../../assets/pdf/educationPage/230201/workPrograms1/FOS_bortprovodnik_op.pdf";

export const OrganizationOfTransportation = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 23.02.01 "Организация
        перевозок и управление на транспорте" (по видам), заочная
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th>ЭЦП</th>
            <th>Фонды оценочных средств (ФОС)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={osnov_filosof_op} target={"_blank"} rel="noreferrer">
                Основы философии
              </a>
            </td>
            <td>
              <a href={osnov_filosof_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={osnov_filosof_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={istoriya2_op} target={"_blank"} rel="noreferrer">
                История
              </a>
            </td>
            <td>
              <a href={istoriya2_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={istoriya2_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={inostr2_op} target={"_blank"} rel="noreferrer">
                Иностранный язык
              </a>
            </td>
            <td>
              <a href={inostr2_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={inostr2_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={fiz_kultura2_op} target={"_blank"} rel="noreferrer">
                Физическая культура
              </a>
            </td>
            <td>
              <a href={fiz_kultura2_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={fiz_kultura2_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={matematika2_op} target={"_blank"} rel="noreferrer">
                Математика
              </a>
            </td>
            <td>
              <a href={matematika2_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={matematika2_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={informatika2_op} target={"_blank"} rel="noreferrer">
                Информатика
              </a>
            </td>
            <td>
              <a href={informatika2_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={informatika2_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={ingener_grafika_op} target={"_blank"} rel="noreferrer">
                Инженерная графика
              </a>
            </td>
            <td>
              <a
                href={ingener_grafika_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={ingener_grafika_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={elektrotechnika_elektronika_op}
                target={"_blank"}
                rel="noreferrer"
              >
                Электротехника и электроника
              </a>
            </td>
            <td>
              <a
                href={elektrotechnika_elektronika_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={elektrotechnika_elektronika_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={metrolog_standart_sert_op}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология, стандартизация и сертификация
              </a>
            </td>
            <td>
              <a
                href={metrolog_standart_sert_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={metrolog_standart_sert_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={transp_syst_Rossii_op}
                target={"_blank"}
                rel="noreferrer"
              >
                Транспортная система России
              </a>
            </td>
            <td>
              <a
                href={transp_syst_Rossii_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={transp_syst_Rossii_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={techn_sredstva_op} target={"_blank"} rel="noreferrer">
                Технические средства (по видам транспорта)
              </a>
            </td>
            <td>
              <a href={techn_sredstva_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={techn_sredstva_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={popd_op} target={"_blank"} rel="noreferrer">
                Правовое обеспечение профессиональной деятельности
              </a>
            </td>
            <td>
              <a href={popd_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={popd_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={ochrana_truda_op} target={"_blank"} rel="noreferrer">
                Охрана труда
              </a>
            </td>
            <td>
              <a href={ochrana_truda_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={ochrana_truda_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={bezop_ghizned_op} target={"_blank"} rel="noreferrer">
                Безопасность жизнедеятельности
              </a>
            </td>
            <td>
              <a href={bezop_ghizned_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={bezop_ghizned_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={PM01_org_perev_process_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.01 Организация перевозочного процесса (по видам транспорта)
              </a>
            </td>
            <td>
              <a
                href={PM01_org_perev_process_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <ul>
                <li>
                  {" "}
                  <a
                    href={PM01_org_perev_process_opFos}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Скачать
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={FOS_MU_Kurs_PM01_op}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    МУ Курсовая
                  </a>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={PM02_org_serv_obsl_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.02 Организация сервисного обслуживания на транспорте (по
                видам транспорта)
              </a>
            </td>
            <td>
              <a
                href={PM02_org_serv_obsl_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={PM02_org_serv_obsl_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={PM03_org_trasp_logist_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.03 Организация транспортно-логистической деятельности (по
                видам транспорта)
              </a>
            </td>
            <td>
              <a
                href={PM03_org_trasp_logist_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <ul>
                <li>
                  <a
                    href={PM03_org_trasp_logist_opFos}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Скачать
                  </a>
                </li>
                <li>
                  <a
                    href={FOS_MU_Kurs_PM03_op}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    МУ Курсовая
                  </a>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={PM04_vipoln_rabot_op} target={"_blank"} rel="noreferrer">
                ПМ.04 Выполнение работ по одной или нескольким профессиям
                рабочих, должностям служащих
              </a>
            </td>
            <td>
              <a
                href={PM04_vipoln_rabot_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={PM04_vipoln_rabot_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={russ_yaz_kul_rehi_op} target={"_blank"} rel="noreferrer">
                ВЧ.01 Русский язык и культура речи
              </a>
            </td>
            <td>
              <a
                href={russ_yaz_kul_rehi_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={russ_yaz_kul_rehi_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={delov_inostr_op} target={"_blank"} rel="noreferrer">
                ВЧ.02 Деловой иностранный язык
              </a>
            </td>
            <td>
              <a href={delov_inostr_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={delov_inostr_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={osnov_delopr_op} target={"_blank"} rel="noreferrer">
                ВЧ.03 Основы делопроизводства
              </a>
            </td>
            <td>
              <a href={osnov_delopr_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={osnov_delopr_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={vnutr_vodn_puti_Rossii_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ВЧ.04 Внутренние водные пути России
              </a>
            </td>
            <td>
              <a
                href={vnutr_vodn_puti_Rossii_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={vnutr_vodn_puti_Rossii_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={org_dostup_sred_invalid_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ВЧ.05 Организация доступной среды для инвалидов на транспорте
              </a>
            </td>
            <td>
              <a
                href={org_dostup_sred_invalid_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={org_dostup_sred_invalid_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                href={inform_techn_prof_deyat_op}
                target={"_blank"}
                rel="noreferrer"
              >
                ВЧ.06 Информационные технологии в профессиональной деятельности
              </a>
            </td>
            <td>
              <a
                href={inform_techn_prof_deyat_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={inform_techn_prof_deyat_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={kommerh_rabota_op} target={"_blank"} rel="noreferrer">
                ВЧ.07 Коммерческая работа на транспорте
              </a>
            </td>
            <td>
              <a href={kommerh_rabota_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={kommerh_rabota_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={strach_riski_op} target={"_blank"} rel="noreferrer">
                ВЧ.08 Страхование и риски
              </a>
            </td>
            <td>
              <a href={strach_riski_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={strach_riski_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={ekonomika_upravl_op} target={"_blank"} rel="noreferrer">
                ВЧ.09 Экономика и управление на водном транспорте
              </a>
            </td>
            <td>
              <a
                href={ekonomika_upravl_opSig}
                target={"_blank"}
                rel="noreferrer"
              >
                ЭЦП
              </a>
            </td>
            <td>
              <a
                href={ekonomika_upravl_opFos}
                target={"_blank"}
                rel="noreferrer"
              >
                Скачать
              </a>
            </td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a href={bortprovodnik_op} target={"_blank"} rel="noreferrer">
                ВЧ.10 Бортпроводник
              </a>
            </td>
            <td>
              <a href={bortprovodnik_opSig} target={"_blank"} rel="noreferrer">
                ЭЦП
              </a>
            </td>
            <td>
              <a href={bortprovodnik_opFos} target={"_blank"} rel="noreferrer">
                Скачать
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
