import React from "react";
import { TableWrapper } from "../../../common";
export const PurposeCab = () => {
  return (
    <TableWrapper title="Сведения об оборудованных учебных кабинетах">
      <table>
        <tbody>
          <tr>
            <td>Адрес</td>
            <td>Наименование оборудованных учебных кабинетов</td>
            <td>Оснащенность оборудованных учебных кабинетов</td>
            <td>
              Приспособленность для использования инвалидами и лицами с
              ограниченными возможностями здоровья
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №5 Музей истории ВМФ и военных моряков, уроженцев Республики
              Башкортостан. Кабинет общегуманитарных, социально-экономических
              дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды)
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №11. Кабинет теории и устройства судна. Кабинет технологии
              судоремонта.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Макеты: кренометр, радиолокатор,
              магнитный компас, тумба штурвала с аксиометром, пульт управления
              ДВС левым и правым, щит приборов ДВС, импульсные отмашки, прибор
              ночного видения; штурманский стол, макеты судов
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №20. Музей имени штурмана В.И. Альбанова Кабинет общегуманитарных,
              социально-экономических дисциплин. Кабинет основ исследовательской
              деятельности. Методический кабинет.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №21. Кабинет истории. Кабинет общегуманитарных,
              социально-экономических дисциплин.{" "}
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды)
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №29. Музей «Боевой славы». Кабинет основ безопасности
              жизнедеятельности, безопасности жизнедеятельности Кабинет
              безопасности жизнедеятельности и охраны труда.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: радиодозиметры,
              химические приборы, комплекты ОЗК, противогаз ГП-7, противогазы
              ГП-5, макет АК, манекены для отработки навыков первой мед.помощи.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №30. Кабинет механики. Кабинет метрологии, стандартизации и
              сертификации. Кабинет метрологии и стандартизации. Кабинет
              общепрофессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: модели зубчатой
              передачи, червячной передачи, модель для демонстрации видов
              деформаций, модель эвольвентного профиля зуба, микроскоп, модель
              для демонстрации действия винта, штанген инструменты, микрометры,
              набор концевых мер длины, калибры, индикаторные головки.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №32 имени С.Т. Аксакова Кабинет русского языка и литературы.
              Кабинет родного языка и литературы
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды).
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №33, 42, 42а, 43. Кабинет иностранного языка
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды), программа «Англомар», электронный учебник по английскому
              языку, наглядные пособия и плакаты.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №34,222. Кабинет информатики. Кабинет информатики и информационных
              систем.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран., лицензионное программное
              обеспечение: офисные программы MS Office Word, MS Office Excel, MS
              Office Access, MS Office PowerPoint
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №35. Кабинет физики. Кабинет математических и естественнонаучных
              дисциплин. Кабинет технической термодинамики и теплопередачи.
              Кабинет общепрофессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: прибор для демонст.
              длины световой волны, набор из линз и стекла, штативы,
              измерительные приборы.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №№36, 38, 109, 225. Кабинет математики. Кабинет математических и
              естественнонаучных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: набор чертёжных
              инструментов для работы у доски, демонстрационные модели.{" "}
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №37. Кабинет организации перевозочного процесса (по видам
              транспорта). Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет, ТВ,
              электронные презентации по темам лекций).
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №39. Кабинет инженерной графики. Кабинет охраны труда. Кабинет
              общепрофессиональных дисциплин. Лекционная аудитория.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: компьютерная программа
              «Компас-график», набор чертёжных инструментов для работы у доски,
              демонстрационные модели.{" "}
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">№40. Кабинет профессиональных дисциплин.</td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: магнитный компас «МК-
              127» , МК-145, МК-75, «КМ-100М2», «КМ-100М3», барометр, анемометр,
              гигрометр, основной прибор ГК «Амур», блоки РЛС «Донец - 2»,
              радиопеленгатор «СРП-5», блоки РЛС Р-722, эхолоты Кубань и НЭЛ-5,
              Лаг МГЛ-25 и НЭЛ-5, авторулевой Печора, модели судов.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №44. Кабинет судовых энергетических установок. Кабинет судовых
              вспомогательных систем и механизмов. Кабинет профессиональных
              дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: макет двигателя 3Д6 в
              разрезе, детали цилиндропоршневой группы, топливный насос,
              фильтры, наборы ключей, стенды, плакаты, видеофильмы, презентации.
              Тренажер судовой энергетической установки
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №45 имени Ф.А. Кибальника Кабинет технологии перевозки грузов.
              Кабинет организации перевозочного процесса (по видам транспорта).
              Организации транспортно-логистической деятельности (по видам
              транспорта). Кабинет транспортной системы России. Кабинет
              профессиональных дисциплин. Кабинет общепрофессиональных
              дисциплин. Лекционная аудитория.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: модели судов.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №46 имени капитана Р.Ф. Исламова Кабинет управления судном.
              Кабинет безопасности движения. Кабинет профессиональных дисциплин.
              Лекционная аудитория.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: наглядность: макеты
              судов, плавучих знаков судоходной обстановки, магнитный компас.{" "}
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №47 имени адмирала Ф.Ф. Беллинсгаузена Кабинет навигации и лоции.
              Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность:, магнитный компас «МК-
              127», МК-145, МК-75, «КМ-100М2», «КМ-100М3», барометр, анемометр,
              гигрометр, модели судов, штурманский прокладочный инструмент,
              морские карты, секстант, звездный глобус
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №48. Кабинет химии и биологии. Кабинет математических и
              естественнонаучных дисциплин. Кабинет экологических основ
              природопользования. Кабинет общепрофессиональных дисциплин.
              Лекционная аудитория.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: микроскопы,
              периодическая таблица Менделеева, колбонагреватель, прибор для
              электролиза, колонки адсорбционные, прибор для окисления спиртов,
              баня комбинированная, весы, разновесы, мультимедиа учебный курс
              «1С: Органическая химия» и электронное издание «Виртуальная
              химическая лаборатория».
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №107. Кабинет судовых электроприводов. Электрических машин.
              Технического обслуживания. Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран.{" "}
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №110. Кабинет экологических основ природопользования. Кабинет
              общепрофессиональных дисциплин. Кабинет технических средств (по
              видам транспорта).
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: географические и
              политические карты, глобус.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №111. Кабинет Безопасности жизнедеятельности на судне. Кабинет
              профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды. Наглядность: Тренажерные стенды: «Тревожная кнопка»,
              «Видеонаблюдение», «Пожарная сигнализация».
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №114. Кабинет общепрофессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель, лабораторные столы «Уралочка»,
              технические средства обучения с набором демонстрационного
              оборудования и учебно-наглядных пособий (компьютерная техника с
              возможностью выхода в Интернет и доступом в электронную
              информационно-образовательную среду филиала, мультимедийные
              презентации, видео-аудиоматериалы по темам лекций, стенды.
              Наглядность: макеты электрооборудования.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №116. Кабинет материаловедения. Кабинет общепрофессиональных
              дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: микроскоп, образцы
              металлов
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №117. Кабинет безопасности жизнедеятельности на судне. Кабинет
              безопасности движения. Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды. Наглядность: дыхательные аппараты, газодымозащитный
              комплект, самоспасатели, снаряжение пожарного, теплоотражающий
              костюм пожарного, противопожарное снаряжение, стенд настенный с
              извещателями и оповещателями, огнетушители ОУ-5 и ОПУ-5.{" "}
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №218 имени адмирала С.О. Макарова Кабинет безопасности движения.
              Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды. Наглядность: тренажер «Глобальной морской системы связи
              при бедствии»: пост аппаратуры ГМССБ, макеты АРБ, макет РЛО, макет
              переносной станции УКВ, «(ГМССБ)».
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №219 имени Петра I Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала, ТВ,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды. Наглядность: навигационный тренажер: (NT PRO 4000), версия
              4.Х.Х., 2 места, «АИС 1.2».
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №222. Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №223. Кабинет основ анализа эффективности работы судна. Кабинет
              организации перевозочного процесса (по видам транспорта). Кабинет
              организации сервисного обслуживания на транспорте (по видам
              транспорта). Кабинет управления качеством и персоналом. Кабинет
              профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: карта внутренних водных
              путей.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №224. Кабинет для самостоятельной работы.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды. Наглядность:
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeCab">
            <td itemProp="addressCab">
              450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
            </td>
            <td itemProp="nameCab">
              №228. Кабинет безопасности жизнедеятельности на судне. Кабинет
              безопасности движения. Кабинет профессиональных дисциплин.
            </td>
            <td itemProp="osnCab">
              специализированная мебель и технические средства обучения с
              набором демонстрационного оборудования и учебно-наглядных пособий
              (компьютерная техника с возможностью выхода в Интернет и доступом
              в электронную информационно-образовательную среду филиала,
              мультимедийные презентации, видео-аудиоматериалы по темам лекций,
              стенды, видеопроектор, экран. Наглядность: манекен-тренажер
              «Максим» с комплектом имитации ранений, жилет спасательный,
              гидротермокостюм, макет снабжения спасательной шлюпки, буй
              светодымящий, спасательный жилет надувной, спасательный круг,
              теплозащитное средство, средство хранения ПСН, огонь поиска,
              гидростат для ПСН, линемет.
            </td>
            <td itemProp="ovzCab">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};
