import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";
import { toggleClassName } from "../../../../../utils/disabled";
export const Partners = observer(() => {
  return (
    <section>
      <h1>Профильные организации - социальные партнеры филиала</h1>
      <p>
        Филиал взаимодействует с{" "}
        <NavLink
          to="../vacanciesFrom"
          className={toggleClassName(
            "education__link",
            "education__link-white",
            "education__link-black",
            "education__link-contrast"
          )}
        >
          {" "}
          работодателями
        </NavLink>
        , информирует их о выпускниках нашего филиала с целью их будущего
        трудоустройства. Тесная и плодотворная работа ведется с такими
        судоходными компаниями, как: ЗАО СК «БашВолготанкер», ООО «Речной порт
        «Бирск», ООО «Бельский район водных путей и судоходства», ООО
        «Судоремонтно-судостроительный завод» г. Уфа, ООО «Дельфин», ООО «Белый
        лебедь», ООО «Речфлот» и др.
      </p>
      <p>
        В целях обеспечения эффективного трудоустройства выпускников, в филиале,
        в структуре подразделения учебно-производственной работы функционирует
        Служба трудоустройства выпускников филиала.
      </p>
      <p>
        Служба трудоустройства выпускников осуществляет следующую деятельность:
      </p>
      <ul>
        <li>
          Консультирование по вопросам трудоустройства:
          <ul>
            <li>предоставление информации о состоянии рынка труда;</li>
            <li>обучение навыкам составления портфолио, самопрезентации;</li>
            <li>
              проведение лекций, консультаций по технологии трудоустройства.
            </li>
          </ul>
        </li>
        <li>
          Аналитическая работа:
          <ul>
            <li>
              сбор данных и подготовка статистических отчетов по
              трудоустройству;
            </li>
            <li>анализ востребованности выпускников филиала на рынке труда;</li>
            <li>изучение интересов курсантов, касающихся трудоустройства.</li>
          </ul>
        </li>
        <li>
          Информационная и координационная работа:
          <ul>
            <li>поиск вакансий;</li>
            <li>
              работа с предприятиями, образовательными учреждениями, центрами
              занятости по вопросам трудоустройства организация презентаций,
              ярмарок вакансий.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Филиал также взаимодействует с городским Центром занятости населения,
        принимает участия в ярмарках вакансий. Центр ведет учет трудоустройства
        выпускников и предоставляет данную информацию по запросам вышестоящим
        органам. После окончания филиала мы отслеживаем карьеру наших
        выпускников в течении 3-х лет с момента выпуска и взаимодействуем с их
        работодателями.
      </p>
      <p>
        <b>
          <i>
            Благодаря тесному сотрудничеству филиала с профильными организациями
            - судоходными компаниями, с Центром занятости населения г. Уфы и
            работой Службы трудоустройства выпускников востребованность наших
            выпускников на рынке труда высокая. Традиционно количество вакантных
            должностей, предлагаемых нашим выпускникам социальными партерами
            значительно превышает предожение.
          </i>
        </b>
      </p>
    </section>
  );
});
