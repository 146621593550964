import { $authHost, $host } from "./index";

export const createDays = async (days) => {
  const { data } = await $authHost.post("api/days", days);
  return data;
};
/* export const editDays = async (employee) => {
  const { data } = await $authHost.put("api/employee", employee);
  return data;
}; */
export const fetchDays = async (employeeId) => {
  const { data } = await $host.get("api/days/" + employeeId);
  return data;
};
/* export const fetchOneDays = async (id) => {
  const data = await $host.get("api/days/" + id);
  return data;
}; */
export const deleteDays = async (id) => {
  const { data } = await $authHost.delete("api/days/" + id);

  return data;
};
