import React, { useEffect, useState } from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import axios from "axios";
import vacancies from "../../../../assets/pdf/vacancies/приказ 468.pdf";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { NavPanel } from "../../../ui/nav-panel/nav-panel";
import { paginate } from "../../../../utils/utils";
import { PaginationComponent } from "../../../common";
export const Vacancies = () => {
  const [vacanciesItems, setVacanciesItems] = useState([]);

  useEffect(() => {
    const apiUrl = "https://api.hh.ru/vacancies?employer_id=5263412";

    axios.get(apiUrl).then((resp) => {
      const vac = resp.data.items;
      setVacanciesItems(vac);
    });
  }, [setVacanciesItems]);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const vacansiesCrop = paginate(vacanciesItems, currentPage, pageSize);
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <NavPanel
        isActive={isActive}
        setIsActive={setIsActive}
        btnOneText={"Общая информация"}
        btnTwoText={"Все вакансии"}
      />
      {isActive ? (
        <section>
          <h1>
            Ищете работу? Мы вас ждем! Обращайтесь в приемную 8 (347) 215-14-00
          </h1>
          <a
            style={{
              color: "#1B386B",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            itemProp="educationRpd"
            href={"https://www.superjob.ru/"}
            target={"_blank"}
            rel="noreferrer"
          >
            Также найти вакансии Уфимского филиала ВГУВТ вы можете на
            https://www.superjob.ru/
          </a>
          <h2>
            <b>
              Объявление о конкурсном отборе на замещение вакантных должностей
              профессорско-преподавательского состава
            </b>
          </h2>
          <p>
            Уфимский филиал ФГБОУ ВО «Волжский государственный университет
            водного транспорта» в соответствии с Приказом Министерства
            образования и науки РФ от 23 июля 2015 года №749 объявляет
            конкурсный отбор на замещение вакантных должностей
            профессорско-преподавательского состава: Старший преподаватель –
            0,32 ст. Доцент – 0,32 ст.
          </p>
          <p>
            <b>
              Квалификационные требования по должностям педагогических
              работников:
            </b>
          </p>
          <p>
            Все кандидаты, претендующие на замещение должностей ППС, должны
            соответствовать квалификационным требованиям, установленным приказом
            Министерства здравоохранения и социального развития РФ от 11 января
            2011 г. №1н «Об утверждении Единого квалификационного справочника
            должностей руководителей, специалистов и служащих, раздел
            «Квалификационные характеристики должностей руководителей и
            специалистов высшего профессионального и дополнительного
            профессионального образования».
          </p>
          <p>
            <i>
              Квалификационные требования по должности старшего преподавателя:
            </i>
          </p>
          <p>
            - высшее профессиональное образование, стаж научно-педагогической
            работы не менее 3 лет, при наличии ученой степени кандидата наук
            стаж научно-педагогической работы не менее 1 года.
          </p>
          <p>
            <i>Квалификационные требования по должности доцента: </i>
          </p>
          <p>
            - высшее профессиональное образование, ученая степень кандидата
            (доктора) наук и стаж научно-педагогической работы не менее 3 лет
            или ученое звание доцента (старшего научного сотрудника).
          </p>
          <p>
            Прием заявлений для участия в конкурсе осуществляется по адресу: г.
            Уфа, ул. Ахметова, д. 275, отдел кадров.{" "}
          </p>
          <p>
            Срок подачи заявления для участия в конкурсе не менее одного месяца
            со дня размещения объявления о конкурсе на сайте организации.
          </p>
          <p>
            Дата и место проведения конкурса: 10 января 2024 года, г. Уфа, ул.
            Ахметова, д. 275 учебный корпус, каб. №20.{" "}
          </p>
          <ul>
            <li>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                style={{ color: "#1B386B", "margin-left": "5px" }}
                itemProp="educationRpd"
                href={vacancies}
                target={"_blank"}
                rel="noreferrer"
              >
                Приказ о проведении конкурса
              </a>
            </li>
          </ul>
        </section>
      ) : (
        <section>
          {vacansiesCrop.length &&
            vacansiesCrop.map((v) => (
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: "#fff",
                  marginBottom: 20,
                }}
                key={v.id}
                gap={20}
              >
                <Collapse.Panel header={v.name} key={v.id}>
                  {v.experience.name && (
                    <p>
                      <b>Требуемый опыт работы:</b> {v.experience.name}
                    </p>
                  )}
                  {v.schedule.name && (
                    <p>
                      <b>График работы:</b> {v.schedule.name}
                    </p>
                  )}
                  {v.salary.from && (
                    <p>
                      <b>Уровень дохода:</b> от {v.salary.from}
                      {v.salary.to !== null ? ` — до ${v.salary.to}` : ""} руб.
                    </p>
                  )}
                  {v.snippet.responsibility && (
                    <p>
                      <b>Освные должностные обязанности:</b>{" "}
                      {v.snippet.responsibility}
                    </p>
                  )}
                  {v.snippet.requirement && (
                    <p>
                      <b>Требования к кандидату:</b> {v.snippet.requirement}
                    </p>
                  )}
                  {v.address.raw && (
                    <p>
                      <b>Место работы (адрес):</b> {v.address.raw}
                    </p>
                  )}
                  <p>
                    <b>Контактное лицо:</b> Бармут Лиля Николаевна Телефон:
                    (347) 215-14-00 доп. 310 Email: ok@uf-vsuwt.ru
                  </p>
                  {v.alternate_url && (
                    <p>
                      Подробнее вы можете ознакомиться с вакансией по{" "}
                      <b>
                        <a href={v.alternate_url}>ссылке</a>
                      </b>
                    </p>
                  )}
                </Collapse.Panel>
              </Collapse>
            ))}
          {vacanciesItems.length <= pageSize ? null : (
            <PaginationComponent
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              news={vacanciesItems}
            />
          )}
        </section>
      )}
    </>
  );
};
