import React from "react";

import Director from "../../../../../assets/img/applicant/director.jpg";
import { Image } from "../../../../common";
export const Message = () => {
  return (
    <section>
      <h1>Уважаемые абитуриенты!</h1>

      <p>
        <div className="messageImage-container">
          <Image src={Director} width="330px" />
        </div>
        Сейчас вы принимаете важнейшее решение в вашей жизни: в какое учебное
        заведение поступить и какую профессию приобрести. Наш филиал готовит
        специалистов водного транспорта: судоводителей, судомехаников,
        электромехаников и техников по организации перевозок и управлению на
        транспорте. Каждая из этих специальностей имеет свои особенности.
        Поступив на судоводителя по окончании обучения, вы получите диплом
        штурмана (помощника капитана), судомеханики – помощника механика,
        электромеханики – помощника электромеханика. Уже после второго курса вы
        пойдете на плавательскую практику. После третьего курса, получив первую
        рабочую профессию (матроса, моториста-рулевого или электрика) вы будете
        проходить производственную практику в штатной должности и получать
        заработную плату. Для прохождения практики мы заключили договора с 30
        судоходными компаниями. Курсанты практику проходят в разных бассейнах
        это реки Москва, Нева, Свирь, Волга, Кама, Дон, Обь, Иртыш, Лена, Белая,
        озера Онежское и Ладожское, Финский залив Балтийского моря, моря Черное,
        Азовское и Средиземное.
      </p>

      <p>
        Профессиональное образование отличается от школы тем, что у вас будет
        возможность совместить личные увлечения и получение интересной,
        перспективной профессии. В период обучения Вас ждут разнообразные
        творческие, спортивные, общественно-значимые мероприятия, участие в
        олимпиадах, конкурсах и конференциях и многое другое. Здесь вы встретите
        мудрых наставников, которые помогут вам найти свою дорогу в жизни, и,
        конечно, надежных друзей.
      </p>
      <p>
        В период обучения вы можете дополнительно пройти обучения на различных
        курсах: от управления маломерными судами до использования
        радиолокационных станций, углубить знания английского языка или освоить
        востребованные профессии.
      </p>
      <p>
        Работникам водного транспорта свойственны такие качества как романтика,
        мужество и дисциплина. И если вы когда-либо мечтали об этом, то это
        является серьезной причиной, чтобы посвятить водному транспорту целую
        жизнь.
      </p>
      <p>
        Желаю вам, дорогие ребята, сделать правильный выбор, успешно пройти
        процедуру зачисления и в ближайшем будущем надеюсь увидеть вас в числе
        наших курсантов! Уверена, что годы, проведённые у нас, станут для вас
        важными, интересными и запоминающимися!
      </p>
      <p>
        Мы ждем активных, любознательных, смелых и умных, готовых развиваться во
        многих направлениях и строить успешную карьеру абитуриентов.
      </p>
      <p>
        Добро пожаловать на наш сайт, где Вы сможете подробнее с нами
        познакомиться.
      </p>
      <p className="justify-end">
        <i>
          Директор Уфимского филиала <br /> Федерального государственного
          бюджетного образовательного учреждения высшего образования
          <br /> «Волжский государственный университет водного транспорта»
          <br /> Ф.Ш. Ахмадеева
        </i>
      </p>
    </section>
  );
};
