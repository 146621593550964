import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import { observer } from "mobx-react-lite";
export const Acts = observer(() => {
  return (
    <>
      {" "}
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Нормативные правовые и иные акты в сфере противодействия коррупции
      </h2>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Федеральные законы
      </h3>
      <ul>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/28623"
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 25 декабря 2008 г. № 273-ФЗ "О противодействии
            коррупции"
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/36431"
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 3 декабря 2012 г. № 230-ФЗ "О контроле за
            соответствием расходов лиц, замещающих государственные должности, и
            иных лиц их доходам"
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.kremlin.ru/acts/bank/37226"
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 7 мая 2013 г. № 79-ФЗ "О запрете отдельным
            категориям лиц открывать и иметь счета (вклады), хранить наличные
            денежные средства и ценности в иностранных банках, расположенных за
            пределами территории Российской Федерации, владеть и (или)
            пользоваться иностранными финансовыми инструментами"{" "}
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.kremlin.ru/acts/bank/24154 "
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 27 июля 2006 г. № 152-ФЗ "О персональных
            данных"
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.kremlin.ru/acts/bank/23543 "
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 8 марта 2006 г. № 40-ФЗ "О ратификации
            Конвенции Организации Объединенных Наций против коррупции"
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.kremlin.ru/acts/bank/24094"
            target={"_blank"}
            rel="noreferrer"
          >
            Федеральный закон от 25 июля 2006 г. № 125-ФЗ "О ратификации
            Конвенции об уголовной ответственности за коррупцию"
          </a>
        </li>
      </ul>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Указы Президента РФ
      </h3>
      <ul>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/47769"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 25 апреля 2022 года № 232 «О
            государственной информационной системе в области противодействия
            коррупции «Посейдон» и внесении изменений в некоторые акты
            Президента Российской Федерации»{" "}
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/47119 "
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 16 августа 2021 г. № 478 «О
            Национальном плане противодействия коррупции на 2021 - 2024 годы»{" "}
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/40324 "
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 22 декабря 2015 г. № 650 «О
            порядке сообщения лицами, замещающими отдельные государственные
            должности Российской Федерации, должности федеральной
            государственной службы, и иными лицами о возникновении личной
            заинтересованности при исполнении должностных обязанностей, которая
            приводит или может привести к конфликту интересов, и о внесении
            изменений в некоторые акты Президента Российской Федерации»{" "}
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/39492"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 8 марта 2015 г. № 120 "О
            некоторых вопросах противодействия коррупции"
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/38585"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 23 июня 2014 г. № 453 "О
            внесении изменений в некоторые акты Президента Российской Федерации
            по вопросам противодействия коррупции"
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/38589"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 23 июня 2014 г. № 460 "Об
            утверждении формы справки о доходах, расходах, об имуществе и
            обязательствах имущественного характера, и внесении изменений в
            некоторые акты Президента Российской Федерации"
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/37451"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 8 июля 2013 г. № 613
            "Вопросы противодействия коррупции"
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://www.kremlin.ru/acts/bank/36988"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 2 апреля 2013 г. № 309 "О
            мерах по реализации отдельных положений Федерального закона "О
            противодействии коррупции"{" "}
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/36989"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 2 апреля 2013 г. № 310 "О
            мерах по реализации отдельных положений Федерального закона "О
            контроле за соответствием расходов лиц, замещающих государственные
            должности, и иных лиц их доходам"{" "}
          </a>
        </li>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/27498"
            target={"_blank"}
            rel="noreferrer"
          >
            Указ Президента Российской Федерации от 19 мая 2008 г. № 815 "О
            мерах по противодействию коррупции"
          </a>
        </li>
      </ul>{" "}
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Постановления Правительства РФ
      </h3>
      <ul>
        <li>
          <a
            href="http://government.ru/docs/all/115609"
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 5 марта 2018 г.
            № 228 «О реестре лиц, уволенных в связи с утратой доверия»{" "}
          </a>
        </li>
        <li>
          <a
            href="http://government.ru/docs/all/94587/"
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 21 января 2015
            г. № 29 "Об утверждении Правил сообщения работодателем о заключении
            трудового или гражданско-правового договора на выполнение работ
            (оказание услуг) с гражданином, замещавшим должности государственной
            или муниципальной службы, перечень которых устанавливается
            нормативными правовыми актами Российской Федерации"{" "}
          </a>
        </li>{" "}
        <li>
          <a
            href="http://government.ru/docs/all/90020/"
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 09.01.2014 № 10
            "О порядке сообщения отдельными категориями лиц о получении подарка
            в связи с их должностным положением или исполнением ими служебных
            (должностных) обязанностей, сдачи и оценки подарка, реализации
            (выкупа) и зачисления средств, вырученных от его реализации"
          </a>
        </li>
        <li>
          <a
            href="http://government.ru/docs/all/87871/"
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 05.07.2013 № 568
            "О распространении на отдельные категории граждан ограничений,
            запретов и обязанностей, установленных Федеральным законом "О
            противодействии коррупции" и другими федеральными законами в целях
            противодействия коррупции"
          </a>
        </li>{" "}
        <li>
          <a
            href="http://government.ru/docs/all/86488/ "
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 13.03.2013 № 207
            "Об утверждении Правил проверки достоверности и полноты сведений о
            доходах, об имуществе и обязательствах имущественного характера,
            представляемых гражданами, претендующими на замещение должностей
            руководителей федеральных государственных учреждений, и лицами,
            замещающими эти должности"{" "}
          </a>
        </li>
        <li>
          <a
            href="http://government.ru/docs/all/86489/"
            target={"_blank"}
            rel="noreferrer"
          >
            Постановление Правительства Российской Федерации от 13.03.2013 № 208
            "Об утверждении Правил представления лицом, поступающим на работу на
            должность руководителя федерального государственного учреждения, а
            также руководителем федерального государственного учреждения
            сведений о своих доходах, об имуществе и обязательствах
            имущественного характера и о доходах, об имуществе и обязательствах
            имущественного характера своих супруга (супруги) и
            несовершеннолетних детей"{" "}
          </a>
        </li>
      </ul>{" "}
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Международные нормативно-правовые акты
      </h3>
      <ul>
        <li>
          <a
            href="http://pravo.gov.ru/proxy/ips/?doc_itself=&nd=201035447#I0"
            target={"_blank"}
            rel="noreferrer"
          >
            "Конвенция Организации Объединенных Наций против коррупции" (принята
            в г. Нью-Йорке 31 октября 2003 г. Резолюцией 58/4 на 51-ом пленарном
            заседании 58-ой сессии Генеральной Ассамблеи ООН){" "}
          </a>
        </li>
        <li>
          <a
            href="https://www.consultant.ru/document/cons_doc_LAW_121544"
            target={"_blank"}
            rel="noreferrer"
          >
            "Конвенция об уголовной ответственности за коррупцию" (заключена в
            г. Страсбурге 27 января 1999 г.)
          </a>
        </li>
      </ul>
    </>
  );
});
