import { observer } from "mobx-react-lite";
import { toggleClassName } from "../../../../utils/disabled";
import { BreadCrumbs, EducationContainer } from "../../../common";
import qr from "../../../../assets/img/nok/qr.png";
import qr1 from "../../../../assets/img/nok/1.png";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

import doc1 from "../../../../assets/pdf/home/nok/1. Результаты оценки НОКО-2021.pdf";
import doc2 from "../../../../assets/pdf/home/nok/2. План по устранению недостатков НОКО-2021.pdf";
import doc3 from "../../../../assets/pdf/home/nok/3. Отчет по устранению недостатков, выявленных в ходе НОКО-2021.pdf";
export const Nok = observer(() => {
  return (
    <>
      {" "}
      <BreadCrumbs />
      <section
        className={toggleClassName(
          "education__container",
          "education__container-white",
          "education__container-black",
          "education__container-contrast"
        )}
      >
        <h1
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Независимая оценка качества условий осуществления образовательной
          деятельности (НОКО) – 2024
        </h1>

        <EducationContainer>
          <p>
            В период с 16 сентября по 19 октября 2024 года в Уфимском филиале
            запланировано проведение независимой оценки качества условий
            осуществления образовательной деятельности (НОКО) в соответствии со
            статьей 95.2 Федерального закона от 29.12.2012 № 273-ФЗ «Об
            образовании в Российской Федерации», решением Общественного совета
            при Министерстве просвещения Российской Федерации по проведению
            независимой оценки качества условий осуществления образовательной
            деятельности федеральными государственными образовательными
            организациями, а также иными организациями, осуществляющими
            образовательную деятельность за счет бюджетных ассигнований
            федерального бюджета от 21 мая 2024 г. № ОС-3/пр.{" "}
          </p>
          <p>
            НОКО - оценочная процедура, которая направлена на получение сведений
            об уровне подготовки обучающихся, факторах, на него влияющих,
            условиях реализации образовательных программ (информационных,
            кадровых, материальных).{" "}
          </p>
          <p>
            НОКО проводится не чаще чем один раз в год и не реже чем один раз в
            три года.
          </p>
          <p>
            Цели и задачи проведения НОКО: повышение качества и эффективности.{" "}
          </p>
          <p>
            Обучающимся и их родителям результаты НОКО необходимы для выбора
            места обучения, оценки собственных возможностей продолжения обучения
            по тем или иным образовательным программам.
          </p>
          <p>
            Образовательным организациям – для оценки соответствия результатов
            своей деятельности запросам и ожиданиям участников образовательных
            отношений, а также для определения перечня мероприятий по улучшению
            результатов и качества предоставления образовательных услуг.
          </p>
          <p>
            {" "}
            Федеральные и региональные органы исполнительной власти используют
            результаты НОКО в целях принятия управленческих решений, в том числе
            при кадровых перестановках, разработке программ по развитию системы
            образования, проведении конкурсного отбора лучших образовательных
            организаций.
          </p>
          <p>
            <strong>Показатели НОКО:</strong>
          </p>
          <ul>
            <li>открытость и доступность информации об организациях; </li>
            <li>
              комфортность условий, в которых осуществляется образовательная
              деятельность;
            </li>
            <li>доброжелательность, вежливость работников; </li>
            <li>
              удовлетворенность условиями ведения образовательной деятельности
              организаций, а также доступность условий для лиц с инвалидностью.
            </li>
          </ul>
          <h2>
            Персонифицированный QR-код для оценки качества условий
            предоставления услуг организации их получателями, который дает
            возможность открыть страницу организации на официальном сайте
            www.bus.gov.ru либо в мобильном приложении «Наше мнение» (далее –
            QR-код) на выбор пользователя
          </h2>
          <h3>Уважаемые родители и курсанты, посетители Уфимского филиала!</h3>
          <p>
            Приглашаем Вас принять участие в опросе для независимой оценки
            качества условий оказания услуг.{" "}
          </p>
          <p>Вы сможете оценить условия оказания услуг: </p>
          <ul>
            <li>«Комфорт и санитарно-гигиеническое состояние помещений» </li>
            <li>«Доброжелательность и вежливость работников»</li>
            <li>
              «Легкость получения информации о работе организации и ее точность»
            </li>
            <li>
              «Легкость записи для получения услуги и своевременность ее
              оказания»
            </li>
            <li>«Доступность для инвалидов».</li>
          </ul>
          <p>
            Кроме того, каждый посетитель может оставить отзыв, обращение и
            получить на него ответ. Ваше мнение позволит улучшить работу
            образовательной организации и повысить качество образования.
          </p>
          <p>
            Ваша оценка поможет нам стать лучше и убедиться, что все хорошо!
          </p>
          <p>
            Чтобы оценить УСЛОВИЯ предоставления услуг наведите камеру Вашего
            телефона и сканируйте QR-код.{" "}
          </p>{" "}
          <div className="image">
            <img src={qr} width="250" alt="изображение уплыло" />
          </div>
          <div className="content">
            <p>
              или перейдите по{" "}
              <a
                href="https://bus.gov.ru/info-card/516047"
                target="_blank"
                rel="noopener noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                ссылке
              </a>
            </p>
          </div>
          <p>
            Для выявления и обобщения мнения граждан о качестве условий оказания
            услуг организациями в сфере культуры, охраны здоровья, образования и
            социального обслуживания создано мобильное приложение «Наше мнение».
            Мобильное приложение «Наше мнение» доступно гражданам для скачивания
            в магазине Rustore.
          </p>
          <div className="image">
            <img src={qr1} width="250" alt="изображение уплыло" />
          </div>
          <ul>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"30px"} width={"30px"} />{" "}
              <a href={doc1} target={"_blank"} rel="noreferrer">
                Результаты оценки НОКО-2021
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"30px"} width={"30px"} />{" "}
              <a href={doc2} target={"_blank"} rel="noreferrer">
                План по устранению недостатков НОКО-2021
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"30px"} width={"30px"} />{" "}
              <a href={doc3} target={"_blank"} rel="noreferrer">
                Отчет по устранению недостатков, выявленных в ходе НОКО-2021
              </a>
            </li>
          </ul>
        </EducationContainer>
      </section>
    </>
  );
});
