import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import Plan_FHD from "../../../../assets/doc/documentPage/План ФХД.docx";
import { ReactComponent as WORD } from "../../../../assets/svg/office/word.svg";
export const Budget = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Финансово-хозяйственная деятельность
      </h1>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения об объеме образовательной деятельности за 2023 год
      </h3>
      <table className="">
        <tbody>
          <tr>
            <td colSpan="2">
              Объем образовательной деятельности, финансовое обеспечение которой
              осуществляется
            </td>
          </tr>
          <tr>
            <td>
              за счет бюджетных ассигнований федерального бюджета (тыс. руб.){" "}
            </td>
            <td itemProp="finBFVolume">73054.90</td>
          </tr>
          <tr>
            <td>за счет субъектов Российской Федерации (тыс. руб.) </td>
            <td itemProp="finBRVolume">-</td>
          </tr>
          <tr>
            <td>за счет местных бюджетов (тыс. руб.) </td>
            <td itemProp="finBMVolume">-</td>
          </tr>
          <tr>
            <td>
              по договорам об образовании за счет средств физических и (или)
              юридических лиц (тыс. руб.)
            </td>
            <td itemProp="finPVolume">10590.40</td>
          </tr>
        </tbody>
      </table>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о поступлении финансовых и материальных средств и об их
        расходовании
      </h3>
      <table>
        <tbody itemProp="volume">
          <tr itemProp="finYear">
            <td>Год</td>
            <td>2023</td>
          </tr>
          <tr itemProp="finPost">
            <td>Поступившие финансовые и материальные средства </td>
            <td>22324.9</td>
          </tr>
          <tr itemProp="finRas">
            <td>Расходованные финансовые и материальные средства</td>
            <td>20219.8</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td colSpan="4">Объем образовательной деятельности за 2023 год</td>
          </tr>
          <tr>
            <td colSpan="4">
              Объем образовательной деятельности, финансовое обеспечение которой
              осуществляется
            </td>
          </tr>
          <tr>
            <td>
              за счет бюджетных ассигнований федерального бюджета (тыс. руб.)
            </td>
            <td>за счет субъектов Российской Федерации (тыс. руб.)</td>
            <td>за счет местных бюджетов (тыс. руб.)</td>
            <td>
              по договорам об образовании за счет средств физических и (или)
              юридических лиц (тыс. руб.)
            </td>
          </tr>
          <tr>
            <td>73054.90</td>
            <td>-</td>
            <td>-</td>
            <td>10590.40</td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li className="doc__item" itemProp="volume">
          <WORD width="25px" height="25px" />
          <a
            itemProp="finPlanDocLink"
            href={Plan_FHD}
            target={"_blank"}
            rel="noreferrer"
          >
            План ФХД на 2023 год
          </a>
        </li>
      </ul>
      <div>
        <p>
          Уфимским филиалом ФГБОУ ВО «ВГУВТ» запланировано расходование средств
          в 2024 году на организацию культурно - массовой, физкультурной и
          спортивной, оздоровительной работы с обучающимися в размере 4000,6
          тыс. руб., в т.ч. за счет средств федерального бюджета –2 835,5 тыс.
          руб., за счет средств полученных по договорам об оказании платных
          образовательных услуг – 1 165,1 тыс. руб.
        </p>
      </div>
    </div>
  );
});
