import { observer } from "mobx-react-lite";
import React from "react";
import BazPraktik from "../../../../assets/pdf/commonPage/baz_praktik.docx";
import { toggleClassName } from "../../../../utils/disabled";
import vipiskaIzReestra from "../../../../assets/pdf/documentPage/Выписка из Реестра о Гос.аккредитации от 18.03.2024.pdf";
import LicenzVip from "../../../../assets/pdf/documentPage/Реестровая выписка.pdf";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

export const Common = observer(() => {
  return (
    <>
      <div
        itemProp="copy"
        className={toggleClassName(
          "common__container",
          "common__container-white",
          "common__container-black",
          "common__container-contrast"
        )}
      >
        <h1
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          История создания и развития
        </h1>
        <p>
          Уфимское речное училище было создано приказом № 89 от 7 августа 1970
          года Министерства речного флота Российской Федерации для обеспечения
          командными кадрами судов Бельского речного пароходства и ЗАО
          Башволготанкер.
        </p>
        <p>
          Распоряжением Правительства Российской Федерации №1550 - р от 30
          сентября 2005 года Уфимское командное речное училище реорганизовано в
          Уфимский филиал Московской Государственной Академии водного
          транспорта. В соответствии с распоряжением Федерального агентства
          морского и речного транспорта (Росморечфлот) № АД-150-р от 26.05.2011
          филиал переименован в Уфимский филиал Федерального бюджетного
          образовательного учреждения высшего профессионального образования
          "Московская государственная академия водного транспорта" (Уфимский
          филиал ФБОУ ВПО «МГАВТ»).
        </p>
        <p>
          Согласно распоряжению Федерального агентства морского и речного
          транспорта (Росморечфлот) № АД-368-р от 22.09.2014 филиал переименован
          в Уфимский филиал Федерального государственного бюджетного
          образовательного учреждения высшего образования "Московская
          государственная академия водного транспорта". В соответствии с
          распоряжением Федерального агентства морского и речного транспорта
          (Росморечфлот) № АД-481-р от 26.12.2014 изменено сокращенное
          наименование филиала – Уфимский филиал ФГБОУ ВО «МГАВТ». Федеральное
          государственное, бюджетное образовательное учреждение высшего
          образования «Московская государственная академия водного транспорта»
          является некоммерческой образовательной организацией высшего
          образования - государственным бюджетным образовательным учреждением
          высшего образования федерального подчинения.
        </p>
        <p>
          На основании распоряжения Федерального агентства морского и речного
          транспорта № ВО-282-Р от 28.11.2016 Уфимский филиал Федерального
          государственного бюджетного образовательного учреждения высшего
          образования «Московская государственная академия водного транспорта»
          реорганизован в Уфимский филиал Федерального государственного
          бюджетного образовательного учреждения высшего образования
          «Государственный университет морского и речного флота имени адмирала
          С.О. Макарова» от 01.02.2017. В соответствии с распоряжением
          Учредителя, от 16.08. 2017 года № АП-193-Р с 01.12.2017 года, на базе
          Уфимского филиала ФГБОУ ВО «ГУМРФ имени адмирала С.О. Макарова»
          образован Уфимский филиал Федерального государственного бюджетного
          образовательного учреждения высшего образования «Волжский
          государственный университет водного транспорта» (Уфимский филиал ФГБОУ
          ВО «ВГУВТ»). Уфимский филиал ФГБОУ ВО «ВГУВТ» является обособленным
          структурным подразделением ФГБОУ ВО «ВГУВТ», расположенным вне места
          его нахождения и осуществляющим постоянно все его функции или их
          часть.
        </p>
        <h1
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Сведения об образовательной организации
        </h1>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>Полное наименование образовательной организации</td>
                <td itemProp="fullName">
                  {" "}
                  Уфимский филиал Федерального государственного бюджетного
                  образовательного учреждения высшего образования «Волжский
                  государственный университет водного транспорта»
                </td>
              </tr>
              <tr>
                <td> Сокращенное наименование образовательной организации</td>
                <td itemProp="shortName">Уфимский филиал ФГБОУ ВО "ВГУВТ"</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Основные сведения об образовательной организации
        </h3>
        <div className="common__scrollTable">
          <table className="">
            <tbody>
              <tr>
                <td>Дата создания образовательной организации</td>
                <td itemProp="regDate">31.08.2017</td>
              </tr>
              <tr>
                <td>Адрес местонахождения образовательной организации</td>
                <td itemProp="address">
                  450017, Республика Башкортостан, г. Уфа, ул. Ахметова, д. 275
                </td>
              </tr>
              <tr>
                <td>Филиалы образовательной организации</td>
                <td>отсутствуют</td>
              </tr>
              <tr>
                <td>Представительства образовательной организации</td>
                <td>отсутствуют</td>
              </tr>
              <tr>
                <td>Режим, график работы</td>
                <td itemProp="workTime">
                  08.15 – 17.15 (пн - пт), обеденный перерыв 12.00-13.00 8.30 -
                  13.25 (суббота) - в период обучения
                </td>
              </tr>
              <tr>
                <td>Контактные телефоны</td>
                <td itemProp="telephone">
                  8 (347) 215-14-00 доб. 6 (приемная);
                </td>
              </tr>
              <tr>
                <td></td>
                <td itemProp="telephone">
                  8 (347) 215-14-00 доб. 1 (бухгалтерия)
                </td>
              </tr>
              <tr>
                <td>Адреса электронной почты</td>
                <td itemProp="email">Приемная uf-vsuwt@uf-vsuwt.ru</td>
              </tr>
              <tr>
                <td></td>
                <td itemProp="email">Бухгалтерия buh@uf-vsuwt.ru</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Информация об учредителе
        </h3>
        <div className="common__scrollTable">
          <table itemProp="uchredLaw">
            <tbody>
              <tr>
                <td>Наименование учредителя</td>
                <td itemProp="nameUchred">
                  Федеральное агентство морского и речного транспорта
                </td>
              </tr>
              <tr>
                <td>Адрес местонахождения учредителя</td>
                <td itemProp="addressUchred">
                  Россия, 125993, Центральный ФО, г. Москва, ул. Петровка, д.
                  3/6
                </td>
              </tr>
              <tr>
                <td>Контактные телефоны</td>
                <td itemProp="telUchred">8(495)626-11-00,</td>
              </tr>
              <tr>
                <td></td>
                <td itemProp="telUchred">8(495)626-10-57</td>
              </tr>
              <tr>
                <td>Адрес электронной почты</td>
                <td itemProp="mailUchred">ud@morflot.ru</td>
              </tr>
              <tr>
                <td>Адрес сайта учредителя в сети "Интернет"</td>
                <td itemProp="websiteUchred">http://morflot.gov.ru/</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места осуществления образовательной деятельности, в том числе не
          указываемые в приложении к лицензии (реестре лицензий) на
          осуществление образовательной деятельности
        </h3>{" "}
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места осуществления образовательной деятельности, которые включаются в
          соответствующую запись в реестре лицензий на осуществление
          образовательной деятельности
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>
              <tr>
                <td>1</td>
                <td itemProp="addressPlaceSet">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td itemProp="addressPlaceSet">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Союзная
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места осуществления образовательной деятельности при использовании
          сетевой формы реализации образовательных программ
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>{" "}
              <tr>
                <td>1</td>
                <td itemProp="addressPlaceSet">
                  450017, г. Уфа, ул. Ахметова, д. 207, ООО
                  «Судоремонтно-судостроительный завод»
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td itemProp="addressPlaceSet">
                  450006, г. Уфа, ул. Ленина, д 130, БРВПиС – филиал ФБУ
                  «Администрация «Камводпуть»
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места проведения практики
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>
              <tr>
                <td>1</td>
                <td itemProp="addressPlacePrac">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>{" "}
              <tr>
                <td>2</td>
                <td itemProp="addressPlacePrac">
                  677000, Республика Саха (Якутия), г. Якутск, ул. Дзержинского,
                  д. 2, ПАО «Ленское объединённое речное пароходство»
                </td>
              </tr>{" "}
              <tr>
                <td>3</td>
                <td itemProp="addressPlacePrac">
                  450039, г. Республика Башкортостан, Уфа, ул. Сельская
                  Богородская, д. 51, ООО «Дельфин»
                </td>
              </tr>{" "}
              <tr>
                <td>4</td>
                <td itemProp="addressPlacePrac">
                  614990, Пермский край, г. Пермь, Екатерининская ул., 53, ООО
                  «Экспрес-Тур»
                </td>
              </tr>{" "}
              <tr>
                <td>5</td>
                <td itemProp="addressPlacePrac">
                  450006, Республика Башкортостан, г. Уфа, ул. Ленина, д. 97/1,
                  ООО Судоходная компания «Волга»
                </td>
              </tr>{" "}
              <tr>
                <td>6</td>
                <td itemProp="addressPlacePrac">
                  125057, г. Москва, ул. Алабяна, д. 5, АО «Донинтурфлот»
                </td>
              </tr>{" "}
              <tr>
                <td>7</td>
                <td itemProp="addressPlacePrac">
                  450022, Республика Башкортостан, г. Уфа, ул. Менделеева, д.
                  141, ООО «Речфлот»
                </td>
              </tr>{" "}
              <tr>
                <td>8</td>
                <td itemProp="addressPlacePrac">
                  614058, Пермский край, г. Пермь, ул. Маяковского, д. 2Б, ООО
                  «Камская лесосплавная компания»
                </td>
              </tr>{" "}
              <tr>
                <td>9</td>
                <td itemProp="addressPlacePrac">
                  420087, Республика Татарстан, г. Казань, ул. Карбышева, д. 40,
                  ООО «Оптимал Шиппинг»
                </td>
              </tr>{" "}
              <tr>
                <td>10</td>
                <td itemProp="addressPlacePrac">
                  452450, Республика Башкортостан, г. Бирск, ул. Набережная, д.
                  1, ООО «Речной Порт Бирск»
                </td>
              </tr>{" "}
              <tr>
                <td>11</td>
                <td itemProp="addressPlacePrac">
                  450074, Республика Башкортостан, г. Уфа, ул. Софьи Перовской,
                  д. 48, ООО «СтройТранс»
                </td>
              </tr>{" "}
              <tr>
                <td>12</td>
                <td itemProp="addressPlacePrac">
                  443099, Самарская область, г. Самара, ул. Максима Горького,
                  д.78в, ООО «Самара Шиппинг»
                </td>
              </tr>{" "}
              <tr>
                <td>13</td>
                <td itemProp="addressPlacePrac">
                  427960, Удмуртская Республика, г. Сарапул, ул. Оползина, д.
                  8А, Нижнекамский район водных путей и судоходства ФБУ
                  «Администрация «Камводпуть»
                </td>
              </tr>{" "}
              <tr>
                <td>14</td>
                <td itemProp="addressPlacePrac">
                  423577, Республика Татарстан, г. Нижнекамск, ул. Спортивная,
                  д. 2, ООО «Набережно-Челнинская судоходная компания»
                </td>
              </tr>{" "}
              <tr>
                <td>15</td>
                <td itemProp="addressPlacePrac">
                  450017, Республика Башкортостан, г. Уфа, ул. Ахметова, д. 207,
                  ООО «Судоремонтно-судостроительный завод»
                </td>
              </tr>{" "}
              <tr>
                <td>16</td>
                <td itemProp="addressPlacePrac">
                  109004, г. Москва, ул. Станиславского, д. 10, стр. 1, ООО
                  «Флот-Сервис»
                </td>
              </tr>{" "}
              <tr>
                <td>17</td>
                <td itemProp="addressPlacePrac">
                  450091, Республика Башкортостан, г. Уфа, пр. Сафроновский, д.
                  4, ООО СК «Основа»
                </td>
              </tr>{" "}
              <tr>
                <td>18</td>
                <td itemProp="addressPlacePrac">
                  606488, Нижегородская область, г. Бор, п. Память Парижской
                  Коммуны, ул. Пролетарская, д. 63, ООО «Белый лебедь»
                </td>
              </tr>{" "}
              <tr>
                <td>19</td>
                <td itemProp="addressPlacePrac">
                  450068, Республика Башкортостан, г. Уфа, ул. Орджоникидзе, д.
                  19/2, ООО «Галион»
                </td>
              </tr>{" "}
              <tr>
                <td>20</td>
                <td itemProp="addressPlacePrac">
                  115191, г. Москва, пер. Духовской, д. 17, стр. 15, ООО «Волга
                  Лайн»
                </td>
              </tr>{" "}
              <tr>
                <td>21</td>
                <td itemProp="addressPlacePrac">
                  358003, Республика Мари-Эл, г. Элиста, ул. Ю. Клыкова, д. 85,
                  ООО «Волгофлот»
                </td>
              </tr>{" "}
              <tr>
                <td>22</td>
                <td itemProp="addressPlacePrac">
                  450098, Республика Башкортостан, г. Уфа, ул. Российская, д.
                  157/1, МБУ «Служба речных переправ»
                </td>
              </tr>{" "}
              <tr>
                <td>23</td>
                <td itemProp="addressPlacePrac">
                  450006, Республика Башкортостан, г. Уфа, ул. Ленина, д 130,
                  БРВПиС – филиал ФБУ «Администрация «Камводпуть»
                </td>
              </tr>{" "}
              <tr>
                <td>24</td>
                <td itemProp="addressPlacePrac">
                  191186, г. Санкт-Петербург, пр. Невский, д. 30, литера А, ООО
                  «Нева-Хаген»
                </td>
              </tr>{" "}
              <tr>
                <td>25</td>
                <td itemProp="addressPlacePrac">
                  603024, Нижегородская область, г. Нижний Новгород, ул.
                  Невзоровых, д. 47, ООО «Речсервис»
                </td>
              </tr>{" "}
              <tr>
                <td>26</td>
                <td itemProp="addressPlacePrac">
                  109028, г. Москва, пер Дурасовский, д. 7, стр. 1, ООО «Цезарь
                  Трэвел»
                </td>
              </tr>{" "}
              <tr>
                <td>27</td>
                <td itemProp="addressPlacePrac">
                  614060, Пермский край, г. Пермь, ул. Красновишерская, д. 37,
                  ООО «Кама Шиппинг»
                </td>
              </tr>{" "}
              <tr>
                <td>28</td>
                <td itemProp="addressPlacePrac">
                  453430, Республика Башкортостан, г. Благовещенск, ул.
                  Буденного, д. 2, ООО «Баштрансфлот»
                </td>
              </tr>{" "}
              <tr>
                <td>29</td>
                <td itemProp="addressPlacePrac">
                  420032, Республика Татарстан, г. Казань, ул. Гладилова, д. 41,
                  офис 3, ООО «Вектор-К»
                </td>
              </tr>{" "}
              <tr>
                <td>30</td>
                <td itemProp="addressPlacePrac">
                  603076, Нижегородская область, г. Нижний Новгород, пр. Ленина,
                  д. 36, ООО «Порт Работки»
                </td>
              </tr>{" "}
              <tr>
                <td>31</td>
                <td itemProp="addressPlacePrac">
                  603158, Нижегородская область, г. Нижний Новгород, ул.
                  Дизельная, д. 23, ком. 3, ООО «Респект – Строй НН»
                </td>
              </tr>{" "}
              <tr>
                <td>32</td>
                <td itemProp="addressPlacePrac">
                  400094, Волгоградская область, г. Волгоград, ул. 51-й
                  Гвардейской, д. 38, ИП Кондакова Е.В.
                </td>
              </tr>{" "}
              <tr>
                <td>33</td>
                <td itemProp="addressPlacePrac">
                  644070, Омская область, г. Омск, ул. Куйбышева, д. 27, ООО
                  «Ривер Транс»
                </td>
              </tr>{" "}
              <tr>
                <td>34</td>
                <td itemProp="addressPlacePrac">
                  445040, Нижегородская область, г. Тольятти, б-р Туполева,
                  д.14, ООО «Трастфлот»
                </td>
              </tr>{" "}
              <tr>
                <td>35</td>
                <td itemProp="addressPlacePrac">
                  603001, Нижегородская область, г. Нижний Новгород, пл.
                  Маркина, д. 15а, АО «СК Волжское пароходство»
                </td>
              </tr>{" "}
              <tr>
                <td>36</td>
                <td itemProp="addressPlacePrac">
                  443099, Самарская область, г. Самара, ул. Алексея Толстого, д.
                  87/8, ООО «АкадемФлот»
                </td>
              </tr>{" "}
              <tr>
                <td>37</td>
                <td itemProp="addressPlacePrac">
                  450076, Республика Башкортостан, г. Уфа, ул. Свердлова, д. 13,
                  ООО «Круиз»
                </td>
              </tr>{" "}
              <tr>
                <td>38</td>
                <td itemProp="addressPlacePrac">
                  450015, Республика Башкортостан, г. Уфа, ул. Карла Маркса, д.
                  35, ГУП «Башавтотранс»
                </td>
              </tr>{" "}
              <tr>
                <td>39</td>
                <td itemProp="addressPlacePrac">
                  607680, Нижегородская область, д Афонино, ул. Родниковая, д.
                  18 ООО «Фарватер»
                </td>
              </tr>{" "}
              <tr>
                <td>40</td>
                <td itemProp="addressPlacePrac">
                  443015, Самарская область, г. Самара, ул. Народная, д. 21,
                  офис 2, ООО «Темп+»
                </td>
              </tr>{" "}
              <tr>
                <td>41</td>
                <td itemProp="addressPlacePrac">
                  450112, Республика Башкортостан, г. Уфа, ул. Новочеркасская,
                  д. 7, офис 14, ООО «Транспортные технологии»
                </td>
              </tr>{" "}
              <tr>
                <td>42</td>
                <td itemProp="addressPlacePrac">
                  125047, г. Москва, ул. 1-я Тверская-Ямская, д. 24, ООО
                  «ВодоходЪ»
                </td>
              </tr>{" "}
              <tr>
                <td>43</td>
                <td itemProp="addressPlacePrac">
                  129110, г. Москва, ул. Гиляровского, д. 65, стр. 1, ООО «СК
                  Созвездие»
                </td>
              </tr>{" "}
              <tr>
                <td>44</td>
                <td itemProp="addressPlacePrac">
                  625002, Тюменская область, г. Тюмень, ул. Пароходская, д. 31,
                  ПАО «Обь-Иртышское речное пароходство»
                </td>
              </tr>{" "}
              <tr>
                <td>45</td>
                <td itemProp="addressPlacePrac">
                  450006, Республика Башкортостан, г. Уфа, ул. Ленина, 97/1, ООО
                  «Адмирал Маритайм»
                </td>
              </tr>{" "}
              <tr>
                <td>46</td>
                <td itemProp="addressPlacePrac">
                  125212, г. Москва, шоссе Головинское, д. 5, к. 1, помещ.
                  20018, ООО «СК Морвенна»
                </td>
              </tr>{" "}
              <tr>
                <td>47</td>
                <td itemProp="addressPlacePrac">
                  125057, г. Москва, ул. Алабяна, д. 5, помещ. 6/1, АО
                  «Донинтурфлот»
                </td>
              </tr>
              <tr>
                <td>48</td>
                <td itemProp="addressPlacePrac">
                  129110, г. Москва, ул. Гиляровского, д. 65, стр. 1, ООО «СК
                  Созвездие»
                </td>
              </tr>
              <tr>
                <td>49</td>
                <td itemProp="addressPlacePrac">
                  450076, Республика Башкортостан, г. Уфа, ул. Заки Валиди, д.
                  2, ООО «Конгресс-Холл Торатау»
                </td>
              </tr>
              <tr>
                <td>50</td>
                <td itemProp="addressPlacePrac">
                  450095, Республика Башкортостан, г. Уфа, ул. Майкопская, д.
                  58/1, ООО «ФУДТАУН»
                </td>
              </tr>
              <tr>
                <td>51</td>
                <td itemProp="addressPlacePrac">
                  452173, Республика Башкортостан, Чишминский р-н, п. Чишмы, ул.
                  Мира, д.1, ООО «Башкир-Агроинвест»
                </td>
              </tr>
              <tr>
                <td>52</td>
                <td itemProp="addressPlacePrac">
                  456012, Челябинская область, г. Аша, ул. Омская, д. 2а, ООО
                  «Илеко»
                </td>
              </tr>
              <tr>
                <td>53</td>
                <td itemProp="addressPlacePrac">
                  603011, Нижегородская область, г. Нижний Новгород, ул.
                  Октябрьской Революции, д. 78, ОАО «РЖД»
                </td>
              </tr>
              <tr>
                <td>54</td>
                <td itemProp="addressPlacePrac">
                  603158, Нижегородская область, г. Нижний Новгород, ул.
                  Дизельная, д. 23, ком. 3, ООО «Респект-Строй НН»
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места проведения практической подготовки обучающихся
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>{" "}
              <tr>
                <td>1</td>
                <td itemProp="addressPlacePodg">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td itemProp="addressPlacePodg">
                  450039, Республика Башкортостан, г. Уфа, ул. Сельская
                  Богородская, д. 51, ООО «Дельфин»
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td itemProp="addressPlacePodg">
                  450017, Республика Башкортостан, г. Уфа, ул. Ахметова, д. 207,
                  ООО «Судоремонтно-судостроительный завод»
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td itemProp="addressPlacePodg">
                  450006, Республика Башкортостан, г. Уфа, ул. Ленина, д 130,
                  БРВПиС – филиал ФБУ «Администрация «Камводпуть»
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td itemProp="addressPlacePodg">
                  450006, Республика Башкортостан, г. Уфа, ул. Ленина, 97/1, ООО
                  «Адмирал Маритайм»
                </td>
              </tr>
            </tbody>
          </table>
        </div>{" "}
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места проведения государственной итоговой аттестации
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>{" "}
              <tr>
                <td>1</td>
                <td itemProp="addressPlaceGia">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>
            </tbody>
          </table>
        </div>{" "}
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места осуществления образовательной деятельности по дополнительным
          образовательным программам
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>{" "}
              <tr>
                <td>1</td>
                <td itemProp="addressPlaceDop">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>
            </tbody>
          </table>
        </div>{" "}
        <h3
          itemProp="addressPlace"
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Места осуществления образовательной деятельности по основным
          программам профессионального обучения
        </h3>
        <div className="common__scrollTable">
          <table>
            <tbody>
              <tr>
                <td>№ п/п</td>
                <td>Адрес места осуществления образовательной деятельности </td>
              </tr>{" "}
              <tr>
                <td>1</td>
                <td itemProp="addressPlaceOppo">
                  450017, Республика Башкортостан, г. Уфа, Ленинский р-н, ул.
                  Ахметова, д. 275
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*  <div>
          <div className="common__scrollTable">
            <table>
              <tbody>
                <tr>
                  <td>
                    № <br />
                    п/п
                  </td>
                  <td>Наименование объекта</td>
                  <td>
                    Адрес места осуществления образовательной деятельности{" "}
                  </td>
                  <td>
                    Назначение для целей осуществления образовательной
                    деятельности (основная, дополнительная профессиональная
                    программа, основная программа профессионального обучения,
                    сетевая форма реализации образовательных программ,
                    проведение практики, проведение практической подготовки
                    обучающихся, проведение государственной итоговой аттестации)
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Учебный корпус</td>
                  <td itemProp="addressPlacePodg">
                    450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
                  </td>
                  <td>
                    основная, дополнительная профессиональная программа,
                    основная программа профессионального обучения, проведение
                    практической подготовки обучающихся, проведение ГИА
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td> Общежитие</td>
                  <td itemProp="addressPlaceDop">
                    450017, Республика Башкортостан, г.Уфа, ул. Ахметова, д. 275
                  </td>
                  <td>
                    основная, дополнительная профессиональная программа,
                    основная программа профессионального обучения проведение
                    практической подготовки обучающихся, проведение ГИА
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td> Крытая спортивная площадка</td>
                  <td> 450017, Республика Башкортостан, г.Уфа, ул. Союзная</td>
                  <td>
                    основная профессиональная программа, проведение практической
                    подготовки обучающихся
                  </td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Базы практики</td>
                  <td itemProp="addressPlacePrac">
                    <a
                      className="active__link"
                      href={BazPraktik}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Список баз практик обучающихся
                    </a>
                  </td>
                  <td>проведение практики</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Федеральное государственное бюджетное образовательное учреждение
          высшего образования «Волжский государственный университет водного
          транспорта» (ФГБОУ ВО «ВГУВТ»)
        </h3>
        <div className="common__scrollTable">
          <table className="">
            <tbody>
              <tr>
                <td>Место нахождения:</td>
                <td>
                  603950, Приволжский ФО, г. Нижний Новгород, ул. Нестерова, 5
                </td>
              </tr>
              <tr>
                <td>Руководитель:</td>
                <td>Кузьмичёв Игорь Константинович</td>
              </tr>
              <tr>
                <td>График работы:</td>
                <td>с 8:00 до 17:00</td>
              </tr>
              <tr>
                <td>Контактные телефоны:</td>
                <td> Приемная комиссия: (831) 419-78-14, (831) 419-79-24</td>
              </tr>
              <tr>
                <td></td>
                <td>Общий отдел: (831) 419-78-58</td>
              </tr>
              <tr>
                <td></td>
                <td>Бухгалтерия: (831) 419-31-79</td>
              </tr>
              <tr>
                <td>Факсы:</td>
                <td>Общий отдел (831) 419-78-58</td>
              </tr>
              <tr>
                <td>Адрес электронной почты: </td>
                <td>Общий отдел: otd_o@vsuwt.ru </td>
              </tr>
              <tr>
                <td>Адрес сайта в сети Интернет:</td>
                <td>http://www.vsuwt.ru </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Федеральное государственное бюджетное образовательное учреждение
          высшего образования «Волжский государственный университет водного
          транспорта» (ФГБОУ ВО «ВГУВТ»)
        </h3>
        <ul>
          <li className="doc__item">
            <PDF height={"40px"} width={"40px"} />
            <a
              itemProp="licenseDocLink"
              href={LicenzVip}
              target={"_blank"}
              rel="noreferrer"
            >
              Выписка из реестра лицензий Федеральной службы по надзору в сфере
              образования и науки (2.04.2024)
            </a>
          </li>
          <li className="doc__item">
            <PDF height={"40px"} width={"40px"} />
            <a
              itemProp="accreditationDocLink"
              href={vipiskaIzReestra}
              target={"_blank"}
              rel="noreferrer"
            >
              Выписка из Реестра о Государственной аккредитации от 18.03.2024г.
            </a>
          </li>
        </ul>
      </div>
    </>
  );
});
