import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";

export const Employees = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <>
        {" "}
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Информация о составе педагогических (научно-педагогических) работников
          Уфимского филиала ФГБОУ ВО "ВГУВТ"
        </h3>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>Ф.И.О</td>
                <td>Должность преподавателя</td>
                <td>Перечень преподаваемых дисциплин</td>
                <td>
                  Уровень (уровни) профессионального образования, квалификация
                </td>
                <td>
                  Наименование направления подготовки и (или ) специальности, в
                  том числе научной
                </td>
                <td>Ученая степень (при наличии)</td>
                <td>Ученое звание (при наличии)</td>
                <td>
                  Сведения о повышении квалификации (за последние 3 года) и
                  сведения о профессиональной переподготовке (при наличии)
                </td>
                <td>Общий стаж работы</td>
                <td>
                  Сведения о продолжительности опыта (лет) работы в
                  профессиональной сфере
                </td>
                <td>
                  Наименование образовательных программ, в реализации которых
                  участвует педагогический работник
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Абкадиров Ильдар Ангамович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Мореходная астрономия Судовое Радиооборудование Технические
                  средства судовождения. Тренажерная подготовка. Организация
                  связи ГМССБ Управление судном и технические средства
                  судовождения Использование РЛС и САРП Оператор связи ГМССБ "
                </td>
                <td itemProp="teachingLevel">
                  Высшее, первая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер-судоводитель, судовождение на морских путях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи пострадавшим";
                  (2023)"Обучение охране труда для работников филиала" (3023)
                </td>
                <td itemProp="genExperience">30</td>
                <td itemProp="specExperience">17</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Адиятуллина Диля Миннемуслимовна</td>
                <td itemProp="post">Социальный педагог, преподаватель</td>
                <td itemProp="teachingDiscipline">Психология общения</td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  "Социальный педагог, педагог психолог"
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "НЛП практик"(2022); "Профилактическая работа в деятельности
                  педагога-психолога образовательной организации" (2021);
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023) "Обучение охране труда для работников
                  филиала"(2023); "Преподавание психологии общения в
                  соответствии с ФГОС СПО" (2023)
                </td>
                <td itemProp="genExperience">13</td>
                <td itemProp="specExperience">5</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Акбарова Залия Шамсуновна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Математика Индивидуальный проект"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  "Учитель физики и математики, физика и математика "
                </td>
                <td itemProp="degree">
                  Ученая степень Кандидат педагогических наук 13.00.08 "Теория и
                  методика профессионального образования"
                </td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  " Методика преподавания математики в СПО в условиях реализации
                  ФГОС СПО" (2022); "Обучение работников по оказанию первой
                  помощи пострадавшим"(2023); "Разработка и реализация рабочих
                  программ дисплин(модулей) для формирования универсальной
                  компетенции в области экономической культуры, в том числе
                  финансовой грамотности" (2023); "Деятельность по управлению
                  страховыми организациями" (2023); "Обучение охране труда для
                  работников филиала" (2023)
                </td>
                <td itemProp="genExperience">31</td>
                <td itemProp="specExperience">27</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Акбашева Альбина Галимзяновна</td>
                <td itemProp="post">
                  Заместитель директора по воспитательной работе, преподаватель
                </td>
                <td itemProp="teachingDiscipline">Обществознание</td>
                <td itemProp="teachingLevel">
                  Высшее, первая квалификационная категория
                </td>
                <td itemProp="employeeQualification">Магистр, экономика</td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  Современные аспекты молодежной политики и организации
                  безопасности в вузах"(2022);. "Обучение работников по оказанию
                  первой помощи пострадавшим"(2023); " Особенности деятельности
                  педагогических работников СПО в свете требований
                  профессионального стандарта" (2023); "Менеджер в образовании"
                  (2024); "Предупреждение коррупции в организациях" (2024);
                  "Контрактная система в сфере закупок, товаров, работ и услуг
                  для обеспечения государственных и муниципальных нужд /Закон
                  №44-ФЗ/" (2024); "Противодействие терроризму и экстремизму"
                  (2024); "Правовое обеспечение в профессиональной деятельности"
                  (2024)
                </td>
                <td itemProp="genExperience">15</td>
                <td itemProp="specExperience">15</td>
                <td itemProp="teachingOp">
                  26.02.06 Эксплуатация судового элктрооборудования и средств
                  автоматики 26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Арсланова Наталья Анатольевна</td>
                <td itemProp="post"> Медицинская сестра, преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Иностранный язык в профессиональной деятельности Оказание
                  первой помощи Иностранный язык"
                </td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория"
                </td>
                <td itemProp="employeeQualification">
                  "Лингвист, переводчик немецкого и английского языка., перевод
                  и переводоведение. Медицинская сестра, сестринское дело"
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Охрана здоровья работников промышленных и других
                  предприятий(медсестра здравпункта промышленных и других
                  предприятий"(2022);"Цифровые технологии в преподавании
                  профильных дисциплин"(2022);"Подготовка преподавателей,
                  обучающих приемам оказания первой помощи" (2023)
                  ;"Интерактивный образовательный модуль: Стандартная
                  операционная процедура "Внутримышечная инъекция" (2023);"
                  Проведение обучения по использованию(применению) СИЗ" (2023);
                  "Оказание первой помощи раненых в военно-полевых условиях
                  (тактическая медицина)" (2023); " Особенности деятельности
                  педагогических работников СПО в свете требований
                  профессионального стандарта" (2023); "Оказание первой помощи
                  при несчастных случаях в природной среде"( 2024);
                </td>
                <td itemProp="genExperience">18</td>
                <td itemProp="specExperience">6</td>
                <td itemProp="teachingOp">
                  26.02.05 Эксплуатация судовых энергетических установок
                  26.02.06 Эксплуатация судового элктрооборудования и средств
                  автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Ахмадеева Фарида Шариповна</td>
                <td itemProp="post">Директор, преподаватель</td>
                <td itemProp="teachingDiscipline">
                  Технология перевозки грузов
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер водного транспорта, организация перевозок и управление
                  на транспорте (водном)
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Безопасные методы и приемы выполнения
                  работ при воздействии вредных и (или) опасных производственных
                  факторов, источников опасности, идентифицированных в рамках
                  специальной оценки труда и оценки профессиональных
                  рисков"(2023); "Предупреждение коррупции в организациях"
                  (2024)
                </td>
                <td itemProp="genExperience">40</td>
                <td itemProp="specExperience">25</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Ахмадуллина Лилия Рашитовна</td>
                <td itemProp="post">Заведующая отделением, преподаватель</td>
                <td itemProp="teachingDiscipline">Обществознание</td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория "
                </td>
                <td itemProp="employeeQualification">
                  Психология с дополнительной специальностью
                  олигофренопедагогики, педагог- психолог
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Особенности деятельности педагогических работников СПО в
                  свете требований профессионального стандарта" (2023);
                  "Методика преподавания обществознания в условиях реализации
                  ФГОС" (2023); "Обучение работников по оказанию первой помощи
                  пострадавшим" (2023)
                </td>
                <td itemProp="genExperience">23</td>
                <td itemProp="specExperience">12</td>
                <td itemProp="teachingOp">
                  26.02.05 Эксплуатация судовых энергетических установок
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Ахметшин Максут Раянович</td>
                <td itemProp="post">
                  Преподаватель, первая квалификационная категория
                </td>
                <td itemProp="teachingDiscipline">
                  "Механика Метрология и стандартизация Инженерная графика
                  Метрология, стандартизация и сертификация"
                </td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория "
                </td>
                <td itemProp="employeeQualification">
                  "Инженер-механик, Механизация сельского хозяйства. Экономист,
                  финансы и кредит."
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Конструирование и расчет с применением САПР Компас-3D на
                  занятиях общепрофессиональных дисциплин" (2022); Прикладной
                  искусственный интеллект в программах дисциплин"(2022);
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); Осуществление образовательного процесса
                  по предмету " Техническая механика" в организациях СПО с
                  учетом требований ФГОС СПО" (2023); " Техническая механика"
                  (2023); " Мультимедийные технологии как средство создания
                  наглядных и информативных обучающих материалов разных
                  форматов" (2024)
                </td>
                <td itemProp="genExperience">32</td>
                <td itemProp="specExperience">22</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорте
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Баширова Альфия Анваровна</td>
                <td itemProp="post">
                  Заведующий отделением высшего образования, преподаватель
                </td>
                <td itemProp="teachingDiscipline">География</td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Учитель башкирского языка и литературы, Филология
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  Переподготовка: "География: теория и методика преподавания в
                  профессиональном образовании"(2023); "Обучение работников по
                  оказанию первой помощи пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">17</td>
                <td itemProp="specExperience">9</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Белобородова Наталья Павловна</td>
                <td itemProp="post">
                  Начальник отдела конвенционной подготовки и дополнительного
                  образования, преподаватель
                </td>
                <td itemProp="teachingDiscipline">Инженерная графика</td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  "Инженер-механик, авиационные двигатели "
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи пострадавшим"
                  (2023); "Особенности деятельности педагогических работников
                  СПО в свете требований профессионального стандарта" (2023); "
                  Содержание и методика преподавания предмета " Инженерная
                  графика" в условиях реализации ФГОС ВО" (2032); " Обучение по
                  охране труда для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">46</td>
                <td itemProp="specExperience">41</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Бублис Юрий Федорович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Теория и устройство судна СВМ и системы Обслуживание и ремонт
                  судовых энергетических установок ТУС Судовые вспомогательные
                  механизмы и системы"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер кораблестроитель, судостроение и судоремонт
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">55</td>
                <td itemProp="specExperience">28</td>
                <td itemProp="teachingOp">
                  260203 26.02.05 Эксплуатация судовых энергетических установок
                  26.02.06 Эксплуатация судового элктрооборудования и средств
                  автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Гайнетдинова Эльвера Галихановна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Материаловедение Устройство и эксплуатация судовых
                  энергетических установок Судовые энергетические установки
                  (включая тренажер вахтенного механика) Техническая
                  эксплуатация главных энергетических установок судна Основы
                  эксплуатации, технического обслуживания и ремонта судового
                  энергетического оборудования"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер-механик, судовые машины и механизмы
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение ОТ для работников филиала"
                  (2023)
                </td>
                <td itemProp="genExperience">43</td>
                <td itemProp="specExperience">32</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Галеева Алина Вадимовна</td>
                <td itemProp="post">Педагог-организатор, преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Основы финансовой грамотности и предпринимательской
                  деятельности в профессиональной сфере Основы финансовой
                  грамотности"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Экономист, финансы и кредит
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение ОТ для работников
                  филиала"(2023; " Особенности деятельности педагогических
                  работников СПО в свете требований профессионального стандарта"
                  (2023); "Финансовая грамотность: принципы и навыки" (2024)
                </td>
                <td itemProp="genExperience">9</td>
                <td itemProp="specExperience">3</td>
                <td itemProp="teachingOp">
                  26.02.05 Эксплуатация судовых энергетических установок
                  26.02.06 Эксплуатация судового элктрооборудования и средств
                  автоматики 26.02.03 Судовождение
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Галимова Альмира Фагимовна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">Математика</td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Учитель математики, Математика
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Преподавание математики в условиях реализации ФГОС"
                  (дистанционно) 2022; " Разработка, внедрение и сертификация
                  системы менеджмента качества организации"(2022);"Обучение
                  работников по оказанию первой помощи пострадавшим"(2023);
                  "Теория и методика обучения математике в условиях реализации
                  ФГОС СОО" (2023); Проверка знаний требований ОТ работников по
                  программе "Общие вопросы охраны труда и функционирование
                  системы управления охраной труда"(2023); Безопасные методы и
                  приемы выполнения работ при воздействии вредных и (или)
                  опасных производственных факторов, источников опасности,
                  идентифицированных в рамках специальной оценки условий труда и
                  оценки профессиональных рисков" (2023); "Обучение ОТ для
                  работников филиала" (2023)
                </td>
                <td itemProp="genExperience">21</td>
                <td itemProp="specExperience">21</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 23.02.01 Организация перевозок и
                  управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Галлямова Раушания Харисовна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">Физика</td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">Физик, физика</td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи пострадавшим"
                  (2023) "Эффективная реализация программ СПО в условиях новых
                  ФГОС" (2023);"Обучение ОТ для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">35</td>
                <td itemProp="specExperience">31</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Гарифуллина Зульфия Муратовна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Иностранный язык Иностранный язык в профессиональной
                  деятельности"
                </td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория "
                </td>
                <td itemProp="employeeQualification">
                  Учитель башкирского языка, литературы и английского языка,
                  родной язык, литература и иностранный язык
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  Обучение и проверка требований ОТ по программе "Обучение по ОТ
                  для педагогических работников и специалистов" (2022); "Дизайн
                  презентаций" (2022);"Обучение работников по оказанию первой
                  помощи пострадавшим"(2023); "Концептуальное и методическое
                  обновление дисциплины" Иностранный язык в условиях реализации
                  ФГОС" (2023); "Обучение ОТ для работников филиала"(2023)
                </td>
                <td itemProp="genExperience">25</td>
                <td itemProp="specExperience">14</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 260206
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Жилина Анна Викторовна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  " Информатика Информационное обеспечение перевозочного
                  процесса (по видам транспорта) Автоматизированные системы
                  управления на транспорте (по видам транспорта)"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Учитель математики и информатики, математика и информатика
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Инновационные подходы к организации учебной деятельности и
                  методикам преподавания дисциплины "Информатика" в организациях
                  СПО с учетом требований ФГОС СПО"( 2023); "Обучение работников
                  по оказанию первой помощи пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">23</td>
                <td itemProp="specExperience">23</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Зкриева Гульнара Робертовна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Электрооборудование судов Судовые электрические машины
                  Эксплуатация, техническое обслуживание и ремонт судовых
                  электроэнергетических систем Эксплуатация, техническое
                  обслуживание и ремонт судовых электрических приводов
                  Эксплуатация, техническое обслуживание и ремонт судовых
                  электрических устройств и систем связи, управления,
                  автоматики, контроля и сигнализации"" Эксплуатация и
                  техническое обслуживание силовых систем с напряжением выше
                  1000 вольт ""Эксплуатация, техническое обслуживание и ремонт
                  навигационного оборудования и судового радиооборудования
                  глобальной морской системы связи при бедствии (ГМССБ)""
                  Эксплуатация, техническое обслуживание и ремонт
                  электрооборудования, электронной аппаратуры и систем
                  управления Судовое радиооборудование Судовая автоматика и
                  контрольно-измерительные приборы Обслуживание и ремонт судовых
                  энергетических установок"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер-электрик, электрификация и автоматизация сельского
                  хозяйства
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение ОТ для работников филиала"
                  (2023)
                </td>
                <td itemProp="genExperience">29</td>
                <td itemProp="specExperience">29</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Иванов Генрих Николаевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Техническая эксплуатация главных энергетических установок
                  судна Основы эксплуатации, технического обслуживания и ремонта
                  судового энергетического оборудования Обслуживание и ремонт
                  судовых энергетических установок"
                </td>
                <td itemProp="teachingLevel">
                  Среднее профессиональное образование, специалист
                </td>
                <td itemProp="employeeQualification">
                  Техник-судоводитель, судовождение на внутренних водных путях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Особенности деятельности педагогических работников СПО в
                  свете требований профессионального стандарта" (2023);
                </td>
                <td itemProp="genExperience">2</td>
                <td itemProp="specExperience">2</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.05 Эксплуатация судовых энергетических установок
                  26.02.03 Судовождение
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Исламгалиева Раушания Ревенировна</td>
                <td itemProp="post"> Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Техническая эксплуатация главных энергетических установок
                  судна Основы эксплуатации, технического обслуживания и ремонта
                  судового энергетического оборудования Обслуживание и ремонт
                  судовых энергетических установок"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">Биология, биолог</td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  Программа профессиональной переподготовки " специалист в
                  области охраны труда" (2023)
                </td>
                <td itemProp="genExperience">8</td>
                <td itemProp="specExperience">1</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Исхакова Лилия Лутфулловна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Технология перевозки грузов Транспортная система России
                  Обеспечение грузовых перевозок (по видам транспорта) Перевозка
                  грузов на особых условиях Экономика и управление на водном
                  транспорте Руководство ВКР"
                </td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория "
                </td>
                <td itemProp="employeeQualification">
                  Бакалавр, технология транспортных процессов
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  Цифровые технологии в преподавании профильных дисциплин
                  (2022);"Обучение работников по оказанию первой помощи
                  пострадавшим"(2023; "Обучение ОТ для работников филиала"(2023)
                </td>
                <td itemProp="genExperience">23</td>
                <td itemProp="specExperience">22</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Козырь Снежана Андреевна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Русский язык Литература Обществознание"
                </td>
                <td itemProp="teachingLevel"></td>
                <td itemProp="employeeQualification">Бакалавр, филология</td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Скрайбинг и веб-инвест как инновационные образовательные
                  технологии в условиях реализации ФГОС СПО"(2022);"Обучение
                  работников по оказанию первой помощи пострадавшим"(2023)
                  "Обучение ОТ для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">7</td>
                <td itemProp="specExperience">7</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Крикунов Сергей Петрович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Навигация и лоция Технические средства судовождения
                  Тренажерная подготовка. Использование ЭКНИС Тренажерная
                  подготовка. Использование РЛС на ВВП Навигационная
                  гидрометеорология Использование ЭКНИС Использование РЛС на ВВП
                  "
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Инженер-судоводитель, судовождение на внутренних водных путях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">50</td>
                <td itemProp="specExperience">48</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Лелюйко Сергей Николаевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  Основы безопасности и защиты Родины Безопасность
                  жизнедеятельности ОБЗР. Основы обороны государства и воинская
                  обязанность
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">Инженер-механик</td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment"></td>
                <td itemProp="genExperience">25</td>
                <td itemProp="specExperience">23</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Миннулина Светлана Рафисовна</td>
                <td itemProp="post">Заведующая отделением, преподаватель</td>
                <td itemProp="teachingDiscipline">
                  Физика Индивидуальный проект
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Учитель информатики, Информатика
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  ""Преподавание физики в условиях ФГОС" (2022); "Обучение
                  работников по оказанию первой помощи пострадавшим" (2023)
                </td>
                <td itemProp="genExperience">16</td>
                <td itemProp="specExperience">16</td>
                <td itemProp="teachingOp">
                  26.02.06 Эксплуатация судового элктрооборудования и средств
                  автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Муратов Раис Фаритович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Контроль выполнения национальных и международных требований
                  по эксплуатации судна Планирование, организация и руководство
                  работой коллектива исполнителей Нормативное правовое
                  регулирование в области руководства работой коллектива
                  исполнителей. Технические средства (по видам транспорта)
                  Транспортно-экспедиционная деятельность (по видам транспорта)
                  Правовое обеспечение профессиональной деятельности Технология
                  перевозочного процесса (по видам транспорта)"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер водного транспорта, эксплуатация водного транспорта
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); " Особенности деятельности педагогических
                  работников СПО в свете требований профессионального стандарта"
                  (2023); "Обучение ОТ для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">41</td>
                <td itemProp="specExperience">13</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Мусина Гульфия Ириковна</td>
                <td itemProp="post">
                  Заместитель директора по учебной работе. Преподаватель
                </td>
                <td itemProp="teachingDiscipline">Литература</td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Учитель русского языка и литературы, русский язык и литература
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Особенности организации изучения учебного предмета "Родная
                  русская литература" с учетом обновленного ФГОС СПО"(2023);
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение ОТ для работников филиала"
                  (2023);"Государственное и муниципальное управление в
                  образовательных организациях" (2024)
                </td>
                <td itemProp="genExperience">33</td>
                <td itemProp="specExperience">30</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Мухутдинова Лилия Ирековна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Иностранный язык Иностранный язык в профессиональной
                  деятельности"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Филолог. Преподаватель немецкого языка
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Дизайн презентаций"(2022);"Обучение работников по оказанию
                  первой помощи пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">37</td>
                <td itemProp="specExperience">10</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Павлова Алина Тахировна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Химия Биология География"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Бакалавр, юриспруденция
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи пострадавшим"
                  (2023); " Особенности деятельности педагогических работников
                  СПО в свете требований профессионального стандарта" (2023);
                  "Обучение по общим вопросам охраны труда и функционирования
                  системы управления охраной труда" (2023)
                </td>
                <td itemProp="genExperience">19</td>
                <td itemProp="specExperience">1</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Портнягина Ирина Валерьевна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Управление судном Управление судном и технические средства
                  судовождения Общая и специальная лоция внутренних водных путей
                  РФ Правила плавания и управление судами на ВВП Общая и
                  специальная лоция внутренних водных путей РФ "
                </td>
                <td itemProp="teachingLevel">
                  "Высшее, первая квалификационная категория "
                </td>
                <td itemProp="employeeQualification">
                  Инженер-судоводитель, судовождение на внутренних водных путях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Цифровые технологии в преподавании профильных дисциплин "
                  (2022); "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение по общим вопросам охраны труда
                  и функционирования системы управления охраной труда"
                  (2023);"Обучение ОТ для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">35</td>
                <td itemProp="specExperience">4</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Решетников Станислав Евгеньевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  " История Основы философии"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер-судоводитель, судовождение на ВВП
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение охране труда для работников
                  филиала" (2023)
                </td>
                <td itemProp="genExperience">23</td>
                <td itemProp="specExperience">13</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики 23.02.01 Организация
                  перевозок и управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Романов Зульфир Вахитович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  ОБЖ Безопасность жизнедеятельности
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Историк, преподаватель истории
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment"></td>
                <td itemProp="genExperience">15</td>
                <td itemProp="specExperience">8</td>
                <td itemProp="teachingOp"></td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Рябова Зинаида Николаевна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">Физическая культура</td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Специалист по физической культуре и спорту, Физическая
                  культура и спорт
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение охране труда для работников
                  филиала" (2023); "Методика преподавания физической культуры в
                  СПО в соответствии с ФГОС СПО" (2024)
                </td>
                <td itemProp="genExperience">19</td>
                <td itemProp="specExperience">19</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Савельев Дмитрий Валерьевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Информатика Автоматизированные системы управления на
                  транспорте (по видам транспорта) Использование современного
                  прикладного программного обеспечения для сбора, обработки и
                  хранения информации и эффективного решения различных задач,
                  связанных с эксплуатацией судна"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  {" "}
                  Бакалавриат, энергетическое машиностроение
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  " Особенности деятельности педагогических работников СПО в
                  свете требований профессионального стандарта" (2023)
                </td>
                <td itemProp="genExperience">1</td>
                <td itemProp="specExperience">1</td>
                <td itemProp="teachingOp">
                  26.02.05 Эксплуатация судовых энергетических установок
                  23.02.01 Организация перевозок и управление на транспорт
                  26.02.03 Судовождение
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Сиразетдинова Эльвира Раисовна</td>
                <td itemProp="post">Старший методист, преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Экологические основы природопользования Предупреждение и
                  предотвращение загрязнения окружающей среды при эксплуатации
                  судна"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Историк, преподаватель истории
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  " По программе" Организация методической работы в
                  образовательной организации СПО" (2022); "По программе:
                  руководители нештатных формирований по обеспечению выполнения
                  мероприятий по ГО, по дополнительной профессиональной
                  программе" (2023); "Обучение работников по оказанию первой
                  помощи пострадавшим"(2023); Обеспечение экологической
                  безопасности руководителям и специалистам экологических служб
                  и систем экологического контроля" (2024)
                </td>
                <td itemProp="genExperience">31</td>
                <td itemProp="specExperience">25</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Строев Николай Александрович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  Судовые работы и основы судовождения
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Техник-судоводитель, судовождение на внутренних водных путях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  " Особенности деятельности педагогических работников СПО в
                  свете требований профессионального стандарта" (2023)
                </td>
                <td itemProp="genExperience">2</td>
                <td itemProp="specExperience">2</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Тарба Растан Александрович</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Устройство и эксплуатация судовых энергетических установок
                  Эксплуатация судовых энергетических установок на
                  вспомогательном уровне Техническая эксплуатация
                  вспомогательных механизмов и систем Эксплуатация судовых
                  энергетических установок, механизмов и систем Техническая
                  эксплуатация главных энергетических установок судна"
                </td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  "Техник-судомеханик. эксплуатация транспортных энергетических
                  установок"
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение по ОТ для работников филиала"
                  (2023)
                </td>
                <td itemProp="genExperience">11</td>
                <td itemProp="specExperience">5</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Уткина Елена Михайловна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Обеспечение безопасности плавания и транспортная безопасность
                  Обеспечение живучести судна. Поиск и спасание на водных путях.
                  Подготовка по борьбе с пожаром по расширенной программе
                  (Правило VI/3) Транспортная безопасность и система управления
                  безопасностью Борьба за живучесть судна и обеспечение
                  выживаемости людей Безопасность жизнедеятельности на судне и
                  транспортная безопасность Борьба за живучесть судна и
                  обеспечение выживаемости людей Борьба за живучесть судна и
                  обеспечение выживаемости людей"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер, судовождение на ВВП
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  " Особенности деятельности педагогических работников СПО в
                  свете требований профессионального стандарта" (2023);
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); "Обучение ОТ для работников филиала"
                  (2023)
                </td>
                <td itemProp="genExperience">37</td>
                <td itemProp="specExperience">14</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Филлипова Снежана Васильевна</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Физическая культура Охрана труда Оператор диспетчерской
                  (производственно-диспетчерской службы) Основы делопроизводства
                  Коммерческая работа на транспорте Страхование и риски
                  Организация доступной среды для инвалидов на транспорте
                  Организация пассажирских перевозок и обслуживание пассажиров
                  (по видам транспорта) Организация и управление безопасностью
                  на водном транспорте Оценка эффективности и качества работы
                  судна Технология перевозок Оптимальные варианты планирования
                  рейса судна, технико-эксплуатационных характеристик"
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер транспортных и технологических машин и оборудования
                  ,сервис транспортных и технологических машин и оборудования
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Обучение работников по оказанию первой помощи"
                  пострадавшим"(2023);" Особенности деятельности педагогических
                  работников СПО в свете требований профессионального стандарта"
                  (2023);
                </td>
                <td itemProp="genExperience">15</td>
                <td itemProp="specExperience">1</td>
                <td itemProp="teachingOp">
                  {" "}
                  26.02.03 Судовождение 23.02.01 Организация перевозок и
                  управление на транспорт
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Филлипов Эдуард Валерьевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">Физическая культура</td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер-экономист, экономика и управление на предприятиях
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  "Методика преподавания физической культуры в СПО в
                  соответствии с ФГОС СПО" (2024)
                </td>
                <td itemProp="genExperience">24</td>
                <td itemProp="specExperience">11</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 260205 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Фомичев Сергей Юрьевич</td>
                <td itemProp="post">Преподаватель</td>
                <td itemProp="teachingDiscipline">
                  "Судовая автоматика и КИП Техническое обслуживание и ремонт
                  судового оборудования Техническая эксплуатация судовой
                  автоматики Основы эксплуатации, технического обслуживания и
                  ремонта судового энергетического оборудования Эксплуатация,
                  техническое обслуживание и ремонт судовых электрических
                  приводов Эксплуатация судовых механизмов Эксплуатация судовых
                  систем Организация эксплуатации, технического обслуживания и
                  ремонта электрических и электронных систем, систем управления
                  (в том числе автоматических систем управления), электрического
                  и электронного оборудования. Эксплуатация, техническое
                  обслуживание и ремонт судового электрического освещения и
                  электротермального оборудования Эксплуатация, техническое
                  обслуживание и ремонт электрооборудования, электронной
                  аппаратуры и систем управления "
                </td>
                <td itemProp="teachingLevel">Высшее</td>
                <td itemProp="employeeQualification">
                  Инженер- электрик, электропривод и автоматизация промышленных
                  установок
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Обучение работников по оказанию первой помощи
                  пострадавшим"(2023); " Особенности деятельности педагогических
                  работников СПО в свете требований профессионального стандарта"
                  (2023); "Обучение по ОТ для работников филиала" (2023)
                </td>
                <td itemProp="genExperience">44</td>
                <td itemProp="specExperience">5</td>
                <td itemProp="teachingOp">
                  26.02.03 Судовождение 26.02.05 Эксплуатация судовых
                  энергетических установок 26.02.06 Эксплуатация судового
                  элктрооборудования и средств автоматики
                </td>
              </tr>{" "}
              <tr itemProp="teachingStaff">
                <td itemProp="fio">Щербакова Амина Байраковна</td>
                <td itemProp="post">
                  Заведующий учебным отделом, преподаватель
                </td>
                <td itemProp="teachingDiscipline">Обществознание</td>
                <td itemProp="teachingLevel">
                  Высшее, высшая квалификационная категория
                </td>
                <td itemProp="employeeQualification">
                  Историк, преподаватель истории и обществоведения, история
                </td>
                <td itemProp="degree"></td>
                <td itemProp="academStat"></td>
                <td itemProp="profDevelopment">
                  {" "}
                  "Совершенствование процесса преподавания истории и
                  обществознания в основной школе в условиях реализации
                  обновленного ФГОС"(2023); "Обучение работников по оказанию
                  первой помощи" пострадавшим"(2023)
                </td>
                <td itemProp="genExperience">39</td>
                <td itemProp="specExperience">39</td>
                <td itemProp="teachingOp">26.02.03 Судовождение</td>
              </tr>{" "}
            </tbody>
          </table>
        </TableWrapper>
      </>
    </div>
  );
});
