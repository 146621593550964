import { makeAutoObservable } from "mobx";
import {
  createMonitoringDocs,
  deleteMonitoringDocs,
  fetchMonitoringDocs,
} from "../httpService/monitoring-docs-api";

export const MonitoringDocsStore = makeAutoObservable({
  monitoringDocs: [],
  isLoadingMonitoringDocs: false,
  errorMonitoringDocs: null,

  *loadMonitoringDocs() {
    MonitoringDocsStore.isLoadingMonitoringDocs = true;
    try {
      const data = yield fetchMonitoringDocs();
      if (data) {
        MonitoringDocsStore.monitoringDocs = data;
      } else {
        MonitoringDocsStore.errorMonitoringDocs = "Данные не найдены";
      }
    } catch (e) {
      if (e) MonitoringDocsStore.errorMonitoringDocs = e.message;
    } finally {
      MonitoringDocsStore.isLoadingMonitoringDocs = false;
    }
  },
  *addMonitoringDocs(docs) {
    MonitoringDocsStore.errorMonitoringDocs = null;
    MonitoringDocsStore.isLoadingMonitoringDocs = true;
    try {
      const data = yield createMonitoringDocs(docs);
      if (data) {
        MonitoringDocsStore.monitoringDocs.push(data);
      } else {
        MonitoringDocsStore.errorMonitoringDocs =
          "Что-то пошло не так, документ не добавлен";
      }
    } catch (e) {
      if (e) MonitoringDocsStore.errorMonitoringDocs = e.message;
    } finally {
      MonitoringDocsStore.isLoadingMonitoringDocs = false;
    }
  },
  *removeMonitoringDocs(id, successRemoove) {
    MonitoringDocsStore.errorMonitoringDocs = null;
    MonitoringDocsStore.isLoadingMonitoringDocs = true;
    try {
      const removeMessge = yield deleteMonitoringDocs(id);
      if (removeMessge === "Документ удален") {
        MonitoringDocsStore.monitoringDocs =
          MonitoringDocsStore.monitoringDocs.filter((e) => e.id !== id);
        successRemoove();
      } else {
        MonitoringDocsStore.errorMonitoringDocs = "Не удалось удалить документ";
      }
    } catch (e) {
      if (e) MonitoringDocsStore.errorMonitoringDocs = e.message;
    } finally {
      MonitoringDocsStore.isLoadingMonitoringDocs = false;
    }
  },
});
