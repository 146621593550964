import React from "react";
import { ReactComponent as WORD } from "../../../../../assets/svg/office/word.svg";
import { ReactComponent as PDF } from "../../../../../assets/svg/office/pdf.svg";

import zayavl from "../../../../../assets/doc/enrollee/заявление на заселение.docx";
import rekvizit from "../../../../../assets/doc/enrollee/реквизиты оплаты за общежитие.docx";
import prikaz from "../../../../../assets/pdf/enrolle/приказ о заселении в общежитие.pdf";
export const Dormitory = () => {
  return (
    <>
      <h1>ВНИМАНИЕ ПЕРВОКУРСНИКИ!</h1>
      <section>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          На учебные занятия необходимо прибыть 2 сентября 2024 года к 8-15.
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Торжественное мероприятие, посвященное Дню знаний, состоится 2
          сентября в 10.00.
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Родительское собрание 1 курса состоится 1 сентября в актовом зале в
          13.00.
        </p>
      </section>
      <section>
        <p>
          Для заселения в общежитие, в соответствии с приказом о заселении,
          иногородние обучающиеся 1 курса могут прибыть 1 сентября. Заселение
          будет проводится с 9.00 до 17.00. В учебные дни - заселение с 14.30 до
          17.00.
        </p>
        <p>
          Общежитие предоставляется для иногородних, если место регистрации
          удалено от Уфы более, чем на 50 км. Лица, зарегистрированные в г. Уфа
          или в Уфимском районе (в радиусе до 50 км от г. Уфа) к категории
          нуждающихся в общежитии не относятся.
        </p>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="localActObSt"
              href={prikaz}
              target={"_blank"}
              rel="noreferrer"
            >
              Приказ о заселении в общежитие
            </a>
          </li>
        </ul>
        <p>
          При заселении в общежитие необходимо иметь при себе следующие
          документы:
        </p>
        <ul>
          <li>оригинал паспорта и копии 2, 3 и 5 страниц;</li>
          <li>приписное свидетельство или военный билет;</li>
          <li>2 фотографии 3х4 см;</li>
          <li>
            оригинал и копию документа, подтверждающего наличие льгот (для
            сирот);
          </li>
          <li>
            справка, получателей социальной помощи (для подтверждения льгот при
            оплате за общежитие и для назначения социальной стипендии);
          </li>
          <li>медицинская справка об эпидокружении;</li>
          <li>медицинский полис ОМС;</li>
          <li>Квитанция об оплате за общежитие</li>
          <li>
            справка от педиатра о назначении спецгруппы по физкультуре (при
            наличии соответствующих заболеваний);
          </li>
          <li>полный сертификат о профилактических прививках;</li>
        </ul>
        <p>
          При заселении в общежитие несовершеннолетних обучающихся (менее 18
          лет) договор заключается с законным представителем (одним из родителей
          или опекунов). Для заключения договора найма жилого помещения с
          законным представителем необходимо в обязательном порядке
          предоставить:
        </p>
        <ol>
          <li>
            оригинал паспорта и копии 2,3 и 5 страниц законного представителя;
          </li>
          <li>
            копию свидетельства о рождении обучающегося (либо иной документ,
            подтверждающий основания представления интересов
            несовершеннолетнего, лицом).
          </li>
        </ol>
        <ul>
          <li className="doc__item">
            <WORD height={"25px"} width={"25px"} />
            <a
              itemProp="localActObSt"
              href={zayavl}
              target={"_blank"}
              rel="noreferrer"
            >
              Заявление на заселение
            </a>
          </li>{" "}
          <li className="doc__item">
            <WORD height={"25px"} width={"25px"} />
            <a
              itemProp="localActObSt"
              href={rekvizit}
              target={"_blank"}
              rel="noreferrer"
            >
              Реквизиты оплаты за общежитие
            </a>
          </li>
        </ul>
      </section>
      <section>
        <p>
          Все обучающиеся на бюджетной основе будут обеспечены 5-разовым
          питанием, полным комплектом обмундирования (летний комплект включает в
          себя: туфли летние, брюки, рубашка белая, галстук, носки летние х/б 6
          пар, трусы 2 пары, пилотка, куртка шерстяная, фланелевка, форменка,
          костюм флотский, тельняшка зимн. 2 штуки, тельняшка летняя без рукавов
          2 шт., гюйс, ремень, кальсоны летние 2 шт.), мягким инвентарем
          (матрац, одеяло, подушка), постельными принадлежностями (пододеяльник,
          простыня, наволочка, полотенце для рук и лица, полотенце для ног,
          банное полотенце).
        </p>
        <p>
          На территории филиала обязательно ношение установленной формы одежды.
          Все курсанты филиала должны иметь аккуратную короткую прическу, либо
          быть аккуратно причесаны (девушки).
        </p>
        <p>Дополнительно при себе иметь:</p>
        <ol>
          <li>
            Спортивную обувь, спортивная одежда для посещения уроков физкультуры
            и спортивных секций
          </li>
          <li>Сланцы, шапочка для посещения бассейна</li>
          <li>
            Черные брюки (юбку для девушек) и белую рубашку на период до выдачи
            форменного обмундирования (выдача форменного обмундирования будет
            осуществляться в период с 9 по 13 сентября).
          </li>
          <li>Мешки для бытового мусора (20-35л.) не менее 5 упаковок.</li>
          <li>Тетради не менее 30 штук (12, 24 или 48 листов)</li>
          <li>Письменные принадлежности, карандаши простые, линейка</li>
        </ol>
        <p>
          <i>
            Запрещается привозить с собой продукты питания, чайники или др.
            нагревательные приборы, спиртные напитки и наркотические вещества,
            колюще-режущие предметы.
          </i>
        </p>
      </section>
      <section>
        <p>Порядок заселения:</p>
        <ol>
          <li>
            Получить и заполнить бланки заявления о заселении, заявления о
            регистрации по месту пребывания, договора найма жилого помещения в
            общежитии
          </li>
          <li>
            Согласовать заявление о заселении с должностными лицами, указанными
            в заявлении (воспитатель для определения комнаты проживания,
            медсестра для внешнего осмотра и получения справки об эпидокружении,
            бухгалтерия для проверки оплаты за проживание, в т.ч. в соответствии
            с льготами)
          </li>
          <li>
            Представить заявление о заселении в общежитии коменданту для
            получения мягкого инвентаря, постельных принадлежностей
          </li>
          <li>Заселиться в общежитие в закрепленную комнату</li>
        </ol>
      </section>
      <section>
        <h2>РАСПОРЯДОК ДНЯ НА 1 СЕНТЯБРЯ.</h2>
        <ol>
          <li>12.00-13.00. Обед</li>
          <li>
            15.00-16.00. Строевое собрание курсантов 1 курса в актовом зале{" "}
          </li>
          <li>16.00-16.30. Полдник</li>
          <li>
            16.30-18.00. Запись в кружки и секции (дополнительно будет дана
            информация)
          </li>
          <li>18.00-18.30. Ужин</li>
          <li>
            18.30-20.30. Мероприятие, проводимое воспитателем роты в общежитии
          </li>
          <li>20.30-21.00. второй ужин</li>
          <li>21.00-21.30. свободное время</li>
          <li>21.30-21.45. вечерняя поверка</li>
          <li>21.45-23.00. личное время</li>
          <li>23.00- 07.00. Отбой. Ночной сон</li>
        </ol>
        <p>
          На информационных стендах в зданиях и в телеграмм-канале филиала будет
          размещена дополнительная и уточняющая информация, в том числе с
          указанием номеров и названий помещений.
        </p>
      </section>
    </>
  );
};
