import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
export const OpenDay = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <div
        className={` ${toggleClassName(
          "min-height",
          "min-height-white",
          "min-height-black",
          "min-height-contrast"
        )}`}
      >
        <h1 className="education__container-title">Вниманию абитуриентов!</h1>
        <h2>Ближайшие дни открытых дверей:</h2>
        <ol style={{ marginLeft: "25px" }}>
          <li>20.11.2024г</li>
          <li>20.02.2025г</li>
          <li>13.03.2025г</li>
          <li>17.04.2025г</li>
        </ol>
        <div className="education__container-content">
          <p>
            Приглашаем выпускников 9-х и 11-х классов школ, лицеев, гимназий,
            выпускников учреждений среднего профессионального образования
            принять участие в открытии приёмной кампании 2025 года. Участников
            встречи ждет информация по вопросам поступления на среднее
            профессиональное образование и высшее образование в 2025 году.
          </p>
          <p>
            Открытие приёмной кампании — это возможность получить информацию о
            правилах приёма, о направлениях подготовки и познакомиться с
            преподавателями спец. дисциплин.
          </p>
          <p>
            Наш адрес: <strong>г.Уфа, Затон, ул. Ахметова, 275.</strong>
          </p>
          <p>
            Проезд автобусами №№ 30,30 К, 107, 108, 112, марш. такси 248, 391 до
            ост. «Речное училище».
          </p>
          <p>
            Тел.: <strong>8 (347) 215-14-00 доп. 4.</strong>
          </p>
        </div>
      </div>
    </section>
  );
});
