import React from "react";
import { observer } from "mobx-react-lite";
import vozmPodachi from "../../../../../../assets/pdf/applicant/VO/Информация о возможности подачи документов, с использованием суперсервиса Поступление в ВУЗ онлайн посредством ЕПГУ.pdf";
import osobPrava from "../../../../../../assets/pdf/applicant/VO/Информация о необходимости прохождения поступающими обязательного предварительного  мед. осмотра.pdf";
import obrazecDogovora from "../../../../../../assets/pdf/applicant/Dogovor_platn_obraz_uslug_2024.pdf";
import perechVstupIspit from "../../../../../../assets/pdf/applicant/VO/Перечень вступительных испытаний с указанием по каждому вступительному испытанию следующих сведений.pdf";
import perechNaptavPodgot from "../../../../../../assets/pdf/applicant/VO/Перечень направлений подготовки (специальностей).pdf";
import poryadokPodach from "../../../../../../assets/pdf/applicant/VO/Порядок подачи и рассмотрения  апелляции по результатам вступительных испытаний.pdf";
import pravilaPriema from "../../../../../../assets/pdf/applicant/VO/Правила приема на обучение по образовательным программам бакалавриата, специалитета, магистратуры в 2024-25 учебном году.docx";

import perechen from "../../../../../../assets/pdf/applicant/VO/Перечень индивидуальных достижений поступающих, учитываемых при приеме на обучение, и порядок учета указанных достижений.pdf";
import osobennosti from "../../../../../../assets/pdf/applicant/VO/Особенности проведения вступительных испытаний для инвалидов и лиц с ограниченными возможностями здоровья.pdf";
import info from "../../../../../../assets/pdf/applicant/VO/Информация о проведении вступительных испытаний очно и (или) с использованием дистанционных технологий.pdf";

import { ReactComponent as PDF } from "../../../../../../assets/svg/office/pdf.svg";
import { toggleClassName } from "../../../../../../utils/disabled";
import { NavLink } from "react-router-dom";

import preim from "../../../../../../assets/pdf/enrolle/Информация о предоставлении особых прав и особого преимущества.pdf";

import { ReactComponent as WORD } from "../../../../../../assets/svg/office/word.svg";

import prikazStoimost from "../../../../../../assets/pdf/enrolle/приказ № 187-с от 17.04.2024 о стоимости обучения.pdf";

import prikaz221s from "../../../../../../assets/pdf/applicant/приказ № 221-с от 07.05.2024 стоим.обуч..pdf";
import listProhojd from "../../../../../../assets/pdf/enrolle/медкомиссия.pdf";

import raspisanie from "../../../../../../assets/pdf/applicant/VO/Расписание ВО.pdf";
import raspisanie2 from "../../../../../../assets/pdf/applicant/VO/Расписание ВО2.pdf";
import osob from "../../../../../../assets/pdf/applicant/VO/Особенности приема на обучение, предусмотренных частями 7 и 8 статьи 5 ФЗ от 17 февраля 2023 г. 19-ФЗ.pdf";

export const HigherEducation = observer(() => {
  return (
    <>
      <h1>Информация для студентов, поступающих на направления ВО</h1>
      <ol>
        <li>
          <WORD width={"25px"} height={"25px"} />{" "}
          <a
            href={pravilaPriema}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Правила приема на обучение по образовательным программам
            бакалавриата, специалитета, магистратуры в 2024-2025 учебном году
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={vozmPodachi}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о возможности подачи документов, с использованием
            суперсервиса Поступление в ВУЗ онлайн посредством ЕПГУ
          </a>
        </li>
        <li>
          <NavLink
            to="../receptionPlaces"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Информация о местах приема документов
          </NavLink>
        </li>
        <li>
          <NavLink
            to="../kcpVo"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            План приема в Уфимский филиал ФГБОУ ВО "ВГУВТ" по программе высшего
            образования специалитет на 2024-2025 учебный год на 2024-2025 уч.
            год
          </NavLink>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={preim}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о предоставлении особых прав и особого преимущества
          </a>
        </li>
        <li>
          <NavLink
            to="../../../sveden/grants"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Информация о наличии мест в общежитии
          </NavLink>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={osobPrava}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о необходимости прохождения поступающими обязательного
            предварительного мед. осмотра
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={listProhojd}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Лист прохождения предварительного медицинского освидетельствования
            по приему
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={obrazecDogovora}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Образец договора на обучение по образовательным программам среднего
            профессионального образования и высшего образования
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={perechVstupIspit}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Перечень вступительных испытаний с указанием по каждому
            вступительному испытанию следующих сведений
          </a>
        </li>{" "}
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={perechNaptavPodgot}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Перечень направлений подготовки (специальностей), по которым
            осуществляется прием
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={poryadokPodach}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Порядок подачи и рассмотрения апелляции по результатам вступительных
            испытаний
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikaz221s}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 221-с от 7.05.2024 о стоимости обучения поступающих на 1
            курс в 2024 учебном году по договорам об образовании
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikazStoimost}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 187-с от 17.04.2024 о стоимости обучения
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={osobennosti}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Особенности проведения вступительных испытаний для инвалидов и лиц с
            ограниченными возможностями здоровья
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={info}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о проведении вступительных испытаний очно и (или) с
            использованием дистанционных технологий
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={perechen}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Перечень индивидуальных достижений поступающих, учитываемых при
            приеме на обучение, и порядок учета указанных достижений
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={raspisanie}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Расписание вступительных испытаний ВО (1 этап)
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={raspisanie2}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Расписание вступительных испытаний ВО (2 этап)
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={osob}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Особенности приема на обучение, предусмотренных частями 7 и 8 статьи
            5 ФЗ от 17 февраля 2023 г. 19-ФЗ
          </a>
        </li>
      </ol>
    </>
  );
});
