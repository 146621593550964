import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";

export const Catering = () => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Организация питания в образовательной организации
      </h1>
      <TableWrapper title="">
        <table>
          <tbody>
            <tr>
              <td>Наименование объекта</td>
              <td>Адрес места нахождения</td>
              <td>Площадь, м</td>
              <td>Количество мест</td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemProp="meals">
              <td itemProp="objName">Столовая</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">1354</td>
              <td itemProp="objCnt">300</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям
              </td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Медицинский пункт</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">13,7</td>
              <td itemProp="objCnt">3</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям
              </td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Изолятор</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">16,5</td>
              <td itemProp="objCnt">3</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям
              </td>
            </tr>
            <tr itemProp="health">
              <td itemProp="objName">Процедурный кабинет</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">17,6</td>
              <td itemProp="objCnt">3</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <div>
        <p>
          В столовой Уфимского филиала ФГБОУ ВО «ВГУВТ» организовано питание
          курсантов, обучающихся по очной форме обучения за счет бюджетных
          ассигнований федерального бюджета государственных образовательных
          организаций Федерального агентства морского и речного транспорта,
          реализующих образовательные программы по специальностям и направлениям
          подготовки в области подготовки плавательных составов морских судов и
          судов внутреннего водного плавания и курсантов, обучающихся на
          коммерческой основе. Курсанты, обучающиеся по очной форме обучения за
          счет бюджетных ассигнований, обеспечены пятиразовым питанием, в
          соответствии с нормами Приказа Росморречфлота от 05.12.2013 № 83. В
          процессе деятельности столовой Уфимского филиала выполняется
          мероприятия по обеспечению соблюдения санитарных правил и
          гигиенических нормативов в соответствии с Программой производственного
          контроля за соблюдением норм и правил при организации питания и
          Руководством по системе безопасности пищевой продукции на основе
          принципов ХАССП. В столовой Уфимского филиала ФГБОУ ВО «ВГУВТ» созданы
          условия для приема пищи инвалидам и лицам с ограниченными
          возможностями здоровья.
        </p>

        <h2>
          2. Условия охраны здоровья обучающихся, в том числе инвалидов и лиц с
          ограниченными возможностями здоровья
        </h2>
        <p>
          Охрана здоровья обучающихся, в том числе инвалидов и лиц с
          ограниченными возможностями здоровья, организована в соответствии со
          статьей 41 Федерального закона от 29 декабря 2012 г. N 273- ФЗ «Об
          образовании в Российской Федерации».{" "}
        </p>
        <p>Меры по охране здоровья включают:</p>
        <ol>
          <li>
            оказание первичной медико-санитарной помощи в порядке, установленном
            законодательством в сфере охраны здоровья;{" "}
          </li>
          <li>организацию питания обучающихся; </li>
          <li>
            определение оптимальной учебной, внеучебной нагрузки, режима учебных
            занятий и продолжительности каникул;
          </li>
          <li>
            пропаганду и обучение навыкам здорового образа жизни, требованиям
            охраны труда;{" "}
          </li>
          <li>
            организацию и создание условий для профилактики заболеваний и
            оздоровления обучающихся, для занятия ими физической культурой и
            спортом;{" "}
          </li>
          <li>
            прохождение обучающимися в соответствии с законодательством
            Российской Федерации медицинских осмотров, в том числе
            профилактических медицинских осмотров, в связи с занятиями
            физической культурой и спортом, диспансеризации;{" "}
          </li>
          <li>
            профилактику и запрещение курения, употребления алкогольных,
            слабоалкогольных напитков, пива, наркотических средств, психотропных
            веществ и других одурманивающих веществ;{" "}
          </li>
          <li>
            обеспечение безопасности обучающихся во время пребывания в
            организации;{" "}
          </li>
          <li>
            профилактику несчастных случаев с обучающимися во время пребывания в
            организации;{" "}
          </li>
          <li>
            проведение санитарно-противоэпидемических и профилактических
            мероприятий;{" "}
          </li>
          <li>
            обучение педагогических работников навыкам оказания первой помощи.{" "}
          </li>
        </ol>
        <p>
          В филиале созданы необходимые условия для охраны здоровья обучающихся,
          в том числе инвалидов и лиц с ограниченными возможностями здоровья:
        </p>
        <ol>
          <li>
            Проводится мониторинг состояния здоровья обучающихся. В частности,
            при поступлении абитуриенты проходят предварительный медицинский
            осмотр. На основании результатов медосмотра составляются списки
            студентов, состоящих на диспансерном учете, определяется медицинская
            группа для занятий физкультурой.{" "}
          </li>
          <li>
            Обеспечивается проведение санитарно-гигиенических, профилактических
            и оздоровительных мероприятий, обучение и воспитание в сфере охраны
            здоровья, обязательный инструктаж по технике безопасности.{" "}
          </li>
          <li>
            Осуществляется информирование обучающихся по вопросам гражданской
            обороны, защиты от чрезвычайных ситуаций, пожарной безопасности.{" "}
          </li>
          <li>
            Организован контроль за соблюдением государственных
            санитарно-эпидемиологических правил и нормативов.{" "}
          </li>
          <li>
            Проводятся профилактика, расследование и учет несчастных случаев с
            обучающимися во время пребывания в университете.{" "}
          </li>
        </ol>
        <p>
          Инфраструктура Уфимского филиала ФГБОУ ВО «ВГУВТ», состояние и
          содержание территории, зданий, помещений, оборудования соответствуют
          требованиям санитарных правил и требованиям пожарной безопасности.
          Учебные кабинеты оснащены необходимым оборудованием и инвентарем в
          соответствии с требованиями санитарно-гигиенических правил для
          освоения основных и дополнительных образовательных программ.{" "}
        </p>
        <p>
          Оздоровительная работа с обучающимися проводится на занятиях
          физической культуры и в спортивных секциях. План воспитательной работы
          Уфимского филиала ФГБОУ ВО «ВГУВТ», рабочие программы воспитания по
          всем реализуемым специальностям включают в себя комплекс мероприятий,
          направленных на формирование здорового образа жизни, негативного
          отношения к вредным привычкам, осознания персональной ответственности
          за свое здоровье, уважения и соблюдения прав других людей на здоровье
          и здоровый образ жизни, а также организацию широкой пропаганды
          физической культуры и спорта.{" "}
        </p>
        <p>
          Медицинское обслуживание обучающихся проводится на базе медицинского
          пункта филиала. В состав медицинского пункта входит: кабинет врача,
          процедурный кабинет, изолятор.
        </p>
        <p>
          Первичная медико-санитарная помощь в филиале оказывается в плановой и
          неотложной формах.
        </p>
        <p>
          Обучающимся, в том числе несовершеннолетним, гарантируется оказание
          медицинской помощи в виде первичной доврачебной медико-санитарной
          помощи.
        </p>
        <p>
          При необходимости получения медицинской помощи в случаях заболевания,
          острого ухудшения состояния здоровья или получения травмы обучающийся
          обращается самостоятельно или с помощью сотрудника филиала:
        </p>
        <ul>
          <li>
            в рабочее время с 8.15 до 17.15 в медицинский кабинет, расположенный
            в здании общежития
          </li>
          <li>
            во внерабочее время (в том числе в выходные и праздничные дни) к
            воспитателю
          </li>
        </ul>
        <p>
          Родители обучающихся могут получить информацию о состоянии здоровья
          своих детей в рабочее время по телефону: +7(347) 215-14-00, доп.321.
        </p>
        <p>
          После первичного осмотра обучающего медицинская сестра (воспитатель)
          принимает решение о дальнейших действиях в соответствии с
          нормативно-правовыми актами, регламентирующими оказание медицинской
          помощи гражданам.
        </p>
        <p>
          При оказании скорой медицинской помощи, в случае необходимости,
          осуществляется транспортировка в медицинскую организацию и (или)
          определение в стационар по профилю заболевания при обязательном
          сопровождении обучающегося сотрудником Филиала.
        </p>
        <p>
          Примечание: При оказании медицинской помощи несовершеннолетнему по
          экстренным показаниям для устранения угрозы жизни без согласия
          родителей (законных представителей), согласно пункту 1 части 9 статьи
          20 Федерального закона «Об основах охраны здоровья граждан в
          Российской Федерации» от 21.11.2011 №323-ФЗ, классный руководитель
          (медицинская сестра, дежурный воспитатель) незамедлительно информирует
          его родителей (законных представителей) об оказанной медицинской
          помощи.
        </p>
      </div>
    </div>
  );
};
