export { AdminPanel } from "./admin-panel/admin-panel";
export { Contacts } from "./contacts/contacts/contacts";
export { Reviews } from "./contacts/reviews/reviews";
export { Vacancies } from "./contacts/vacancies/vacancies";
export { MakeAnAppointment } from "./make-an-appointment/make-an-appointment";
export { CreateNewsPage } from "./create-news-page/create-news-page";
export { OrganizationOfTransportation } from "./educations-programs/230201/organization-of-transportation";
export { OrganizationOfTransportation2020 } from "./educations-programs/230201/organization-of-transportation2020";
export { OrganizationOfTransportation2020FOS } from "./educations-programs/230201/organization-of-transportation2020FOS";
export { OrganizationOfTransportation2021 } from "./educations-programs/230201/organization-of-transportation2021";
export { OrganizationOfTransportation2021FOS } from "./educations-programs/230201/organization-of-transportation2021FOS";
export { OrganizationOfTransportation2022 } from "./educations-programs/230201/organization-of-transportation2022";
export { OrganizationOfTransportation2022FOS } from "./educations-programs/230201/organization-of-transportation2022FOS";
export { OrganizationOfTransportation2023 } from "./educations-programs/230201/organization-of-transportation2023";
export { Certificate } from "./contacts/certificate/certificate";
export { NavigationDepth } from "./educations-programs/260203/navigation-depth";
export { Navigation2019 } from "./educations-programs/260203/navigation2019";
export { Navigation2019FOS } from "./educations-programs/260203/navigation2019FOS";
export { Navigation2019FOSZ } from "./educations-programs/260203/navigation2019FOS-Z";
export { Navigation2020 } from "./educations-programs/260203/navigation2020";
export { Navigation2020FOS } from "./educations-programs/260203/navigation2020FOS";
export { Navigation2021 } from "./educations-programs/260203/navigation2021";
export { Navigation2021FOS } from "./educations-programs/260203/navigation2021FOS";
export { Navigation2022 } from "./educations-programs/260203/navigation2022";
export { Navigation2022FOS } from "./educations-programs/260203/navigation2022FOS";
export { Navigation2023 } from "./educations-programs/260203/navigation2023";
export { SiteMap } from "./home-page/site-map/site-map";
export { ShipPowerPlants2020 } from "./educations-programs/260205/ship-power-plants2020";
export { ShipPowerPlants2020FOS } from "./educations-programs/260205/ship-power-plants2020FOS";
export { ShipPowerPlants2021 } from "./educations-programs/260205/ship-power-plants2021";
export { ShipPowerPlants2021FOS } from "./educations-programs/260205/ship-power-plants2021FOS";
export { ShipPowerPlants2022 } from "./educations-programs/260205/ship-power-plants2022";
export { ShipPowerPlants2023 } from "./educations-programs/260205/ship-power-plants2023";
export { ShipPowerPlants2022FOS } from "./educations-programs/260205/ship-power-plants2022FOS";

export { ShipPowerPlantsVO2023 } from "./educations-programs/260506/ship-power-plantsVO2023";
export { ShipPowerPlantsVO2023FOS } from "./educations-programs/260506/ship-power-plantsVO2023FOS";

export { MarineElectricalEquipment2020 } from "./educations-programs/260206/marine-electrical-equipment2020";
export { MarineElectricalEquipment2020FOS } from "./educations-programs/260206/marine-electrical-equipment2020FOS";
export { MarineElectricalEquipment2021 } from "./educations-programs/260206/marine-electrical-equipment2021";
export { MarineElectricalEquipment2021FOS } from "./educations-programs/260206/marine-electrical-equipment2021FOS";
export { MarineElectricalEquipment2022 } from "./educations-programs/260206/marine-electrical-equipment2022";
export { MarineElectricalEquipment2022FOS } from "./educations-programs/260206/marine-electrical-equipment2022FOS";
export { MarineElectricalEquipment2023 } from "./educations-programs/260206/marine-electrical-equipment2023";
export { ApplicantsInfo } from "./enrollee/applicants/applicants-info/applicants-info";
export { Dormitory } from "./enrollee/applicants/dormitory/dormitory";
export { Message } from "./enrollee/applicants/message/message";
export { Monitoring } from "./enrollee/applicants/monitoring/monitoring";
export { AdmissionConditions } from "./enrollee/applicants/reception/admission-conditions/admission-conditions";
export { EducationCosts } from "./enrollee/applicants/reception/education-costs/education-costs";
export { EducationLvlRequirements } from "./enrollee/applicants/reception/education-lvl-requirements/education-lvl-requirements";
export { KCP } from "./enrollee/applicants/reception/KCP/KCP";
export { KcpVo } from "./enrollee/applicants/reception/KCP-VO/kcp-vo";
export { PassingScores } from "./enrollee/applicants/reception/passing-scores/passing-scores";
export { ReceptionPlaces } from "./enrollee/applicants/reception/reception-places/reception-places";
export { ReceptionTiming } from "./enrollee/applicants/reception/reception-timing/reception-timing";
export { Spo } from "./enrollee/applicants/reception/spo/spo";
export { HigherEducation } from "./enrollee/applicants/reception/vo/vo";
export { Reception } from "./enrollee/applicants/reception/reception";
export { SubmissionDoc } from "./enrollee/applicants/submission-doc/submission-doc";
export { ApplicantsEnrollee } from "./enrollee/applicants/applicants-enrollee";
export { OpenDay } from "./enrollee/open-day/open-day";

export { AboutBranch } from "./home-page/about-branch/about-branch";
export { HistoricalReference } from "./home-page/historical-reference/historical-reference";
export { Services } from "./home-page/services/services";
export { StateForPeople } from "./home-page/state-for-people/state-for-people";
export { UniversityEvents } from "./home-page/university-events/university-events";
export { UniversityNews } from "./home-page/university-news/university-news";
export { AntiCorruption } from "./home-page/anti-corruption/anti-corruption";
export { Acts } from "./home-page/anti-corruption/anti-corruption-pages/acts";
export { AntiCorruptionExpertise } from "./home-page/anti-corruption/anti-corruption-pages/anti-corruption-expertise";
export { Commission } from "./home-page/anti-corruption/anti-corruption-pages/commission";
export { DocumentForms } from "./home-page/anti-corruption/anti-corruption-pages/document-forms";
export { Feedback } from "./home-page/anti-corruption/anti-corruption-pages/feedback";
export { IncomeInformation } from "./home-page/anti-corruption/anti-corruption-pages/income-information";
export { MethodologicalMaterials } from "./home-page/anti-corruption/anti-corruption-pages/methodological-materials";

export { ApplicantsPage } from "./info-container/applicants-page/applicants-page";
export { InfoPage } from "./info-container/info-page/info-page";
export { Nok } from "./home-page/nok//nok";
export { Cadets } from "./learning/cadets/cadets";
export { EducationsPlatforms } from "./learning/educations-platforms";
export { About } from "./learning/employment-service/about/about";
export { Faq } from "./learning/employment-service/faq/faq";
export { Partners } from "./learning/employment-service/partners/partners";
export { VacanciesFrom } from "./learning/employment-service/vacancies-from/vacancies-from";
export { EmploymentService } from "./learning/employment-service/employment-service";
export { Health } from "./learning/health/health";
export { Parents } from "./learning/parents/parents";
export { Practice } from "./learning/practice/practice";
export { DistanceEducation } from "./learning/distance-education/distance-education";
export {
  Abkadirov,
  Interview,
  Portnyagina,
  Timofeev,
  Tokmakov,
} from "./live/interview";
export { OurPride } from "./live/our-pride/our-pride";
export { Science } from "./live/science/science";
export { Sport } from "./live/sport/sport";
export { StudentsLive } from "./live/students-live/students-live";

export { OneNewsPage } from "./one-news-page/one-news-page";
export { AutoSchedule, AutoScheduleVo } from "./schedule";
export { Budget } from "./sveden/budget/budget";
export { Common } from "./sveden/common/common";
export { Document } from "./sveden/document/document";
export { EduStandarts } from "./sveden/edu-standarts/edu-standarts";
export { Education } from "./sveden/education/education";
export { Employees } from "./sveden/employees/employees";
export { Grants } from "./sveden/grants/grants";
export { Inter } from "./sveden/inter/inter";
export { Objects } from "./sveden/objects/objects";
export { Ovz } from "./sveden/ovz/ovz";
export { PaidEdu } from "./sveden/paid-edu/paid-edu";
export { Struct } from "./sveden/struct/struct";
export { Vacant } from "./sveden/vacant/vacant";
export { Managers } from "./sveden/managers/managers";
export { Catering } from "./sveden/catering/catering";

export { AboutCdo } from "./training/about-cdo/about-cdo";
export { AddEducationProgramsChildrenAdults } from "./training/add-education-programs-children-adults/add-education-programs-children-adults";
export { AdvancedTraining } from "./training/advanced-training/advanced-training";
export { ContactsCdo } from "./training/contacts/contacts";
export { DistanceLearning } from "./training/distance-learning/distance-learning";
export { ObsherazvivaushieProgrammi } from "./training/obsherazvivaushie-programmi/obsherazvivaushie-programmi";
export { ProfessionalTrainingPrograms } from "./training/professionaltraining-programs/professional-training-programs";
export { ProgrammiProfObuchenia } from "./training/programmi-prof-obuchenia/programmi-prof-obuchenia";
export { ProgrammiProfPerepodgotovki } from "./training/programmi-prof-perepodgotovki/programmi-prof-perepodgotovki";
