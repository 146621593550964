import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { toggleFontSize } from "../../../../utils/disabled";

export const Cadets = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  /* const fac = [
    {
      facId: "1",
      group: "Раздел «Учебный процесс»",
      question: "Как можно оформить социальную стипендию?",
      answer: `На основании Положения о стипендиальном обеспечении и других формах материальной поддержки студентов и аспирантов «ВГУВТ», п. 7.1.5,  социальная стипендия  начисляется социально незащищенным категориям курсантов. Для оформления социальной стипендии – необходимо на отделение СПО (2 этаж) представить справку о том, что Вы являетесь получателем государственной социальной помощи и  написать соответствующее заявление. Выплата государственной социальной стипендии курсантам осуществляется  ежемесячно: начиная с момента обращения на отделение с подтверждающим документом,  по месяц прекращения действия основания ее назначения.`,
       addition: `Для оформления справки о том, что Вы являетесь получателем государственной социальной помощи  необходимо собрать следующий комплект документов:`,
      list: {
        0: "Взять в филиале  справку о том, что Вы являетесь курсантом и обучаетесь в нашем филиале (Канцелярия 1 этаж), и в бухгалтерии (1 этаж) справку о том, в каком размере Вы получаете стипендию.",
        1: "Обратиться по месту жительства и взять справку о составе семьи.",
        2: "Собрать документы о доходах всех членов семьи из п. 2.",
        3: "С комплектом документов обратиться в МФЦ или в центр соцзащиты по месту жительства.",
        4: "Получить документ, который будет подтверждать оформление, что Вы являетесь получателем государственной социальной помощи.",
      }, 
    },
  ];
 */
  const getItemsStudyProcess = (panelStyle) => [
    {
      key: "1",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Какое количество бюджетных мест для очного обучения выделено в 2024
          году для Уфимского филиала?
        </span>
      ),
      children: (
        <>
          <p>
            Контрольные цифры приема на бюджетные места в 2024-2025 учебном
            году:
          </p>

          <ul>
            <li>
              для специальности 26.02.03 «Судовождение» (углубленная подготовка)
              на базе 9 класса – 75 мест
            </li>
            <li>
              для специальности 26.02.06 «Эксплуатация судового
              электрооборудования и средств автоматики» на базе 9 класса – 50
              мест
            </li>
            <li>
              для специальности 26.02.05 «Эксплуатация судовых энергетических
              установок» на базе 9 класса – 50 мест
            </li>
          </ul>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Как подтвердить подлинность полученного мной диплома?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            В России есть единственный официальный реестр, где хранится
            информация обо всех документах об образовании и (или) о квалификации
            — Федеральная информационная система «Федеральный реестр сведений о
            документах об образовании и (или) о квалификации, документах об
            обучении» (ФИС ФРДО).
          </p>
          <p>
            Чтобы подтвердить подлинность своего диплома — найдите его в базе
            ФИС ФРДО.
          </p>
          <p>
            Откройте на сайте Рособрнадзора страницу «Формирование и ведение
            Федерального реестра сведений о документах об образовании и (или) о
            квалификации, документах об обучении» (ФИС ФРДО).
          </p>
          <p>
            <a
              href="https://obrnadzor.gov.ru/gosudarstvennye-uslugi-i-funkczii/7701537808-gosfunction/formirovanie-i-vedenie-federalnogo-reestra-svedenij-o-dokumentah-ob-obrazovanii-i-ili-o-kvalifikaczii-dokumentah-ob-obuchenii/"
              target="_blank"
              rel="noreferrer"
            >
              Перейти на сайт ФИС ФРДО
            </a>
          </p>
          <p>
            Внизу страницы найдите поисковую строку «Сервис поиска сведений о
            документах об образовании». Нажмите на кнопку «Открыть». Выберите
            уровень образования «Среднее профессиональное образование».{" "}
          </p>
          <p>
            <img src="/img/fis.jpg" alt="инструкция ФИС" width={"600px"} />
          </p>
          <p>
            Нажмите кнопку «Найти по справочнику» и введите название: Уфимский
            филиал Федерального государственного бюджетного образовательного
            учреждения высшего образования «Волжский государственный университет
            водного транспорта» (Уфимский филиал ФГБОУ ВО "ВГУВТ") ОГРН:
            1025203032645
          </p>
          <p>
            Заполните все поля формы: внесите фамилию, серию и номер бланка,
            регистрационный номер, дату выдачи. Обращайте внимание на
            правильность заполнения каждого поля! Регистрационный номер может
            содержать символ «/» или «пробел» перед дефисом.\ Введите код с
            картинки и нажмите кнопку «Поиск». Вы увидите карточку с информацией
            о Вашем дипломе из базы ФИС ФРДО. Сфотографируйте эту карточку или
            сделайте скриншот. Именно она подтверждает подлинность Вашего
            диплома.{" "}
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Я учусь на выпускном курсе, имею оценки в большинстве 5, но есть и 4.
          Могу ли я рассчитывать на «красный диплом» (диплом с отличием)?
        </span>
      ),
      children: (
        <>
          <p>
            Правила выдачи дипломов и приложений к ним определены «Порядком
            заполнения, учета и выдачи дипломов о среднем профессиональном
            образовании и их дубликатов, утвержденного приказом Минпросвещения
            России от 14.10.2022 № 906». При этом согласно пункту 14 Порядка №
            906 диплом о среднем профессиональном образовании с отличием
            выдается при выполнении следующих условий: задолженностей.
          </p>
          <ul>
            <li>
               все указанные в приложении к диплому оценки по учебным
              предметам, курсам, дисциплинам (модулям), практикам, оценки за
              курсовые работы (проекты), за исключением оценок «зачтено»,
              являются оценками «отлично» и «хорошо»;
            </li>
            <li>
               все оценки по результатам государственной итоговой аттестации
              являются оценками «отлично»;
            </li>
            <li>
               количество указанных в приложении к диплому оценок «отлично»,
              включая оценки по результатам государственной итоговой аттестации,
              составляет не менее 75% от общего количества оценок, указанных в
              приложении, за исключением оценок «зачтено».
            </li>
          </ul>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Как оформить получение социальной стипендии?
        </span>
      ),
      children: (
        <>
          <p>
            На основании «Положения о стипендиальном обеспечении и других формах
            материальной поддержки студентов и аспирантов «ВГУВТ», социальная
            стипендия начисляется социально незащищенным категориям курсантов.
            Для оформления социальной стипендии необходимо:
          </p>
          <ol>
            <li>
              Получить справку о том, что вы являетесь получателем
              государственной социальной помощи{" "}
            </li>
            <li>
              Обратиться в отделение СПО со справкой о том, что вы являетесь
              получателем государственной социальной помощи и заявлением на
              получение социальной стипендии.
            </li>
          </ol>
          <p>
            Для оформления справки о том, что вы являетесь получателем
            государственной социальной помощи необходимо собрать следующий
            комплект документов:
          </p>
          <ol>
            <li>
              Удобным для вас способом (заказ через информационный киоск, сайт,
              лично в канцелярии/бухгалтерии) получить в филиале справку о том,
              что являетесь курсантом и обучаетесь в нашем филиале и справку о
              размере стипендии.
            </li>
            <li>
              Обратиться по месту жительства и взять справку о составе семьи.
            </li>
            <li>Собрать документы о доходах всех членов семьи из п. 2.</li>
            <li>
              С комплектом документов обратиться в МФЦ или в центр соцзащиты по
              месту жительства.
            </li>
            <li>
              Получить документ, который будет подтверждать оформление, что вы
              являетесь получателем государственной социальной помощи.
            </li>
          </ol>
          <p>
            Выплата государственной социальной стипендии курсантам
            осуществляется ежемесячно: начиная с момента обращения на отделение
            с подтверждающим документом, по месяц прекращения действия основания
            ее назначения.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Когда и как можно перевестись с платного обучения на бесплатное?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            На основании Положения о комиссии по переводу студентов с платного
            обучения на бесплатное, п. 7.1.3.1: «Право на переход с платного
            обучения на бесплатное имеет лицо, обучающееся в университете на
            основании договора об оказании платных образовательных услуг, не
            имеющее на момент подачи заявления академической задолженности,
            дисциплинарных взысканий, задолженности по оплате обучения, с
            соблюдением одного из следующих условий:
          </p>
          <ol>
            <li>
              Сдачи экзаменов за два последних семестра обучения, предшествующих
              подаче заявления, на оценки «отлично» или «отлично» и «хорошо»,
              или «хорошо»;
            </li>
            <li>
              Отнесения к следующим категориям граждан:{" "}
              <ul>
                <li>
                  детей-сирот и детей, оставшихся без попечения родителей, а
                  также лицам из числа детей-сирот и детей, оставшихся без
                  попечения родителей;
                </li>
                <li>
                  граждан в возрасте до 20 лет, имеющих только одного родителя –
                  инвалида I группы, если среднедушевой доход семьи ниже
                  величины прожиточного минимума, установленного в
                  соответствующем субъекте Российской Федерации;
                </li>
                <li>женщин, родивших ребенка в период обучения;</li>
                <li>
                  детей лиц, принимающих или принимавших участие в СВО на
                  территориях Украины, ДНР, ЛНР, Запорожской и Херсонской
                  областей;
                </li>
                <li>
                  утраты обучающимся в период обучения одного или обоих
                  родителей (законных представителей) или единственного родителя
                  (законного представителя).
                </li>
              </ul>
            </li>
          </ol>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Какова процедура получения дубликата документов об образовании?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>Дубликат диплома и дубликат приложения к диплому выдается:</p>
          <ol>
            <li>взамен утраченного диплома и (или) приложения к диплому;</li>
            <li>
              • взамен диплома и (или) приложения к диплому, содержащего ошибки,
              обнаруженные выпускником после его получения;
            </li>
            <li>
              • лицу, изменившему свою фамилию (имя, отчество). Обмен
              производится на основании заявления лица, изменившего свою фамилию
              (имя, отчество), с приложением копий документов, подтверждающих
              изменение фамилии (имени, отчества) лица.
            </li>
          </ol>
          <p>
            Дубликаты диплома и приложения к нему выдаются на основании личного
            заявления.
          </p>
          <p>
            В целом, процедура получения дубликата документа об образовании
            описана в Приказе Минобрнауки России от 25.10.2013 №1186 (ред. от
            27.04.2015) «Об утверждении Порядка заполнения, учета и выдачи
            дипломов о среднем профессиональном образовании и их дубликатов».
          </p>
          <p>
            Что касается конкретного случая в нашем учебном заведении, зависит
            от того, в каком году вы окончили учебное заведение.{" "}
          </p>
          <p>
            <strong>
              {" "}
              Вариант 1. Вы окончили учебное заведение до 01.09.2019 года
            </strong>
          </p>
          <p>
            Вы пишете заявление на имя ректора ФГБОУ ВО «Государственный
            университет морского и речного транспорта имени адмирала С.О.
            Макарова» Барышникова С.О. с просьбой о выдаче дубликата диплома. В
            заявлении обязательно должна содержаться полная информация: фамилия,
            имя, отчество, по какой специальности обучались, в каком году
            закончили образовательное учреждение, контактные данные (телефон и
            электронный адрес). В случае, если не имеете возможности поехать в
            Санкт-Петербург за дубликатом, в заявлении указываете, что дубликат
            просите выслать на почтовый адрес (указываете свой полный почтовый
            адрес обязательно с индексом).{" "}
          </p>
          <p>Сканируете заявление и отправляете на эл.адрес otd_umof@mail.ru</p>
          <p>
            По исполнении заявления, Вас уведомят отправкой скана дубликата на
            электронную почту. О приходе оригинала дубликата уведомят извещением
            с отделения почты России.
          </p>
          <p>
            <strong>
              Вариант 2. Вы окончили учебное заведение после 01.09.2019 года{" "}
            </strong>
          </p>
          <p>
            Вы пишете заявление на имя ректора ФГБОУ ВО «ВГУВТ» Кузьмичева И.К.
            Информация в заявлении та же. Отправляете на электронную почту
            rector@vsuwt.ru{" "}
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "8",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Кем и как формируется меню для питания курсантов в столовой филиала?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Меню формируется заведующей отделом коллективного питания Уфимского
            филиала на основании норм обеспечения питания, указанных в приказе
            Федерального агентства морского и речного транспорта от 5 декабря
            2013 г. №83 «Об определении норм и порядка обеспечения питанием
            обучающихся федеральных государственных образовательных организаций
            Федерального агентства морского и речного транспорта, реализующих
            образовательные программы по специальностям и направлениям
            подготовки в области подготовки плавательных составов морских судов
            и судов внутреннего водного плавания»
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "9",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Зачем курсанты филиала несут дежурно-вахтенную службу?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Курсанты филиала несут дежурно-вахтенную службу (ДВС) в соответствии
            с пп.11. п 7.1.4.4 «Правил внутреннего распорядка и организации
            службы курсантов Уфимского филиала ФГБОУ ВО «ВГУВТ». ДВС
            осуществляется в целях формирования навыков самоуправления,
            укрепления дисциплины среди курсантов.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "10",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Оказывает ли данное учебное заведение платные образовательные услуги?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Да, Уфимский филиал оказывает широкий перечень образовательных
            услуг. Это и профессиональная подготовка по профессиям рабочих (12
            программ), обучения и повышения квалификации по специальным
            программам подготовки для специалистов сферы транспорта (18
            программ). Подробнее ознакомиться с информацией можно здесь, на
            нашем сайте, в разделе «Перечень дополнительных образовательных
            услуг».
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "11",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Могу ли я пройти в данном учебном заведении обучение по управлению
          маломерным судном?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Да, в нашем филиале производится обучение по программе «Управление
            маломерным судном». Программа составляет 128 часов, в том числе на
            практическое обучение отводится 15 часов. Для удобства слушателей и
            противодействия распространению коронавирусной инфекции
            теоретическая часть курса проводится в дистанционном режиме в
            системе дистанционного обучения «Парус». После того, как вы
            запишетесь на курс, заключите договор и оплатите за обучение, вам
            будет предоставлен доступ к теоретическому курсу и проведен
            соответствующий инструктаж.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "12",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Разъясните, с чего начать, чтобы перевестись для продолжения обучения
          в ваш филиал из другого образовательного учреждения?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Для того, чтобы перевестись для продолжения обучения в наш филиал
            вам необходимо убедиться, что на выбранную вами специальность и курс
            есть вакантные взять места. Это лучше сделать в разделе «Сведения об
            образовательной организации», либо обратившись удобным для вас
            способом (обращение на сайте, на электронную почту, по телефону,
            лично).
          </p>
          <p>
            При наличии вакантных мест необходимо получить справку об обучении
            из той профессиональной образовательной организации, в которой вы
            обучаетесь в настоящее время. С полученной справкой обращаетесь к
            заместителю директора по учебной работе. Данные из справки об
            обучении на заседании комиссии по переаттестации мы сравниваем с
            образовательной программой той специальности, по которой вы
            намереваетесь обучаться, и в случае положительного решения комиссии,
            выдаем справку, подтверждающую, что готовы принять вас на
            соответствующую специальность. Далее с полученной справкой вы
            обращаетесь в образовательную организацию, в которой обучаетесь с
            заявлением об отчислении в связи с переходом для продолжения
            обучения в другое образовательное учреждение. После отчисления в
            связи с переводом получаете выписку из приказа об отчислении,
            документы из личного дела и прибываете в наш филиал для зачисления.
          </p>
          <p>
            Переводы рассматриваются в течение учебного года в межсессионный
            период, перевод возможен не ранее прохождения обучающимся хотя бы
            одной промежуточной аттестации.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "13",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Можно ли совмещать учебу в филиале с работой?
        </span>
      ),
      children: (
        <>
          {" "}
          <p>
            Можно, но только в том случае, если работа вам не помешает соблюдать
            расписание занятий и выполнять домашние задания. Многие обучающиеся
            старших курсов в процессе обучения начинают работать, но только
            соблюдая вышеназванные условия.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "14",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Перечислите все социальные выплаты, которые должен получать
          студент-сирота во время обучения в профессиональной образовательной
          организации?
        </span>
      ),
      children: (
        <ol>
          {" "}
          <li>Выплата социальной стипендии</li>
          <li>
            Выплата ежегодного пособия в размере трехмесячной государственной
            академической стипендии на приобретение учебной литературы и
            письменных принадлежностей;
          </li>
          <li>Зачисление на полное государственное обеспечение;</li>
          <li>
            Единовременная выплата по окончании обучения в профессиональной
            образовательной организации (Федеральный закон № 159-ФЗ от 21.12.
            1996 г. «О дополнительных гарантиях по социальной поддержке
            детей-сирот и детей, оставшихся без попечения родителей», ст.6,
            п.3).
          </li>
        </ol>
      ),
      style: panelStyle,
    },
  ];
  const getItemsCommitteeSelection = (panelStyle) => [
    {
      key: "1",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Есть ли отсрочка от армии?
        </span>
      ),
      children: (
        <>
          <p>
            Обучающимся предоставляется отсрочка от призыва на военную службу в
            течение всего периода обучения, но не свыше сроков получения
            среднего профессионального образования, установленных федеральными
            государственными образовательными стандартами
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Какой диплом выдается выпускникам Вашего учебного заведения?
        </span>
      ),
      children: (
        <>
          <p>
            Выпускникам нашего учебного заведения выдается диплом
            государственного образца, что позволяет им работать в любом регионе
            Российской Федерации
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Предоставляется ли общежитие для курсантов филиала?
        </span>
      ),
      children: (
        <>
          <p>Да. Иногородним курсантам филиала предоставляется общежитие.</p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Слышали, что в данном учебном заведении для курсантов бесплатное
          питание. Верно ли это?
        </span>
      ),
      children: (
        <>
          <p>
            Для курсантов, обучающихся на бюджетной основе, бесплатно
            предоставляется 5-ти разовое питание. Для курсантов, обучающихся на
            коммерческой основе, в столовой филиала организована платная линия,
            с социально-ориентированными ценами.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Какой проходной балл в этом году в Ваше учебное заведение?
        </span>
      ),
      children: (
        <>
          <p>
            Проходной балл формируется ближе к 15 августа из числа подавших
            заявлений по рейтингу.
          </p>
        </>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          Как считается средний балл аттестата?
        </span>
      ),
      children: (
        <>
          <p>
            Средний балл аттестата представляет собой сумму оценок по всем
            дисциплинам из приложения аттестата, разделенную на количество
            дисциплин.
          </p>
        </>
      ),
      style: panelStyle,
    },
  ];
  return (
    <>
      <div style={{ textAlign: "center" }}>
        {" "}
        <h1>Часто задаваемые вопросы</h1>{" "}
      </div>
      <p style={{ paddingLeft: "10px" }}>
        Задать свой вопрос вы можете через{" "}
        <a
          href="https://forms.yandex.ru/cloud/65dd6ba4c09c021fd27c2378/"
          target="_blank"
          rel="noreferrer"
        >
          виртуальную приемную
        </a>
      </p>
      <h2>Раздел «Учебный процесс»</h2>

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
          fontSize: toggleFontSize(14),
        }}
        items={getItemsStudyProcess(panelStyle)}
      />
      <h2>Приемная комиссия</h2>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
          fontSize: toggleFontSize(14),
        }}
        items={getItemsCommitteeSelection(panelStyle)}
      />
    </>
  );
};
