import React from "react";
import { Outlet } from "react-router";
import { toggleClassName } from "../utils/disabled";
import { observer } from "mobx-react-lite";
import { ChapterMenu } from "../components/ui";
export const Home = observer(() => {
  const linksArray = [
    { title: "Главная", path: "/" },
    { title: "О филиале", path: "/home/aboutBranch" },
    { title: "Историческая справка", path: "/home/historicalReference" },
    { title: "Новости", path: "/home/univercityNews" },
    { title: "Противодействие коррупции", path: "/home/anti-corruption" },
    /*     { title: "План мероприятий", path: "/home/univercityEvents" }, */
    { title: "Предоставляемые услуги", path: "/home/services" },
    { title: "Интервью с выпускниками", path: "live/interview" },
    { title: "Государство для людей", path: "/home/stateForPeople" },
    { title: "Независимая оценка качества", path: "/home/nok" },
    { title: "Навигация по сайту", path: "/home/site-map" },
  ];
  return (
    <div
      className={` ${toggleClassName(
        "_container-main min-height",
        "_container-white min-height-white",
        " _container-black min-height-black",
        "_container-contrast min-height-contrast"
      )}`}
    >
      <div className="chapter__menu-show">
        <ChapterMenu linksArray={linksArray} />
      </div>
      <Outlet />
    </div>
  );
});
