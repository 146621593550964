import { Form, TimePicker } from "antd";
import React, { useState } from "react";
export const TimePickerComponent = ({ field }) => {
  const [recordingTime, setRecordingTime] = useState({
    start: "",
    end: "",
  });
  const handleTimeChange = (time) => {
    if (time[0]) {
      setRecordingTime((prevState) => ({ ...prevState, start: time[0] }));
    }
    if (time[1]) {
      setRecordingTime((prevState) => ({ ...prevState, end: time[1] }));
    }
    console.log(recordingTime);
  };
  return (
    <Form.Item name={[field.name, "recordingTime"]} noStyle>
      {" "}
      <TimePicker.RangePicker
        placeholder={["Время начала записи", "Время окончания записи"]}
        onChange={handleTimeChange}
      />
    </Form.Item>
  );
};
