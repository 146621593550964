import React from "react";

import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import perevod_res from "../../../../assets/pdf/educationPage/perevod_res.pdf";
/* import { TableWrapper } from "../../../common"; */

export const InfoOResultatahPerevoda = () => {
  return (
    <>
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduPerevodEl"
            href={perevod_res}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о результатах перевода, восстановления и отчисления на
            01.09.2024г.
          </a>
        </li>
      </ul>
    </>
  );
};
