import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import Perechen from "../../../../../assets/doc/applicant/perechen.docx";
import Priem2023 from "../../../../../assets/pdf/applicant/priem2024_v_el_forme.pdf";
import Priem2023Vo from "../../../../../assets/pdf/applicant/ВО.pdf";
import zayavlenie from "../../../../../assets/doc/enrollee/Заявление на поступление ВО.doc";
import zayavlenieSpo from "../../../../../assets/pdf/enrolle/Заявление  на 1 курс в Уфимский филиал ФГБОУ ВО ВГУВТ. 2024.pdf";
import poshag from "../../../../../assets/doc/enrollee/Прием документов по электронной почте.docx";
import med from "../../../../../assets/pdf/enrolle/медкомиссия.pdf";

import { ReactComponent as DOC } from "../../../../../assets/svg/office/word.svg";
import { ReactComponent as PDF } from "../../../../../assets/svg/office/pdf.svg";
import { NavPanel } from "../../../../ui/nav-panel/nav-panel";
export const SubmissionDoc = observer(() => {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <NavPanel
        isActive={isActive}
        setIsActive={setIsActive}
        btnOneText={"СПО"}
        btnTwoText={"Высшее образование"}
        center={true}
      />

      {isActive ? (
        <div>
          <section>
            <h1>Перечень документов для поступления по программам СПО:</h1>
            <ol>
              <li>
                Оригинал или ксерокопия документа, удостоверяющего личность,
                гражданство (паспорт)
              </li>
              <li>
                Оригинал или ксерокопия документа государственного образца об
                образовании
              </li>
              <li>Фотографии, 3х4, 5 шт.</li>
              <li>Медицинское заключение об отсутствии противопоказаний</li>
              <li>
                Копия паспорта родителей или законного представителя (для
                несовершеннолетних)
              </li>
              <li>Свидетельство о рождении (копия)</li>
              <li>Справка с места жительства о составе семьи</li>
              <li>Характеристика из школы</li>
              <li>Приписное свидетельство, военный билет (при наличии)</li>
              <li>Социальный номер ИНН (копия)</li>
              <li>Пенсионное страховое свидетельство СНИЛС (копия)</li>
              <li>Медицинский полис (копия)</li>
              <li>Сертификат о прививках (оригинал или копия)</li>
              <li>Копия трудовой книжки (при наличии)</li>
            </ol>
            <p>
              <DOC width={"25px"} height={"25px"} />{" "}
              <a
                href={Perechen}
                target={"_blank"}
                rel="noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                Скачать перечень документов
              </a>
            </p>
            <p>
              <a
                href={zayavlenieSpo}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                <PDF width={"25px"} height={"25px"} />
              </a>{" "}
              <a
                href={zayavlenieSpo}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                Заявление на поступление
              </a>{" "}
            </p>
            <p>
              <a
                href={zayavlenie}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                <PDF width={"25px"} height={"25px"} />
              </a>{" "}
              <a
                href={med}
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                ЛИСТ прохождения предварительного медицинского
                освидетельствования
              </a>{" "}
            </p>
          </section>
          <section>
            <h1>
              Прием документов для поступления на обучение в университет по
              основным образовательным программам СПО в электронной форме
            </h1>
            <p>
              Заявление можно отправить по электронной почте, сканированные
              формы документов отправлять на{" "}
              <a
                href="mailto:uf-vsuwt@uf-vsuwt.ru"
                target={"_blank"}
                rel="noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                uf-vsuwt@uf-vsuwt.ru
              </a>
            </p>
            <p>
              Заявление и перечень документов формируются в соответствии с
              пунктом IV Правил приема на обучение по образовательным программам
              среднего профессионального образования в Федеральное
              государственное бюджетное образовательное учреждение высшего
              образования «Волжский государственный университет водного
              транспорта» на 2024/25 учебный год.
            </p>

            <ul>
              <li>
                Прием документов на очную форму обучения с
                <b> 20 июня по 15 августа (бюджет) 2024 года</b>;
              </li>
              <li>
                На очную форму обучения по договору с
                <b> 20 июня по 23 августа 2024 года;</b>
              </li>
              <li>
                При наличии мест на очную форму обучения по договорам прием
                документов до <b>25 ноября 2024 года;</b>
              </li>
              <li>
                {" "}
                На заочную форму обучения с <b>20 июня по 15 ноября</b>
              </li>
              <li>
                {" "}
                Заключение договоров на заочную форму обучения{" "}
                <b>до 28 ноября 2024 года</b>.
              </li>
            </ul>
            {/*   <p> Прием заявлений в университет и филиалы завершается:</p>

            <ul>
              <li>
                на <b>очную</b> форму получения образования – 15 августа;
              </li>
              <li>
                на <b>очно-заочную</b> получения образования – 13 сентября;
              </li>
              <li>
                на <b>заочную</b> форму получения образования – 15 ноября.
              </li>
            </ul> */}
            <p>
              <ul>
                <li>
                  {" "}
                  <a
                    href={Priem2023}
                    target={"_blank"}
                    rel="noreferrer"
                    className={toggleClassName(
                      "education__link",
                      "education__link-white",
                      "education__link-black",
                      "education__link-contrast"
                    )}
                  >
                    Прием документов для поступления на обучение в университет
                    по основным образовательным программам СПО в электронной
                    форме
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={poshag}
                    target={"_blank"}
                    rel="noreferrer"
                    className={toggleClassName(
                      "education__link",
                      "education__link-white",
                      "education__link-black",
                      "education__link-contrast"
                    )}
                  >
                    Пошаговая подача документов по электронной почте Абитуриенту
                    2024 в Уфимский филиал ФГБОУ ВО «ВГУВТ»
                  </a>
                </li>
              </ul>
            </p>
          </section>
        </div>
      ) : (
        <section>
          <h2>Перечень документов для поступления по программам ВО:</h2>
          <ul>
            <li>
              Оригинал или ксерокопия документа, удостоверяющего личность,
              гражданство (паспорт)
            </li>
            <li>
              Оригинал или ксерокопия документа о среднем общем образовании, или
              о среднем профессиональном образовании, или о высшем образовании
            </li>
            <li>Фотографии, 3х4, 5 шт.</li>
            <li>Медицинское заключение об отсутствии противопоказаний</li>
            <li>Социальный номер ИНН (копия)</li>
            <li>Пенсионное страховое свидетельство СНИЛС (копия)</li>
            <li>Копия трудовой книжки (при наличии)</li>
            <li>Заявление на поступление</li>
          </ul>
          <p>
            <a
              href={zayavlenie}
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              <DOC width={"25px"} height={"25px"} />
            </a>{" "}
            <a
              href={zayavlenie}
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              Заявление на поступление
            </a>{" "}
          </p>
          <ul>
            <li>
              {" "}
              <a
                href={Priem2023Vo}
                target={"_blank"}
                rel="noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                Прием документов для поступления на обучение в Уфимский филиал
                по основным образовательным программам ВО в электронной форме
              </a>
            </li>
          </ul>

          <p>
            Заявление и сканы документов можно отправить по электронной почте
            <a
              href="mailto:uf-vsuwt@uf-vsuwt.ru"
              target={"_blank"}
              rel="noreferrer"
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              uf-vsuwt@uf-vsuwt.ru
            </a>
          </p>

          <p>
            Заявление и перечень документов формируются в соответствии с
            Правилами приема на обучение по образовательным программам высшего
            образования в Федеральное государственное бюджетное образовательное
            учреждение высшего образования «Волжский государственный университет
            водного транспорта» на 2024/25 учебный год.
          </p>

          <p>Прием документов на первый курс начинается:</p>
          <p>
            На <b>заочную</b> форму получения образования – <b>20 июня;</b>
          </p>
          <p>
            Прием заявлений в университет и филиалы <b>завершается</b>:
          </p>
          <p>
            На <b>заочную</b> форму от поступающих на обучение с прохождением
            внутренних экзаменов, проводимых университетом самостоятельно –{" "}
            <b>10 июля;</b>
          </p>
          <p>
            На <b>заочную форму</b> от поступающих на обучение без вступительных
            испытаний в соответствии с частью 4 и 12 статьи 71 Федерального
            закона № 273т-ФЗ.либо без проведения вступительных испытаний в
            соответствии с частью 5.2 статьи 71 Федерального закона № 273-ФЗ –
            <b>25 июля.</b>
          </p>
        </section>
      )}
    </>
  );
});
