import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../../utils/disabled";

import { ReactComponent as PDF } from "../../../../../../assets/svg/office/pdf.svg";
import kcp from "../../../../../../assets/pdf/enrolle/План приема на 2024-2025 уч.г.pdf";
import { TableWrapper } from "../../../../../common";

export const KcpVo = observer(() => {
  return (
    <section className="flex jcc">
      <div
        className={toggleClassName(
          "common__container",
          "common__container-white",
          "common__container-black",
          "common__container-contrast"
        )}
      >
        <h1>
          {" "}
          План приема в Уфимский филиал ФГБОУ ВО "ВГУВТ" по программе высшего
          образования специалитет на 2024-2025 учебный год (без учета квот)
        </h1>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={kcp}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            План приема в Уфимский филиал ФГБОУ ВО "ВГУВТ" по программе высшего
            образования специалитет на 2024-2025 учебный год (без учета квот)
          </a>
        </li>
        <TableWrapper
          title={`План приема в Уфимский филиал ФГБОУ ВО "ВГУВТ" по программе высшего образования специалитет на 2024-2025 учебный год с учетом квот`}
        >
          <table>
            <tbody>
              <tr>
                <td rowSpan="2">Код</td>
                <td rowSpan="2">Направление подготовки (специальность)</td>
                <td rowSpan="2">Всего</td>
                <td rowSpan="2">Форма обучения</td>
                <td colSpan="4">Контрольные цифры приема (бюджетные места)</td>
                <td rowSpan="2">
                  По договорам об оказании платных образовательных услуг
                </td>
              </tr>
              <tr>
                <td>квота на целевое обучение</td>
                <td>Особая квота</td>
                <td>Отдельная квота</td>
                <td>Общие условия</td>
              </tr>
              <tr>
                <td>26.05.06</td>
                <td>Эксплуатация судовых энергетических установок</td>
                <td>40</td>
                <td>Заочная</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>7</td>
                <td>30</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </div>
    </section>
  );
});
