import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../../utils/disabled";
export const ReceptionPlaces = observer(() => {
  return (
    <section>
      <h1>Места приема документов, необходимых для поступления</h1>
      <p>
        450017, г. Уфа, ул. Ахметова, 275, тел.{" "}
        <a
          href="tel:+7 (347) 2782886"
          className={toggleClassName(
            "education__link",
            "education__link-white",
            "education__link-black",
            "education__link-contrast"
          )}
          target={"_blank"}
          rel="noreferrer"
        >
          +7 (347) 215-14-00 доб. 3
        </a>
      </p>
      <p>
        Электронный адрес для направления документов, необходимых для
        поступления в электронной форме:{" "}
        <a
          href="mailto:okpdo@uf-vsuwt.ru"
          target={"_blank"}
          rel="noreferrer"
          className={toggleClassName(
            "education__link",
            "education__link-white",
            "education__link-black",
            "education__link-contrast"
          )}
        >
          okpdo@uf-vsuwt.ru
        </a>
      </p>
      <h2>
        Почтовый адрес для направления документов, необходимых для поступления:
      </h2>
      <h2>Информация о сроках проведения приема</h2>
      <ul>
        <li>
          Прием документов на очную форму обучения с 20 июня по 15 августа
          (бюджет) 2024 года;
        </li>
        <li>
          На очную форму обучения по договору с 20 июня по 23 августа 2024 года;
        </li>
        <li>
          При наличии мест на очную форму обучения по договорам прием документов
          до 25 ноября 2024 года;{" "}
        </li>
        <li>На заочную форму обучения с 20 июня по 15 ноября;</li>
        <li>
          {" "}
          Заключение договоров на заочную форму обучения до 28 ноября 2024года.
        </li>
      </ul>
    </section>
  );
});
