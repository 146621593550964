import React from "react";

import { EducationContainer } from "../../../common";
import { BreadCrumbs } from "../../../common/";
export const Сorruption = () => {
  return (
    <>
      <BreadCrumbs />
      <EducationContainer
        title="Информация  
об условиях питания и охраны здоровья обучающихся
"
      >
        <div>
          <h2>
            1. Условия питания обучающихся, в том числе инвалидов и лиц с ОВЗ
          </h2>
          <p>
            Основное питание обучающихся, в том числе инвалидов и лиц с ОВЗ,
            организовано в столовой на 300 мест, общей площадью 1354 м2
            (расположена на ул. Ахметова, 275). Перед входом в столовую
            установлена кнопка вызова персонала, дверные проемы расширенные.
          </p>
          <p>
            Санитарно-гигиеническое состояние столовой удовлетворяет всем
            необходимым требованиям. Уборка помещений проводится ежедневно,
            периодически организуется проведение санитарного дня. Хранение
            продуктов осуществляется в соответствии с санитарными нормами.
            Обязательным является соблюдение правил техники безопасности,
            сотрудники проходят регулярный инструктаж.{" "}
          </p>
          <p>
            В соответствии с требованиями «Санитарно-эпидемиологических правил и
            нормативов 2.4.5. 2409-08 п.6.8» в Уфимском филиале организовано
            пятиразовое питание (завтрак, обед, полдник, ужин и второй ужин) для
            курсантов, проживающих в общежитии, и трехразовое питание для
            курсантов, проживающих вне общежития. Приготовление и отпуск
            курсантам питания осуществляется по установленным нормам продуктов
            питания и суточному рациону, согласно двухнедельного плана-меню,
            согласованного с управлением Роспотребнадзора по РБ и суточной
            заявке, подаваемой сотрудниками организационно-воспитательного
            отдела. Питание курсантов соответствует принципам щадящей диеты:
            используются такие способы приготовления блюд, как варка,
            приготовление на пару, тушение, запекание, исключение продуктов с
            раздражающими свойствами. В меню не допускается повторение одних и
            тех же блюд или кулинарных изделий в последующие 2-3 дня. Ежедневно
            в рацион включены мясо, сливочное и растительное масло, рыба, яйца,
            сыр, творог, кисломолочные продукты, мучные изделия, хлеб.
          </p>
          <p>
            Питание курсантов организовано при наличии 1 и 2 курсов в две смены,
            при наличии 1, 2, 3, 4 курсов в 4 смены под руководством сотрудников
            подразделения воспитательной работы. На каждом приеме пищи в
            столовой находится дежурный воспитатель, контролирующий порядок в
            зале, оказывающий помощь в организации питания.
          </p>
          <p>
            Для инвалидов и лиц с ОВЗ дополнительно к основному питанию,
            организована возможность питания в комнате для приема пищи на 5
            мест, которая приспособленна для использования инвалидами и лицами с
            ОВЗ (ул. Ахметова, 275, 1 этаж 5-этажного корпуса общежития).
          </p>

          <h2>
            2. Условия охраны здоровья обучающихся, в том числе инвалидов и лиц
            с ограниченными возможностями здоровья
          </h2>
          <p>
            Охрана здоровья обучающихся, в том числе инвалидов и лиц с
            ограниченными возможностями здоровья, организована в соответствии со
            статьей 41 Федерального закона от 29 декабря 2012 г. N 273- ФЗ «Об
            образовании в Российской Федерации».{" "}
          </p>
          <p>Меры по охране здоровья включают:</p>
          <ol>
            <li>
              оказание первичной медико-санитарной помощи в порядке,
              установленном законодательством в сфере охраны здоровья;{" "}
            </li>
            <li>организацию питания обучающихся; </li>
            <li>
              определение оптимальной учебной, внеучебной нагрузки, режима
              учебных занятий и продолжительности каникул;
            </li>
            <li>
              пропаганду и обучение навыкам здорового образа жизни, требованиям
              охраны труда;{" "}
            </li>
            <li>
              организацию и создание условий для профилактики заболеваний и
              оздоровления обучающихся, для занятия ими физической культурой и
              спортом;{" "}
            </li>
            <li>
              прохождение обучающимися в соответствии с законодательством
              Российской Федерации медицинских осмотров, в том числе
              профилактических медицинских осмотров, в связи с занятиями
              физической культурой и спортом, диспансеризации;{" "}
            </li>
            <li>
              профилактику и запрещение курения, употребления алкогольных,
              слабоалкогольных напитков, пива, наркотических средств,
              психотропных веществ и других одурманивающих веществ;{" "}
            </li>
            <li>
              обеспечение безопасности обучающихся во время пребывания в
              организации;{" "}
            </li>
            <li>
              профилактику несчастных случаев с обучающимися во время пребывания
              в организации;{" "}
            </li>
            <li>
              проведение санитарно-противоэпидемических и профилактических
              мероприятий;{" "}
            </li>
            <li>
              обучение педагогических работников навыкам оказания первой помощи.{" "}
            </li>
          </ol>
          <p>
            В филиале созданы необходимые условия для охраны здоровья
            обучающихся, в том числе инвалидов и лиц с ограниченными
            возможностями здоровья:
          </p>
          <ol>
            <li>
              Проводится мониторинг состояния здоровья обучающихся. В частности,
              при поступлении абитуриенты проходят предварительный медицинский
              осмотр. На основании результатов медосмотра составляются списки
              студентов, состоящих на диспансерном учете, определяется
              медицинская группа для занятий физкультурой.{" "}
            </li>
            <li>
              Обеспечивается проведение санитарно-гигиенических,
              профилактических и оздоровительных мероприятий, обучение и
              воспитание в сфере охраны здоровья, обязательный инструктаж по
              технике безопасности.{" "}
            </li>
            <li>
              Осуществляется информирование обучающихся по вопросам гражданской
              обороны, защиты от чрезвычайных ситуаций, пожарной безопасности.{" "}
            </li>
            <li>
              Организован контроль за соблюдением государственных
              санитарно-эпидемиологических правил и нормативов.{" "}
            </li>
            <li>
              Проводятся профилактика, расследование и учет несчастных случаев с
              обучающимися во время пребывания в университете.{" "}
            </li>
          </ol>
          <p>
            Инфраструктура Уфимского филиала ФГБОУ ВО «ВГУВТ», состояние и
            содержание территории, зданий, помещений, оборудования соответствуют
            требованиям санитарных правил и требованиям пожарной безопасности.
            Учебные кабинеты оснащены необходимым оборудованием и инвентарем в
            соответствии с требованиями санитарно-гигиенических правил для
            освоения основных и дополнительных образовательных программ.{" "}
          </p>
          <p>
            Оздоровительная работа с обучающимися проводится на занятиях
            физической культуры и в спортивных секциях. План воспитательной
            работы Уфимского филиала ФГБОУ ВО «ВГУВТ», рабочие программы
            воспитания по всем реализуемым специальностям включают в себя
            комплекс мероприятий, направленных на формирование здорового образа
            жизни, негативного отношения к вредным привычкам, осознания
            персональной ответственности за свое здоровье, уважения и соблюдения
            прав других людей на здоровье и здоровый образ жизни, а также
            организацию широкой пропаганды физической культуры и спорта.{" "}
          </p>
          <p>
            Медицинское обслуживание обучающихся проводится на базе медицинского
            пункта филиала. В состав медицинского пункта входит: кабинет врача,
            процедурный кабинет, изолятор.
          </p>
          <p>
            Первичная медико-санитарная помощь в филиале оказывается в плановой
            и неотложной формах.
          </p>
          <p>
            Обучающимся, в том числе несовершеннолетним, гарантируется оказание
            медицинской помощи в виде первичной доврачебной медико-санитарной
            помощи.
          </p>
          <p>
            При необходимости получения медицинской помощи в случаях
            заболевания, острого ухудшения состояния здоровья или получения
            травмы обучающийся обращается самостоятельно или с помощью
            сотрудника филиала:
          </p>
          <ul>
            <li>
              в рабочее время с 8.15 до 17.15 в медицинский кабинет,
              расположенный в здании общежития
            </li>
            <li>
              во внерабочее время (в том числе в выходные и праздничные дни) к
              воспитателю
            </li>
          </ul>
          <p>
            Родители обучающихся могут получить информацию о состоянии здоровья
            своих детей в рабочее время по телефону: +7(347) 215-14-00, доп.321.
          </p>
          <p>
            После первичного осмотра обучающего медицинская сестра (воспитатель)
            принимает решение о дальнейших действиях в соответствии с
            нормативно-правовыми актами, регламентирующими оказание медицинской
            помощи гражданам.
          </p>
          <p>
            При оказании скорой медицинской помощи, в случае необходимости,
            осуществляется транспортировка в медицинскую организацию и (или)
            определение в стационар по профилю заболевания при обязательном
            сопровождении обучающегося сотрудником Филиала.
          </p>
          <p>
            Примечание: При оказании медицинской помощи несовершеннолетнему по
            экстренным показаниям для устранения угрозы жизни без согласия
            родителей (законных представителей), согласно пункту 1 части 9
            статьи 20 Федерального закона «Об основах охраны здоровья граждан в
            Российской Федерации» от 21.11.2011 №323-ФЗ, классный руководитель
            (медицинская сестра, дежурный воспитатель) незамедлительно
            информирует его родителей (законных представителей) об оказанной
            медицинской помощи.
          </p>
        </div>
      </EducationContainer>
    </>
  );
};
