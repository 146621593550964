import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../../utils/disabled";

import InfoMedOsmotr from "../../../../../../assets/pdf/applicant/infoMedOsmotr.pdf";
import PerechenSpec from "../../../../../../assets/pdf/applicant/priem2024_special_SPO.pdf";
import Dogovor from "../../../../../../assets/pdf/applicant/Dogovor_platn_obraz_uslug_2024.pdf";
import { ReactComponent as PDF } from "../../../../../../assets/svg/office/pdf.svg";
import { ReactComponent as EXCEL } from "../../../../../../assets/svg/office/excel.svg";
import { NavLink } from "react-router-dom";
import pravilsPriema2 from "../../../../../../assets/pdf/applicant/2024-2024/Правила приема на обучение по образовательным программам среднего профессионального образования на 2024-25 учебный год.pdf";
import prikaz99 from "../../../../../../assets/pdf/applicant/2024-2024/приказ 99.pdf";
import prikazStoimost from "../../../../../../assets/pdf/enrolle/приказ № 187-с от 17.04.2024 о стоимости обучения.pdf";
import prikazStoimostPril from "../../../../../../assets/pdf/enrolle/Приложение к приказу №187-с от 17.04.2024 г. О стоимости обучения на 2024-2025 уч год в Уфимском филиале.pdf";
import prikaz221s from "../../../../../../assets/pdf/applicant/приказ № 221-с от 07.05.2024 стоим.обуч..pdf";
import prilPrikaz221s from "../../../../../../assets/pdf/applicant/стоимость обучения 1 курс.xlsx";
import listProhojd from "../../../../../../assets/pdf/enrolle/медкомиссия.pdf";

export const Spo = observer(() => {
  return (
    <>
      <h1>Информация для студентов, поступающих на направления СПО</h1>{" "}
      <ol>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={pravilsPriema2}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Правила приема на обучение по программам среднего профессионального
            образования в 2024-2025 году
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikaz99}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ об утверждении правил приема на 2024 год
          </a>
        </li>
        <li>
          <NavLink to="../kcp">
            План приема в Уфимский филиал ФГБОУ ВО "ВГУВТ" по программе среднего
            профессионального образования подготовки специалистов среднего звена
            на 2024-2025 уч. год
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../educationLvlRequirements"}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Требования к уровню образования
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../admissionConditions"}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Условия приема на обучение по договорам
          </NavLink>
        </li>
        <li>
          <NavLink
            to="../receptionPlaces"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Информация о местах приема документов
          </NavLink>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={InfoMedOsmotr}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о необходимости прохождения дополнительного медицинского
            осмотра
          </a>
        </li>{" "}
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={listProhojd}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Лист прохождения предварительного медицинского освидетельствования
            по приему
          </a>
        </li>
        <li>
          <NavLink
            to="../receptionTiming"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Информация о сроках проведения приема.
          </NavLink>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={PerechenSpec}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Перечень специальностей
          </a>
        </li>
        <li>
          <NavLink
            to="../../../sveden/grants"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Информация о наличии мест в общежитии
          </NavLink>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={Dogovor}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Образец договора по образовательным программам среднего
            профессионального образования
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikaz221s}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 221-с от 7.05.2024 о стоимости обучения поступающих на 1
            курс в 2024 учебном году по договорам об образовании
          </a>
        </li>
        {/* <li>
          <EXCEL width={"25px"} height={"25px"} />{" "}
          <a
            href={prilPrikaz221s}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приложение к приказу № 221-с от 7.05.2024 Стоимость обучения для
            поступающих на 1 курс в 2024 году по программам высшего и среднего
            профессионального образования, обучающихся по договорам об
            образовании за счет средств физических и/или юридических лиц в
            Уфимский филиал ФГБОУ ВО «ВГУВТ»
          </a>
        </li> */}
        <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikazStoimost}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 187-с от 17.04.2024 о стоимости обучения
          </a>
        </li>
        {/*  <li>
          <PDF width={"25px"} height={"25px"} />{" "}
          <a
            href={prikazStoimostPril}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приложение к приказу №187-с от 17.04.2024 г. О стоимости обучения на
            2024-2025 уч год в Уфимском филиале
          </a>
        </li> */}
      </ol>
    </>
  );
});
