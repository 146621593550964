import { makeAutoObservable } from "mobx";
import {
  createDays as createNewDay,
  deleteDays,
  fetchDays,
} from "../httpService/days-api";

export const DaysStore = makeAutoObservable({
  days: [],
  isLoadingDays: false,
  errorDays: null,
  isCreateDaysLoading: false,
  errorOfCreateingDays: null,
  isEditedDaysLoading: false,
  errorEditedDays: null,
  currentDays: null,
  isLoadingCurrentDays: true,
  *loadDaysByEmployeeId(employeeId) {
    DaysStore.isLoadingDays = true;
    try {
      const data = yield fetchDays(employeeId);
      if (data) {
        DaysStore.days = data;
      } else {
        DaysStore.errorDays = "Данные не найдены";
      }
    } catch (e) {
      if (e) DaysStore.error = e.message;
    } finally {
      DaysStore.isLoadingDays = false;
    }
  },

  *createDays(day, reset) {
    DaysStore.errorOfCreateingDays = null;
    DaysStore.isCreateDaysLoading = true;
    try {
      const data = yield createNewDay(day);
      if (data) {
        DaysStore.days.push(data);
        reset();
      } else {
        DaysStore.errorOfCreateingDays =
          "Что-то пошло не так, попробуйте обновить страницу";
      }
    } catch (e) {
      if (e) DaysStore.errorOfCreateingDays = e.response.data.message;
    } finally {
      DaysStore.isCreateDaysLoading = false;
    }
  },
  *remooveDay(id) {
    try {
      yield deleteDays(id);
      DaysStore.days = DaysStore.days.filter((d) => d.id !== id);
    } catch (e) {
      if (e) console.log(e);
    }
  },
});
