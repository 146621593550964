import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";

export const Vacant = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Вакантные места для приёма (перевода) обучающихся на 01.10.2024
      </h1>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Код, шифр группы научных специальностей</td>
              <td>
                Наименование профессии, специальности, направления подготовки,
                наименование группы научных специальностей{" "}
              </td>
              <td>Уровень образования</td>
              <td>
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td>Курс</td>
              <td>Форма обучения</td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируемые за счет бюджетных ассигнований федерального
                бюджета
              </td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируем ые за счет бюджетов субъектов Российской Федерации
              </td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируем ые за счет местных бюджетов
              </td>
              <td>
                Количество вакантных мест для приема (перевода) за счёт средств
                физических и (или) юридических лиц
              </td>
            </tr>
            {/*   <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">Высшее образование. Специалитет</td>
              <td itemProp="eduProf">
                26.05.06 Эксплуатация судовых энергетических установок, приём
                2024
              </td>
              <td itemProp="eduCourse">1</td>
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">12</td>
            </tr>{" "} */}
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">Высшее образование. Специалитет</td>
              <td itemProp="eduProf">
                26.05.06 Эксплуатация судовых энергетических установок, приём
                2023
              </td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">23.00.00</td>
              <td itemProp="eduName">
                {" "}
                «Техника и технологии наземного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                23.02.01 Организация перевозок и управление на транспорте, прием
                2023
              </td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">4</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">2</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">23.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии наземного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                23.02.01 Организация перевозок и управление на транспорте, прием
                2022
              </td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">6</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">23.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии наземного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                23.02.01 Организация перевозок и управление на транспорте, прием
                2022
              </td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBFVacant">2</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">0</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">23.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии наземного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                23.02.01 Организация перевозок и управление на транспорте, прием
                2021
              </td>
              <td itemProp="eduCourse">4</td>
              <td itemProp="eduForm">очная </td>
              <td itemProp="numberBFVacant">2</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">0</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2024</td>
              <td itemProp="eduCourse">1</td>
              <td itemProp="eduForm">очная </td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2023</td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">4</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2023</td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2022</td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">очная </td>
              <td itemProp="numberBFVacant">2</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">4</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2022</td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">заочная </td>
              <td itemProp="numberBFVacant">1</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">4</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2021</td>
              <td itemProp="eduCourse">4</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">11</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">1</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">26.02.03 Судовождение, прием 2020</td>
              <td itemProp="eduCourse">5</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">6</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.05 Эксплуатация судовых энергетических установок, прием
                2024
              </td>
              <td itemProp="eduCourse">1</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">3</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">3</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.05 Эксплуатация судовых энергетических установок, прием
                2023
              </td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">5</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.05 Эксплуатация судовых энергетических установок, прием
                2022
              </td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">1</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">5</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.05 Эксплуатация судовых энергетических установок, прием
                2022
              </td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">5</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.05 Эксплуатация судовых энергетических установок, прием
                2021
              </td>
              <td itemProp="eduCourse">4</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">15</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">2</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики, прием 2024
              </td>
              <td itemProp="eduCourse">1</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">1</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">0</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики, прием 2023
              </td>
              <td itemProp="eduCourse">2</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">1</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">5</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики, прием 2022
              </td>
              <td itemProp="eduCourse">3</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">0</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">5</td>
            </tr>
            <tr itemProp="vacant">
              <td itemProp="eduCode">26.00.00</td>
              <td itemProp="eduName">
                «Техника и технологии кораблестроения и водного транспорта»
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduProf">
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики, прием 2021
              </td>
              <td itemProp="eduCourse">4</td>
              <td itemProp="eduForm">очная</td>
              <td itemProp="numberBFVacant">2</td>
              <td itemProp="numberBRVacant">-</td>
              <td itemProp="numberBMVacant">-</td>
              <td itemProp="numberPVacant">0</td>
            </tr>
            <tr itemProp="vacant">
              <td>итого</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>57</td>
              <td></td>
              <td></td>
              <td>60</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
});
