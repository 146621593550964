import { Card, DatePicker, Flex } from "antd";
import React, { useEffect } from "react";
import { EmployeeStore } from "../../../store/employee-store";
import locale from "antd/es/date-picker/locale/ru_RU";
import { DaysStore } from "../../../store/days-store";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
export const MakeAnAppointment = observer(() => {
  const { employee, loadEmployee, errorEmployee, isLoadingEmployee } =
    EmployeeStore;
  const navigate = useNavigate();
  const {
    createDays,
    isCreateDaysLoading,
    errorOfCreateingDays,
    isLoadingDays,
    days,
    remooveDay,
    loadDaysByEmployeeId,
  } = DaysStore;

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="makeAnAppointment-wrapper">
      <h1>Для записи на прием выберите сотрудника:</h1>
      <Flex justify="center" gap="middle" wrap="wrap">
        {isLoadingEmployee
          ? "Загрузка..."
          : employee &&
            employee.map((e) => (
              <Card
                key={e.id}
                onClick={() => navigate(`${e.id}`)}
                className="makeAnAppointment-card"
                title={e.post}
              >
                {e.surname + " " + e.name + " " + e.lastname}
                <div>Выберите день недели:</div>
                <DatePicker locale={locale} onChange={onChange} />
              </Card>
            ))}
      </Flex>
    </div>
  );
});
