import React from "react";
import { NavLink } from "react-router-dom";
import Image1 from "../../../../../assets/pdf/educationPage/15. График учебного процесса2024.pdf";
import OOP13 from "../../../../../assets/pdf/educationPage/230201/oop/ОП ППССЗ СВ  23.02.01 Организация перевозок 2022.pdf";
import UP17 from "../../../../../assets/pdf/educationPage/230201/ОП 2022 гп/2. Учебный план очная.pdf";
import UP27 from "../../../../../assets/pdf/educationPage/230201/ОП 2022 гп/2. Учебный план заочная.pdf";
import rp_praktic from "../../../../../assets/pdf/educationPage/РП Практик/РП Практик 2022 ОП.zip";
import vr from "../../../../../assets/pdf/educationPage/Ссылка на методические и иные документы ОП.rar";

export const SPOOrganiz_perevoz_upravlenia_22_230201 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">23.02.01</td>
      <td>
        <NavLink
          itemProp="eduName"
          to="../../educationPrograms/organizationOfTransportation2022"
        >
          Организация перевозок и управление на транспорте (по видам) ФГОС №376
          от 22.04.2014 (в ред. Приказа Минобранауки России от 13.07.2021 №450)
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink
          itemProp="eduProf"
          to="../../educationPrograms/organizationOfTransportation2022"
        >
          Организация перевозок и управление на транспорте (по видам) (прием
          2022)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная-заочная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="opMain"
              href={OOP13}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP17}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (очная)
            </a>
          </li>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationPlan"
              href={UP27}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (заочная)
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/organizationOfTransportation2022"
            >
              Скачать РП
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image1}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={rp_praktic}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={vr}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
};
