import React, { useEffect, useState } from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import axios from "axios";
import vacancies from "../../../../assets/pdf/vacancies/приказ 468.pdf";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { NavPanel } from "../../../ui/nav-panel/nav-panel";
import { paginate } from "../../../../utils/utils";
import { PaginationComponent } from "../../../common";
export const Vacancies = () => {
  const [vacanciesItems, setVacanciesItems] = useState([
    {
      name: "Преподаватель математики на 0,45 ставки (328ч)",
      schedule: "",
      experience: "от 1 года",
      salary: { from: "9 957", to: "9 957" },
      responsibility:
        "Обучение курсантов в соответствии с требованиями федеральных государственных образовательных стандартов по специальностям среднего профессионального образования и индивидуальным планом.",
      requirement:
        "Высшее образование или среднее профессиональное образование по направлению подготовки Образование и педагогика или в области, соответствующей преподаваемому предмету,  либо высшее образование или среднее профессиональное образование и дополнительное профессиональное образование по направлению деятельности в образовательном учреждении",
    },
    {
      name: "Воспитатель (командир роты)",
      schedule: "1–3 года",
      experience: "по графику",
      salary: { from: "28.000", to: "" },
      responsibility: `Планирование воспитательной работы с группой обучающихся.

Организация социально и личной деятельности курсантов роты.

Организационно-методическое обеспечение воспитательного процесса в группе обучающихся.

Ведение и учет дистанционной практики.

Обеспечение санитарного содержания жилых помещений, сохранности имущества.

Организация несения дежурно-вахтенной службы.

Организация питания обучающихся.

Организация социально и личностно значимой деятельности курсантов роты.

Осуществление контроля выполнения курсантами правил внутреннего распорядка, распорядка дня, правил проживания в общежитии и других локальных актов.`,
      requirement: "Высшее / среднее педагогическое или военное образование",
    },
    {
      name: "Специалист по охране труда",
      schedule: "нормированный день",
      experience: "от 3 лет",
      salary: { from: "30 274", to: "40 274" },
      responsibility: `Внедрение и обеспечение функционирования системы управления охраной труда в Филиале;

Обеспечение подготовки работников Филиала в области охраны труда;

Взаимодействие с представительными органами работников по вопросам условий и охраны труда, а также согласование локальной документации по вопросам охраны труда;

Контроль за соблюдением требований охраны труда в Филиале;

Контроль за соблюдением условий труда на рабочих местах;

Контроль за безопасным ведением работ, состоянием помещений, тренажеров, оборудования столовой, механизмов, станков, инструментов, их соответствиям техники безопасности и санитарно-гигиеническим правилам

Разработка и внедрение мероприятий  направленных на устранения причин травматизма и заболеваемости;

Вводный инструктаж;

Участие в расследовании и  учет несчастных случаев;

Выдача предписания по устранению выявленных недостатков.`,
      requirement: `Диплом техносферная безопасность

Удостоверение (охрана труда программа А,Б, СИЗ, охрана труда первая помощь)`,
    },
    {
      name: "Рабочий по комплексному обслуживанию и ремонту зданий",
      schedule: "нормированный день",
      experience: "1–3 года",
      salary: { from: "25 000", to: "25 000" },
      responsibility: `​​​​​​​Выполнение работ по текущему, косметическому и восстановительному ремонту зданий, внутренних помещений и сооружений;

Штукатурные и молярные работы.`,
      requirement: "Среднее или среднее профессиональное образование",
    },
    {
      name: "Кухонный работник",
      schedule: "нормированный день",
      experience: "на требуется",
      salary: { from: "25 000", to: "25 000" },
      responsibility: `По заданию зав. производством ОКП производит выгрузку продукции из тары, внутрицеховую транспортировку сырья, полуфабрикатов, продуктов, посуды, инвентаря, заполняет котлы водой, включает плиты, обеспечивает доставку полуфабрикатов и сырья в производственные цеха, готовой продукции к раздаче, открывает мешки с продуктами, вскрывает стеклянные ,жестяные консервные банки, устанавливает подносы при комплекта обедов, столовых приборов ,хлеба,               тарелок с холодными закусками, стаканов с напитками на подносы, производит сбор пищевых отходов, соблюдает санитарно-гигиенические требования.`,
      requirement: "",
    },
  ]);

  /*   useEffect(() => {
    const apiUrl = "https://api.hh.ru/vacancies?employer_id=5263412";

    axios.get(apiUrl).then((resp) => {
      const vac = resp.data.items;
      setVacanciesItems(vac);
    });
  }, [setVacanciesItems]);
 */
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const vacansiesCrop = paginate(vacanciesItems, currentPage, pageSize);
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <NavPanel
        isActive={isActive}
        setIsActive={setIsActive}
        btnOneText={"Общая информация"}
        btnTwoText={"Все вакансии"}
      />
      {isActive ? (
        <section>
          <h1>
            Ищете работу? Мы вас ждем! Обращайтесь в приемную 8 (347) 215-14-00
          </h1>
          <a
            style={{
              color: "#1B386B",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            itemProp="educationRpd"
            href={"https://www.superjob.ru/"}
            target={"_blank"}
            rel="noreferrer"
          >
            Также найти вакансии Уфимского филиала ВГУВТ вы можете на
            https://www.superjob.ru/
          </a>
          <h2>
            <b>
              Объявление о конкурсном отборе на замещение вакантных должностей
              профессорско-преподавательского состава
            </b>
          </h2>
          <p>
            Уфимский филиал ФГБОУ ВО «Волжский государственный университет
            водного транспорта» в соответствии с Приказом Министерства
            образования и науки РФ от 23 июля 2015 года №749 объявляет
            конкурсный отбор на замещение вакантных должностей
            профессорско-преподавательского состава: Старший преподаватель – 0,4
            ст. Профессор – 0,17 ст.
          </p>
          <p>
            <b>
              Квалификационные требования по должностям педагогических
              работников:
            </b>
          </p>
          <p>
            Все кандидаты, претендующие на замещение должностей ППС, должны
            соответствовать квалификационным требованиям, установленным приказом
            Министерства здравоохранения и социального развития РФ от 11 января
            2011 г. №1н «Об утверждении Единого квалификационного справочника
            должностей руководителей, специалистов и служащих, раздел
            «Квалификационные характеристики должностей руководителей и
            специалистов высшего профессионального и дополнительного
            профессионального образования».
          </p>
          <p>
            <i>
              Квалификационные требования по должности старшего преподавателя:
            </i>
          </p>
          <p>
            - высшее образование, стаж научно-педагогической работы не менее 3
            лет, при наличии ученой степени кандидата наук стаж
            научно-педагогической работы не менее 1 года.
          </p>
          <p>
            <i>Квалификационные требования по должности профессора:</i>
          </p>
          <p>
            - высшее образование, ученая степень доктора наук и стаж
            научно-педагогической работы не менее 5 лет или ученое звание
            профессора.
          </p>
          <p>
            Прием заявлений для участия в конкурсе осуществляется по адресу: г.
            Уфа, ул. Ахметова, д. 275, отдел кадров.
          </p>
          <p>
            Срок подачи заявления для участия в конкурсе не менее одного месяца
            со дня размещения объявления о конкурсе на сайте организации.
          </p>
          <p>
            Дата и место проведения конкурса: 29 августа 2024 года, г. Уфа, ул.
            Ахметова, д. 275 учебный корпус, каб. №20.
          </p>
          <ul>
            <li>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                style={{ color: "#1B386B", "margin-left": "5px" }}
                itemProp="educationRpd"
                href={vacancies}
                target={"_blank"}
                rel="noreferrer"
              >
                Приказ о проведении конкурса
              </a>
            </li>
          </ul>
        </section>
      ) : (
        <section>
          {vacansiesCrop.length &&
            vacansiesCrop.map((v) => (
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: "#fff",
                  marginBottom: 20,
                }}
                key={v.id}
                gap={20}
              >
                <Collapse.Panel header={v.name} key={v.id}>
                  <p>
                    <b>Требуемый опыт работы:</b> {v.experience}
                  </p>

                  <p>
                    <b>График работы:</b> {v.schedule}
                  </p>

                  <p>
                    <b>Уровень дохода:</b> от {v.salary.from}
                    {v.salary.to !== null ? ` — до ${v.salary.to}` : ""} руб.
                  </p>

                  <p>
                    <b>Освные должностные обязанности:</b> {v.responsibility}
                  </p>
                  {v.requirement && (
                    <p>
                      <b>Требования к кандидату:</b> {v.requirement}
                    </p>
                  )}

                  <p>
                    <b>Место работы (адрес):</b> Республика Башкортостан, г.Уфа,
                    ул. Ахметова, 275
                  </p>

                  <p>
                    <b>Контактное лицо:</b> Бармут Лиля Николаевна Телефон:
                    (347) 215-14-00 доп. 310 Email: ok@uf-vsuwt.ru
                  </p>
                </Collapse.Panel>
              </Collapse>
            ))}
          {vacanciesItems.length <= pageSize ? null : (
            <PaginationComponent
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              news={vacanciesItems}
            />
          )}
        </section>
      )}
    </>
  );
};
