import React from "react";
import classes from "./style/nav-panel.module.scss";
export const NavPanel = ({
  isActive,
  setIsActive,
  btnOneText,
  btnTwoText,
  center = "",
}) => {
  return (
    <div
      className={
        center
          ? classes.doc_navpanel + " " + classes.justifyCenter
          : classes.doc_navpanel
      }
    >
      <span
        onClick={() => setIsActive(true)}
        className={isActive ? classes.isActive : ""}
      >
        {btnOneText}
      </span>
      <span
        onClick={() => setIsActive(false)}
        c
        className={!isActive ? classes.isActive : ""}
      >
        {btnTwoText}
      </span>
    </div>
  );
};
