import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
export const Feedback = observer(() => {
  return (
    <>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Обратная связь
      </h2>
      <p>Обратная связь для сообщений о фактах коррупции</p>
      <p>Тел.доверия: 8(347) 215-14-00 # 7</p>
      <p>
        Также вам доступна виртуальная приемная, которая расположена в
        "Контакты" - "Виартуальная приемная"
      </p>{" "}
    </>
  );
});
