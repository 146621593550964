import React from "react";
export const EducationLvlRequirements = () => {
  return (
    <main className="flex jcc">
      <section className="common__container">
        <h1 className="mb-1em">
          Требования к уровню образования, которое необходимо для поступления в
          Федеральное государственное бюджетное образовательное учреждение
          высшего образования «Волжский государственный университет водного
          транспорта» объявляет прием в соответствии с лицензией на
          осуществление образовательной деятельности в 2024-2025 учебном году
        </h1>
        <table className="mb-1em">
          <tbody>
            <tr>
              <td>
                Наименование профессии, специальности, направления подготовки
              </td>
              <td>Форма обучения</td>
              <td>Необходимый уровень образования</td>
            </tr>

            <tr>
              <td>26.02.05 Эксплуатация судовых энергетических установок</td>
              <td>Очная</td>
              <td>Основное общее</td>
            </tr>
            <tr>
              <td>
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики
              </td>
              <td>Очная</td>
              <td>Основное общее</td>
            </tr>
            <tr>
              <td>26.02.03 Судовождение (углубленная подготовка)</td>
              <td>Очная / Заочная</td>
              <td>Основное общее, среднее общее</td>
            </tr>
          </tbody>
        </table>
        <h1 className="mb-1em">
          ПЕРЕЧЕНЬ специальностей среднего профессионального образования, по
          которым Федеральное государственное бюджетное образовательное
          учреждение высшего образования «Волжский государственный университет
          водного транспорта» объявляет прием в соответствии с лицензией на
          осуществление образовательной деятельности в 2024-2025 учебном году
        </h1>
        <table>
          <tbody>
            <tr>
              <td>
                Наименование профессии, специальности, направления подготовки
              </td>
              <td>Форма обучения и нормативный срок обучения</td>
              <td>Квалификация</td>
            </tr>

            <tr>
              <td>26.02.05 Эксплуатация судовых энергетических установок</td>
              <td>
                Очная
                <br />3 года 10 месяцев
              </td>
              <td>Техник-судомеханник</td>
            </tr>
            <tr>
              <td>
                26.02.06 Эксплуатация судового электрооборудования и средств
                автоматики
              </td>
              <td>
                Очная <br />3 года 10 месяцев
              </td>
              <td>Техник-электромеханник</td>
            </tr>
            <tr>
              <td>26.02.03 Судовождение (углубленная подготовка)</td>
              <td>
                Очная на базе 9 классов
                <br />4 года 6 месяцев <br />
                Заочная на базе 9 классов
                <br /> 5 лет 4 месяца <br /> Заочная на базе 11 классов
                <br /> 4 года 4 месяца
              </td>
              <td>
                Старший техник-судоводитель с правом эксплуатации судовых
                энергетических установок
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="common__container"></section>
    </main>
  );
};
