import React from "react";

import { observer } from "mobx-react-lite";
import { EducationContainer } from "../../common";
import { Tabs } from "antd";
import { CreateNewsPage } from "../create-news-page/create-news-page";
import { CreateMeeting } from "../create-meeting/create-meeteng";

export const AdminPanel = observer(() => {
  const items = [
    {
      key: "1",
      label: "Редактор новостей",
      children: <CreateNewsPage />,
    },
    {
      key: "2",
      label: "Запись на прием",
      children: <CreateMeeting />,
    },
  ];

  return (
    <EducationContainer
      title={"Панель администратора"}
      classes={"admin-wrapper"}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </EducationContainer>
  );
});
