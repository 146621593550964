import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";
export const Target = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <div
        className={` ${toggleClassName(
          "min-height",
          "min-height-white",
          "min-height-black",
          "min-height-contrast"
        )}`}
      >
        <h1 className="education__container-title">
          С 1 мая 2024 года вступили в силу изменения в Федеральный закон от
          29.12.2012 № 273-Ф3
        </h1>
        <h2>«Об образовании в Российской Федерации» (ред. от 19.10.2023)</h2>
        <p>
          Целевое обучение — это программа подготовки специалистов по
          направлениям от государственного ведомства, учреждения или от
          предприятия. После завершения учебы в университете выпускник
          обязательно должен отработать на предприятии оговоренный срок.
        </p>
        <p>
          Программа нацелена на то, чтобы ликвидировать дефицит кадров в тех
          областях, где он на данный момент есть. Поэтому от ее реализации
          выигрывает и государство, и работодатель, и абитуриент.
        </p>
        <p>Основные изменения коснутся:</p>
        <ul>
          <li>порядка заключения договора;</li>
          <li>содержания договора;</li>
          <li>
            способа поиска заказчика на целевое обучение; перечня заказчиков
            целевого обучения.
          </li>
        </ul>
        <div className="common__container">
          <TableWrapper
            title="Что изменится в правилах целевого приема и обучения"
            children={
              <table className="table">
                <tbody>
                  <tr>
                    <td>Основные положения в законе</td>
                    <td>Было до 01.05.2024</td>
                    <td>Будет после 01.05.2024</td>
                  </tr>
                  <tr>
                    <td>Срок заключения договора</td>
                    <td>До зачисления абитуриента</td>
                    <td>
                      После зачисления абитуриента и до начала учебного года
                    </td>
                  </tr>
                  <tr>
                    <td>Стороны договора</td>
                    <td>
                      Основные стороны — гражданин и заказчик. Третьей стороной
                      может выступать образовательная организация и
                      работодатель.
                    </td>
                    <td>
                      Основные стороны — гражданин, заказчик и образовательная
                      организация, которая следит за успеваемостью студента:
                      прохождением практики, В качестве третьей стороны можно
                      включать работодателя.
                    </td>
                  </tr>
                  <tr>
                    <td>Количество заключаемых договоров</td>
                    <td>Несколько</td>
                    <td>
                      Один договор — абитуриен подает заявление в один вуз на
                      одну образовательную программу.
                    </td>
                  </tr>
                  <tr>
                    <td>Срок отработки после обучения</td>
                    <td>Минимальный срок — три года</td>
                    <td>
                      Минимальный срок — три года. Максимальный срок - пять лет.
                    </td>
                  </tr>
                  <tr>
                    <td>Способ поиска заказчика на целевое обучение</td>
                    <td>
                      Абитуриент самостоятельно ищет информацию о потенциальном
                      заказчике на разных ресурсах, договаривается с ним о
                      целевом обучении.
                    </td>
                    <td>
                      Заказчик размещает предложение о целевом обучении на
                      единой государственной платформ «Работа России».
                      Информация доступна всем желающим.
                    </td>
                  </tr>
                </tbody>
              </table>
            }
          />
        </div>
        <p>С 1 мая 2024 года к уже имеющимся категориям добавят:</p>
        <ul>
          <li>
            резидентов территорий опережающего развития, особых экономических
            зон, зон территориального развития, Арктической зоны, свободного
            порта Владивосток;
          </li>
          <li>
            участников проекта «Сколково», международного медицинского кластера,
            инновационных научно-технологических центров, военного
            инновационного технополиса «Эра».
          </li>
        </ul>
        <p>
          Заказчиками не могут быть организации и граждане, прямо или косвенно
          связанные с недружественными странами, а также иноагенты.
        </p>
        <p>
          Перечень недружественных стран утвержден Правительством РФ. Реестр
          иностранных агентов публикует Минюст.
        </p>
        <p>Как искать предложения о целевом обучении</p>
        <p>
          В России Минтрудом разработана и действует Единая цифровая платформа
          «Работа России». Это большая база вакансий и резюме соискателей.
        </p>
        <p>
          С 1 мая 2024 года заказчики целевого обучения должны будут публиковать
          свои предложения на платформе. Информация будет находиться в свободном
          доступе. Абитуриент сможет выбрать предложение в любом регионе страны.
          В ближайшее время Минобрнауки дополнительно разработает подзаконные
          акты, которые подробнее разъяснят механизм размещения предложений.
        </p>
        <p>Какую информацию абитуриент найдет в предложении заказчика:</p>
        <ul>
          <li>кем и где он будет работать после обучения;</li>
          <li>где будет проходить целевое обучение;</li>
          <li>какие меры поддержки он получит во время учебы;</li>
          <li>
            какие требования предъявляет заказчик к студенту, с которым
            заключает договор, и какие санкции предусмотрены за их несоблюдение;
          </li>
          <li>как связаться с заказчиком.</li>
        </ul>
        <p>
          Абитуриент выбирает предложение и подает заявку - электронно (через
          «Госуслуги») или в бумажном варианте.
        </p>
        <p>Преимущества целевого обучения для абитуриента:</p>
        <ul>
          <li>
            Поступление в образовательную организацию по отдельному конкурсу для
            целевиков;
          </li>
          <li>Бесплатное обучение;</li>
          <li>Прохождение практики на своем предприятии;</li>
          <li>100-процентное трудоустройство после обучения;</li>
          <li>
            Обеспечение жильем и стипендией на время учебы в соответствии с
            договором о целевом обучении.
          </li>
        </ul>
        <p>Преимущества целевого обучения для заказчика/работодателя:</p>
        <ul>
          <li>Гарантия ликвидации дефицита кадров;</li>
          <li>
            Подготовка квалифицированных кадров уже во время обучения в
            соответствии с требованиями работодателя.
          </li>
        </ul>
        <p>
          Санкции за неисполнение обязательств по освоению образовательной
          программы и осуществлению трудовой деятельности в течение 3 лет:{" "}
          <b>Придется компенсировать все расходы.</b>
        </p>
      </div>
    </section>
  );
});
