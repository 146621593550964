import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
export const AboutBranch = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <h1 className="education__container-title">О филиале</h1>
      <div className="education__container-content">
        <p>
          Уфимский филиал Федерального государственного бюджетного
          образовательного учреждения высшего образования «Волжский
          государственный университет водного транспорта» (Уфимский филиал ФГБОУ
          ВО «ВГУВТ») создан 31.08.2017 на основании распоряжения Федерального
          агентства морского и речного транспорта №АП-193-р от 16.08.2017.
          Филиал является обособленным структурным подразделением университета,
          реализующим образовательные программы подготовки специалистов среднего
          звена, дополнительного образования и профессионального обучения.
          Руководит филиалом директор Ахмадеева Фарида Шариповна, Заслуженный
          работник образования Республики Башкортостан.
        </p>
        <p>
          Уфимский филиал ФГБОУ ВО «ВГУВТ» является единственным в регионе
          образовательным учреждением, которое ведет подготовку специалистов
          водного транспорта и обеспечивает квалифицированными кадрами все
          судоходные компании Республики Башкортостан, а также предприятия
          транспортной отрасли восточной и западной Сибири, европейской части и
          северо-западного региона России. История филиала насчитывает более 50
          лет, за которые подготовлено свыше 7 тысяч квалифицированных
          специалистов отрасли.{" "}
        </p>
        <p>
          В октябре 2019 года филиал прошел успешно государственную аккредитацию
          по всем реализуемым образовательных программ среднего
          профессионального образования:
        </p>
        <b>
          <ul>
            <li>26.02.03 Судовождение</li>
            <li>26.02.05 Эксплуатация судовых энергетических установок</li>
            <li>
              26.02.06 Эксплуатация судового электрооборудования и средств
              автоматики
            </li>
            <li>
              23.02.01 Организация перевозок и управление на транспорте (по
              видам транспорта).
            </li>
          </ul>
        </b>
        <p>
          Уфимский филиал ФГБОУ ВО «ВГУВТ» имеет необходимые здания, сооружения
          и помещения с кабинетами и лабораториями и необходимым оборудованием,
          для качественного проведения учебных занятий, выполнения практических
          и лабораторных работ, располагает высококвалифицированными
          педагогическими кадрами.{" "}
        </p>
        <p>
          Ученым Советом ФГБОУ ВО «ВГУВТ» утверждены приоритетные направлениями
          развития филиала до 2025 года:{" "}
        </p>
        <ul>
          <li>Обеспечение качества образования</li>
          <li>
            Развитие электронного обучения и дистанционных образовательных
            технологий{" "}
          </li>
          <li>
            Модернизация материально-технического обеспечения образовательного и
            управленческих процессов
          </li>
          <li>
            Повышение эффективности воспитательной работы на основе
            личностно-ориентированных технологий, направленной на формирование
            гражданско-патриотической позиции и осознанного поведения,
            базирующегося на традиционных общечеловеческих ценностях
          </li>
          <li>
            Продвижение филиала на рынке образовательных услуг и формирование
            позитивного имиджа филиала
          </li>
          <li>
            Повышение эффективности функционирования филиала посредством
            внедрения цифровых технологий и системы менеджмента качества{" "}
          </li>
          <li>
            Повышение объема и эффективности использования финансовых ресурсов.{" "}
          </li>
        </ul>
        <p>
          За время существования учебного заведения курсанты и сотрудники
          неоднократно занимали призовые места во Всероссийских, республиканских
          и городских олимпиадах, конкурсах и соревнованиях. По результатам
          мониторинга качества подготовки кадров среднего профессионального
          образования Российской Федерации 2022 года филиал имеет самые высокие
          результаты среди всех подведомственных образовательных организаций
          Федерального агентства морского и речного транспорта, реализующих
          программы подготовки специалистов среднего звена и на 3 месте среди
          образовательных учреждений Республики Башкортостан.
        </p>
        <p>
          В 2023 году по результатам конкурса Федерального агентства морского и
          речного транспорта «Лидер отрасли» филиал отмечен дипломом конкурса в
          номинации «Лучшее учебное заведение среднего образования».
        </p>
      </div>
    </section>
  );
});
