import React from "react";
import { Carousel } from "antd";
import portnyagina from "../../../../assets/img/interview/portnyagina/1.jpg";
import portnyagina1 from "../../../../assets/img/interview/portnyagina/2.jpg";
import portnyagina3 from "../../../../assets/img/interview/portnyagina/3.JPG";

const customDotStyle = {
  width: "12px",
  height: "12px",
  backgroundColor: "red", // Измените цвет точек здесь
  borderRadius: "50%",
};

export const Portnyagina = () => {
  return (
    <section className="interview-container">
      <h1>ВЫПУСКНИК – КАПИТАН-ЖЕНЩИНА</h1>
      <div className="image-wrapper">
        <img src={portnyagina} alt="Фото уплыло:(" height="100%" width="100%" />
        <p>
          <i>
            «Если знаешь досконально свое дело, свою профессию, вопрос о поле
            становится не важен» Портнягина Ирина Валерьевна
          </i>
        </p>
      </div>
      <p>
        Портнягина Ирина Валерьевна – выпускник 1989 года, капитан пассажирских
        теплоходов Бельского речного пароходства, в настоящее время,
        преподаватель специальных судоводительских дисциплин Уфимского филиала
        ФГБОУ ВО «ВГУВТ».
      </p>
      <p>
        <strong>
          Как получилось, что Вы выбрали профессию, которые многие считают
          абсолютно «мужской»?
        </strong>
      </p>
      <p>
        - Можно сказать, что я потомственный речник, мой папа – капитан
        теплохода «Джамбул», с детства для меня река и теплоходы родные.
      </p>
      <p>
        <strong>С чего начинался Ваш путь в капитаны?</strong>
      </p>
      <p>
        - С должности рулевого. Потом, окончив Уфимское речное училище – стала
        помощником капитана, работала, набиралась опыта, получила высшее
        профессиональное образование в Московской академии водного транспорта.
        Работала на пассажирских теплоходах 305 проекта «Владимир Зайцев»,
        «Борис Полевой», «Салават Юлаев».
      </p>
      <p>
        <strong>Что самое трудное в работе капитана?</strong>
      </p>
      <p>
        - Подбор и создание экипажа судна. Люди работают в замкнутом
        пространстве, команда должна быть очень слаженной. Капитан отвечает за
        каждого, в любой нештатной ситуации.
      </p>
      <p>
        <strong>А были нештатные ситуации в Вашей работе?</strong>
      </p>
      <p>
        - Конечно. И шторма были. Или вот такой был момент, когда отказало
        рулевое управление при заходе в Чебоксарский шлюз. Устранили неполадку
        быстро, минут за 20, а времени потеряно было почти день. Судно встало у
        стены шлюза, лето, жарко, пассажиры переживают.
      </p>
      <p>
        <strong>
          Все-таки женщина-капитан – это пока нечастое явление. Как к Вам
          относились капитаны-мужчины?
        </strong>
      </p>
      <p>
        - Если знаешь досконально свое дело, свою профессию, вопрос о поле
        становится не важен. В нашем пароходстве все знали моего отца, потом
        узнали меня и никаких вопросов или проблем не возникало. За пределами
        республики, капитаны из других пароходств иногда вредничали: не
        пропускали, когда просила обгон, могли не отвечать по радиосвязи, когда
        слышали женский голос. Но это были единичные случаи.
      </p>

      <div className="carousel-wrapper">
        <Carousel dotStyle={customDotStyle} autoplay={true} dotPosition="top">
          <div>
            <img src={portnyagina3} alt="Фото уплыло:(" />
          </div>
          <div>
            <img src={portnyagina1} alt="Фото уплыло:(" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};
