import React from "react";
import { TableWrapper } from "../../../common";
export const PurposeSport = () => {
  return (
    <TableWrapper title="Сведения о наличии объектов спорта">
      <table>
        <tbody>
          <tr>
            <td>Наименование объекта</td>
            <td>Адрес места нахождения</td>
            <td>Площадь, м2</td>
            <td>Количество мест</td>
            <td>
              Приспособленность для использования инвалидами и лицами с
              ограниченными возможностями здоровья
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">Спортивный зал</td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">240,9</td>
            <td itemProp="objCnt">25</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">
              Крытая спортивная площадка стадиона широкого профиля с элементами
              полосы препятствий
            </td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">478,5</td>
            <td itemProp="objCnt">35</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">
              Открытый стадион широкого профиля с элементами полосы препятствий
              (с оборудованной лыжной трассой и велосипедной дорожкой)
            </td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">2362,0</td>
            <td itemProp="objCnt">100</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">Зал атлетической гимнастики</td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">110,0</td>
            <td itemProp="objCnt">20</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>

          <tr itemProp="purposeSport">
            <td itemProp="objName">Зал спортивных единоборств</td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">146,0</td>
            <td itemProp="objCnt">30</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">Хоккейная коробка</td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">1098,0</td>
            <td itemProp="objCnt">30</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
          <tr itemProp="purposeSport">
            <td itemProp="objName">Стрелковый тир</td>
            <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
            <td itemProp="objSq">61,0</td>
            <td itemProp="objCnt">20</td>
            <td itemProp="objOvz">
              Обеспечена возможность беспрепятственного доступа для слабовидящих
              и слабослышащих, а также для лиц с инвалидностью по общим
              заболеваниям.
            </td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};
