import React from "react";
export const ReceptionTiming = () => {
  return (
    <section>
      <h1>Информация о сроках проведения приема</h1>
      <p>
        Прием на <b>очную</b> форму обучения <b>с 20 июня по 15 августа</b>, на{" "}
        <b>заочную</b> форму обучения <b>с 20 июня по 15 ноября</b>. Прием
        документов на <b>очную</b> форму обучения по договорам{" "}
        <b>с 20 июня по 23 августа</b>, на <b>заочную</b> форму обучения
        <b> с 20 июня по 28 ноября</b> .
      </p>
    </section>
  );
};
