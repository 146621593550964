import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";
import { InfoAboutPrepodSudov } from "./info-about-prepod-sudov";
import { InfoAboutPrepodEnerget } from "./info-about-prepod-energet";
import { InfoAboutPrepodElectroobor } from "./info-about-prepod-electroobor";
import { InfoAboutPrepodPerevozki } from "./info-about-prepod-perevozki";

export const Employees = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <>
        {" "}
        <h3
          className={`common__container-title ${toggleClassName(
            "title",
            "title-white",
            "title-black",
            "title-contrast"
          )}`}
        >
          Информация о составе педагогических (научно-педагогических) работников
          Уфимского филиала ФГБОУ ВО "ВГУВТ"
        </h3>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>Ф.И.О</td>
                <td>Должность преподавателя, категория </td>
                <td>
                  Перечень преподаваемых учебных предметов, курсов, дисциплин
                  (модулей){" "}
                </td>
                <td>Уровень образования </td>
                <td>Квалификация</td>
                <td>
                  Наименование направления подготовки и (или) специальности
                </td>
                <td>Ученая степень (при наличии)</td>
                <td>Ученое звание (при наличии)</td>
                <td>
                  Сведения о повышении квалификации и (или) профессиональной
                  переподготовке педагогического работника (при наличии)
                </td>
                <td>Общий стаж работы</td>
                <td>Стаж работы пед. раб./раб.по спец.</td>
                <td>
                  Наименование образовательных программ, в реализации которых
                  участвует педагогический работник
                </td>
              </tr>
              <InfoAboutPrepodSudov />
              <InfoAboutPrepodEnerget />
              <InfoAboutPrepodElectroobor />
              <InfoAboutPrepodPerevozki />
            </tbody>
          </table>
        </TableWrapper>
      </>
    </div>
  );
});
