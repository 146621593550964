import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";
import dogovorUfanet from "../../../../assets/pdf/objects/Договор оказания услуг связи, ТВ и интернета.pdf";
import { ReactComponent as DOC } from "../../../../assets/svg/office/word.svg";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import id_lani from "../../../../assets/pdf/objects/44-96-23 ИД Лань_copy.pdf";
import ebs_lani from "../../../../assets/pdf/objects/44-97-23 ЭБС Лань_copy.pdf";
import urait from "../../../../assets/pdf/objects/Контракт Юрайт 2023-24.pdf";
import morkniga from "../../../../assets/pdf/objects/Контракт № 44-65-23 Оказание услуг по предоставлению доступа к электронной библиотечной системе Моркнига.PDF";
import znanium from "../../../../assets/pdf/objects/Контракт № 44-99-23 Знаниум_Просвещение_copy.pdf";
import ebs_znanium from "../../../../assets/pdf/objects/ЛИЦЕНЗИОННЫЙ ДОГОВОР ЭБС ЗНАНИУМ ВГУВТ (Просвещение) 09.08.2023 итог.doc";
import { PurposeCab } from "./purpose-cab";
import { PurposePrac } from "./purpose-prac";
import { PurposeSport } from "./purpose-sport";
import smeta from "../../../../assets/pdf/documentPage/Смета общ с 01.07.24.pdf";
import prikaz_286 from "../../../../assets/pdf/documentPage/Приказ о стоим общ 286 от 20.06.24.pdf";
export const Objects = observer(() => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Материально-техническое обеспечение и оснащенность образовательного
        процесса. Доступная среда
      </h1>
      <PurposeCab />
      <PurposePrac />
      <TableWrapper title="Сведения о наличии оборудованных библиотек">
        <table>
          <tbody>
            <tr>
              <td>Наименование объекта </td>
              <td>Адрес места нахождения </td>
              <td>Площадь, м2 </td>
              <td>Количество мест </td>
              <td>
                Приспособленность для использования инвалидами и лицами с
                ограниченными возможностями здоровья{" "}
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
            </tr>
            <tr itemProp="purposeLibr">
              <td itemProp="objName">
                Библиотека с читальным залом (учебный корпус)
              </td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">238,4</td>
              <td itemProp="objCnt">64</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям.
              </td>
            </tr>
            <tr itemProp="purposeLibr">
              <td itemProp="objName">Читальный зал (общежитие)</td>
              <td itemProp="objAddress">г. Уфа, ул. Ахметова, 275</td>
              <td itemProp="objSq">36,0</td>
              <td itemProp="objCnt">20</td>
              <td itemProp="objOvz">
                Обеспечена возможность беспрепятственного доступа для
                слабовидящих и слабослышащих, а также для лиц с инвалидностью по
                общим заболеваниям.
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <PurposeSport />
      <p itemProp="purposeFacil">
        В соответствии с Федеральным законом от 29.12.2012 N 273-ФЗ (ред. от
        27.12.2019) «Об образовании в Российской Федерации» обучающимся,
        осваивающим основные образовательные программы, бесплатно
        предоставляются в пользование на время получения образования учебники и
        учебные пособия, а также учебно-методические материалы, средства
        обучения и воспитания.
      </p>
      <p>
        Уфимский филиал ФГБОУ ВО «ВГУВТ» обладает обширной базой средств
        обучения и воспитания, в которую входят: приборы и оборудование (в том
        числе тренажеры, лабораторные стенды и профессиональное оборудование,
        станки), включая спортивное оборудование и инвентарь, инструменты,
        музыкальные инструменты (фортепиано, синтезатор, инструменты духового
        оркестра, инструментального ансамбля), учебно-наглядные пособия,
        компьютеры (144 компьютеров, 27 видеопроекторов, 5 интерактивных досок),
        информационно-телекоммуникационные сети, аппаратно-программные и
        аудиовизуальные средства, печатные и электронные образовательные и
        информационные ресурсы и иные материальные объекты, необходимые для
        организации образовательной деятельности.{" "}
      </p>
      <p itemProp="purposeFacilOvz">
        В целях обеспечения учебного процесса для обучающихся с ограниченными
        возможностями здоровья, имеющими нарушения опорно-двигательного
        аппарата, в филиале созданы следующие условия:
      </p>
      <ul>
        <ul>
          В здании учебного корпуса:
          <li>
            для предоставления возможности беспрепятственного перемещения лиц с
            ОВЗ аудитории для проведения учебных занятий расположены на первом
            этаже (лифт не требуется), ширина дверных проёмов и проходов
            обеспечивает доступ в помещение ЛОВЗ по опорно-двигательному
            аппарату, перепады уровней полов компенсированы инвентарными
            съёмными и несъёмными пандусами, имеется гардероб и место,
            отведённое для собаки-поводыря. Выделенная аудитория оборудована
            расширенным дверным проемом, условиями для въезда на коляске,
            световыми маяками, дублирующими звонки на урок и перемену,
            компьютером с выходом в Интернет через локальную проводную сеть,
            ЖК-телевизором, проецирующим изображение экрана ПК, средствами для
            проведения видео-конференц-связи. На дверных проемах в помещениях,
            доступных для инвалидов нанесена контрастная маркировка.{" "}
          </li>
          <li>
            Для обучающихся с ограниченными возможностями здоровья, являющихся
            слепыми или слабовидящими установлен информационный киоск с
            адаптированным расписанием.{" "}
          </li>
          <li>
            На входной группе установлена тактильная вывеска с наименованием
            образовательной организации и режимом работы. Для обучающихся с
            ограниченными возможностями здоровья, являющихся слабослышащими или
            глухими установлен информационный киоск с адаптированным
            расписанием, индукционная «петля» для слабослышащих VERT.
          </li>
          <li>
            Расширенные дверные проёмы так же оборудованы в библиотеке,
            санитарно-гигиеническом помещении для инвалидов и лиц с ОВЗ.{" "}
          </li>
        </ul>
        <ul>
          В здании общежития:{" "}
          <li>
            для предоставления возможности беспрепятственного перемещения лиц с
            ОВЗ аудитории для проведения учебных занятий расположены на первом
            этаже (лифт не требуется), ширина дверных проёмов и проходов
            обеспечивает доступ в помещение ЛОВЗ по опорно-двигательному
            аппарату, перепады уровней полов компенсированы инвентарными
            съёмными и несъёмными пандусами. Выделенная аудитория оборудована
            расширенным дверным проемом, условиями для въезда на коляске,
            световыми маяками, дублирующими звонки на урок и перемену,
            компьютером с выходом в Интернет через локальную проводную сеть,
            ЖК-телевизором, проецирующим изображение экрана ПК, средствами для
            проведения видео-конференц-связи. На дверных проемах в помещениях,
            доступных для инвалидов нанесена контрастная маркировка.{" "}
          </li>
          <li>
            Расширенные дверные проёмы так же оборудованы в
            санитарно-гигиеническом помещении для инвалидов и лиц с ОВЗ.{" "}
          </li>
        </ul>
      </ul>
      <h2 itemProp="comNet">
        Информация о доступе к информационным системам и
        информационно-телекоммуникационным сетям, в том числе о доступе к
        информационным системам и информационно-телекоммуникационным сетям,
        приспособленным для использования инвалидами и лицами с ограниченными
        возможностями здоровья
      </h2>
      <p itemProp="comNetOvz">
        Доступ к информационным системам и информационно-коммуникационным сетям
        осуществляется в читальных залах библиотеки филиала, кабинетах для
        самостоятельной работы обучающихся, кабинетах информационных технологий,
        адаптированных для обучающихся с ограниченными возможностями здоровья.
      </p>
      <p>
        Официальный сайт Уфимского филиала ФГБОУ ВО «ВГУВТ», а также электронные
        ресурсы библиотеки адаптированы под нужды слабовидящих обучающихся
        посредством специальной версии. Электронные библиотечные системы «Лань»,
        «Юрайт», «Знаниум» поддерживают версию для слабовидящих.
      </p>
      <h2 itemProp="purposeEios">
        Наличие в организации электронной информационно-образовательной среды
      </h2>
      <p>
        В Уфимском филиале ФГБОУ ВО «ВГУВТ» функционирует электронная
        информационно-образовательная среда, включающая в себя следующие
        элементы:
      </p>
      <ul>
        <li>официальный сайт;</li>
        <li>автоматизированная система управления «Учебный процесс»;</li>
        <li>система дистанционного обучения «Парус»;</li>
        <li>
          читальные залы, кабинеты для самостоятельной работы студентов,
          компьютерные классы.
        </li>
        Электронная информационно-образовательная среда обеспечивает:
        <li>
          доступ к учебным планам, рабочим программам дисциплин (модулей),
          практик, к изданиям электронных библиотечных систем и электронным
          образовательным ресурсам, указанным в рабочих программах;
        </li>
        <li>
          фиксацию хода образовательного процесса, результатов промежуточной
          аттестации и результатов освоения программы;
        </li>
        <li>
          формирование электронного портфолио обучающегося, в том числе
          сохранение работ обучающегося, рецензий и оценок на эти работы со
          стороны любых участников образовательного процесса;
        </li>
        <li>
          взаимодействие между участниками образовательного процесса, в том
          числе синхронное и (или) асинхронное взаимодействие посредством сети
          «Интернет».
        </li>
      </ul>
      <TableWrapper
        title="Информация о наличии электронных образовательных и информационных
        ресурсов по реализуемым в соответствии с лицензией образовательным
        программам"
      >
        <table>
          <thead>
            <tr>
              <td>Наименование</td>
              <td>Количество</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                Количество собственных электронных образовательных и
                информационных ресурсов
              </td>
              <td itemProp="eoisOwn">2</td>
            </tr>
            <tr>
              <td>
                Количество сторонних электронных образовательных и
                информационных ресурсов
              </td>
              <td itemProp="eoisSide">10</td>
            </tr>
            <tr>
              <td>Количество баз данных электронного каталога </td>
              <td itemProp="bdec">5</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h2>
        Перечень электронных и электронных образовательных ресурсов, доступных
        для использования обучающимися:
      </h2>
      <h2>Сторонние электронные образовательные и информационные ресурсы</h2>
      <ol>
        <li>
          <a
            itemProp="erList"
            href="https://minobrnauki.gov.ru"
            target={"_blank"}
            rel="noreferrer"
          >
            minobrnauki.gov.ru
          </a>{" "}
          - Министерство науки и высшего образования Российской Федерации
        </li>
        <li>
          <a
            itemProp="erList"
            href="https://edu.gov.ru"
            target={"_blank"}
            rel="noreferrer"
          >
            edu.gov.ru
          </a>{" "}
          - Министерство просвещения Российской Федерации
        </li>
        <li>
          <a
            href="http://obrnadzor.gov.ru/ru/"
            target={"_blank"}
            rel="noreferrer"
            itemProp="erList"
          >
            obrnadzor.gov.ru/ru
          </a>{" "}
          - Федеральная служба по надзору в сфере образования и науки
        </li>
        <li>
          <a
            itemProp="erList"
            href="http://window.edu.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            window.edu.ru
          </a>{" "}
          - Информационная система "Единое окно доступа к образовательным
          ресурсам"
        </li>
        <li>
          <a
            itemProp="erList"
            href="http://fcior.edu.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            fcior.edu.ru
          </a>{" "}
          - Федеральный центр информационно-образовательных ресурсов
        </li>
        <li>
          <a href="https://www.garant.ru/" target={"_blank"} rel="noreferrer">
            www.garant.ru
          </a>{" "}
          - «ГАРАНТ», информационно-правовой портал{" "}
        </li>
        <li>
          <a
            href="https://www.consultant.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            www.consultant.ru
          </a>
          - «Консультант плюс»- Разработка правовых систем
        </li>
      </ol>
      <h2>Собственные электронные образовательные и информационные ресурсы</h2>
      <ul>
        <li>Единая электронная информационно-образовательная среда ВГУВТ</li>
        <li>
          Информационный ресурс библиотеки филиала: база данных электронного
          каталога учебной литературы (установлена в читальном зале библиотеки);{" "}
        </li>
        <li>
          Электронно-методическая база учебно-методического комплекса и
          конспектов лекций.
        </li>
      </ul>
      <h2>
        Базы данных электронного каталога. Электронные библиотечные системы и
        ресурсы:
      </h2>
      <ol>
        <li>
          Электронный каталог{" "}
          <a
            href="http://lib.vsuwt.ru/marcweb2/"
            target={"_blank"}
            rel="noreferrer"
            itemProp="erListOvz"
          >
            ВГУВТ
          </a>{" "}
          (через Электронный каталог ВГУВТ возможен доступ к электронной
          библиотеке университета и НРУ)
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="https://urait.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            «Юрайт»
          </a>{" "}
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="http://e.lanbook.com"
            target={"_blank"}
            rel="noreferrer"
          >
            «Лань»
          </a>{" "}
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="https://znanium.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            «Знаниум»
          </a>{" "}
        </li>
        <li>
          Электронно – библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="https://www.morkniga.ru/library/"
            target={"_blank"}
            rel="noreferrer"
          >
            "Моркнига"
          </a>{" "}
        </li>
      </ol>
      <h2>
        Документы, подтверждающие доступ к цифровым электронным библиотекам:
      </h2>
      <ol>
        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={id_lani} target={"_blank"} rel="noreferrer">
            Договор 44-96-23 об оказании доступа к "Электронно-библиотечной
            истеме" («Лань»)
          </a>
        </li>

        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={ebs_lani} target={"_blank"} rel="noreferrer">
            Договор 44-97-23 об оказании доступа к "Электронно-библиотечной
            истеме" («Лань»)
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={morkniga} target={"_blank"} rel="noreferrer">
            Контракт № 44-65-23 Оказание услуг по предоставлению доступа к
            электронной библиотечной системе «Моркнига»
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={urait} target={"_blank"} rel="noreferrer">
            Контракт Юрайт 2023-24
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={znanium} target={"_blank"} rel="noreferrer">
            Контракт № 44-99-23 Знаниум Просвещение
          </a>
        </li>
        <li>
          <DOC width={"25px"} height={"25px"} />
          <a href={ebs_znanium} target={"_blank"} rel="noreferrer">
            ЛИЦЕНЗИОННЫЙ ДОГОВОР ЭБС ЗНАНИУМ ВГУВТ (Просвещение) 09.08.2023г.
          </a>
        </li>
        <li>
          <PDF width={"25px"} height={"25px"} />
          <a href={dogovorUfanet} target={"_blank"} rel="noreferrer">
            Договор о подключении к сети "Интернет"
          </a>
        </li>
      </ol>
      <h2>
        Информация об обеспечении доступа в здания образовательной организации
        инвалидов и лиц с ограниченными возможностями здоровья
      </h2>
      <p>
        В целях обеспечения беспрепятственного доступа обучающихся с
        ограниченными возможностями здоровья, имеющих нарушения
        опорно-двигательного аппарата, в учебные помещения и другие помещения в
        филиале созданы следующие условия:
      </p>
      <ul>
        <li>
          перед входом на территорию Уфимского филиала имеется разметка стоянки
          автотранспорта для инвалидов.
        </li>
        <li>
          в здании учебного корпуса имеется приспособленная входная группа для
          инвалидов и лиц с ОВЗ, оборудованная пандусом с поручнями и кнопкой
          вызова персонала, звуковыми и световыми маяками, световыми маяками для
          обозначения габаритов дверного проема, информационным табло,
          обеспечена контрастная окраска дверей и лестниц входной группы. Вход
          оборудован расширенными дверными проёмами, на путях передвижения
          инвалидов демонтированы пороги в дверных проемах, в местах, где по
          техническим причинам отсутствует возможность демонтажа порогов,
          имеются переносные пандусы. В холле оборудована локальная пониженная
          стойка-барьер.{" "}
        </li>
        <li>
          в здании общежития: имеется приспособленная входная группа для
          инвалидов и лиц с ОВЗ, оборудованная пандусом с поручнями и кнопкой
          вызова персонала, звуковыми и световыми маяками, световыми маяками для
          обозначения габаритов дверного проема, информационным табло,
          обеспечена контрастная окраска дверей и лестниц входной группы. Вход
          оборудован расширенным дверным проёмом.
        </li>
        <li>
          на путях передвижения инвалидов демонтированы пороги в дверных
          проемах, в местах, где по техническим причинам отсутствует возможность
          демонтажа порогов, имеются переносные пандусы.
        </li>
        <li>
          в здании столовой: перед входом установлена кнопка вызова персонала.
          Вход оборудован расширенным дверным проёмом.{" "}
        </li>
        <li>
          имеется стадион с полосой препятствий, волейбольной, баскетбольной
          площадкой, футбольным полем, велосипедной дорожкой и освещенной лыжной
          трассой, который может использоваться лицами с ограниченными
          возможностями здоровья.
        </li>
      </ul>
      <h2>
        Информация о наличии специальных технических средств обучения
        коллективного и индивидуального пользования для инвалидов и лиц с
        ограниченными возможностями здоровья
      </h2>
      <p itemProp="techOvz">
        Официальный сайт Уфимского филиала ФГБОУ ВО «ВГУВТ», а также электронные
        ресурсы библиотеки адаптированы под нужды слабовидящих обучающихся
        посредством специальной версии. Электронные библиотечные системы «Лань»
        и «Юрайт» поддерживают версию для слабовидящих.
      </p>
      <p itemProp="techOvz">
        Электронная библиотечная система «Лань» поддерживает специальное
        мобильное приложение для студентов с ограниченными возможностями -
        синтезатор речи, который воспроизводит тексты книг и меню навигации.
        Специальные технические средства обучения коллективного и
        индивидуального пользования для инвалидов и лиц с ограниченными
        возможностями здоровья:
      </p>
      <ul>
        <li itemProp="techOvz">Программы-синтезаторы речи</li>
        <li itemProp="techOvz">Звукоусиливающая аппаратура</li>
        <li itemProp="techOvz">Устройства воспроизведения информации</li>
        <li itemProp="techOvz">
          Индукционная петля VERT предназначенная для усиления восприятия
          информации людьми с нарушениями по слуху (использующих слуховые
          аппараты) в ограниченном пространстве и при большом скоплении людей, а
          также наличии посторонних звуков или преграды между собеседниками.
        </li>
      </ul>
      <h2>
        Информация об электронных образовательных ресурсах, приспособленных для
        использования инвалидами и лицами с ограниченными возможностями здоровья
      </h2>
      <p>
        Перечень электронных и электронных образовательных ресурсов, доступных
        для использования обучающимися, в том числе для использования инвалидами
        и лицами с ограниченными возможностями здоровья. Все указанные ресурсы
        адаптированы для лиц с ограничениями здоровья по зрению (слабовидящих):
      </p>
      <ol>
        <li>
          <a
            itemProp="erListOvz"
            href="https://minobrnauki.gov.ru"
            target={"_blank"}
            rel="noreferrer"
          >
            minobrnauki.gov.ru
          </a>{" "}
          - Министерство науки и высшего образования Российской Федерации
        </li>
        <li>
          <a
            itemProp="erListOvz"
            href="https://edu.gov.ru"
            target={"_blank"}
            rel="noreferrer"
          >
            edu.gov.ru
          </a>{" "}
          - Министерство просвещения Российской Федерации
        </li>
        <li>
          <a
            href="http://obrnadzor.gov.ru/ru/"
            target={"_blank"}
            rel="noreferrer"
            itemProp="erListOvz"
          >
            obrnadzor.gov.ru/ru
          </a>{" "}
          - Федеральная служба по надзору в сфере образования и науки
        </li>
        <li>
          <a
            itemProp="erListOvz"
            href="http://window.edu.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            window.edu.ru
          </a>{" "}
          - Информационная система "Единое окно доступа к образовательным
          ресурсам"
        </li>
        <li>
          <a
            itemProp="erListOvz"
            href="http://fcior.edu.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            fcior.edu.ru
          </a>{" "}
          - Федеральный центр информационно-образовательных ресурсов
        </li>
        <li>
          <a
            itemProp="erListOvz"
            href="https://www.garant.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            www.garant.ru
          </a>{" "}
          - «ГАРАНТ», информационно-правовой портал{" "}
        </li>
        <li>
          <a
            itemProp="erListOvz"
            href="https://www.consultant.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            www.consultant.ru
          </a>
          - «Консультант плюс»- Разработка правовых систем
        </li>
      </ol>
      <h2>
        Базы данных электронного каталога. Электронные библиотечные системы и
        ресурсы:
      </h2>
      <ol>
        <li>
          Электронный каталог{" "}
          <a
            href="http://lib.vsuwt.ru/marcweb2/"
            target={"_blank"}
            rel="noreferrer"
            itemProp="erListOvz"
          >
            ВГУВТ
          </a>{" "}
          (через Электронный каталог ВГУВТ возможен доступ к электронной
          библиотеке университета и НРУ)
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="https://urait.ru/"
            target={"_blank"}
            rel="noreferrer"
          >
            «Юрайт»
          </a>{" "}
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="http://e.lanbook.com"
            target={"_blank"}
            rel="noreferrer"
          >
            «Лань»
          </a>{" "}
        </li>
        <li>
          Электронно-библиотечная система{" "}
          <a
            itemProp="erListOvz"
            href="https://znanium.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            «Знаниум»
          </a>{" "}
        </li>
      </ol>
      <TableWrapper
        title="Сведения об общежитии, интернате, количестве жилых помещений в
        общежитии, интернате для иногородних обучающихся"
      >
        <table>
          <tbody>
            <tr>
              <td>Наименование показателя</td>
              <td>Общежития</td>
              <td>Интернаты</td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Количество общежитий/интернатов </td>
              <td itemProp="hostelInfo">1</td>
              <td itemProp="interInfo">Нет</td>
            </tr>
            <tr>
              <td>Количество мест </td>
              <td itemProp="hostelNum">512</td>
              <td itemProp="interNum">Нет</td>
            </tr>
            <tr>
              <td>
                Количество жилых помещений, приспособленных для использования
                инвалидами и лицами с ограниченными возможностями здоровья{" "}
              </td>
              <td itemProp="hostelNumOvz">1</td>
              <td itemProp="interNumOvz">Нет</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <h2>
        Информация о наличии условий для беспрепятственного доступа в общежитии,
        интернате
      </h2>
      <p>
        В целях обеспечения беспрепятственного доступа обучающихся с
        ограниченными возможностями здоровья, имеющих нарушения
        опорно-двигательного аппарата, в здание общежития созданы следующие
        условия:
      </p>
      <ul>
        <li itemProp="hostelInterOvz">
          имеется приспособленная входная группа для инвалидов и лиц с ОВЗ,
          оборудованная пандусом с поручнями и кнопкой вызова персонала,
          звуковыми и световыми маяками, световыми маяками для обозначения
          габаритов дверного проема, информационным табло, обеспечена
          контрастная окраска дверей и лестниц входной группы. Вход оборудован
          расширенным дверным проёмом;
        </li>
        <li itemProp="hostelInterOvz">
          на путях передвижения инвалидов демонтированы пороги в дверных
          проемах, в местах, где по техническим причинам отсутствует возможность
          демонтажа порогов, имеются переносные пандусы.
        </li>
      </ul>
      <h2>Информация о формировании платы за проживание в общежитии</h2>
      <ul>
        <li className="doc__item">
          <PDF height={"40px"} width={"40px"} />
          <a
            itemProp="localActObSt"
            href={smeta}
            target={"_blank"}
            rel="noreferrer"
          >
            Смета стоимости проживания в общежитии с 01.07.2024
          </a>
        </li>{" "}
        <li className="doc__item">
          <PDF height={"40px"} width={"40px"} />
          <a
            itemProp="localActObSt"
            href={prikaz_286}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ о стоимости общежития №286 от 20.06.24
          </a>
        </li>
      </ul>
    </div>
  );
});
