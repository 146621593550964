import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import inform_o_yaz from "../../../../assets/pdf/educationPage/inf_o_yazikah.pdf";
import { TableWrapper } from "../../../common";
import sudov20 from "../../../../assets/pdf/educationPage/Судовождение, прием 2020..zip";
import sudov2124 from "../../../../assets/pdf/educationPage/Судовождение, прием 2021,2022, 2023, 2024.zip";
import eseu2124 from "../../../../assets/pdf/educationPage/26.02.05 Эксплуатация СЭУ.zip";
import eseuVo from "../../../../assets/pdf/educationPage/ВО ЭСЕУ.zip";
import organizPerev from "../../../../assets/pdf/educationPage/Организация перевозок 2021-2023.zip";
import explSudovEl from "../../../../assets/pdf/educationPage/Эксплуатация судового электрооборудования.zip";

export const InfoORealisUrovnyahObrazovaniya = () => {
  return (
    <>
      <TableWrapper
        itemProp="languageEl"
        title="1. Информация о реализуемых уровнях образования, о формах обучения, нормативных сроках обучения, сроке действия государственной аккредитации образовательной программы (при наличии государственной аккредитации)
"
      >
        <table>
          <tbody>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">Код</td>
              <td itemProp="eduName">
                Наименование профессии, специальности, направления подготовки
              </td>
              <td itemProp="eduProf">
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td itemProp="eduLevel">Уровень образования</td>
              <td itemProp="eduForm">Формы обучения</td>
              <td itemProp="learningTerm">Срок получения образования</td>
              <td itemProp="dateEnd">
                Срок действия государственной аккредитации (дата окончания
                действия свидетельства о государственной аккредитации)
              </td>
              <td itemProp="language">
                Языки, на которых осуществляется образование (обучение)
              </td>
              <td itemProp="eduPred">
                Учебные предметы, курсы, дисциплины (модули), предусмотренные
                соответствующей образовательной программой
              </td>
              <td itemProp="eduPrac">
                Практики, предусмотренные соответствующей образовательной
                программой
              </td>
              <td itemProp="eduEl">
                Информация об использовании при реализации образовательных
                программ электронного обучения и дистанционных образовательных
                технологий
              </td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.05.06</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок ФГОС №192 от
                15.03.2018 (в ред. Приказа Минобранауки России от 26.11.2020
                №1456)
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок судов смешанного
                река-море плавания (прием 2023, 2024)
              </td>
              <td itemProp="eduLevel">высшее образование-специалитет</td>
              <td itemProp="eduForm">Заочная</td>
              <td itemProp="learningTerm">6 лет</td>
              <td itemProp="dateEnd">-</td>
              <td itemProp="language">русский</td>
              <td>
                {" "}
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={eseuVo}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Эксплуатация судовых энергетических
                      установок судов смешанного река-море плавания (прием 2023,
                      2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                {" "}
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={eseuVo}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Эксплуатация судовых энергетических установок судов
                      смешанного река-море плавания (прием 2023,2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">используются</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">Судовождение ФГОС №441 от 07.05.2014</td>
              <td itemProp="eduProf">Судовождение (прием 2020)</td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Очная</td>
              <td itemProp="learningTerm">4 года 10 месяцев</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={sudov20}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Судовождение (прием 2020 г.)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={sudov20}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Судовождение (прием 2020 г.)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Не используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">
                Судовождение ФГОС №691 от 02.12.2020 (в ред. Приказа
                Минобранауки России от 13.07.2021 №450)
              </td>
              <td itemProp="eduProf">
                Судовождение (прием 2021, 2022, 2023,2024)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Очная</td>
              <td itemProp="learningTerm">4 года 6 месяцев</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={sudov2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Судовождение (прием 2021,2022, 2023,
                      2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={sudov2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Судовождение (прием 2021,
                      2022, 2023, 2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Не используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">
                Судовождение ФГОС №691 от 02.12.2020 (в ред. Приказа
                Минобранауки России от 13.07.2021 №450)
              </td>
              <td itemProp="eduProf">
                Судовождение (прием 2021, 2022, 2023,2024)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Заочная</td>
              <td itemProp="learningTerm">4 года 4 месяца</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={sudov2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Судовождение (прием 2021,2022, 2023,
                      2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={sudov2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Судовождение (прием 2021,
                      2022, 2023, 2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">Судовождение ФГОС №441 от 07.05.2014</td>
              <td itemProp="eduProf">Судовождение (прием 2020)</td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Заочная</td>
              <td itemProp="learningTerm">4 года 4 месяца</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={sudov20}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Судовождение (прием 2020 г.)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={sudov20}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Судовождение (прием 2020 г.)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.05</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок ФГОС №674 от
                26.11.2020
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок (прием 2021, 2022,
                2023, 2024)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Очная</td>
              <td itemProp="learningTerm">3 года 10 месяцев</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={eseu2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Эксплуатация судовых энергетических
                      установок (прием 2021,2022,2023,2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={eseu2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Эксплуатация судовых
                      энергетических установок (прием 2021,2022,2023,2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Не используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.05</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок ФГОС №674 от
                26.11.2020
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок (прием 2021, 2022)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Заочная</td>
              <td itemProp="learningTerm">3 года 4 месяца</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={eseu2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Эксплуатация судовых энергетических
                      установок (прием 2021,2022)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={eseu2124}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Эксплуатация судовых
                      энергетических установок (прием 2021,2022)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Используется</td>
            </tr>

            <tr itemProp="eduAccred">
              <td itemProp="eduCode">26.02.06</td>
              <td itemProp="eduName">
                Эксплуатация судового электрооборудования и средств автоматики
                ФГОС №675 от 26.11.2020
              </td>
              <td itemProp="eduProf">
                Эксплуатация судового электрооборудования и средств автоматики
                (прием 2021, 2022, 2023, 2024)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Очная</td>
              <td itemProp="learningTerm">3 года 10 месяцев</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={explSudovEl}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Эксплуатация судового
                      электрооборудования и средств автоматики (прием
                      2021,2022,2023,2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={explSudovEl}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Эксплуатация судового
                      электрооборудования и средств автоматики (прием
                      2021,2022,2023,2024)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Не используется</td>
            </tr>

            <tr itemProp="eduAccred">
              <td itemProp="eduCode">23.02.01</td>
              <td itemProp="eduName">
                Организация перевозок и управление на транспорте (по видам) ФГОС
                №376 от 22.04.2014 (в ред. Приказа Минобранауки России от
                13.07.2021 №450)
              </td>
              <td itemProp="eduProf">
                Организация перевозок и управление на транспорте (на водном
                транспорте) (прием 2020, 2021, 2022, 2023)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Очная</td>
              <td itemProp="learningTerm">3 года 10 месяцев</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={organizPerev}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Организация перевозок и управление на
                      транспорте (на водном транспорте) (прием 2021,2022,2023)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={organizPerev}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Организация перевозок и
                      управление на транспорте (на водном транспорте) (прием
                      2021,2022,2023).
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Не используется</td>
            </tr>
            <tr itemProp="eduAccred">
              <td itemProp="eduCode">23.02.01</td>
              <td itemProp="eduName">
                Организация перевозок и управление на транспорте (по видам) ФГОС
                №376 от 22.04.2014 (в ред. Приказа Минобранауки России от
                13.07.2021 №450)
              </td>
              <td itemProp="eduProf">
                Организация перевозок и управление на транспорте (на водном
                транспорте) (прием 2020, 2021, 2022, 2023)
              </td>
              <td itemProp="eduLevel">среднее профессиональное образование</td>
              <td itemProp="eduForm">Заочная</td>
              <td itemProp="learningTerm">3 года 4 месяца</td>
              <td itemProp="dateEnd">Бессрочная</td>
              <td itemProp="language">русский</td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPred"
                      href={organizPerev}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Учебные предметы, курсы, дисциплины (модули),
                      предусмотренные ОП Организация перевозок и управление на
                      транспорте (на водном транспорте) (прием 2021,2022,2023)
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <PDF height={"25px"} width={"25px"} />
                    <a
                      itemProp="eduPrac"
                      href={organizPerev}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Практики, предусмотренные ОП Организация перевозок и
                      управление на транспорте (на водном транспорте) (прием
                      2021,2022,2023)
                    </a>
                  </li>
                </ul>
              </td>
              <td itemProp="eduEl">Используется</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="languageEl"
            href={inform_o_yaz}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о реализуемых уровнях образования, о формах обучения,
            нормативных сроках обучения, сроке действия государственной
            аккредитации образовательной программы (при наличии государственной
            аккредитации)
          </a>
        </li>
      </ul>
    </>
  );
};
