import React from "react";
export const AdmissionConditions = () => {
  return (
    <section>
      <h1>
        Условия приема на обучение по договорам об оказании платных
        образовательных услуг
      </h1>
      <ol>
        <li>
          «Условия приема на обучение по договорам об оказании платных
          образовательных услуг» (далее – Условия) разработаны в соответствии с
          Федеральным законом от 29 декабря 2012 г. № 273-ФЗ «Об образовании в
          Российской Федерации»; Правилами оказания платных образовательных
          услуг, утверждены постановлением Правительства РФ от 15.09.2020 г. №
          1441.
        </li>
        <li>
          Поступающие не прошедшие по конкурсу на места, финансируемые за счет
          федерального бюджета (в случае, если численность поступающих превышает
          количество бюджетных мест), имеют право претендовать на зачисление на
          места по договорам с оплатой стоимости обучения.
        </li>
        <li>
          К освоению образовательных программ среднего профессионального
          образования на договорной основе допускаются лица, имеющие образование
          не ниже основного общего или среднего общего образования.
        </li>
        <li>
          Зачисление на места по договорам с оплатой стоимости обучения
          проводится при условии заключения договора и оплаты согласно условиям
          договора.
        </li>
      </ol>
    </section>
  );
};
