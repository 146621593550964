import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import del_angl from "../../../../assets/pdf/educationPage/260506/del_angl.pdf";
import vved_v_spec from "../../../../assets/pdf/educationPage/260506/vved_v_spec.pdf";
import asu from "../../../../assets/pdf/educationPage/260506/asu.pdf";
import vaht from "../../../../assets/pdf/educationPage/260506/vaht.pdf";
import upr_teh_expl from "../../../../assets/pdf/educationPage/260506/upr_teh_expl.pdf";
import teh_bezop from "../../../../assets/pdf/educationPage/260506/teh_bezop.pdf";
import expl_dizel from "../../../../assets/pdf/educationPage/260506/expl_dizel.pdf";
import expl_turbin from "../../../../assets/pdf/educationPage/260506/expl_turbin.pdf";
import expl_kotel from "../../../../assets/pdf/educationPage/260506/expl_kotel.pdf";
import expl_vspomogat from "../../../../assets/pdf/educationPage/260506/expl_vspomogat.pdf";
import sudov_oborud from "../../../../assets/pdf/educationPage/260506/sudov_oborud.pdf";
import kurs_podgot_crews from "../../../../assets/pdf/educationPage/260506/kurs_podgot_crews.pdf";
import konvent_podgot from "../../../../assets/pdf/educationPage/260506/konvent_podgot.pdf";
import osn_teor_nad from "../../../../assets/pdf/educationPage/260506/osn_teor_nad.pdf";
import energ_ustan_vspom from "../../../../assets/pdf/educationPage/260506/energ_ustan_vspom.pdf";
import fizkult_sport from "../../../../assets/pdf/educationPage/260506/fizkult_sport.pdf";
import filosof from "../../../../assets/pdf/educationPage/260506/filosof.pdf";
import history from "../../../../assets/pdf/educationPage/260506/history.pdf";
import foreign from "../../../../assets/pdf/educationPage/260506/foreign.pdf";
import bezop_ghiz from "../../../../assets/pdf/educationPage/260506/bezop_ghiz.pdf";
import his_trans_ros from "../../../../assets/pdf/educationPage/260506/his_trans_ros.pdf";
import pravoved from "../../../../assets/pdf/educationPage/260506/pravoved.pdf";
import mathem from "../../../../assets/pdf/educationPage/260506/pravoved.pdf";
import physics from "../../../../assets/pdf/educationPage/260506/physics.pdf";
import chemistry from "../../../../assets/pdf/educationPage/260506/chemistry.pdf";
import informatics from "../../../../assets/pdf/educationPage/260506/informatics.pdf";
import ecology from "../../../../assets/pdf/educationPage/260506/ecology.pdf";
import draw_geomtry from "../../../../assets/pdf/educationPage/260506/draw_geomtry.pdf";
import teor_mech from "../../../../assets/pdf/educationPage/260506/teor_mech.pdf";
import sopromat from "../../../../assets/pdf/educationPage/260506/sopromat.pdf";
import teor_mech_mash from "../../../../assets/pdf/educationPage/260506/teor_mech_mash.pdf";
import detal_mash from "../../../../assets/pdf/educationPage/260506/detal_mash.pdf";
import hydromech from "../../../../assets/pdf/educationPage/260506/hydromech.pdf";
import tech_thermodyn from "../../../../assets/pdf/educationPage/260506/tech_thermodyn.pdf";
import material_ved from "../../../../assets/pdf/educationPage/260506/material_ved.pdf";
import metrology from "../../../../assets/pdf/educationPage/260506/metrology.pdf";
import eltech_electron from "../../../../assets/pdf/educationPage/260506/el-tech_electron.pdf";
import teor_eltech from "../../../../assets/pdf/educationPage/260506/teor_el-tech.pdf";
import tus from "../../../../assets/pdf/educationPage/260506/tus.pdf";
import sud_dvig_vuntr_sgor from "../../../../assets/pdf/educationPage/260506/sud_dvig_vuntr_sgor.pdf";
import sud_turbomash from "../../../../assets/pdf/educationPage/260506/sud_turbomash.pdf";
import sud_kotel_ustan from "../../../../assets/pdf/educationPage/260506/sud_kotel_ustan.pdf";
import sud_holod_ustan from "../../../../assets/pdf/educationPage/260506/sud_holod_ustan.pdf";
import sud_vspomogat_ustr from "../../../../assets/pdf/educationPage/260506/sud_vspomogat_ustr.pdf";
import electro_oborud_sudov from "../../../../assets/pdf/educationPage/260506/electro_oborud_sudov.pdf";
import osnovy_avtomatiki from "../../../../assets/pdf/educationPage/260506/osnovy_avtomatiki.pdf";
import techology_techobslug_sudov from "../../../../assets/pdf/educationPage/260506/techology_techobslug_sudov.pdf";
import fizkult_health from "../../../../assets/pdf/educationPage/260506/fizkult_health.pdf";
import primenen_topliv_masel from "../../../../assets/pdf/educationPage/260506/primenen_topliv_masel.pdf";
import technology_water from "../../../../assets/pdf/educationPage/260506/technology_water.pdf";
import automation_devices from "../../../../assets/pdf/educationPage/260506/automation_devices.pdf";
import analiz_prichin_povreghden from "../../../../assets/pdf/educationPage/260506/analiz_prichin_povreghden.pdf";
import proc_upravl_bezopasn from "../../../../assets/pdf/educationPage/260506/proc_upravl_bezopasn.pdf";
import diagnos_sistem_upravl from "../../../../assets/pdf/educationPage/260506/diagnos_sistem_upravl.pdf";
import perspek_sistemy_uprvl_dizel from "../../../../assets/pdf/educationPage/260506/perspek_sistemy_uprvl_dizel.pdf";
import lider_psych_osnovy from "../../../../assets/pdf/educationPage/260506/lider_psych_osnovy.pdf";
import Psychology_pedagogika from "../../../../assets/pdf/educationPage/260506/Psychology_pedagogika.pdf";
import diagnos_SAEES from "../../../../assets/pdf/educationPage/260506/diagnos_SAEES.pdf";
import diagnos_sud_eloborud from "../../../../assets/pdf/educationPage/260506/diagnos_sud_el-oborud.pdf";
import inf_systems_expl_sudov from "../../../../assets/pdf/educationPage/260506/inf_systems_expl_sudov.pdf";
import inform_tecnologies from "../../../../assets/pdf/educationPage/260506/inform_tecnologies.pdf";
import convention_labour from "../../../../assets/pdf/educationPage/260506/convention_labour.pdf";
import upravl_interface_crew from "../../../../assets/pdf/educationPage/260506/upravl_interface_crew.pdf";
import dvuhtopl from "../../../../assets/pdf/educationPage/260506/dvuhtopl.pdf";
import prof_fizpodgotovka from "../../../../assets/pdf/educationPage/260506/prof_fizpodgotovka.pdf";

export const ShipPowerPlantsVO2023 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Рабочие программы дисциплин, профессиональных модулей программы
        подготовки специалистов среднего звена в соответствии с ФГОС по
        специальности "26.05.06 Эксплуатация судовых энергетических установок"
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={del_angl}
                target={"_blank"}
                rel="noreferrer"
              >
                Деловой английский язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={vved_v_spec}
                target={"_blank"}
                rel="noreferrer"
              >
                Введение в специальность
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={asu}
                target={"_blank"}
                rel="noreferrer"
              >
                Автоматизированные системы управления СЭУ
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={vaht}
                target={"_blank"}
                rel="noreferrer"
              >
                Вахтенное обслуживание СЭУ (тренажер машинного отделения)
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={upr_teh_expl}
                target={"_blank"}
                rel="noreferrer"
              >
                Управление технической эксплуатацией судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={teh_bezop}
                target={"_blank"}
                rel="noreferrer"
              >
                Техническое обеспечение безопасности судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={expl_dizel}
                target={"_blank"}
                rel="noreferrer"
              >
                Эксплуатация дизельных энергетических установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={expl_turbin}
                target={"_blank"}
                rel="noreferrer"
              >
                Эксплуатация судовых турбинных установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={expl_kotel}
                target={"_blank"}
                rel="noreferrer"
              >
                Эксплуатация судовых котельных и паропроизводящих установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={expl_vspomogat}
                target={"_blank"}
                rel="noreferrer"
              >
                Эксплуатация судовых вспомогательных механизмов, устройств и
                систем
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sudov_oborud}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовое оборудование и процедуры предотвращения загрязнения
                окружающей среды
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={kurs_podgot_crews}
                target={"_blank"}
                rel="noreferrer"
              >
                Курс подготовки экипажей гражданских судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={konvent_podgot}
                target={"_blank"}
                rel="noreferrer"
              >
                Конвенционная подготовка
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={dvuhtopl}
                target={"_blank"}
                rel="noreferrer"
              >
                Двухтопливные и традиционные двигательные установки судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={osn_teor_nad}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы теории надежности и диагностики
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={energ_ustan_vspom}
                target={"_blank"}
                rel="noreferrer"
              >
                Энергетические установки судов вспомогательного флота и их
                эксплуатация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizkult_sport}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура и спорт
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={filosof}
                target={"_blank"}
                rel="noreferrer"
              >
                Философия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={history}
                target={"_blank"}
                rel="noreferrer"
              >
                История (история России, всеобщая история)
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={foreign}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={bezop_ghiz}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнедеятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={his_trans_ros}
                target={"_blank"}
                rel="noreferrer"
              >
                История транспорта России
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pravoved}
                target={"_blank"}
                rel="noreferrer"
              >
                Правоведение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mathem}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={physics}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={chemistry}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={informatics}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={ecology}
                target={"_blank"}
                rel="noreferrer"
              >
                Экология
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={draw_geomtry}
                target={"_blank"}
                rel="noreferrer"
              >
                Начертательная геометрия и инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={teor_mech}
                target={"_blank"}
                rel="noreferrer"
              >
                Теоретическая механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sopromat}
                target={"_blank"}
                rel="noreferrer"
              >
                Сопротивление материалов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={teor_mech_mash}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория механизмов машин
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>33</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={detal_mash}
                target={"_blank"}
                rel="noreferrer"
              >
                Детали машин и основы конструирования
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>34</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={hydromech}
                target={"_blank"}
                rel="noreferrer"
              >
                Гидромеханика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>35</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tech_thermodyn}
                target={"_blank"}
                rel="noreferrer"
              >
                Техническая термодинамика и теплопередача
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>36</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={material_ved}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение и технология конструкционных материалов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>37</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrology}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология, стандартизация и сертификация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>38</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={eltech_electron}
                target={"_blank"}
                rel="noreferrer"
              >
                Общая электротехника и электроника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>39</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={teor_eltech}
                target={"_blank"}
                rel="noreferrer"
              >
                Теоретические основы электротехники
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>40</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tus}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория и устройство судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>41</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sud_dvig_vuntr_sgor}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовые двигатели внутреннего сгорания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>42</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sud_turbomash}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовые турбомашины
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>43</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sud_kotel_ustan}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовые котельные и паропроизводящие установки
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>44</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sud_holod_ustan}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовые холодильные установки и системы кондиционирования
                воздуха
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>45</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={sud_vspomogat_ustr}
                target={"_blank"}
                rel="noreferrer"
              >
                Судовые вспомогательные механизмы, системы и устройства
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>46</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={electro_oborud_sudov}
                target={"_blank"}
                rel="noreferrer"
              >
                Электрооборудование судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>47</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={osnovy_avtomatiki}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы автоматики и теории управления техническими системами
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>48</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={techology_techobslug_sudov}
                target={"_blank"}
                rel="noreferrer"
              >
                Технология технического обслуживания и ремонта судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>49</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={prof_fizpodgotovka}
                target={"_blank"}
                rel="noreferrer"
              >
                Профессионально-прикладная физическая подготовка
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>50</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizkult_health}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура в обеспечении здоровья
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>51</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={primenen_topliv_masel}
                target={"_blank"}
                rel="noreferrer"
              >
                Применение топлив и масел на судах
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>52</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={technology_water}
                target={"_blank"}
                rel="noreferrer"
              >
                Технологии обработки воды на судах
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>53</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={automation_devices}
                target={"_blank"}
                rel="noreferrer"
              >
                Элементы и устройства автоматизации СЭУ
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>54</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={analiz_prichin_povreghden}
                target={"_blank"}
                rel="noreferrer"
              >
                Анализ причин повреждений судовых технических средств
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>55</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={proc_upravl_bezopasn}
                target={"_blank"}
                rel="noreferrer"
              >
                Процедуры управления и технического обеспечения безопасности
                речных судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>56</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={diagnos_sistem_upravl}
                target={"_blank"}
                rel="noreferrer"
              >
                Диагностирование систем автоматического управления
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>57</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={perspek_sistemy_uprvl_dizel}
                target={"_blank"}
                rel="noreferrer"
              >
                Перспективные системы управления и обслуживания дизельных
                установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>58</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={lider_psych_osnovy}
                target={"_blank"}
                rel="noreferrer"
              >
                Лидерство и психологические основы управления экипажем судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>59</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={Psychology_pedagogika}
                target={"_blank"}
                rel="noreferrer"
              >
                Психология и педагогика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>60</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={diagnos_SAEES}
                target={"_blank"}
                rel="noreferrer"
              >
                Диагностирование САЭЭС
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>61</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={diagnos_sud_eloborud}
                target={"_blank"}
                rel="noreferrer"
              >
                Диагностирование судового электрооборудования
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>62</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inf_systems_expl_sudov}
                target={"_blank"}
                rel="noreferrer"
              >
                Информационные системы в эксплуатации судов
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>63</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform_tecnologies}
                target={"_blank"}
                rel="noreferrer"
              >
                Информационные технологии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>64</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={convention_labour}
                target={"_blank"}
                rel="noreferrer"
              >
                Конвенция о труде в морском судоходстве
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>65</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={upravl_interface_crew}
                target={"_blank"}
                rel="noreferrer"
              >
                Управление социально-трудовыми отношениями в судовых экипажах
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
