/* eslint-disable eqeqeq */
export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return [...items].splice(startIndex, pageSize);
}
export function logOut(user, navigate) {
  user.logOut();
  navigate("/");
}

export function transformDayOfWeek(params) {
  if (params == 0) {
    return "Понедельник";
  } else if (params == 1) {
    return "Вторник";
  } else if (params == 2) {
    return "Среда";
  } else if (params == 3) {
    return "Четверг";
  } else if (params == 4) {
    return "Пятница";
  } else if (params == 5) {
    return "Суббота";
  } else if (params == 6) {
    return "Воскресенье";
  }
}
