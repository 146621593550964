import React, { useState } from "react";

import { toggleClassName } from "../../../../utils/disabled";
import { observer } from "mobx-react-lite";
import { LocalActs } from "./local-acts";
import { MainDocs } from "./main-docs";
import { HrLine } from "../../../common";
import { NavPanel } from "../../../ui/nav-panel/nav-panel";

export const Document = observer(() => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div
      itemProp="copy"
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <NavPanel
        isActive={isActive}
        setIsActive={setIsActive}
        btnOneText={"Основные документы"}
        btnTwoText={"Локальные нормативные акты"}
        center={true}
      />

      <HrLine className="document__hrLine" />
      <div className="doc_container">
        <MainDocs isActive={isActive} />
        <LocalActs isActive={isActive} />
      </div>
    </div>
  );
});
