import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import { observer } from "mobx-react-lite";
import pam from "../../../../../assets/pdf/home/Памятка о борьбе с коррупцией.pdf";
import { ReactComponent as PDF } from "../../../../../assets/svg/office/pdf.svg";
export const MethodologicalMaterials = observer(() => {
  return (
    <>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Методические материалы
      </h2>
      <ul>
        <li>
          <a
            href="https://mintrud.gov.ru/ministry/anticorruption/Methods/13"
            target={"_blank"}
            rel="noreferrer"
          >
            Методические рекомендации по вопросам представления сведений о
            доходах, расходах, об имуществе и обязательствах имущественного
            характера и заполнения соответствующей формы справки в 2024 году (за
            отчетный 2023 год)" (утв. Минтрудом России)
          </a>
        </li>{" "}
        <li>
          <a
            href="https://mintrud.gov.ru/ministry/programms/anticorruption/9"
            target={"_blank"}
            rel="noreferrer"
          >
            Методические материалы, одобренные президиумом Совета при Президенте
            Российской Федерации по противодействию коррупции, методические
            рекомендации, обзоры, разъяснения и другие документы, размещенные на
            сайте Минтруда России
          </a>
        </li>{" "}
        <li>
          <a
            href="https://mintrud.gov.ru/docs/mintrud/employment/15"
            target={"_blank"}
            rel="noreferrer"
          >
            Обзор рекомендаций по осуществлению комплекса организационных,
            разъяснительных и иных мер по недопущению должностными лицами
            поведения, которое может восприниматься окружающими как обещание
            дачи взятки или предложение дачи взятки либо как согласие принять
            взятку или как просьба о даче взятки
          </a>
        </li>{" "}
        <li>
          <a
            href="https://mintrud.gov.ru/ministry/programms/anticorruption/015/0"
            target={"_blank"}
            rel="noreferrer"
          >
            МЕТОДИЧЕСКИЕ РЕКОМЕНДАЦИИ для должностных лиц подразделений по
            профилактике коррупционных и иных правонарушений по проведению
            антикоррупционной работы
          </a>
        </li>{" "}
        <li>
          <a
            href="https://minobrnauki.gov.ru/open_ministry/anticorr/method/"
            target={"_blank"}
            rel="noreferrer"
          >
            Методические материалы на сайте Минобрнауки России
          </a>
        </li>
        <li>
          <PDF width="25px" height="25px" />{" "}
          <a href={pam} target={"_blank"} rel="noreferrer">
            Памятка о борьбе с коррупцией
          </a>
        </li>
      </ul>
    </>
  );
});
