/* eslint-disable react/jsx-pascal-case */
import React from "react";

import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { TableWrapper } from "../../../common";
import { VOEkspluatSudEnergUstanovok_23_260506 } from "./neadaptirovannie-programmi/23_260506_VO_ekspluat-sud-energ-ustanovok";
import { SPOSudovojdenie_22_260203 } from "./neadaptirovannie-programmi/22_260203_SPO_sudovojdenie";
import { SPOSudovojdenie_21_260203 } from "./neadaptirovannie-programmi/21_260203_SPO_sudovojdenie";
import { SPOSudovojdenie_20_260203 } from "./neadaptirovannie-programmi/20_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEelectrooborud_22_260206 } from "./neadaptirovannie-programmi/22_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEelectrooborud_21_260206 } from "./neadaptirovannie-programmi/21_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEnergUstanovok_22_260205 } from "./neadaptirovannie-programmi/22_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOEkspluatSudovEnergUstanovok_21_260205 } from "./neadaptirovannie-programmi/21_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOOrganiz_perevoz_upravlenia_22_230201 } from "./neadaptirovannie-programmi/22_230201_SPO_organiz_perevoz_upravleniya";
import { SPOOrganiz_perevoz_upravlenia_21_230201 } from "./neadaptirovannie-programmi/21_230201_SPO_organiz_perevoz_upravleniya";
import { SPOSudovojdenie_23_260203 } from "./neadaptirovannie-programmi/23_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEelectrooborud_23_260206 } from "./neadaptirovannie-programmi/23_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEnergUstanovok_23_260205 } from "./neadaptirovannie-programmi/23_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOOrganiz_perevoz_upravlenia_23_230201 } from "./neadaptirovannie-programmi/23_230201_SPO_organiz_perevoz_upravleniya";
import { SPOSudovojdenie_24_260203 } from "./neadaptirovannie-programmi/24_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEnergUstanovok_24_260205 } from "./neadaptirovannie-programmi/24_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOEkspluatSudovEelectrooborud_24_260206 } from "./neadaptirovannie-programmi/24_260206_SPO_expluat_sudov_electroob";

export const NeadaptirovannieProgrammi = () => {
  return (
    <TableWrapper title="Информация по образовательным программам:">
      <table>
        <tbody>
          <tr itemProp="eduOp">
            <td itemProp="eduCode">Код, шифр</td>
            <td itemProp="eduName">
              Наименование профессии, специальности, направления подготовки,
              наименование группы научных специальностей
            </td>
            <td itemProp="eduLevel">Уровень образования</td>
            <td itemProp="eduProf">
              Образовательная программа, направленность, профиль, шифр и
              наименование научной специальности
            </td>
            <td itemProp="eduForm">Реализуемые формы обучения</td>
            <td itemProp="opMain">
              Ссылка на описание образовательной программы с приложением ее
              копии
            </td>
            <td itemProp="educationPlan">Ссылка на учебный план</td>

            <td itemProp="educationRpd">
              Ссылки на рабочие программы (по каждой дисциплине в составе
              образовательной программы)
            </td>
            <td itemProp="educationShedule">
              Ссылка на календарный учебный график
            </td>
            <td itemProp="eduPr">
              Ссылка на рабочие программы практик, предусмотренных
              соответствующей образовательной программой
            </td>

            <td itemProp="methodology">
              Ссылка на методические и иные документы, разработанные ОО для
              обеспечения образовательного процесса, а также рабочие программы
              воспитания и календарные планы воспитательной работы, включаемых в
              ООП
            </td>
          </tr>
          <VOEkspluatSudEnergUstanovok_23_260506 PDF={PDF} />
          <SPOSudovojdenie_24_260203 PDF={PDF} />
          <SPOSudovojdenie_23_260203 PDF={PDF} />
          <SPOSudovojdenie_22_260203 PDF={PDF} />
          <SPOSudovojdenie_21_260203 PDF={PDF} />
          <SPOSudovojdenie_20_260203 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_24_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_23_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_22_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_21_260206 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_24_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_23_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_22_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_21_260205 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_23_230201 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_22_230201 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_21_230201 PDF={PDF} />
        </tbody>
      </table>
      * Информация по реализуемым общеобразовательным программам: программа
      основного общего образования является частью Основных профессиональных
      образовательных программ СПО по специальностям
    </TableWrapper>
  );
};
