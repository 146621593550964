import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
export const IncomeInformation = observer(() => {
  return (
    <>
      {" "}
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о доходах, расходах, об имуществе и обязательствах
        имущественного характера
      </h2>
      <p>
        Данный подраздел в соответствии с подпунктом «ж» пункта 1 Указа
        Президента Российской Федерации от 29 декабря 2022 г. № 968 «Об
        особенностях исполнения обязанностей, соблюдения ограничений и запретов
        в области противодействия коррупции некоторыми категориями граждан в
        период проведения специальной военной операции» в период проведения СВО
        и впредь до издания соответствующих нормативных правовых актов
        Российской Федерации размещение сведений на официальных сайтах органов
        публичной власти и организаций в сети "Интернет" и их предоставление
        общероссийским СМИ для опубликования не осуществляются.
      </p>
    </>
  );
});
