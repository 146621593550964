import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import Prez1 from "../../../../assets/pdf/practice/prez1.pdf";
import Prez2 from "../../../../assets/pdf/practice/prez2.pdf";
import Prez3 from "../../../../assets/pdf/practice/prez3.pdf";
import { Image } from "../../../common";
import Port from "../../../../assets/img/education/port.jpg";
import PractZaochn from "../../../../assets/pdf/practice/grafik_praktik_2023_zaoch.pdf";
import PractOchn from "../../../../assets/pdf/practice/grafik_praktik_2023_ochn.pdf";
import bazi from "../../../../assets/pdf/practice/Базы учебной и производственной (по профилю специальности и преддипломной) практики.pdf";
import lopr from "../../../../assets/pdf/practice/lopr.pdf";
import { observer } from "mobx-react-lite";
import { toggleClassName } from "../../../../utils/disabled";
export const Practice = observer(() => {
  return (
    <>
      <div
        className={toggleClassName(
          "education__container",
          "education__container-white",
          "education__container-black",
          "education__container-contrast"
        )}
      >
        <h1 className="education__container-title">
          Отдел практической подготовки
        </h1>
        <div className="education__container-image">
          <Image src={Port} width={"100%"} height={"400px"} />
        </div>

        <div className="education__container-content">
          <h2>
            Организация практической подготовки в филиале проводится в полном
            соответствии с профессиональными стандартами, Положением о
            дипломировании членов экипажей судов внутреннего водного транспорта,
            ожиданиям потребителей, а также нормативно-правовыми актами в
            области образования, перечисленными ниже:
          </h2>
          <ol>
            <li>
              Федерального закона от 29.12.2012 №273-ФЗ «Об образовании в
              Российской Федерации»;
            </li>
            <li>
              Приказа Министерства науки и высшего образования РФ и Министерства
              просвещения РФ от 5 августа 2020 г. №885/390 «О практической
              подготовке обучающихся» (с изменениями и дополнениями от
              18.11.2020 г.);
            </li>
            <li>
              Положения о прохождении практики курсантами, обучающимися по
              специальностям подготовки плавсостава судов, утвержденное приказом
              ректора ФГБОУ ВО «ВГУВТ» №111 от 10.10.2017 г.;
            </li>
            <li>
              Положения об организации и прохождении плавательной практики
              курсантами, обучающимися по специальностям подготовки членов
              экипажей судов в Уфимском филиале ФГБОУ ВО «ВГУВТ», утвержденное
              приказом директором филиала №228/1 от 09.08.2022 г.;
            </li>
            <li>Учебными планами Уфимского филиала ФГБОУ ВО «ВГУВТ».</li>
          </ol>
          <h2>
            {" "}
            Курсанты, перед отправлением на учебную и производственную практику
            в судоходные компании обязаны:
          </h2>
          <ol>
            <li>
              {" "}
              Полностью пройти мед.комиссию, получить заключение о
              проф.пригодности.
            </li>
            <li>Ликвидировать задолженности по учебным дисциплинам.</li>
            <li>
              Сдать в УПО (учебно-производственный отдел) полностью пройденный
              обходной лист со всеми подписями и печатями, последней подписью
              является подпись зав. отделением.
            </li>
            <li>
              Получить в УПО направление на практику, чистый бланк - справку о
              стаже плавания, аттестационный лист, характеристика по освоению
              общих компетенций, журнал регистрации практической подготовки
              курсанта (для обучающихся по второй специальности получить 2
              журнала).
            </li>
            <li>
              После трудоустройства принести в УПО выписку из приказа о
              назначении на судно.
            </li>
          </ol>
          <h2>
            При устройстве на штатную должность курсанты-практиканты обязаны
            предоставить в отдел кадров следующие документы:{" "}
          </h2>
          <ol>
            <li>Паспорт</li>
            <li>Приписное свидетельство</li>
            <li>Пенсионное страховое свидетельство</li>
            <li>Идентификационный номер налогоплательщика (ИНН)</li>
            <li>
              Медицинская книжка с пройденной мед. комиссией и обязательным мед.
              заключением
            </li>
            <li>
              Удостоверения матроса, рулевого, моториста, судового электрика
              (при устройстве на производственную практику)
            </li>
            <li>Направление на практику</li>
            <li>Справка от нарколога и психиатра</li>
            <li>
              Загран. паспорт (для курсантов, идущих в судоходные компании
              река-море)
            </li>
            <li>
              Удостоверение личности моряка (для курсантов, идущих в судоходные
              компании река-море)
            </li>
            <li>
              Мореходная книжка (для курсантов, идущих в судоходные компании
              река-море)
            </li>
            <li>
              Свидетельство «Начальная подготовка по безопасности» (для
              курсантов, идущих в судоходные компании река-море)
            </li>
            <li>
              Свидетельство ОСПС - Охрана судов и портовых средств (для
              курсантов, идущих в судоходные компании река-море)
            </li>
            <li>
              Мед. книжка с пройденной мед. комиссией, дополнительно с
              заключением врачей сурдолога и окулиста, справку от нарколога и
              психиатра, (для курсантов, идущих в судоходные компании река-море)
            </li>
          </ol>
          <h2>
            После прохождения учебной и производственной практики в течении 3
            дней курсанты обязаны отчитаться по практике в
            учебно-производственном отделе:
          </h2>
          <ol>
            <li>
              Сдать заполненный Журнал регистрации практической подготовки (для
              обучающихся по второй специальности сдать 2 Журнала), с подписями
              капитана, датами, судовыми печатями, (Заполнение Журнала
              регистрации практической подготовки курсантов: 2 курс - 1раздел, 2
              раздел и 5; 3 курс – 3 раздел и 5; 4 курс- 4 раздел и 5).
            </li>
            <li>
              Сдать заполненную справку о стаже плавания и справку,
              подтверждающую справку о стаже плаванья (или же выписку из приказа
              о принятии на работу), подписанную капитаном и механиком судна,
              заверенную судовой печатью и печатью организации, для курсантов,
              проходивших практику на судах река-море справка о стаже плавания
              установленного образца судоходной компании.
            </li>
            <li>
              Сдать направление на практику, подписанное капитаном судна,
              заверенное судовой печатью, с отметками начала и окончания
              практики в отделе кадров судоходной компании, заверенное печатью
              отдела кадров.
            </li>
            <li>
              Аттестационный лист с отметками по 5-балльной системе, заполненный
              капитаном (механиком, электромехаником), заверенным подписью
              капитана и судовой печатью.
            </li>
            <li>
              Характеристику по освоению общих компетенций, подписанную
              капитаном (механиком, электромехаником), заверенную судовой
              печатью.
            </li>
          </ol>
          <section>
            <ul>
              <li itemProp="localActStud" className="doc__item">
                <PDF height={"40px"} width={"40px"} />{" "}
                <a href={bazi} target={"_blank"} rel="noreferrer">
                  Базы учебной и производственной (по профилю специальности и
                  преддипломной) практики
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h2>
              Презентации ведущих предприятий водного транспорта Республики
              Башкортостан
            </h2>
            <ul>
              <li>
                {" "}
                <a
                  href={Prez1}
                  target={"_blank"}
                  rel="noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Бельский район водных путей и судоходства
                </a>
              </li>
              <li>
                <a
                  href={Prez2}
                  target={"_blank"}
                  rel="noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  ЗАО СК БашВолготанкер
                </a>
              </li>
              <li>
                <a
                  href={Prez3}
                  target={"_blank"}
                  rel="noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  ООО Судоремонтно-судостроительный завод
                </a>
              </li>
              <li>
                <a
                  href={lopr}
                  target={"_blank"}
                  rel="noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  ПАО «ЛОРП»
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h1>
              График проведения учебных, производственных и преддипломных
              практик очного обучения СПО в 2023-2024 учебном году
            </h1>

            <p>
              <PDF height={"40px"} width={"40px"} />{" "}
              <a
                href={PractOchn}
                target={"_blank"}
                rel="noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                График практики (очники)
              </a>
            </p>
          </section>
          <section>
            <h1>
              График проведения учебных, производственных и преддипломных
              практик заочного обучения СПО в 2023-2024 учебном году
            </h1>
            <p>
              <PDF height={"40px"} width={"40px"} />{" "}
              <a
                href={PractZaochn}
                target={"_blank"}
                rel="noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                График практики (заочники)
              </a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
});
