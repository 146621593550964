import { $authHost, $host } from "./index";

export const createMonitoringDocs = async (doc) => {
  const { data } = await $authHost.post("api/monitoringDocs", doc);
  return data;
};

export const fetchMonitoringDocs = async () => {
  const { data } = await $host.get("api/monitoringDocs");
  return data;
};

export const deleteMonitoringDocs = async (id) => {
  const { data } = await $authHost.delete("api/monitoringDocs/" + id);
  return data;
};
