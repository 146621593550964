import React, { useEffect, useState } from "react";
import { NavPanel } from "../../../../ui/nav-panel/nav-panel";
import { SpoLvl } from "./spo-lvl/spo-lvl";
import { VoLvl } from "./vo-lvl/vo-lvl";
import { MonitoringDocsStore } from "../../../../../store/monitoring-docs-store";
import { observer } from "mobx-react-lite";

export const Monitoring = observer(() => {
  const [isActive, setIsActive] = useState(true);
  const { loadMonitoringDocs } = MonitoringDocsStore;
  useEffect(() => {
    loadMonitoringDocs();
  }, [loadMonitoringDocs]);
  return (
    <>
      <NavPanel
        isActive={isActive}
        setIsActive={setIsActive}
        btnOneText={"СПО"}
        btnTwoText={"Высшее образование"}
        center={true}
      />
      {isActive ? <SpoLvl /> : <VoLvl />}
    </>
  );
});
