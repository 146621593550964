import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { observer } from "mobx-react-lite";

export const Health = observer(() => {
  return (
    <div
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <section className="education__container-content">
        <h1>Оказание медико-санитарной помощи</h1>
        <p>
          Первичная медико-санитарная помощь в Филиале оказывается в плановой и
          неотложной формах.
        </p>
        <p>
          Обучающимся, в том числе несовершеннолетним, гарантируется оказание
          медицинской помощи в виде первичной доврачебной медико-санитарной
          помощи.
        </p>
        <p>
          При необходимости получения медицинской помощи в случаях заболевания,
          острого ухудшения состояния здоровья или получения травмы обучающийся
          обращается самостоятельно или с помощью сотрудника Филиала:
        </p>
        <ul>
          <li>
            в рабочее время с 8.15 до 17.15 в медицинский кабинет, расположенный
            в здании общежития
          </li>
          <li>
            во внерабочее время (в том числе в выходные и праздничные дни) к
            воспитателю
          </li>
        </ul>
        <p>
          Родители обучающихся могут получить информацию о состоянии здоровья
          своих детей в рабочее время по телефону: +7(347) 215-14-00, доп.321.
        </p>
        <p>
          После первичного осмотра обучающего медицинская сестра (воспитатель)
          принимает решение о дальнейших действиях в соответствии с
          нормативно-правовыми актами, регламентирующими оказание медицинской
          помощи гражданам.
        </p>
        <p>
          При оказании скорой медицинской помощи, в случае необходимости,
          осуществляется транспортировка в медицинскую организацию и (или)
          определение в стационар по профилю заболевания при обязательном
          сопровождении обучающегося сотрудником Филиала.{" "}
        </p>
        <p>
          <b>Примечание:</b> При оказании медицинской помощи несовершеннолетнему
          по экстренным показаниям для устранения угрозы жизни без согласия
          родителей (законных представителей), согласно пункту 1 части 9 статьи
          20 Федерального закона «Об основах охраны здоровья граждан в
          Российской Федерации» от 21.11.2011 №323-ФЗ, классный руководитель
          (медицинская сестра, дежурный воспитатель) незамедлительно информирует
          его родителей (законных представителей) об оказанной медицинской
          помощи.
        </p>
        <a href="https://forms.yandex.ru/cloud/65e568e390fa7b13033fa644/">
          Просим вас оценить качество предоставленной услуги
        </a>
      </section>
    </div>
  );
});
