import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import prikaz337 from "../../../../../assets/pdf/home/Приказ № 337-1 от 01.09.2023 Коррупц.состав комис..PDF";
import prikaz352 from "../../../../../assets/pdf/home/Приказ № 352-1 от 06.09.2023 Корруп.состав ком..PDF";
import { ReactComponent as PDF } from "../../../../../assets/svg/office/pdf.svg";
export const Commission = observer(() => {
  return (
    <>
      {" "}
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Комиссия по соблюдению требований к служебному поведению и
        урегулированию конфликта интересов (аттестационная комиссия)
      </h2>
      <ul>
        <li>
          <a
            href="http://www.kremlin.ru/acts/bank/31341"
            target={"_blank"}
            rel="noreferrer"
          >
            ПОЛОЖЕНИЕ о комиссиях по соблюдению требований к служебному
            поведению федеральных государственных служащих и урегулированию
            конфликта интересов
          </a>
        </li>
        <li>
          <PDF width="25px" height="25px" />{" "}
          <a href={prikaz337} target={"_blank"} rel="noreferrer">
            Приказ № 337-1 от 01.09.2023
          </a>
        </li>
        <li>
          {" "}
          <PDF width="25px" height="25px" />{" "}
          <a href={prikaz352} target={"_blank"} rel="noreferrer">
            Приказ № 352-1 от 06.09.2023
          </a>
        </li>
      </ul>
    </>
  );
});
