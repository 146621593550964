import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { toggleClassName } from "../utils/disabled";
import { ChapterMenu } from "../components/ui";
import { NewsNavbar } from "../components/ui";
import { InfoPage } from "../components/page";
import { ApplicantsPage } from "../components/page";
import { LiveCardContainer } from "../components/common";
import { HrLine } from "../components/common";
import { PartnersContainer } from "../components/ui";
import { Carousel } from "../components/common";

export const Main = observer(() => {
  const [mainComponents, setMainComponents] = useState("news");

  const linksArray = [
    { title: "Главная", path: "/" },
    { title: "О филиале", path: "/home/aboutBranch" },
    { title: "Историческая справка", path: "/home/historicalReference" },
    { title: "Новости", path: "/home/univercityNews" },
    { title: "Противодействие коррупции", path: "/home/anti-corruption" },
    /*  { title: "План мероприятий", path: "/home/univercityEvents" }, */
    { title: "Предоставляемые услуги", path: "/home/services" },
    { title: "Интервью с выпускниками", path: "live/interview" },
    { title: "Государство для людей", path: "/home/stateForPeople" },
    { title: "Независимая оценка качества", path: "/home/nok" },
    { title: "Вопросы и ответы", path: "/learning/cadets" },
    { title: "Навигация по сайту", path: "/home/site-map" },
  ];

  return (
    <>
      <div className="main-mobileMenu">
        <ChapterMenu linksArray={linksArray} />
      </div>
      <Carousel />
      <a
        href="https://bus.gov.ru/info-card/516047"
        target="_blank"
        rel="noreferrer"
      >
        <div className="main__banner-container">
          <img
            src="/img/Вы довольны.svg"
            style={{ cursor: "pointer" }}
            width={"100%"}
            alt=""
          />
        </div>{" "}
      </a>
      {/* <div style={{ maxWidth: "1300px", margin: "auto" }}>
        <video src={"video.mp4"} controls width={"100%"}>
          <source src={"video.mp4"} type="video/mp4" />
          <p>Ваш браузер не поддерживает HTML5 видео.</p>
        </video>
      </div> */}
      <div
        className={toggleClassName(
          "_container",
          "_container-white",
          "_container-black",
          "_container-contrast"
        )}
      >
        <NewsNavbar
          mainComponents={mainComponents}
          setMainComponents={setMainComponents}
        />
      </div>
      <div>
        <div
          className={toggleClassName(
            "_container-main",
            "_container-white",
            "_container-black",
            "_container-contrast"
          )}
        >
          {mainComponents === "news" ? (
            <InfoPage />
          ) : mainComponents === "applicants" ? (
            <ApplicantsPage />
          ) : null}

          {/*     {params.element === "events" ? <EventsPages /> : null} */}
          {/*       {params.element === "security" ? <SecurityPage /> : null} */}
        </div>
      </div>
      <LiveCardContainer />
      <HrLine />
      <PartnersContainer />
    </>
  );
});
