import React, { useEffect, useState } from "react";
import { EducationContainer } from "../../common";
import { EmployeeStore } from "../../../store/employee-store";
import { useNavigate, useParams } from "react-router";
import { Button, Card, Flex, Form, Modal, Select, Typography } from "antd";
import { deleteEmployee } from "../../../httpService/employee-api";
import { observer } from "mobx-react-lite";
import { DaysStore } from "../../../store/days-store";
import { transformDayOfWeek } from "../../../utils/utils";
import { deleteDays } from "../../../httpService/days-api";

export const EmployeePage = observer(() => {
  const [form] = Form.useForm();
  const [removeLoading, setRemoveLoading] = useState(false);
  const [errorDelite, setErrorDelite] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();

  const { currentEmployee, isLoadingCurrentEmployee, loadOneEmployee } =
    EmployeeStore;
  const {
    createDays,
    isCreateDaysLoading,
    errorOfCreateingDays,
    isLoadingDays,
    days,
    remooveDay,
    loadDaysByEmployeeId,
  } = DaysStore;
  useEffect(() => {
    loadOneEmployee(id);
    loadDaysByEmployeeId(id);
  }, [id, loadOneEmployee, loadDaysByEmployeeId]);

  const navigate = useNavigate();
  if (isLoadingCurrentEmployee || currentEmployee === null)
    return "Загрузка...";

  // Логика работы с модалкой
  const onDelete = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setRemoveLoading(true);
    deleteEmployee(id)
      .then((data) => {
        if (data === "Сотрудник удален") {
          setIsModalOpen(false);
          navigate(-1);
        } else {
          throw new Error("Не удалось удалить сотрудника");
        }
      })
      .catch(() => setErrorDelite("Не удалось удалить сотрудника!"))
      .finally(() => {
        setRemoveLoading(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //конец логики работы с модаллкой
  const optionsOfRecordingDurationTime = [
    {
      value: 5,
      label: "5 минут",
    },
    {
      value: 10,
      label: "10 минут",
    },
    {
      value: 15,
      label: "15 минут",
    },
    {
      value: 20,
      label: "20 минут",
    },
    {
      value: 25,
      label: "25 минут",
    },
    {
      value: 30,
      label: "30 минут",
    },
  ];
  const optionsOfSelectedDay = [
    { label: "Понедельник", value: 0 },
    { label: "Вторник", value: 1 },
    { label: "Среда", value: 2 },
    { label: "Четверг", value: 3 },
    { label: "Пятница", value: 4 },
    { label: "Суббота", value: 5 },
  ];
  const optionsOfHours = [
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
  ];
  const optionsOfMinutes = [
    { label: "00", value: 0 },
    { label: "05", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "30", value: 30 },
    { label: "35", value: 35 },
    { label: "40", value: 40 },
    { label: "45", value: 45 },
    { label: "50", value: 50 },
    { label: "55", value: 55 },
  ];

  //валидация полей
  function validateTime() {
    const days = form.getFieldsValue();
    const startH = days.startH;
    const startM = days.startM;
    const endH = days.endH;
    const endM = days.endM;
    if (startH && startM && endH && endM) {
      const differnce = endH * 60 + endM - (startH * 60 + startM);
      if (differnce <= 0) {
        return Promise.reject(
          new Error(
            "Время окончания записи должно быть больше времени начала записи!"
          )
        );
      }
    } else {
      return Promise.resolve();
    }
  }
  function validateRecording() {
    const days = form.getFieldsValue();
    console.log(days);
    const startH = days.startH;
    const startM = days.startM;
    const endH = days.endH;
    const endM = days.endM;
    const recordingDuration = days.recordingDuration;
    if (!recordingDuration) {
      return Promise.reject(new Error("Укажите длительность приема!"));
    }
    if (startH && startM && endH && endM && recordingDuration) {
      const remainder =
        (endH * 60 + endM - (startH * 60 + startM)) % recordingDuration;
      if (remainder !== 0) {
        return Promise.reject(
          new Error(
            "Время длительности записи должно быть кратно времени всей записи!"
          )
        );
      }
    } else {
      return Promise.resolve();
    }
  }

  function handleSubmit(e) {
    console.log(e);
    form
      .validateFields()
      .then(() => {
        createDays({ ...e, employeeId: Number(id) }, form.resetFields);
      })
      .catch((e) => console.log(e));
  }

  return (
    <EducationContainer title="Карточка сотрудника">
      <Flex justify="space-between">
        <Button onClick={() => navigate(-1)}>Назад</Button>
        <Button onClick={onDelete} danger type="primary">
          Удалить
        </Button>
      </Flex>
      <Flex justify="center" wrap="wrap">
        <Card
          title={currentEmployee.post}
          style={{ width: "800px", alignItems: "center", margin: "15px" }}
        >
          <Flex>
            <div>
              {" "}
              <div>
                ФИО:{" "}
                {currentEmployee.surname +
                  " " +
                  currentEmployee.name +
                  " " +
                  currentEmployee.lastname}
              </div>
              <div>Почта: {currentEmployee.email}</div>
            </div>
            <div className="employee-page-days-card">
              {isLoadingDays && "Загрузка..."}
              {days.map((d) => (
                <div key={d.dayOfWeek} className="day">
                  <div>
                    <div>{transformDayOfWeek(d.dayOfWeek)}</div>
                    <div>Длительность приема: {d.recordingDuration} минут</div>
                  </div>
                  <div>{`${d.startH} ч ${d.startM} мин - ${d.endH} ч ${d.endM}`}</div>
                  <Button danger onClick={() => remooveDay(d.id)}>
                    Удалить
                  </Button>
                </div>
              ))}
            </div>
          </Flex>
        </Card>

        <Form name="createDays" form={form} onFinish={handleSubmit}>
          <Card
            size="small"
            title={`Выберите день приёма`}
            style={{ margin: "15px", maxWidth: "400px" }}
          >
            <Form.Item
              label="Выберите день недели"
              name={"dayOfWeek"}
              rules={[
                {
                  required: true,
                  message: "Выберите день недели!",
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Выберите день недели"
                options={optionsOfSelectedDay}
              />
            </Form.Item>
            <Form.Item
              label="Длительность записи"
              name={"recordingDuration"}
              rules={[
                {
                  required: true,
                  message: "Укажите длительность приема!",
                },
                /* {
                  validator: validateRecording,
                }, */
              ]}
            >
              <Select
                placeholder="Выберите длительность приема"
                style={{
                  width: "100%",
                }}
                options={optionsOfRecordingDurationTime}
              />
            </Form.Item>
            <Typography>Укажите начало записи:</Typography>
            <Flex
              align="center"
              justify="center"
              wrap="wrap"
              style={{ width: "100%" }}
            >
              <Form.Item
                name={"startH"}
                rules={[
                  {
                    required: true,
                    message: "Укажите длительность приема!",
                  },
                ]}
                style={{
                  width: "48%",
                  margin: "1%",
                }}
              >
                <Select
                  placeholder="Часы"
                  style={{
                    width: "100%",
                  }}
                  options={optionsOfHours}
                />
              </Form.Item>
              <Form.Item
                name={"startM"}
                rules={[
                  {
                    required: true,
                    message: "Укажите длительность приема!",
                  },
                ]}
                style={{
                  width: "48%",
                  margin: "1%",
                }}
              >
                <Select
                  placeholder="Минуты"
                  style={{
                    width: "100%",
                  }}
                  options={optionsOfMinutes}
                />
              </Form.Item>
            </Flex>
            <Typography>Укажите окончание записи:</Typography>
            <Flex
              align="center"
              justify="center"
              style={{ width: "100%" }}
              wrap="wrap"
            >
              <Form.Item
                name={"endH"}
                rules={[
                  {
                    validator: validateTime,
                  },
                  {
                    required: true,
                    message: "Укажите длительность приема!",
                  },
                ]}
                style={{
                  width: "48%",
                  margin: "1%",
                }}
              >
                <Select
                  placeholder="Часы"
                  style={{
                    width: "100%",
                  }}
                  options={optionsOfHours}
                />
              </Form.Item>
              <Form.Item
                name={"endM"}
                rules={[
                  {
                    validator: validateTime,
                  },
                  {
                    required: true,
                    message: "Укажите длительность приема!",
                  },
                ]}
                style={{
                  width: "48%",
                  margin: "1%",
                }}
              >
                <Select
                  placeholder="Минуты"
                  style={{
                    width: "100%",
                  }}
                  options={optionsOfMinutes}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                style={{ margin: "20px", width: "200px" }}
              >
                {" "}
                {isCreateDaysLoading ? "Загрузка..." : "+ Добавитьдень приёма"}
              </Button>
              <Typography>{errorOfCreateingDays}</Typography>
            </Flex>
          </Card>
        </Form>
      </Flex>

      <Modal
        title="Удалить сотрудника?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="Удалить"
        okType="danger"
      >
        <p>{removeLoading ? "Загрузка" : "Сотрудник будет удален!"}</p>
        <p>{errorDelite}</p>
      </Modal>
    </EducationContainer>
  );
});
