import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import img1 from "../../../../assets/img/certificate/1.png";
import img2 from "../../../../assets/img/certificate/2.png";
export const Certificate = observer(() => {
  return (
    <div className="reviews_container">
      <h1 style={{ marginBottom: "25px" }}>
        Для заказа услуги, выберите услугу, перейдите по ссылке или отсканируйте
        QR-код
      </h1>
      <section>
        <div>
          {" "}
          <div className="content">
            <p>
              Справка о наличии стипендии{" "}
              <a
                href="https://forms.yandex.ru/cloud/6405c618c417f382a7918351/"
                target="_blank"
                rel="noopener noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                Заказать справку
              </a>
            </p>
          </div>
          <div className="image">
            <img
              style={{ margin: "10px" }}
              src={img1}
              width={250}
              alt="изображение уплыло"
            />
          </div>
        </div>
      </section>{" "}
      <section>
        <div>
          {" "}
          <div className="content">
            <p>
              Справка об обучении{" "}
              <a
                href="https://forms.yandex.ru/cloud/6406b0975d2a060e90350569/"
                target="_blank"
                rel="noopener noreferrer"
                className={toggleClassName(
                  "education__link",
                  "education__link-white",
                  "education__link-black",
                  "education__link-contrast"
                )}
              >
                Заказать справку
              </a>
            </p>
          </div>
          <div className="image">
            <img
              style={{ margin: "10px" }}
              src={img2}
              width={250}
              alt="изображение уплыло"
            />
          </div>
        </div>
      </section>
    </div>
  );
});
