import React from "react";
import { toggleClassName } from "../../../../utils/disabled";

import { ReactComponent as WORD } from "../../../../assets/svg/office/word.svg";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

import prikazStoimost from "../../../../assets/pdf/enrolle/приказ № 187-с от 17.04.2024 о стоимости обучения.pdf";
import prikazStoimostPril from "../../../../assets/pdf/enrolle/Приложение к приказу №187-с от 17.04.2024 г. О стоимости обучения на 2024-2025 уч год в Уфимском филиале.docx";

import prikaz221s from "../../../../assets/pdf/applicant/приказ № 221-с от 07.05.2024 стоим.обуч..pdf";
import PoryadPlatnUslug from "../../../../assets/pdf/documentPage/Poryadok_platn_uslug.pdf";
import PoryadPlatnObrUslug21 from "../../../../assets/pdf/applicant/Dogovor_platn_obraz_uslug_2024.pdf";
export const PaidEdu = () => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Платные образовательные услуги
      </h1>
      <p>
        Оказание платных образовательных услуг производится по договорам,
        заключенным с юридическими и физическими лицами с оплатой на основании
        смет, составленных по тарифам и ценам, утвержденным в установленном
        порядке прейскурантами, а при отсутствии их по договорным тарифам и
        ценам, установленным филиалом в индивидуальном порядке по соглашению
        сторон. Сметы составляются экономистом, подписываются заведующим центром
        дополнительного образования и утверждаются директором филиала. Отдельные
        сметы рассматриваются и утверждаются на Совете филиала.
      </p>
      <p>
        Оплата может производится за учебный год или за семестр, за безналичный
        расчет, и должна быть произведена до начала учебного года или семестра.
      </p>
      <ul>
        <li className="doc__item">
          <PDF height={"40px"} width={"40px"} />
          <a
            itemProp="paidEdu"
            href={PoryadPlatnUslug}
            target={"_blank"}
            rel="noreferrer"
          >
            Положение об оказании платных образовательных услуг
          </a>
        </li>
        <li className="doc__item">
          <PDF height={"40px"} width={"40px"} />
          <a
            itemProp="paidDog"
            href={PoryadPlatnObrUslug21}
            target={"_blank"}
            rel="noreferrer"
          >
            Образец договора об оказании платных образовательных услуг
          </a>
        </li>
        <li>
          <PDF width={"40px"} height={"40px"} />{" "}
          <a
            href={prikaz221s}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            itemProp="paidSt"
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 221-с от 7.05.2024 о стоимости обучения поступающих на 1
            курс в 2024 учебном году по договорам об образовании
          </a>
        </li>

        <li>
          <PDF width={"40px"} height={"40px"} />{" "}
          <a
            href={prikazStoimost}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приказ № 187-с от 17.04.2024 о стоимости обучения
          </a>
        </li>
        <li>
          <WORD width={"40px"} height={"40px"} />{" "}
          <a
            href={prikazStoimostPril}
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
            target={"_blank"}
            rel="noreferrer"
          >
            Приложение к приказу №187-с от 17.04.2024 г. О стоимости обучения на
            2024-2025 уч год в Уфимском филиале
          </a>
        </li>
        <li className="doc__item" itemProp="paidParents">
          Документ об установлении размера платы, взимаемой с родителей
          (законных представителей) за присмотр и уход за детьми, осваивающими
          образовательные программы дошкольного образования в организациях,
          осуществляющих образовательную деятельность, за содержание детей в
          образовательной организации, реализующей образовательные программы
          начального общего, основного общего или среднего общего образования,
          если в такой образовательной организации созданы условия для
          проживания обучающихся в интернате, либо за осуществление присмотра и
          ухода за детьми в группах продленного дня в образовательной
          организации, реализующей образовательные программы начального общего,
          основного общего или среднего общего образования.{" "}
          <b>
            (Образовательные программы дошкольного, начального общего, основного
            общего, среднего общего образования не реализуются.)
          </b>
        </li>
      </ul>
    </div>
  );
};
