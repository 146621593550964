import { $authHost, $host } from "./index";

export const createEmployee = async (employee) => {
  const { data } = await $authHost.post("api/employee", employee);
  return data;
};
export const editEmployee = async (employee) => {
  const { data } = await $authHost.put("api/employee", employee);
  return data;
};
export const fetchEmployee = async () => {
  const { data } = await $host.get("api/employee/");
  return data;
};
export const fetchOneEmployee = async (id) => {
  const data = await $host.get("api/employee/" + id);
  return data;
};
export const deleteEmployee = async (id) => {
  const { data } = await $authHost.delete("api/employee/" + id);

  return data;
};
