import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { observer } from "mobx-react-lite";
import vipusk from "../../../../assets/img/reviews/vipusk.png";
import work from "../../../../assets/img/reviews/work.png";
import prakt from "../../../../assets/img/reviews/prakt.png";
import pedagogi from "../../../../assets/img/reviews/pedagogi.png";
import ank from "../../../../assets/img/reviews/qr-code (2).png";
import qr from "../../../../assets/img/nok/qr.png";
export const Reviews = observer(() => {
  return (
    <>
      <div className="reviews_container">
        <h1>Анкетирование</h1>
        <section>
          <h2>Уважаемые родители и курсанты, посетители Уфимского филиала!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                Приглашаем Вас принять участие в опросе для независимой оценки
                качества условий оказания услуг.{" "}
              </p>
              <p>Вы сможете оценить условия оказания услуг: </p>
              <ul style={{ textAlign: "start" }}>
                <li>
                  «Комфорт и санитарно-гигиеническое состояние помещений»{" "}
                </li>
                <li>«Доброжелательность и вежливость работников»</li>
                <li>
                  «Легкость получения информации о работе организации и ее
                  точность»
                </li>
                <li>
                  «Легкость записи для получения услуги и своевременность ее
                  оказания»
                </li>
                <li>«Доступность для инвалидов».</li>
              </ul>
              <p>
                Кроме того, каждый посетитель может оставить отзыв, обращение и
                получить на него ответ. Ваше мнение позволит улучшить работу
                образовательной организации и повысить качество образования.
              </p>
              <p>
                Ваша оценка поможет нам стать лучше и убедиться, что все хорошо!
              </p>
              <p>
                Чтобы оценить УСЛОВИЯ предоставления услуг наведите камеру
                Вашего телефона и сканируйте QR-код или перейдите по{" "}
                <a
                  href="https://bus.gov.ru/info-card/516047"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  ссылке
                </a>
              </p>{" "}
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={qr} width="250" alt="изображение уплыло" />
            </div>
          </div>
        </section>
        <section>
          <h2>Уважаемые студенты!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                Уважаемые студенты и курсанты! Нам важно знать ваше мнение об
                условиях и качестве обучения, поэтому предлагаем вам принять
                участие в анкетировании по следующей ссылке:{" "}
                <a
                  href="https://forms.yandex.ru/cloud/65e97f6f73cee70185416be2/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Анкета студента
                </a>
              </p>
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={ank} alt="изображение уплыло" />
            </div>
          </div>
        </section>
        <section>
          <h2>Уважаемые работодатели!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                Уважаемые работодатели! С целью повышения качества образования,
                просим вас принять участие в опросе по следующей ссылке:{" "}
                <a
                  href="https://forms.yandex.ru/u/6571a759c769f108ebbf9bf6/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Анкета работодателя
                </a>
              </p>
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={work} alt="изображение уплыло" />
            </div>
          </div>
        </section>
        <section>
          <h2>Уважаемые студенты!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                Уважаемые студенты и курсанты! Практика это важный этап
                становления профессионала. Нам важно знать ваши впечатления о
                том, как была организована практика, поэтому просим принять
                участие в анкетировании по следующей ссылке:{" "}
                <a
                  href="https://forms.yandex.ru/u/6566b7e073cee7258f0d5de8/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Анкета студента, прошедшего практику
                </a>
              </p>
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={prakt} alt="изображение уплыло" />
            </div>
          </div>
        </section>
        <section>
          <h2>Уважаемые выпускники!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                В целях совершенствования качества образования мы проводим
                анкетирование наших выпускников. Ответив на вопросы нашей
                анкеты, Вы поможете нам сделать процесс обучения в нашем филиале
                еще более эффективным. Для этого вам нужно заполнить анкету.
                Ваше мнение важно для нас!{" "}
                <a
                  href="https://forms.yandex.ru/u/6572bba150569027c63cbd62/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Анкета выпускника
                </a>
              </p>
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={vipusk} alt="изображение уплыло" />
            </div>
          </div>
        </section>
        <section>
          <h2>Уважаемые сотрудники!</h2>
          <div>
            {" "}
            <div className="content">
              <p>
                В целях совершенствования качества образования мы проводим
                анкетирование наших педагогических работников. Ответив на
                вопросы нашей анкеты, Вы поможете нам сделать процесс обучения и
                условия труда в нашем филиале еще лучше. Для этого вам нужно
                заполнить анкету. Ваше мнение важно для нас!{" "}
                <a
                  href="https://forms.yandex.ru/u/657bc875d04688a12a6bc577/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Анкета педагогического работника
                </a>
              </p>
            </div>
            <div style={{ width: "250px" }} className="image">
              <img src={pedagogi} alt="изображение уплыло" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
});
