import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import doc1 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/1. РП  ПМ.01. 23.02.01.pdf";
import doc2 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/2. РП  ПМ.02. 23.02.01.pdf";
import doc3 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/3. РП  ПМ.03. 23.02.01.pdf";
import doc4 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/4. РП  ПМ.04. 23.02.01.pdf";
import doc5 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/5. РП ВЧ.01. Основы делопроизводства 23.02.01.pdf";
import doc6 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/6. РП ВЧ.02.Орг. дост.среды 23.02.01,.pdf";
import doc7 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/7. РП ВЧ.03. Коммерческая работа на транспорте 23.02.01.pdf";
import doc8 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/8. РП ВЧ.04. Страхование и риски 23.02.01.pdf";
import doc9 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/9. РП ВЧ.05. Экономика и управление на транспорте 23.02.01.pdf";
import doc10 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/10. РП ВЧ.06. Теория и устройство судна 23.02.01.pdf";
import doc11 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/11. РП ЕН.01. Математика 23.02.01.pdf";
import doc12 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/12. РП ЕН.02. Информатика 23.02.01.pdf";
import doc13 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/13. РП Иностранный язык 1 курс  23.02.01.pdf";
import doc14 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/14. РП Информатика 1 курс 23.02.01.pdf";
import doc15 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/15. РП История 1 курс 23.02.01,.pdf";
import doc16 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/16. РП Литература 23.02.01,.pdf";
import doc17 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/17. РП Математика 1 курс 23.02.01.pdf";
import doc18 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/18. РП ОБЖ 23.02.01,.pdf";
import doc19 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/19. РП ОГСЭ.01.Основы философии 23.02.01.pdf";
import doc20 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/20. РП ОГСЭ.02. История 23.02.01,.pdf";
import doc21 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/21. РП ОГСЭ.03.Иностранный язык 23.02.01.pdf";
import doc22 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/22. РП ОП.01. Инженерная графика 23.02.01.pdf";
import doc23 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/23. РП ОП.03.Метрология, стандартизация и сертификация 23.02.01.pdf";
import doc24 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/24. РП ОП.04 Транспортная система России 23.02.01.pdf";
import doc25 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/25. РП ОП.05. Технические средства (по видам транспорта) 23.02.01.pdf";
import doc26 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/26. РП ОП.06. ПОПД 23.02.01.pdf";
import doc27 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/27. РП ОП.07. Охрана труда 23.02.01.pdf";
import doc28 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/28. РП ОП.08. Безопасность жизнедеятельности 23.02.01, 2020.pdf";
import doc29 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/29. РП Русский язык 23.02.01,.pdf";
import doc30 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/30. РП Физика 23.02.01,.pdf";
import doc31 from "../../../../assets/pdf/educationPage/230201/4. РП ОП 2023/31. РП Химия 23.02.01.pdf";

export const OrganizationOfTransportation2023 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 23.02.01 "Организация
        перевозок и управление на транспорте" (год набора 2023)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc1}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ.01. 23.02.01
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc2}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ.02. 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc3}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ.03. 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc4}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ.04. 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc5}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.01. Основы делопроизводства 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc6}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.02.Орг. дост.среды 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc7}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.03. Коммерческая работа на транспорте 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc8}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.04. Страхование и риски 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc9}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.05. Экономика и управление на транспорте 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc10}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ВЧ.06. Теория и устройство судна 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc11}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ЕН.01. Математика 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc12}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ЕН.02. Информатика 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc13}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Иностранный язык 1 курс 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc14}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Информатика 1 курс 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc15}
                target={"_blank"}
                rel="noreferrer"
              >
                РП История 1 курс 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc16}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Литература 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc17}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Математика 1 курс 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc18}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОБЖ 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc19}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОГСЭ.01.Основы философии 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc20}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОГСЭ.02. История 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc21}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОГСЭ.03.Иностранный язык 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc22}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.01. Инженерная графика 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc23}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.03.Метрология, стандартизация и сертификация 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc24}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.04 Транспортная система России 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc25}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.05. Технические средства (по видам транспорта) 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc26}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.06. ПОПД 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc27}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.07. Охрана труда 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc28}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОП.08. Безопасность жизнедеятельности 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc29}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Русский язык 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc30}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Физика 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc31}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Химия 23.02.01
              </a>
            </td>
            <td></td>
          </tr>{" "}
        </tbody>
      </table>
    </section>
  );
};
