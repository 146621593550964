import React from "react";
import { NavLink } from "react-router-dom";
import Image1 from "../../../../../assets/pdf/educationPage/15. График учебного процесса2024.pdf";
import OOP5 from "../../../../../assets/pdf/educationPage/260206/2023/ОП ППССЗ  26. 02. 06  Эксплуатация судового электрического оборудования и средств автоматики 2023 г.п..pdf";
import educationPlan from "../../../../../assets/pdf/educationPage/260206/ЭМ 2022 гп/2. Учебный план   очное.pdf";
import educationPlanZ from "../../../../../assets/pdf/educationPage/260206/ЭМ 2022 гп/2. Учебный план заочное.pdf";
import rp_praktic from "../../../../../assets/pdf/educationPage/РП Практик/РП Практик 2023 ЭМ.zip";
import vr from "../../../../../assets/pdf/educationPage/Ссылка на методические и иные документы ЭМ.rar";

export const SPOEkspluatSudovEelectrooborud_23_260206 = ({ PDF }) => {
  return (
    <tr itemProp="eduOp">
      <td itemProp="eduCode">26.02.06</td>
      <td>
        <NavLink
          itemProp="eduName"
          to="../../educationPrograms/marineElectricalEquipment2023"
        >
          Эксплуатация судового электрооборудования и средств автоматики ФГОС
          №675 от 26.11.2020
        </NavLink>
      </td>
      <td itemProp="eduLevel">Cреднее профессиональное образование</td>
      <td>
        <NavLink
          itemProp="eduProf"
          to="../../educationPrograms/marineElectricalEquipment2023"
        >
          Эксплуатация судового электрооборудования и средств автоматики (прием
          2023)
        </NavLink>
      </td>
      <td itemProp="eduForm">Очная</td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a itemProp="opMain" href={OOP5} target={"_blank"} rel="noreferrer">
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp={"educationPlan"}
              href={educationPlan}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (очная)
            </a>
          </li>
          <li className="doc__item">
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp={"educationPlan"}
              href={educationPlanZ}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать (заочная)
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li className="doc__item">
            <NavLink
              itemProp="educationRpd"
              to="../../educationPrograms/marineElectricalEquipment2023"
            >
              Скачать РП
            </NavLink>
          </li>
        </ul>
      </td>
      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={Image1}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
      <td>
        {" "}
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={rp_praktic}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>

      <td>
        <ul>
          <li>
            <PDF height={"25px"} width={"25px"} />
            <a
              itemProp="educationShedule"
              href={vr}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать
            </a>
          </li>
        </ul>
      </td>
    </tr>
  );
};
