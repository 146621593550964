import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../../utils/disabled";
import { ReactComponent as WORD } from "../../../../../assets/svg/office/word.svg";
import doc1 from "../../../../../assets/pdf/home/формы/Заявление о выкупе подарка.docx";
import doc2 from "../../../../../assets/pdf/home/формы/Заявление работника о невозможности по объективным причинам.docx";
import doc3 from "../../../../../assets/pdf/home/формы/Обращение гражданина, юридического лица по фактам коррупционных правонарушений.docx";
import doc4 from "../../../../../assets/pdf/home/формы/Специальное программное обеспечение «Справки БК».docx";
import doc5 from "../../../../../assets/pdf/home/формы/Уведомление о получении подарка.doc";
import doc6 from "../../../../../assets/pdf/home/формы/Уведомление представителя нанимателя (работодателя).docx";
import doc7 from "../../../../../assets/pdf/home/формы/Уведомление представителя нанимателя (работодателя) о фактах.docx";
import doc8 from "../../../../../assets/pdf/home/формы/Форма для заполнения работником Уфимского филиала ФГБОУ ВО.docx";
import doc9 from "../../../../../assets/pdf/home/формы/Форма справки о доходах.rtf";
export const DocumentForms = observer(() => {
  return (
    <>
      <h2
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Формы документов, связанных с противодействием коррупции, для заполнения
      </h2>
      <ul>
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc1}
            target={"_blank"}
            rel="noreferrer"
          >
            Заявление о выкупе подарка
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc2}
            target={"_blank"}
            rel="noreferrer"
          >
            Заявление работника о невозможности по объективным причинам
            представить сведения о доходах своих супруги (супруга) и
            несовершеннолетних детей
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc3}
            target={"_blank"}
            rel="noreferrer"
          >
            ЗаявОбращение гражданина, юридического лица по фактам коррупционных
            правонарушений
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc4}
            target={"_blank"}
            rel="noreferrer"
          >
            Специальное программное обеспечение «Справки БК», размещенное на
            официальном сайте Президента Российской Федерации или на официальном
            сайте государственной информационной системы в области
            государственной службы
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc5}
            target={"_blank"}
            rel="noreferrer"
          >
            Уведомление о получении подарка
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc6}
            target={"_blank"}
            rel="noreferrer"
          >
            Уведомление представителя нанимателя (работодателя) и своего
            непосредственного начальника о возникшем конфликте интересов или о
            возможности его возникновения
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc7}
            target={"_blank"}
            rel="noreferrer"
          >
            Уведомление представителя нанимателя (работодателя) о фактах
            обращения в целях склонения работника к совершению коррупционных
            правонарушений
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc8}
            target={"_blank"}
            rel="noreferrer"
          >
            Форма для заполнения работником Уфимского филиала ФГБОУ ВО «Волжский
            государственный университет водного транспорта» о соблюдении
            отдельных ограничений и запретов
          </a>
        </li>{" "}
        <li>
          <WORD height={"25px"} width={"25px"} />{" "}
          <a
            itemProp="eduPriemEl"
            href={doc9}
            target={"_blank"}
            rel="noreferrer"
          >
            Форма справки о доходах, расходах, имуществе и обязательствах
            имущественного характера
          </a>
        </li>
      </ul>
    </>
  );
});
