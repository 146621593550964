import React from "react";
import { TableWrapper } from "../../../common";
import trud from "../../../../assets/xlsx/Трудоустройство на сайт.xlsx";
import { ReactComponent as EXCEL } from "../../../../assets/svg/office/excel.svg";
export const InfoOTrudoustr = () => {
  return (
    <>
      <TableWrapper
        title="7. Информация о трудоустройстве выпускников для каждой реализуемой образовательной программы, по которой состоялся выпуск
"
      >
        <table>
          <tbody>
            <tr>
              <td>Шифр</td>
              <td>
                Наименование профессии, специальности, направления подготовки
                наименование группы научных специальностей
              </td>
              <td>
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td>Количество выпускников</td>
              <td>Количество трудоустроенных выпускников</td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
            </tr>
            <tr itemProp="graduateJob">
              <td itemProp="eduCode">23.02.01</td>
              <td itemProp="eduName">
                Организация перевозок и управление на транспорте
              </td>
              <td itemProp="eduProf">
                Организация перевозок и управление на транспорте
              </td>
              <td itemProp="v1">24</td>
              <td itemProp="t1">21</td>
            </tr>
            <tr itemProp="graduateJob">
              <td itemProp="eduCode">26.02.03</td>
              <td itemProp="eduName">Судовождение (углубленная подготовка)</td>
              <td itemProp="eduProf">Судовождение (углубленная подготовка)</td>
              <td itemProp="v1">80</td>
              <td itemProp="t1">41</td>
            </tr>
            <tr itemProp="graduateJob">
              <td itemProp="eduCode">26.02.05</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduProf">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="v1">39</td>
              <td itemProp="t1">26</td>
            </tr>
            <tr itemProp="graduateJob">
              <td itemProp="eduCode">26.02.06</td>
              <td itemProp="eduName">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduProf">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="v1">42</td>
              <td itemProp="t1">30</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </>
  );
};
