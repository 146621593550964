import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import astronom1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/astronom11.pdf";
import BGHD1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/BGHD11.pdf";
import chimiya1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/chimiya11.pdf";
import elektronika1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/elektronika11.pdf";
import EOP1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/EOP11.pdf";

import filosof1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/filosof11.pdf";
import fiz_kult1_1kurs from "../../../../assets/pdf/educationPage/260205/workPrograms2020/fiz_kult11_1kurs.pdf";
import fizika1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/fisika11.pdf";
import inform1_1kurs from "../../../../assets/pdf/educationPage/260205/workPrograms2020/informatika11_1kurs.pdf";
import inform1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/informatika11.pdf";
import ingh_grafika1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/ingh_grafika11.pdf";
import inostr1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/inostr11.pdf";
import istor1_1kurs from "../../../../assets/pdf/educationPage/260205/workPrograms2020/istoriya11_1kurs.pdf";
import istor1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/istoriya11.pdf";

import matem1_1kurs from "../../../../assets/pdf/educationPage/260205/workPrograms2020/matem11.pdf";
import matem1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/matem11.pdf";
import mechanika1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/mechanika11.pdf";
import metrolog1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/metrolog11.pdf";
import OBGH1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/obgh11.pdf";
import PM01_uprabl_expl1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/PM01_expl_remont11.pdf";
import PM02_obesp_bezop_plav1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/PM02_obesp_bezop_plav11.pdf";
import PM03_otrab_razm_gruza1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/PM03_org_rab_struk11.pdf";
import PM04_analiz_effekt_sudna1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/PM04_profes11.pdf";

import prog_vospit1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/prog_vospit9.pdf";

import TUS1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/TUS11.pdf";
import biolog2 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/biologiya11.pdf";
import geograf2 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/geograf11.pdf";
import obshest2 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/obshesv11.pdf";
import fiz_kult1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/fiz_kult11.pdf";
import material1 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/materialoved11.pdf";

import expl_sudna11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/expl_sudna11.pdf";
import komp_grafika11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/komp_grafika11.pdf";
import rodn_liter11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/rodn_liter11.pdf";
import rodn_yaz11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/rodn_yaz11.pdf";
import termodin11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/termodin11.pdf";
import gidravlika11 from "../../../../assets/pdf/educationPage/260205/workPrograms2020/rodn_liter11.pdf";
import rpgiasm from "../../../../assets/pdf/educationPage/260205/workPrograms2020/РП ГИА СМ 2023-2024.doc";

export const ShipPowerPlants2020 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.05 "Эксплуатация
        судовых энергетических установок" 2020 года набора
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={astronom1}
                target={"_blank"}
                rel="noreferrer"
              >
                Астрономия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={BGHD1}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнидеятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={biolog2}
                target={"_blank"}
                rel="noreferrer"
              >
                Биология
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={chimiya1}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={elektronika1}
                target={"_blank"}
                rel="noreferrer"
              >
                Электроника и электротехника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={expl_sudna11}
                target={"_blank"}
                rel="noreferrer"
              >
                Эксплуатация судна на вспомогательном уровне
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={EOP1}
                target={"_blank"}
                rel="noreferrer"
              >
                Экологические основы природопользования
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={filosof1}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы философии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fiz_kult1_1kurs}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура, 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fiz_kult1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizika1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={geograf2}
                target={"_blank"}
                rel="noreferrer"
              >
                География
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform1_1kurs}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика, 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform1}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={ingh_grafika1}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inostr1}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык, 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inostr1}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor1_1kurs}
                target={"_blank"}
                rel="noreferrer"
              >
                {" "}
                История, 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor1}
                target={"_blank"}
                rel="noreferrer"
              >
                История
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={komp_grafika11}
                target={"_blank"}
                rel="noreferrer"
              >
                Компьютерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem1_1kurs}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика, 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem1}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={material1}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mechanika1}
                target={"_blank"}
                rel="noreferrer"
              >
                Механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrolog1}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология и стандартизация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={OBGH1}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы безопасности жизнедеятельности
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obshest2}
                target={"_blank"}
                rel="noreferrer"
              >
                Обществознание
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={PM01_uprabl_expl1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.01 Эксплуатация, техническое обслуживание и ремонт судового
                энергетического оборудования
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={PM02_obesp_bezop_plav1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.02 Обеспечение безопасности плавания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={PM03_otrab_razm_gruza1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.03 Организация работы структурного подразделения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={PM04_analiz_effekt_sudna1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ.04 Выполнение работы по профессии Моторист
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rodn_liter11}
                target={"_blank"}
                rel="noreferrer"
              >
                Родная литература
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>33</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={TUS1}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория и устройство судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>34</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={prog_vospit1}
                target={"_blank"}
                rel="noreferrer"
              >
                Рабочая программа воспитания обучающихся
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>35</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rodn_yaz11}
                target={"_blank"}
                rel="noreferrer"
              >
                Родной язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>36</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={termodin11}
                target={"_blank"}
                rel="noreferrer"
              >
                Термодинамика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>36</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={gidravlika11}
                target={"_blank"}
                rel="noreferrer"
              >
                Гидравлика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>37</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rpgiasm}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ГИА СМ 2023-2024
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
