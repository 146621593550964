import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import pm1 from "../../../../assets/pdf/educationPage/260206/2024/1. 26.02.06 РП ПМ1 прием 2024 РУП 22.pdf";
import fizra2 from "../../../../assets/pdf/educationPage/260206/2024/2. ЭМ Физкультура 2,3,4 курс.pdf";
import bz from "../../../../assets/pdf/educationPage/260206/2024/3. БЖ 26.02.06, 2024 г.п., РУП 22.pdf";
import biologia from "../../../../assets/pdf/educationPage/260206/2024/4. Биология ЭМ РУП 22.pdf";
import geografia from "../../../../assets/pdf/educationPage/260206/2024/5. География 26.02.06  РУП 22 2024 гп.pdf";
import inYaz from "../../../../assets/pdf/educationPage/260206/2024/6. Ин.яз. в проф.деят. 2-4 курс  26.02.06.pdf";
import inzGraf from "../../../../assets/pdf/educationPage/260206/2024/7. Инженерная графика 26.02.06, 2024 г.п., РУП 22.pdf";
import inYaz1k from "../../../../assets/pdf/educationPage/260206/2024/8. Иностранный язык 1к.  26.02.06 РУП 22.pdf";
import inform1 from "../../../../assets/pdf/educationPage/260206/2024/9. Информатика 1 курс 26.02.06, 2024 г.п., РУП 22.pdf";
import inform from "../../../../assets/pdf/educationPage/260206/2024/10. Информатика 26.02.06, 2024 г.п., РУП 22.pdf";
import istor1 from "../../../../assets/pdf/educationPage/260206/2024/11. История 1 курс РУП 22 26.02.06, 2024 г.п..pdf";
import istor2 from "../../../../assets/pdf/educationPage/260206/2024/12. История 2 курс 26.02.06, РУП 22 2024 г.п..pdf";
import liter from "../../../../assets/pdf/educationPage/260206/2024/13. Литература 26.02.06 2024 г.п..pdf";
import matem from "../../../../assets/pdf/educationPage/260206/2024/14. Математика 2 курс  26.02.06, РУП 22.pdf";
import matemRup from "../../../../assets/pdf/educationPage/260206/2024/15. Математика РУП 22.pdf";
import materialoved from "../../../../assets/pdf/educationPage/260206/2024/16. Материаловедение 26.02.06, 2024  г.п., РУП 22.pdf";
import metrolog from "../../../../assets/pdf/educationPage/260206/2024/17. Метрология и стандартизация 26.02.06, 2024 г.п., РУП 22.pdf";
import mehanica from "../../../../assets/pdf/educationPage/260206/2024/18. Механика 26.02.06 РУП 22    2024 г.п..pdf";
import obiz from "../../../../assets/pdf/educationPage/260206/2024/19. ОБиЗР 26.02.06, РУП 22 2024г.п..pdf";
import obshest from "../../../../assets/pdf/educationPage/260206/2024/20. Обществознание РУП 22   2024гп.pdf";
import filos from "../../../../assets/pdf/educationPage/260206/2024/21. Основы философии 26.02.06 РУП 22 2024 г.п..pdf";
import ainGram from "../../../../assets/pdf/educationPage/260206/2024/22. Основы финансовой грамотности 26.02.06, 2023 г.п. РУП 21.pdf";
import pm2 from "../../../../assets/pdf/educationPage/260206/2024/23. ПМ 2, 26.02.06, 2024 г.п., РУП 22.pdf";
import pm3 from "../../../../assets/pdf/educationPage/260206/2024/24. ПМ 03, 26.02.06, 2024 г.п., РУП 22.pdf";
import pm4 from "../../../../assets/pdf/educationPage/260206/2024/25. ПМ 4, 26.02.06, 2024 г.п. РУП 22.pdf";
import psiholog from "../../../../assets/pdf/educationPage/260206/2024/26. Психология общения 26.02.06, РУП 22  2024 г.п..pdf";
import russYaz from "../../../../assets/pdf/educationPage/260206/2024/27. Русский язык РУП 22  26.02.06 2024 п.pdf";
import tus from "../../../../assets/pdf/educationPage/260206/2024/28. ТУС 26.02.06, 2024 г.п., РУП 22.pdf";
import fizika1 from "../../../../assets/pdf/educationPage/260206/2024/29. Физика 26.02.06, РУП 22 2024.pdf";
import fizRa from "../../../../assets/pdf/educationPage/260206/2024/30. Физкультура  1 курс 2024 гп РУП 25.pdf";
import himia from "../../../../assets/pdf/educationPage/260206/2024/31. Химия 26.02.06 (2024.pdf";
import electron from "../../../../assets/pdf/educationPage/260206/2024/32. Электрон и электротех. ЭМ, 26.02.06, 2024 г.п. РУП 22.pdf";
import eop from "../../../../assets/pdf/educationPage/260206/2024/33. ЭОП 26.02.06, 2024 г.п., РУП 22.pdf";

export const MarineElectricalEquipment2024 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.06 "Эксплуатация
        судового электрооборудования и средств автоматики" (год набора 2024)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm1}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 01 Техническая эксплуатация судового электрооборудования и
                средств автоматики
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra2}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 2,3,4 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={bz}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнидейятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={biologia}
                target={"_blank"}
                rel="noreferrer"
              >
                Биология
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={geografia}
                target={"_blank"}
                rel="noreferrer"
              >
                География
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык в профессиональной деятельности
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inzGraf}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inYaz1k}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform1}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor1}
                target={"_blank"}
                rel="noreferrer"
              >
                История 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor2}
                target={"_blank"}
                rel="noreferrer"
              >
                История 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={liter}
                target={"_blank"}
                rel="noreferrer"
              >
                Литература
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matemRup}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={materialoved}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrolog}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология и стандартизация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mehanica}
                target={"_blank"}
                rel="noreferrer"
              >
                Механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obiz}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы безопасности и защиты родины
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obshest}
                target={"_blank"}
                rel="noreferrer"
              >
                Обществознание
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={filos}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы философии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={ainGram}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы финансовой грамотности в профессиональной деятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm2}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 02 Организация работы коллектива исполнителей
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm3}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 03 Обеспечение безопасности плавания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm4}
                target={"_blank"}
                rel="noreferrer"
              >
                ПМ 04 Выполнение работ по одной или нескольким профессиям
                рабочих, должностям служащих
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={psiholog}
                target={"_blank"}
                rel="noreferrer"
              >
                Психология общения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={russYaz}
                target={"_blank"}
                rel="noreferrer"
              >
                Русский язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tus}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория устройства судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizika1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizRa}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={himia}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={electron}
                target={"_blank"}
                rel="noreferrer"
              >
                Электротехника и электроника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>33</td>
            <td>
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={eop}
                target={"_blank"}
                rel="noreferrer"
              >
                Экологические основы природопользования
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
