import { observer } from "mobx-react-lite";
import React from "react";
import Doc1 from "../../../../../assets/pdf/employmentService/pismo_vopros.pdf";
import answer from "../../../../../assets/pdf/practice/22427 _О направлении вопросов и о.pdf";
import pismo from "../../../../../assets/pdf/employmentService/Письмо Минпросвещения России.pdf";
import { toggleClassName } from "../../../../../utils/disabled";
export const Faq = observer(() => {
  return (
    <section>
      <h1>
        Вопросы и ответы по организации практики и трудоустройства выпускников
      </h1>
      <p>
        На этой странице размещены популярные вопросы по организации практики и
        трудоустройства и ответы на эти вопросы. Задать свой вопрос или
        комментировать ответ Вы можете сообщением в нашей группе{" "}
        <a
          href="https://vk.com/vgavtufa"
          target={"_blank"}
          rel="noreferrer"
          className={toggleClassName(
            "education__link",
            "education__link-white",
            "education__link-black",
            "education__link-contrast"
          )}
        >
          ВКонтакте
        </a>{" "}
        или по{" "}
        <a
          href="mailto:opp@uf-vsuwt.ru"
          target={"_blank"}
          rel="noreferrer"
          className={toggleClassName(
            "education__link",
            "education__link-white",
            "education__link-black",
            "education__link-contrast"
          )}
        >
          электронным адресам службы трудоустройства.
        </a>
      </p>
      <p>
        Ответы на часто задаваемые вопросы, касающиеся правового регулирования
        практической подготовки обучающихся приведены в источниках.
      </p>

      <p>
        <b>Источник: </b>{" "}
        <ul>
          <li>
            {" "}
            <a
              href={Doc1}
              target={"_blank"}
              rel="noreferrer"
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              Письмо Минобрнауки России от 30.10.2020 № МН-5/20730 "О
              направлении вопросов-ответов" (вопрос №4)
            </a>
          </li>
          <li>
            <a
              href={answer}
              target={"_blank"}
              rel="noreferrer"
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              Письмо Минобрнауки России от 08.12.2021 г. №МН — 5/22427 «О
              направлении вопросов и ответов.
            </a>
          </li>
          <li>
            <a
              href={pismo}
              target={"_blank"}
              rel="noreferrer"
              className={toggleClassName(
                "education__link",
                "education__link-white",
                "education__link-black",
                "education__link-contrast"
              )}
            >
              Письмо Минпросвещения России «О возможности изменения места
              практики в период ее прохождения
            </a>
          </li>
        </ul>
      </p>
    </section>
  );
});
