import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import Pologen22 from "../../../../assets/pdf/documentPage/Pologen_2022.pdf";
import PolBuh from "../../../../assets/pdf/structPage/pol-buh.pdf";
import PolHoz from "../../../../assets/pdf/structPage/pol-hoz.pdf";
import PolOkp from "../../../../assets/pdf/structPage/pol-okp.pdf";
import PolPopr from "../../../../assets/pdf/structPage/pol-popr.pdf";
import PolPur from "../../../../assets/pdf/structPage/pol-pur.pdf";
import PolVospit from "../../../../assets/pdf/structPage/pol-vospit.pdf";

import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { TableWrapper } from "../../../common";
export const Struct = observer(() => {
  return (
    <div
      itemProp="copy"
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <TableWrapper
        title={`Структура и органы управления Уфимского филиала ФГБОУ ВО "ВГУВТ"`}
      >
        <table>
          <tbody>
            <tr>
              <td>
                Наименование органа управления/ структурного подразделения
              </td>
              <td>ФИО руководителя структурного подразделения</td>
              <td>Должность руководителя структурного подразделения</td>
              <td>Адрес местонахождения структурного подразделения</td>
              <td>Адрес официального сайта структурного подразделения</td>
              <td>Адреса электронной почты структурного подразделения</td>
              <td>
                Положение об органе управления/ о структурном подразделении
              </td>
            </tr>
            <tr>
              <td colSpan="7">
                <h3 className="struct-title">
                  Административно-управленческий персонал
                </h3>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name"></td>
              <td itemProp="fioPost">Ахмадеева Фарида Шариповна</td>
              <td itemProp="post">Директор</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #201#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:uf-vsuwt@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  uf-vsuwt@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={Pologen22}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о филиале
                </a>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name"></td>
              <td itemProp="fioPost">Мусина Гульфия Ириковна</td>
              <td itemProp="post">Заместитель директора по учебной работе</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #312#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:pur@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  pur@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name"></td>
              <td itemProp="fioPost">Акбашева Альбина Галимзяновна</td>
              <td itemProp="post">
                Заместитель директора по воспитательной работе
              </td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #320#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:uf-vsuwt@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  pvr@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolVospit}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПВР
                </a>
              </td>
            </tr>
            <tr>
              <td className="struct-title" colSpan="7">
                Подразделение учебной работы
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Учебный отдел</td>
              <td itemProp="fioPost">Щербакова Амина Байраковна</td>
              <td itemProp="post">Начальник учебного отдела</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #313#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:uch-otd@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  uch-otd@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Учебный отдел</td>
              <td itemProp="fioPost">Сиразетдинова Эльвира Раисовна</td>
              <td itemProp="post">Старший методист</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #317#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:metod@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  metod@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Учебный отдел</td>
              <td itemProp="fioPost">Кошелев Александр Вячеславович</td>
              <td itemProp="post">Инженер-программист</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #307#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:oit@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  oit@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">
                Судоводительско-судомеханическое отделение СПО
              </td>
              <td itemProp="fioPost">Ахмадуллина Лилия Рашитовна</td>
              <td itemProp="post">Заведующий отделением</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #315#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:spo-svm@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  spo-svm@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">
                Электромеханическое и организации перевозок отделение СПО
              </td>
              <td itemProp="fioPost">Миннулина Светлана Рафисовна</td>
              <td itemProp="post">Заведующий отделением</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #316#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:cpo-eop@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  cpo-eop@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">
                Отдел практической подготовки и трудоустройства выпускников
              </td>
              <td itemProp="fioPost">Еникеев Алик Гайсинович</td>
              <td itemProp="post">Начальник отдела</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #314#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:opp@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  opp@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">
                Отдел конвенционной подготовки и дополнительного образования
              </td>
              <td itemProp="fioPost">Белобородова Наталия Павловна</td>
              <td itemProp="post">Начальник отдела</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #302#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:okpdo@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  okpdo@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Учебный отдел</td>
              <td itemProp="fioPost">Баширова Альфия Анваровна</td>
              <td itemProp="post">Заведующий отделением</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #319#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:vo@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  vo@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПУР
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan="7">
                <h3 className="struct-title">
                  Подразделение воспитательной работы
                </h3>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Учебно-воспитательный отдел</td>
              <td itemProp="fioPost">Мещерякова Валентина Александровна</td>
              <td itemProp="post">Заведующий библиотекой</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #322#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:bibl@uf-vsuwt.ru​​​​​​​ "
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  bibl@uf-vsuwt.ru​​​​​​​ 
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolVospit}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПВР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Организационно-строевой отдел.</td>
              <td itemProp="fioPost">Шаймухаметов Даян Сафуанович</td>
              <td itemProp="post">Старший воспитатель</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #323#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:pvr@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  pvr@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolVospit}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ПВР
                </a>
              </td>
            </tr>{" "}
            <tr>
              <td colSpan="7">
                <h3 className="struct-title"></h3>
              </td>
            </tr>
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Центр организационно-правовой работы</td>
              <td itemProp="fioPost">Шабалина Елена Павловна</td>
              <td itemProp="post">Начальник центра</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #311#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:copr@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  copr@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPopr}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ЦОПР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Отдел кадров</td>
              <td itemProp="fioPost">Бармут Лиля Николаевна</td>
              <td itemProp="post">Начальник отдела</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #310#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:ok@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  ok@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolPopr}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ЦОПР
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Административно-хозяйственный отдел</td>
              <td itemProp="fioPost">Самушия Автандил Давидович</td>
              <td itemProp="post">Начальник отдела</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #326#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:aho@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  aho@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolHoz}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении АХО
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Бухгалтерия</td>
              <td itemProp="fioPost">Жоссан Елена Евгеньевна</td>
              <td itemProp="post">Главный бухгалтер</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #308#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:buh@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  buh@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolBuh}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении Бухгалтерия
                </a>
              </td>
            </tr>{" "}
            <tr itemProp="structOrgUprav">
              <td itemProp="name">Отдел коллективного питания</td>
              <td itemProp="fioPost">Макулова Зиля Шамиловна</td>
              <td itemProp="post">Заведующий производством</td>
              <td itemProp="addressStr">
                450017, Приволжский ФО, Республика Башкортостан, г. Уфа, ул.
                Ахметова, 275 т. <br />
                <a href="tel:+73472151400">+7(347) 215-14-00</a> доп. #325#
              </td>
              <td itemProp="site">
                <a
                  href="https://rivercollege.ru/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.rivercollege.ru
                </a>
              </td>
              <td itemProp="email">
                <a
                  href="mailto:kolpit@uf-vsuwt.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  kolpit@uf-vsuwt.ru
                </a>
              </td>
              <td itemProp="divisionClauseDocLink">
                <PDF width={"25px"} height={"25px"} />{" "}
                <a
                  href={PolOkp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={toggleClassName(
                    "education__link",
                    "education__link-white",
                    "education__link-black",
                    "education__link-contrast"
                  )}
                >
                  Положение о подразделении ОКП
                </a>
              </td>
            </tr>{" "}
          </tbody>
        </table>
      </TableWrapper>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о филиалах (при наличии)
      </h3>
      <table className="">
        <tbody>
          <tr>
            <td>№ п/п</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Наименование филиала</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Адрес места нахождения</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Режим и график работы</td>
            <td>-</td>{" "}
          </tr>
          <tr>
            <td>Контактные телефоны</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Электронная почта</td>
            <td>-</td>
          </tr>
          <tr>
            <td>
              Адрес официального сайта или страницы филиала в сети "Интернет"
              (при наличии)
            </td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Сведения о представительствах (при наличии)
      </h3>
      <div className="common__scrollTable">
        <table className="">
          <tbody>
            <tr>
              <td>№ п/п</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Наименование филиала</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Адрес места нахождения</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Режим и график работы</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Контактные телефоны</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Электронная почта</td>
              <td>-</td>
            </tr>
            <tr>
              <td className="">
                Адрес официального сайта или страницы филиала в сети "Интернет"
                (при наличии)
              </td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
