import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
export const OpenDay = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <div
        className={` ${toggleClassName(
          "min-height",
          "min-height-white",
          "min-height-black",
          "min-height-contrast"
        )}`}
      >
        <h1 className="education__container-title">Вниманию абитуриентов!</h1>
        <h2>
          20 июня в 14:00 Уфимском филиале ФГБОУ ВО «Волжский государственный
          университет водного транспорта» пройдет День открытых дверей
        </h2>
        <div className="education__container-content">
          <p>
            Приглашаем выпускников 9-х и 11-х классов школ, лицеев, гимназий,
            выпускников учреждений среднего профессионального образования
            принять участие в открытии приёмной кампании 2024 года «По фарватеру
            в будущее». Участников встречи, которая пройдет 20 июня
            (ул.Ахметова,275), ждет информация по вопросам поступления на
            среднее профессиональное образование и высшее образование в 2024
            году.
          </p>
          <p>
            Открытие приёмной кампании — это возможность получить информацию о
            правилах приёма, о направлениях подготовки и познакомиться с
            преподавателями спец. дисциплин.
          </p>
          <p>
            Наш адрес: <strong>г.Уфа, Затон, ул. Ахметова, 275.</strong>
          </p>
          <p>
            Проезд автобусами №№ 30,30 К, 107, 108, 112, марш. такси 248, 391 до
            ост. «Речное училище».
          </p>
          <p>
            Тел.: <strong>8 (347) 215-14-00 доп. 3.</strong>
          </p>
        </div>
      </div>
    </section>
  );
});
