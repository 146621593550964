import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import doc1 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/1. РП СВ Физкультура 2-5 курс новый.pdf";
import doc2 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/2. РП БЖ СВ 26.02.03 РУП 25 2023 гп.pdf";
import doc3 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/3. РП Биология СВ РУП 25.pdf";
import doc4 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/4. РП География 26.02.03 РУП 2023.pdf";
import doc5 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/5. РП Инженерная графика СВ РУП 25 2023 гп.pdf";
import doc6 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/6. РП Иностранный язык 1к.СВ 26.02.03 РУП 25.pdf";
import doc7 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/7. РП Информатика 1 курс 26.02.03, 2023 г.п., РУП 25.pdf";
import doc8 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/8. РП Информатика 26.02.03, 2023 г.п., РУП 25.pdf";
import doc9 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/9. РП История 1 курс РУП 24 26.02.03, 2022 г.п. с курсом  Россия- моя история.pdf";
import doc10 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/10. РП История 2 курс 26.02.03, РУП 25 2023 г.п..pdf";
import doc11 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/11. РП ИЯ в ПД 2-5 курс СВ 26.02.03.pdf";
import doc12 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/12. РП Литература 26.02.03, 2023 г.п..pdf";
import doc13 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/13. РП Математика 2 курс  26.02.03, 2023 г.п., РУП 25.pdf";
import doc14 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/14. РП Материаловедение 26.02.03, РУП 25.pdf";
import doc15 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/15. РП Метрология и стандартизация 26.02.03. РУП 25 2023 гп.pdf";
import doc16 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/16. РП Механика 26.02.03. РУП 25.pdf";
import doc17 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/17. РП ОБЖ 26.02.03, РУП 25 2023г.п..pdf";
import doc18 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/18. РП Обществознание СВ.pdf";
import doc19 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/19. РП Основы философии 26.02.03, РУП 25 2023 г.п..pdf";
import doc20 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/20. РП Основы финансовой грамотности 26.02.03, РУП 25 2023 г.pdf";
import doc21 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/21. РП ПМ 02 26.02.03 2023 гп  РУП 25.pdf";
import doc22 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/22. РП ПМ 4 26.02.03.РУП 25.pdf";
import doc23 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/23. РП ПМ.03 РУП 23,24.pdf";
import doc24 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/24. РП ПМ1 26.02.03   РУП.pdf";
import doc25 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/25. РП ПМ05.pdf";
import doc26 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/26. РП Психология общения 26.02.03, РУП 25.pdf";
import doc27 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/27. РП Русский язык РУП 25  26.02.03 2023гп.pdf";
import doc28 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/28. РП Техническая термодинамика и теплопередача 26.02.03, 2023 г.п. РУП 25.pdf";
import doc29 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/29. РП ТУС 26.02.03, РУП 25  2023 г.п..pdf";
import doc30 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/30. РП Физика 26.02.03, РУП 25 2023.pdf";
import doc31 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/31. РП Физкультура  1 курс 26.02.03 РУП 25 2023 гп.pdf";
import doc32 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/32. РП Химия 26.02.06, РУП 212023 г.п..pdf";
import doc33 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/33. РП Электроника и электротехника  26.02.03. РУП 25.pdf";
import doc34 from "../../../../assets/pdf/educationPage/260203/1. РП СВ 2023/34. РП ЭОП 26.02.03,  РУП 25 2023 г.п..pdf";

export const Navigation2023 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.03 "Судовождение"
        (углубленная подготовка) (год набора 2023)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc1}
                target={"_blank"}
                rel="noreferrer"
              >
                СВ Физкультура 2-5 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc2}
                target={"_blank"}
                rel="noreferrer"
              >
                СВ 26.02.03 РУП 25 2023 гп
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc3}
                target={"_blank"}
                rel="noreferrer"
              >
                Биология СВ РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc4}
                target={"_blank"}
                rel="noreferrer"
              >
                География 26.02.03 РУП 2023
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc5}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика СВ РУП 25 2023 гп
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc6}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык 1к.СВ 26.02.03 РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc7}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 1 курс 26.02.03, 2023 г.п., РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc8}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 26.02.03, 2023 г.п., РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc9}
                target={"_blank"}
                rel="noreferrer"
              >
                История 1 курс РУП 24 26.02.03, 2022 г.п. с курсом Россия- моя
                история
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc10}
                target={"_blank"}
                rel="noreferrer"
              >
                РП История 2 курс 26.02.03, РУП 25 2023 г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc11}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ИЯ в ПД 2-5 курс СВ 26.02.03
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc12}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Литература 26.02.03, 2023 г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc13}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Математика 2 курс 26.02.03, 2023 г.п., РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc14}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Материаловедение 26.02.03, РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc15}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Метрология и стандартизация 26.02.03. РУП 25 2023 гп
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc16}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Механика 26.02.03. РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc17}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ОБЖ 26.02.03, РУП 25 2023г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc18}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Обществознание СВ
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc19}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Основы философии 26.02.03, РУП 25 2023 г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc20}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Основы финансовой грамотности 26.02.03, РУП 25 2023 г
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc21}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ 02 26.02.03 2023 гп РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc22}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ 4 26.02.03.РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc23}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ.03 РУП 23,24
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc24}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ1 26.02.03 РУП
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc25}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ПМ05
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc26}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Психология общения 26.02.03, РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc27}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Русский язык РУП 25 26.02.03 2023гп
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc28}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Техническая термодинамика и теплопередача 26.02.03, 2023 г.п.
                РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc29}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ТУС 26.02.03, РУП 25 2023 г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc30}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Физика 26.02.03, РУП 25 2023
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc31}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Физкультура 1 курс 26.02.03 РУП 25 2023 гп
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc32}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Химия 26.02.06, РУП 212023 г.п.
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>33</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc33}
                target={"_blank"}
                rel="noreferrer"
              >
                РП Электроника и электротехника 26.02.03. РУП 25
              </a>
            </td>
            <td></td>
          </tr>{" "}
          <tr>
            <td>34</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={doc34}
                target={"_blank"}
                rel="noreferrer"
              >
                РП ЭОП 26.02.03, РУП 25 2023 г.п.
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
