import { MonitoringDocsStore } from "../../../../../../store/monitoring-docs-store";
import { observer } from "mobx-react-lite";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Upload } from "antd";
import classes from "../style/monitoring.module.scss";
import { HrLine } from "../../../../../common";
import { useState } from "react";

export const AddDocForm = observer(({ educationLvl, category }) => {
  const { addMonitoringDocs, isLoadingMonitoringDocs } = MonitoringDocsStore;

  const [form] = Form.useForm();

  const [file, setFile] = useState(null);

  function handleSubmit(e) {
    const formData = new FormData();

    formData.append("title", `${form.getFieldValue("title")}`);
    formData.append("category", `${category}`);
    formData.append("file", file);
    formData.append("educationLvl", `${educationLvl}`);
    form
      .validateFields()
      .then(() => {
        addMonitoringDocs(formData);
        form.resetFields();
      })
      .catch((e) => {
        console.log("err", e);
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleBeforeUpload = (file) => {
    // Проверяем расширение файла
    const allowedExtensions = ["doc", "docx", "xls", "xlsx", "pdf"];
    const extension = file.name.split(".").pop().toLowerCase();

    // Если расширение файла не разрешено, останавливаем загрузку
    if (!allowedExtensions.includes(extension)) {
      message.error("Допустимое расширение файлов - doc, docx, xls, xlsx");

      return false; // Возвращает false, чтобы остановить загрузку
    }

    // Если расширение допустимо, устанавливаем состояние файла
    setFile(file);
    return true; // Возвращает true, чтобы продолжить загрузку
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={classes.addFormItemContainer}>
          <div className={classes.addFormItemInput}>
            <div className={classes.labelFormItem}>
              <span className={classes.redColor}>*</span> Укажите название
              документа:
            </div>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Введите название документа!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={classes.addFormItemBtn}>
            {" "}
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: "Прикрепите документ!",
                },
              ]}
              onChange={(e) => handleBeforeUpload(e.target.files[0])}
            >
              <Upload maxCount={1}>
                <Button icon={<UploadOutlined />}>
                  {isLoadingMonitoringDocs
                    ? "Загрузка..."
                    : "Прикрепить документ"}
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Отправить
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <HrLine />
    </>
  );
});
