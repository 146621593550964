import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import inform_o_chislen from "../../../../assets/pdf/educationPage/inf_o_chisl_ob.pdf";
import inform_o_chislen_in from "../../../../assets/pdf/educationPage/inf_o_chisl_ob_in.pdf";

export const InfoOChislenObuch = () => {
  return (
    <>
      {" "}
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduChislenEl"
            href={inform_o_chislen}
            target={"_blank"}
            rel="noreferrer"
          >
            Информация о численности обучающихся по реализуемым образовательным
            программам за счет бюджетных ассигнований федерального бюджета,
            бюджетов субъектов Российской Федерации, местных бюджетов и по
            договорам об образовании за счет средств физических и (или)
            юридических лиц на 01.10.2024г.
          </a>
        </li>{" "}
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduChislenEl"
            href={inform_o_chislen_in}
            target={"_blank"}
            rel="noreferrer"
          >
            По состоянию контингента обучающихся на 01.10.2024 года иностранные
            студенты отсутствуют
          </a>
        </li>
      </ul>
      {/* <TableWrapper
        title="Информация о численности обучающихся по реализуемым образовательным
        программам за счет бюджетных ассигнований федерального бюджета, бюджетов
        субъектов Российской Федерации, местных бюджетов и по договорам об
        образовании за счет средств физических и (или) юридических лиц на
        01.09.2024г."
      >
        <table>
          <tbody>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="3">
                Код
              </td>
              <td itemProp="eduName" rowSpan="3">
                Наименование специальности, направления подготовки, шифр и
                наименование научной специальности
              </td>
              <td itemProp="eduLevel" rowSpan="3">
                Уровень образования
              </td>
              <td itemProp="eduForm" rowSpan="3">
                Формы обучения
              </td>
              <td colSpan="8">
                Численность обучающихся за счет (количество человек):
              </td>
              <td itemProp="numberAll" rowSpan="3">
                Общая численность обучающихся
              </td>
            </tr>
            <tr itemProp="eduChislen">
              <td colSpan="2">бюджетных ассигнований федерального бюджета</td>
              <td colSpan="2">бюджетов субъектов Российской Федерации</td>
              <td colSpan="2">местных бюджетов </td>
              <td colSpan="2">средств физических и (или) юридических лиц</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="numberBF">всего</td>
              <td itemProp="numberBFF">в том числе иностранных граждан</td>
              <td itemProp="numberBR">всего</td>
              <td itemProp="numberBRF">в том числе иностранных граждан</td>
              <td itemProp="numberBM">всего</td>
              <td itemProp="numberBMF">в том числе иностранных граждан</td>
              <td itemProp="numberP">всего</td>
              <td itemProp="numberPF">в том числе иностранных граждан</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode">26.05.06</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel">Высшее образование. Специалитет</td>
              <td itemProp="eduForm">Заочная (Прием 2024)</td>
              <td itemProp="numberBF">10</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">28</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">38</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode">26.05.06</td>
              <td itemProp="eduName">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel">Высшее образование. Специалитет</td>
              <td itemProp="eduForm">Заочная (прием 2023)</td>
              <td itemProp="numberBF">-</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">35</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">35</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="3">
                23.02.01
              </td>
              <td itemProp="eduName" rowSpan="3">
                Организация перевозок и управление на транспорте
              </td>
              <td itemProp="eduLevel" rowSpan="3">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">очная (прием 2023)</td>
              <td itemProp="numberBF">22</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">3</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">25</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">очно-заочная</td>
              <td itemProp="numberBF">-</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">-</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBF">3</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">3</td>
            </tr>{" "}
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="3">
                23.02.01
              </td>
              <td itemProp="eduName" rowSpan="3">
                Организация перевозок и управление на транспорте
              </td>
              <td itemProp="eduLevel" rowSpan="3">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">очная (прием 2022)</td>
              <td itemProp="numberBF">19</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">2</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">21</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">очно-заочная</td>
              <td itemProp="numberBF">-</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">-</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">заочная</td>
              <td itemProp="numberBF">3</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">3</td>
            </tr>{" "}
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.01
              </td>
              <td itemProp="eduName" rowSpan="1">
                Организация перевозок и управление на транспорте
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">очная (прием 2021)</td>
              <td itemProp="numberBF">23</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">23</td>
            </tr>
            <tr itemProp="eduChislen">
              <td rowSpan="1">23.02.03</td>
              <td rowSpan="1">Судовождение</td>
              <td rowSpan="1">среднее профессиональное образование</td>
              <td itemProp="eduForm">очная (прием 2024)</td>
              <td itemProp="numberBF">75</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">2</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">77</td>
            </tr>
            <tr itemProp="eduChislen">
              <td rowSpan="2">23.02.03</td>
              <td rowSpan="2">Судовождение</td>
              <td rowSpan="2">среднее профессиональное образование</td>
              <td itemProp="eduForm">очная (прием 2023)</td>
              <td itemProp="numberBF">51</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">2</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">53</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">заочная (прием 2023)</td>
              <td itemProp="numberBF">-</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">22</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">22</td>
            </tr>
            <tr itemProp="eduChislen">
              <td rowSpan="2">23.02.03</td>
              <td rowSpan="2">Судовождение</td>
              <td rowSpan="2">среднее профессиональное образование</td>
              <td itemProp="eduForm">очная (прием 2022)</td>
              <td itemProp="numberBF">73</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">73</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">заочная (прием 2022)</td>
              <td itemProp="numberBF">5</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">22</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">27</td>
            </tr>
            <tr itemProp="eduChislen">
              <td rowSpan="1">23.02.03</td>
              <td rowSpan="1">Судовождение</td>
              <td rowSpan="1">среднее профессиональное образование</td>
              <td itemProp="eduForm">очная (прием 2021)</td>
              <td itemProp="numberBF">60</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">3</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">63</td>
            </tr>
            <tr itemProp="eduChislen">
              <td rowSpan="1">23.02.03</td>
              <td rowSpan="1">Судовождение</td>
              <td rowSpan="1">среднее профессиональное образование</td>
              <td itemProp="eduForm">очная (прием 2020)</td>
              <td itemProp="numberBF">42</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">2</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">44</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2024</td>
              <td itemProp="numberBF">50</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">1</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">51</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2023</td>
              <td itemProp="numberBF">50</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">50</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="2">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="2">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel" rowSpan="2">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2022</td>
              <td itemProp="numberBF">51</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">51</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduForm">Заочная, прием 2022</td>
              <td itemProp="numberBF">5</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">1</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">6</td>
            </tr>{" "}
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судовых энергетических установок
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2021</td>
              <td itemProp="numberBF">34</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">3</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">37</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2024</td>
              <td itemProp="numberBF">50</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">50</td>
            </tr>{" "}
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2023</td>
              <td itemProp="numberBF">53</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">53</td>
            </tr>
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2022</td>
              <td itemProp="numberBF">50</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">50</td>
            </tr>{" "}
            <tr itemProp="eduChislen">
              <td itemProp="eduCode" rowSpan="1">
                23.02.05
              </td>
              <td itemProp="eduName" rowSpan="1">
                Эксплуатация судового электрооборудования и средств автоматики
              </td>
              <td itemProp="eduLevel" rowSpan="1">
                среднее профессиональное образование
              </td>
              <td itemProp="eduForm">Очная, прием 2021</td>
              <td itemProp="numberBF">51</td>
              <td itemProp="numberBFF">-</td>
              <td itemProp="numberBR">-</td>
              <td itemProp="numberBRF">-</td>
              <td itemProp="numberBM">-</td>
              <td itemProp="numberBMF">-</td>
              <td itemProp="numberP">-</td>
              <td itemProp="numberPF">-</td>
              <td itemProp="numberAll">51</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper> */}
    </>
  );
};
